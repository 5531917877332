import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import './styles.css';
import { SplitButton } from 'primereact/splitbutton';
import  GestGemsysService from '../service/GestGemsysService';
import { ProgressSpinner } from 'primereact/progressspinner';
import { InputText } from 'primereact/inputtext';
import { Dialog } from "primereact/dialog";
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';

export class EditionPersonnelDocs extends Component {
    constructor() {
        super();
        this.state = {
            liste_data: [],
            liste_bulettin: [],
            liste_mission: [],
            selectedListes: [],
            selectedBulletin: null,
            selectedMission: null,
            showindicator: false,
            visible_dlg_bulletin: false,
            visible_dlg_mission: false,
            visible_dlg_attestation: false,
            liste_paie: [],
            liste_missions: [],
            selectedMission: null,
            liste_pers: [{ value: 'Tous', label: 'Personnel' }],
            selectedPaie: null,
            rmatrPers: null,
            fonctPers: '',
            items: [
                {
                    label: 'Liste générale PDF',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.conf_print('PDF');
                    }
                },
                {
                    label: 'Liste générale XLS',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.conf_print('XLS');
                    }
                },
                {
                    label: 'Badge',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.conf_print_badge('XLS');
                    }
                },
                {
                    label: 'Bulletins de paie',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.show_dlg_bulletin();
                    }
                },
                {
                    label: 'Ordres de mission',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.show_dlg_mission();
                    }
                },
                {
                    label: 'Attestation travail',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.show_dlg_attestation();
                    }
                }
            ],
        };
        this.conf_print = this.conf_print.bind(this);
        this.conf_print_badge = this.conf_print_badge.bind(this);
        this.onIndexTemplate = this.onIndexTemplate.bind(this);
        this.conf_print_bulletin = this.conf_print_bulletin.bind(this);
        this.show_dlg_bulletin = this.show_dlg_bulletin.bind(this);
        this.show_dlg_mission = this.show_dlg_mission.bind(this);
        this.show_dlg_attestation = this.show_dlg_attestation.bind(this);
        this.conf_print_attestation = this.conf_print_attestation.bind(this);

        this.conf_print_ordre_mission = this.conf_print_ordre_mission.bind(this);


        this.gemsysService = new GestGemsysService();
    }

    show_dlg_attestation() {
        this.setState({ visible_dlg_attestation: true });
    }
    show_dlg_bulletin() {
        this.setState({ visible_dlg_bulletin: true });
    }

    show_dlg_mission() {
        this.setState({ visible_dlg_mission: true });
    }

    onIndexTemplate(data, props) {
        return props.rowIndex + 1;
    }


    get_liste_personnel() {
        this.setState({ showindicator: true });
        this.gemsysService.getPersonnel().then(data => {
            this.setState({ showindicator: false });
            this.setState({ liste_data: data });
            if (data != null && data != undefined) {
                let liste = data.map(x => {
                    return { value: x.matrPers, label: x.prenomPers + " " + x.nomPers }
                });
                this.setState({ liste_pers: this.state.liste_pers.concat(liste) });
            }
        });
    }

    get_liste_data() {
        this.setState({
            showindicator: true,
        });
        this.gemsysService.get_liste_assignation().then(data => {
            this.setState({ liste_data: data, showindicator: false });
        });
    }

    conf_print(format) {
        this.gemsysService.generer_liste_personnel(format);
    }

    conf_print_attestation() {
        if (this.state.fonctPers == "" || this.state.fonctPers == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir la fonction' });
        } else {
            let ids = "";
            if (this.state.selectedListes.length > 0) {
                ids = this.state.selectedListes.map(x => {
                    return x.matrPers
                }).join("_");
            } else {
                ids = this.state.liste_data.map(x => {
                    return x.matrPers
                }).join("_");
            }
            this.gemsysService.generer_attestation_travail(ids, this.state.fonctPers);
        }
    }

    photoTemplate(rowData, column) {
        let fic = rowData.photo;
        if (fic === undefined || fic === null || fic === '') {
            fic = 'nophoto.png';
        }
        return <img src={this.gemsysService.download_file(fic, 'photos/')} alt='Photo' style={{ width: 45, height: 45 }} />
    }

    conf_print_badge() {
        let ids = "";
        if (this.state.selectedListes.length > 0) {
            ids = this.state.selectedListes.map(x => {
                return x.matrPers
            }).join("_");
        } else {
            ids = this.state.liste_data.map(x => {
                return x.matrPers
            }).join("_");
        }
        this.gemsysService.generer_badge_personnel(ids);
    }

    conf_print_bulletin(data) {
        this.gemsysService.generer_badge_personnel(data.codePaie);
    }
    conf_print_ordre_mission(data) {
        this.gemsysService.generer_ordre_mission(data.idMission);
    }
    componentDidMount() {
        this.get_liste_personnel();
    }

    get_liste_classe(codeEnseign) {
        this.gemsysService.getClasse().then(data => {
            if (data !== null && data !== undefined) {
                let liste = data.map(x => {
                    return { value: x.codeClasse, label: x.libClasse + " " + x.optClasse, codeEnseign: x.codeEnseign.codeEnseign }
                }).filter(function (el) {
                    return el.codeEnseign == codeEnseign;
                });
                this.setState({ liste_classes: liste });
            }
        });
    }

    actionTemplateBulletin(rowData) {
        return <Button onClick={() => this.conf_print_bulletin(rowData)} className="p-button-success" style={{ width: 25, height: 25 }} icon="pi pi-print" />
    }

    actionTemplateMission(rowData) {
        return <Button onClick={() => this.conf_print_ordre_mission(rowData)} className="p-button-success" style={{ width: 25, height: 25 }} icon="pi pi-print" />
    }

    render() {
        let header = <SplitButton label="Rechercher" style={{ width: '140px' }} onClick={(e) => this.get_liste_personnel()} icon="pi pi-search" model={this.state.items} />;
        let header_dp = <div className="grid">
            <div className="col-12 md:col-3">
                <Dropdown options={this.state.liste_pers} placeholder="Personnel" optionLabel="label" value={this.state.rmatrPers} onChange={(e) => this.setState({ rmatrPers: e.target.value, showindicator: true }, () => {
                    if(this.state.visible_dlg_bulletin==true){
                        this.gemsysService.get_liste_paies_by_criteria('Tous', this.state.rmatrPers, 'Tous', 'Tous').then(data => this.setState({ liste_paie: data, showindicator: false }));
                    }else{
                        this.gemsysService.get_liste_mission_by_pers(this.state.rmatrPers).then(data => this.setState({ liste_missions: data, showindicator: false  }));
                    }
                })} appendTo={document.body}
                    itemTemplate={(option) => {
                        if (!option.value) {
                            return option.label;
                        }
                        else {
                            return (
                                <div className="grid" >
                                    <div className="col-12 md:col-3">
                                        <label>{option.value}</label>
                                    </div>
                                    <div className="col-12 md:col-9">
                                        <label>{option.label}</label>
                                    </div>
                                </div>
                            );
                        }
                    }} autoWidth={false} filter={true} filterBy='label,value' filterMatchMode='contains' panelStyle={{ width: '400px' }} />
            </div>
            <div className="col-12 md:col-2">
                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
            </div>
        </div>


        return (
            <div className="p-fluid">
                <Toast ref={(el) => this.toast = el} />
                <Dialog header="Attestation de travail" visible={this.state.visible_dlg_attestation} style={{ width: '300px' }} modal={true} onHide={() => this.setState({ visible_dlg_attestation: false })}>
                    <label htmlFor="matricule">Fonction </label>
                    <InputText value={this.state.fonctPers} onChange={(e) => this.setState({ fonctPers: e.target.value })} />
                    <center>
                        <Button style={{ width: 140, marginTop: 10 }} label="Générer" icon="pi pi-check" onClick={(e) => this.conf_print_attestation()} />
                    </center>
                </Dialog>

                <Dialog header="Ordres de mission" visible={this.state.visible_dlg_mission} style={{ width: '70%' }} modal={true} onHide={() => this.setState({ visible_dlg_mission: false })}>
                    <DataTable value={this.state.liste_missions} style={{ minHeight: '500px' }} selectionMode="single" header={header_dp}
                        alwaysShowPaginator={false} onRowClick={e => this.setState({ selectedMission: e.data })} onRowSelect={e => this.setState({ selectedMission: e.data })}
                        responsive={true} selection={this.state.selectedMission} 
                        dataKey="idMission" className="p-datatable-gridlines" showGridlines responsiveLayout="scroll" paginator rows={10}>
                        <Column header="#" body={this.actionTemplateMission.bind(this)} style={{ width: '5%' }} />
                        <Column field="libelleMission" header="Libellé" style={{ width: '15%' }} filter={true} filterMatchMode='contains' sortable={true} />
                        <Column field="motifMission" header="Description" style={{ width: '20%' }} filter={true} filterMatchMode='contains' sortable={true} />
                        <Column field="lieuMission" header="Lieu" style={{ width: '15%' }} filter={true} filterMatchMode='contains' sortable={true} />
                        <Column field="dateDebutMission" body={(rowData, Column) => {
                            return <div>
                                <span>{rowData.dateDebutMission} - {rowData.dateFinMission}</span>
                            </div>
                        }} header="Periode" style={{ width: '20%' }} filter={true} filterMatchMode='contains' sortable={true} />
                        <Column field="fraisMission" body={(rowData, column) => {
                            return <div>
                                <span >{this.gemsysService.formaterValueSep(parseFloat(rowData.fraisMission))}</span>
                            </div>
                        }} header="Montant" style={{ width: '11%' }} filter={true} filterMatchMode='contains' sortable={true} />
                        <Column field="moyDeplMission" header="Moyen" style={{ width: '10%' }} filter={true} filterMatchMode='contains' sortable={true} />
                    </DataTable>
                </Dialog>
                <Dialog header="Bulletins" visible={this.state.visible_dlg_bulletin} style={{ width: '70%' }} modal={true} onHide={() => this.setState({ visible_dlg_bulletin: false })}>
                    <DataTable value={this.state.liste_paie} selectionMode="single" style={{ minHeight: '400px' }} 
                        onRowClick={e => this.setState({ selectedPaie: e.data })} onRowSelect={e => this.setState({ selectedPaie: e.data })} header={header_dp}
                        selection={this.state.selectedPaie} dataKey="idPaie" className="p-datatable-gridlines" showGridlines responsiveLayout="scroll" paginator rows={50}>
                        <Column header="#" body={this.actionTemplateBulletin.bind(this)} style={{ width: '5%' }} />
                        <Column field="libPaie" header="Libelle" style={{ width: '22%' }} filter={true} filterMatchMode='contains' sortable={true} />
                        <Column field="moisPaie" header="Mois" style={{ width: '7%' }} filter={true} filterMatchMode='contains' sortable={true} />
                        <Column field="salaireB" body={(rowData, column) => {
                            return <div>
                                <span style={{ color: 'black' }}>{this.gemsysService.formaterValueSep(parseFloat(rowData.salaireB))}</span>
                            </div>
                        }} header="Sal. brut" style={{ width: '10%' }} filter={true} filterMatchMode='contains' sortable={true} />
                        <Column field="mtPaie" body={(rowData, column) => {
                            return <div>
                                <span style={{ color: 'green' }}>{this.gemsysService.formaterValueSep(parseFloat(rowData.mtPaie))}</span>
                            </div>
                        }} header="Mt Payé" style={{ width: '10%' }} filter={true} filterMatchMode='contains' sortable={true} />
                        <Column field="mtBon" body={(rowData, column) => {
                            return <div>
                                <span style={{ color: 'red' }}>{this.gemsysService.formaterValueSep(parseFloat(rowData.mtBon))}</span>
                            </div>
                        }} header="Mt bon" style={{ width: '10%' }} filter={true} filterMatchMode='contains' sortable={true} />
                    </DataTable>
                </Dialog>

                <div className="grid">
                    <div className="col-12">
                        <div className="card card-w-title">
                            <DataTable value={this.state.liste_data} style={{ minHeight: '600px' }}  ref={(el) => { this.dt = el; }}
                                onSelectionChange={e => this.setState({ selectedListes: e.value })} selection={this.state.selectedListes} header={header}
                                dataKey="matrPers" className="p-datatable-gridlines" showGridlines responsiveLayout="scroll" paginator rows={10}>
                                <Column selectionMode="multiple" style={{ width: '4%' }} />
                                <Column body={this.onIndexTemplate.bind(this)} header="No" style={{ width: '4%' }} />
                                <Column body={this.photoTemplate.bind(this)} header="Photo" style={{ width: '5%' }} />
                                <Column field="matrPers" header="Matricule" sortable={true} style={{ width: '10%' }} filter={true} filterMatchMode='contains' />
                                <Column field="nomPers" sortable={true} filterMatchMode='contains' header="Nom" style={{ width: '10%' }} filter={true}  />
                                <Column field="prenomPers" sortable={true} filterMatchMode='contains' header="Prénom" style={{ width: '20%' }} filter={true}  />
                                <Column field="sexe" header="Sexe" sortable={true} style={{ width: '7%' }} filter={true} filterMatchMode='contains' />
                                <Column field="categorie" header="Catégorie" sortable={true} style={{ width: '10%' }} filter={true} filterMatchMode='contains' />
                                <Column field="telpers" header="Contacts" sortable={true} style={{ width: '9%' }} filter={true} filterMatchMode='contains' />
                                <Column field="quartierpers" header="Quartier" sortable={true} style={{ width: '13%' }} filter={true} filterMatchMode='contains' />
                                <Column field="estEnFonction" header="Actif" style={{ width: '8%' }} sortable={true} filter={true} filterMatchMode='contains' />
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}