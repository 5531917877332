import React, { Component } from 'react';
import GestGemsysService from '../service/GestGemsysService';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import moment from 'moment';
import { InputTextarea } from 'primereact/inputtextarea';
import { ProgressSpinner } from 'primereact/progressspinner';

export class FormEvent extends Component {
    constructor() {
        super();
        this.state = {
            pd1: new Date(),
            pd2: new Date(),
            libEvent: '',
            descEvent: '',
            debEvent: new Date(),
            finEvent: '',
            etatEvent: 'Activé',
            msgEvent: '',
            categorie: 'Académique',
            file: null,
            liste_events: [],
            selectedEvent: null,
            showindicator: false,
            msg: '',
            modif: false,
            visible: false
        };
        this.edit_event = this.edit_event.bind(this);
        this.conf_delete_event = this.conf_delete_event.bind(this);
        this.gestGemsysService = new GestGemsysService();
    }

    componentDidMount() {
        this.gestGemsysService.get_liste_events().then(data => this.setState({ liste_events: data }));
    }

    resultat(code, contenu, id) {
        if (code == '200') {
            this.toast.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_event();
            this.componentDidMount();
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: contenu + ' \n' + id });
        }
        this.setState({showindicator: false});
    }

    edit_event(data) {
        if (data != null && data != undefined) {
            this.setState({ idEvent: data.idEvent });
            this.setState({ dateEvent: data.dateEvent });
            this.setState({ libEvent: data.libEvent });
            this.setState({ descEvent: data.descEvent });

            this.setState({ debEvent: new Date(moment(data.debEvent, 'DD/MM/YYYY').format('YYYY-MM-DD')) });
            this.setState({ finEvent: new Date(moment(data.finEvent, 'DD/MM/YYYY').format('YYYY-MM-DD')) });

            this.setState({ etatEvent: data.etatEvent });
            this.setState({ msgEvent: data.msgEvent });
            this.setState({ typeEvent: data.typeEvent });
            this.setState({ categorie: data.categorie });
            this.setState({ modif: true });
            this.setState({ selectedEvent: data });
            window.scrollTo(0, 0);
        }
    }

    annule_event() {
        this.setState({ idEvent: '' });
        this.setState({ libEvent: '' });
        this.setState({ descEvent: '' });
        this.setState({ debEvent: new Date() });
        this.setState({ finEvent: '' });
        this.setState({ etatEvent: 'Activé' });
        this.setState({ denregEvent: new Date() });
        this.setState({ msgEvent: '' });
        this.setState({ typeEvent: 'Périodique' });
        this.setState({ categorie: 'Académique' });
        this.setState({ modif: false });
        this.forceUpdate();
    }

    enreg_event() {
        if (this.state.libEvent == "" || this.state.categorie == "" || this.state.descEvent == "" || this.state.debEvent == ""|| this.state.finEvent == "") {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else {
            let upload = false;
            let nomfic = this.state.photo;
            if (this.state.file != null && this.state.file != undefined) {
                let name = this.state.file.name;
                nomfic = 'E'+this.gestGemsysService.get_userconnected().numUtil + moment(new Date()).format('DDMMYYYYHHmmss') + '.' + name.slice((name.lastIndexOf(".") - 1 >>> 0) + 2);
                upload = true;
            }
            this.setState({showindicator: true});
            if (this.state.modif == false) {
                let val = {
                    idEvent: '0',
                    libEvent: this.state.libEvent,
                    descEvent: this.state.descEvent,
                    debEvent: moment(this.state.debEvent, 'DD/MM/YYYY').format('DD/MM/YYYY'),
                    finEvent: moment(this.state.finEvent, 'DD/MM/YYYY').format('DD/MM/YYYY'),
                    etatEvent: this.state.etatEvent,
                    msgEvent: this.state.msgEvent,
                    typeEvent: 'Inconnu',
                    categorie: this.state.categorie,
                    anneeEvent: this.gestGemsysService.get_rannee()
                }
                this.gestGemsysService.AddEvenements(val).then(data => {
                    if (data != undefined && data != null) {
                        if (upload === true) {
                            this.fileUpload(this.state.file, nomfic);
                        }
                        this.resultat(data.code, data.contenu, data.id);
                    }
                });
            } else {
                let val = this.state.selectedEvent;
                val.libEvent = this.state.libEvent;
                val.descEvent = this.state.descEvent;
                val.etatEvent = this.state.etatEvent;
                val.finEvent = moment(this.state.finEvent, 'DD/MM/YYYY').format('DD/MM/YYYY');
                val.debEvent = moment(this.state.debEvent, 'DD/MM/YYYY').format('DD/MM/YYYY');
                val.msgEvent = this.state.msgEvent;
                val.categorie = this.state.categorie;
                this.gestGemsysService.UpdateEvenements(val).then(data => {
                    if (data != undefined && data != null) {
                        if (upload === true) {
                            this.fileUpload(this.state.file, nomfic);
                        }
                        this.resultat(data.code, data.contenu);
                    }
                });
            }
        }
    }

    fileUpload(file, namefile) {
        this.gestGemsysService.upload_file(file, namefile, 'fics').then(data => {
            if (data.code == '200') {
                this.toast.show({ severity: 'success', summary: 'Success', detail: 'Chargement effectué avec succès' });
            } else {
                this.toast.show({ severity: 'error', summary: 'Error', detail: 'Echec upload' });
            }
        });
    }

    

    actionTemplate(rowData) {
        return <div className="grid" style={{ width: 90 }}>
            <div className="col"><Button onClick={() => this.edit_event(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
            <div className="col"><Button onClick={() => this.conf_delete_event(rowData)} className="p-button-danger" style={{ width: 25, height: 25,marginLeft: -20 }} icon="pi pi-times" /></div>
        </div>
    }

    delete_event() {
        if (this.state.selectedEvent == null || this.state.selectedEvent == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.setState({showindicator: true});
            this.gestGemsysService.DeleteEvenements(this.state.selectedEvent).then(data => this.resultat(data.code, data.contenu));
        }
    }

    conf_delete_event(data) {
        if (data !== null && data !== undefined) {
            this.setState({ selectedEvent: data });
            this.setState({ msg: data.idEvent });
            this.setState({ visible: true });
        }
    }
    render() {
       
        let categories = [
            { label: 'Académique', value: 'Académique' },
            { label: 'Mariage', value: 'Mariage' },
            { label: 'Recouvrement', value: 'Recouvrement' },
            { label: 'Bourse', value: 'Bourse' },
            { label: 'Baptème', value: 'Baptème' },
            { label: 'Culture et sport', value: 'Culture et sport' }
        ];
        let etats = [
            { label: 'Activé', value: 'Activé' },
            { label: 'Désactive', value: 'Désactive' }
        ];
        const dialogFooter = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible: false });
                    this.delete_event();
                }} label="Oui" loading={this.state.showindicator}/>
                <Button icon="pi pi-times" onClick={() => this.setState({ visible: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );
        return (
            <div className="p-fluid">
                <Toast ref={(el) => this.toast = el} />
                <Dialog header="Confirmation" visible={this.state.visible} style={{ width: '250px' }} modal={true} footer={dialogFooter} onHide={() => this.setState({ visible: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>
                <div className="grid">
                    <div className="col-12">
                        <div className="card card-w-title">
                            <div className="grid " >
                                <div className="col-12 md:col-3">
                                    <label htmlFor="libEvent">Libellé *</label>
                                </div>
                                <div className="col-12 md:col-3" >
                                    <InputText id="libEvent" value={this.state.libEvent} onChange={(e) => this.setState({ libEvent: e.target.value })} />
                                </div>
                                <div className="col-12 md:col-3">
                                    <label htmlFor="categorie">Catégorie *</label>
                                </div>
                                <div className="col-12 md:col-3" >
                                    <Dropdown options={categories} value={this.state.categorie} onChange={(e) => this.setState({ categorie: e.target.value })} autoWidth={false} />
                                </div>
                                <div className="col-12 md:col-3">
                                    <label htmlFor="descEvent">Description *</label>
                                </div>
                                <div className="col-12 md:col-3" >
                                    <InputTextarea id="descEvent" placeholder="Saisir la description" value={this.state.descEvent} onChange={(e) => this.setState({ descEvent: e.target.value })} />
                                </div>
                                <div className="col-12 md:col-3">
                                    <label htmlFor="etatEvent">Etat *</label>
                                </div>
                                <div className="col-12 md:col-3" >
                                    <Dropdown options={etats} value={this.state.etatEvent} onChange={(e) => this.setState({ etatEvent: e.target.value })} autoWidth={false} />
                                </div>
                                <div className="col-12 md:col-3">
                                    <label htmlFor="debEvent">Date début *</label>
                                </div>
                                <div className="col-12 md:col-3">
                                    <Calendar dateFormat="dd/mm/yy" value={this.state.debEvent} onChange={(e) => this.setState({ debEvent: e.target.value })}></Calendar>
                                </div>
                                <div className="col-12 md:col-3">
                                    <label htmlFor="finEvent">Date fin *</label>
                                </div>
                                <div className="col-12 md:col-3">
                                    <Calendar dateFormat="dd/mm/yy" value={this.state.finEvent} onChange={e => this.setState({ finEvent: e.target.value })}></Calendar>
                                </div>
                                <div className="col-12 md:col-3">
                                    <label htmlFor="msgEvent">Message *</label>
                                </div>
                                <div className="col-12 md:col-3" >
                                    <InputTextarea id="msgEvent" placeholder="Saisir le message pas plus de 160 caractères" value={this.state.msgEvent} rows={3} onChange={(e) => this.setState({ msgEvent: e.target.value })} />
                                </div>
                                <div className="col-12 md:col-3">
                                    <label htmlFor="img">Image *</label>
                                </div>
                                <div className="col-12 md:col-3" >
                                    <input type="file" onChange={e => this.setState({ file: e.target.files[0] })} />
                                </div>
                            </div>

                            <center>
                                <div className="grid" style={{ width: 300, marginTop: '0%' }}>
                                    <div className="col"><Button label="Annuler" icon="pi pi-ban" onClick={() => this.annule_event()} /></div>
                                    <div className="col"><Button label="Valider" loading={this.state.showindicator} icon="pi pi-check" onClick={() => this.enreg_event()} /></div>
                                </div>
                                <br />
                                    {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                            </center>


                            <DataTable value={this.state.liste_events} selectionMode="single" style={{ minHeight: '400px' }}
                                alwaysShowPaginator={false} onRowClick={e => this.setState({ selectedEvent: e.data })} onRowSelect={e => this.setState({ selectedEvent: e.data })}
                                selection={this.state.selectedEvent} dataKey="idEvent" className="p-datatable-gridlines" showGridlines responsiveLayout="scroll" paginator rows={20}>
                                <Column header="Actions" body={this.actionTemplate.bind(this)} style={{ width: '7%' }} />
                                <Column field="dateEvent"header="Date" style={{ width: '8%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="libEvent" header="Libellé" style={{ width: '20%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="categorie" header="Catégorie" style={{ width: '11%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="msgEvent" header="Message" style={{ width: '30%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="etatEvent" header="Etat" style={{ width: '7%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="debEvent" header="Début" style={{ width: '9%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="finEvent" header="Fin" style={{ width: '9%' }} filter={true} filterMatchMode='contains' sortable={true} />
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}