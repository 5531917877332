import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column'
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { ProgressSpinner } from 'primereact/progressspinner';
import GestGemsysService from '../service/GestGemsysService';
export class FormComptesUsers extends Component {
    constructor() {
        super();
        this.state = {
            rEnseignants: [],
            rSemestres: [],
            rMatieres: [],
            liste_gerer: [],
            liste_enseignement: [],
            rJours: [
                { label: 'Jour', value: ' ' },
                { label: 'Lundi', value: 'Lundi' },
                { label: 'Mardi', value: 'Mardi' },
                { label: 'Mercredi', value: 'Mercredi' },
                { label: 'Jeudi', value: 'Jeudi' },
                { label: 'Vendredi', value: 'Vendredi' },
                { label: 'Samedi', value: 'Samedi' },
                { label: 'Dimmanche', value: 'Dimmanche' }
            ],
            numUtil: "",
            compte: "",
            mp: "0000",
            profilu: "Administrateur",
            activer: "Non",
            ch: 1,
            denregu: new Date(),
            matricule: null,
            typecmpte: "PERSONNEL",
            iduser: "",
            liste_comptes: [],
            selectedCompte: null,
            selectedGerer: null,
            personnels: null,
            codeEnseign: null,
            msg: '',
            modif: false,
            visible: false,
            visible_dlg_gerer: false,
            showindicator: false

        };
        this.edit_compte = this.edit_compte.bind(this);
        this.conf_delete_compte = this.conf_delete_compte.bind(this);
        this.show_dlg_gerer = this.show_dlg_gerer.bind(this);
        this.gestGemsysService = new GestGemsysService();
    }

    componentDidMount() {
        this.get_liste_enseignement();
        this.get_liste_data();
        this.get_liste_personnel();
    }


    show_dlg_gerer(data) {
        if (data !== null && data !== undefined) {
            this.setState({ selectedCompte: data }, () => {
                this.get_liste_gerer();
                this.setState({ visible_dlg_gerer: true });
            });
        }
    }


    get_liste_data() {
        this.setState({ showindicator: true });
        this.gestGemsysService.getUser().then(data => this.setState({ liste_comptes: data, showindicator: false }));
    }

    get_liste_personnel() {
        this.setState({ showindicator: true });
        this.gestGemsysService.getPersonnel().then(data => this.setState({ personnels: data, showindicator: false }));
    }

    get_liste_gerer() {
        this.setState({ showindicator: true });
        this.gestGemsysService.get_liste_gerer_enseignement(this.state.selectedCompte.numUtil).then(data => this.setState({ liste_gerer: data, showindicator: false }));
    }

    get_liste_enseignement() {
        this.setState({ showindicator: true });
        this.gestGemsysService.getEnseignement().then(data => {
            this.setState({ showindicator: false });
            if (data !== null && data !== undefined && data !== '') {
                if (data.length > 0) {
                    let liste = data.map(x => {
                        return { value: x.codeEnseign, label: x.libEnseign }
                    });
                    this.setState({ liste_enseignement: liste });
                }
            }
        });
    }

    resultat(code, contenu) {
        if (code == '200') {
            this.toast.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_compte();
            this.get_liste_data();
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({ showindicator: false });
    }

    resultat_gerer(code, contenu) {
        if (code == '200') {
            this.toast.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.get_liste_gerer();
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({ showindicator: false });
    }

    edit_compte(data) {
        if (data != null && data != undefined) {
            this.setState({ numUtil: data.numUtil });
            this.setState({ compte: data.compte });
            this.setState({ profilu: data.profilu });
            this.setState({ activer: data.activer });
            this.setState({ typecmpte: data.typecmpte });
            this.setState({ modif: true });
            this.setState({ selectedCompte: data });
            window.scrollTo(0, 0);
        }
    }

    annule_compte() {
        this.setState({ numUtil: "" });
        this.setState({ compte: "" });
        this.setState({ profilu: "" });
        this.setState({ activer: "" });
        this.setState({ matricule: "" });
        this.setState({ typecmpte: "" });
        this.setState({ modif: false });
        this.forceUpdate();
    }

    enreg_compte() {
        if (this.state.compte == "") {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else {
            if (this.state.modif == false) {
                if (this.state.matricule == "" || this.state.matricule == null) {
                    this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez choisir le tuteur du compte' });
                    return;
                }
                let compteuser = {
                    numUtil: this.state.numUtil,
                    compte: this.state.compte,
                    mp: '0000',
                    profilu: this.state.profilu,
                    activer: 'Oui',
                    ch: 1,
                    matricule: this.state.matricule,
                    typecmpte: this.state.typecmpte,
                    iduser: this.state.iduser
                };
                this.setState({ showindicator: true });
                this.gestGemsysService.AddUser(compteuser).then(data => this.resultat(data.code, data.contenu));
            } else {
                let compteuser = this.state.selectedCompte;
                compteuser.compte = this.state.compte;
                compteuser.profilu = this.state.profilu;
                this.setState({ showindicator: true });
                this.gestGemsysService.UpdateUser(compteuser).then(data => this.resultat(data.code, data.contenu));
            }
        }
    }


    enreg_gerer() {
        if (this.state.codeEnseign == null) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez renseigner les champs obligatoires' });
        } else {
            let formData = {
                idGerer: '0',
                codeEnseign: { codeEnseign: this.state.codeEnseign },
                idUser: this.state.selectedCompte,
            };
            this.setState({ showindicator: true });
            this.gestGemsysService.enreg_gerer_enseignement(formData).then(data => this.resultat_gerer(data.code, data.contenu));
        }
    }


    actionTemplate(rowData) {
        let icon = "";
        if (rowData.activer == "Oui") {
            icon = "pi pi-lock-open";
        } else {
            icon = "pi pi-lock";
        }
        return <div className="grid" style={{ width: 160 }}>
            <div className="col"><Button onClick={() => this.edit_compte(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
            <div className="col"><Button loading={this.state.showindicator} onClick={() => this.conf_delete_compte(rowData)} className="p-button-danger" style={{ width: 25, height: 25, marginLeft: -20 }} icon="pi pi-times" /></div>
            <div className="col"><Button loading={this.state.showindicator} onClick={() => this.activer_compte(rowData)} className="p-button-warning" style={{ width: 25, height: 25, marginLeft: -20 }} icon={icon} /></div>
            <div className="col"><Button onClick={() => this.show_dlg_gerer(rowData)} className="p-button-info" style={{ width: 25, height: 25, marginLeft: -20 }} icon="pi pi-sitemap" /></div>

        </div>
    }

    actionTemplate_gerer(rowData, column) {
        return <Button title="Supprimer" onClick={(e) => this.delete_gerer(rowData)} className="p-button-danger" style={{ width: 25, height: 25 }} icon="pi pi-times" />
    }

    delete_gerer(rowData) {
        this.setState({ showindicator: true });
        this.gestGemsysService.delete_gerer_enseignement(rowData).then(data => this.resultat_gerer(data.code, data.contenu));
    }

    delete_compte_user() {
        if (this.state.selectedCompte == null || this.state.selectedCompte == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.setState({ showindicator: true });
            this.gestGemsysService.DeleteUser(this.state.selectedCompte).then(data => this.resultat(data.code, data.contenu));
        }
    }
    activer_compte(data) {
        if (data !== null && data !== undefined) {
            if (data.activer == "Oui") {
                data.activer = "Non";
                this.setState({ showindicator: true });
                this.gestGemsysService.UpdateUser(data, data.numUtil).then(data => this.resultat(data.code, data.contenu));
            } else {
                data.activer = "Oui";
                this.setState({ showindicator: true });
                this.gestGemsysService.UpdateUser(data, data.numUtil).then(data => this.resultat(data.code, data.contenu));
            }
        }
    }
    conf_delete_compte(data) {
        if (data !== null && data !== undefined) {
            this.setState({ selectedCompte: data });
            this.setState({ msg: data.numUtil });
            this.setState({ visible: true });
        }
    }
    render() {
        let listepersonnels = null;
        if (this.state.personnels != null && this.state.personnels != undefined) {
            listepersonnels = this.state.personnels.map(x => {
                return { value: x.matrPers, label: x.prenomPers + " " + x.nomPers, categoriePers: x.categorie }
            });
        }
        let profiles = [];

        if (this.gestGemsysService.get_userconnected().idEtabl.typeEtabl == 'Université') {
            profiles = [
                { label: 'Administrateur', value: 'Administrateur' },
                { label: 'Assistant Chargé scolarité', value: 'Assistant Chargé scolarité' },
                { label: 'Assistant Comptable', value: 'Assistant Comptable' },
                { label: 'Chargé scolarité', value: 'Chargé scolarité' },
                { label: 'Chef departement', value: 'Chef departement' },
                { label: 'Comptable', value: 'Comptable' },
                { label: 'Doyen faculté', value: 'Doyen faculté' },
                { label: 'Guest', value: 'Guest' },
                { label: 'Recteur', value: 'Recteur' },
                { label: 'Surveillant', value: 'Surveillant' },
                { label: 'Vice Recteur', value: 'Vice Recteur' },
            ];
        } else if (this.gestGemsysService.get_userconnected().idEtabl.typeEtabl == 'Pré-universitaire') {
            profiles = [
                { label: 'Administrateur', value: 'Administrateur' },
                { label: 'Assistant Chargé scolarité', value: 'Assistant Chargé scolarité' },
                { label: 'Assistant Comptable', value: 'Assistant Comptable' },
                { label: 'Assistant RE', value: 'Assistant RE' },
                { label: 'Chargé scolarité', value: 'Chargé scolarité' },
                { label: 'Comptable', value: 'Comptable' },
                { label: 'DG', value: 'DG' },
                { label: 'DGE', value: 'DGE' },
                { label: 'Guest', value: 'Guest' },
                { label: 'Responsable enseignement', value: 'Responsable enseignement' },
                { label: 'Surveillant', value: 'Surveillant' },
            ];
        } else {
            profiles = [
                { label: 'Administrateur', value: 'Administrateur' },
                { label: 'Assistant Chargé scolarité', value: 'Assistant Chargé scolarité' },
                { label: 'Assistant Comptable', value: 'Assistant Comptable' },
                { label: 'Chargé scolarité', value: 'Chargé scolarité' },
                { label: 'Comptable', value: 'Comptable' },
                { label: 'DG', value: 'DG' },
                { label: 'DGE', value: 'DGE' },
                { label: 'Guest', value: 'Guest' },
                { label: 'Responsable enseignement', value: 'Responsable enseignement' },
                { label: 'Surveillant', value: 'Surveillant' },
            ];
        }

        const dialogFooter = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible: false });
                    this.delete_compte_user();
                }} label="Oui" loading={this.state.showindicator} />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );
        return (
            <div className="p-fluid">
                <Toast ref={(el) => this.toast = el} />
                <Dialog header="Confirmation" visible={this.state.visible} style={{ width: '250px' }} modal={true} footer={dialogFooter} onHide={() => this.setState({ visible: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>
                <Dialog header="Rattachements" visible={this.state.visible_dlg_gerer} style={{ width: '550px' }} modal={true} onHide={(e) => this.setState({ visible_dlg_gerer: false })}>
                    <div className="grid">
                        <div className="col-12">
                            <div className="card card-w-title" style={{ height: 350 }}>
                                <div className="p-fluid p-formgrid grid">
                                    <div className="p-field col-12 md:col-3">
                                        <label htmlFor="profile">{this.gestGemsysService.get_userconnected().idEtabl.typeEtabl == 'Université' ? 'Département' : 'Enseignement'} *</label>
                                    </div>
                                    <div className="p-field col-12 md:col-6">
                                        <Dropdown value={this.state.codeEnseign} options={this.state.liste_enseignement} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ codeEnseign: e.value })} autoWidth={false} />
                                    </div>
                                    <div className="p-field col-12 md:col-3">
                                        <Button label="Ajouter" loading={this.state.showindicator} style={{ width: 100 }} icon="pi pi-check" onClick={(e) => this.enreg_gerer()} />
                                    </div>

                                </div>
                                <DataTable value={this.state.liste_gerer} selectionMode="single"
                                    dataKey="idGerer" className="p-datatable-gridlines" showGridlines
                                    onRowClick={e => this.setState({ selectedGerer: e.data })} onRowSelect={e => this.setState({ selectedGerer: e.data })}
                                    responsive={true} selection={this.state.selectedGerer} paginator rows={5} alwaysShowPaginator={false} >
                                    <Column header="#" body={this.actionTemplate_gerer.bind(this)} style={{ width: '10%' }} />
                                    <Column field="codeEnseign.libEnseign" header="Nom" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '90%' }} />
                                </DataTable>
                            </div>
                        </div>
                    </div>
                </Dialog>
                <div className="grid">
                    <div className="col-12">
                        <div className="card card-w-title">
                            <div className="p-fluid p-formgrid grid">
                                <div className="p-field col-12 md:col-4">
                                    <label htmlFor="firstname2">Login *</label>
                                    <InputText id="compte" value={this.state.compte} onChange={(e) => this.setState({ compte: e.target.value })} />
                                </div>

                                <div className="p-field col-12 md:col-4">
                                    <label htmlFor="firstname2">Profil *</label>
                                    <Dropdown options={profiles} value={this.state.profilu} onChange={(e) => this.setState({ profilu: e.target.value })} autoWidth={false} filter={true} filterBy='label' filterMatchMode='contains' />
                                </div>

                                <div className="p-field col-12 md:col-4">
                                    <label htmlFor="firstname2">Titulaire *</label>
                                    <Dropdown options={listepersonnels} filter={true} filterBy='label' filterMatchMode='contains' value={this.state.matricule} optionLabel="label" onChange={(e) => this.setState({ matricule: e.target.value })} autoWidth={false} />
                                </div>
                            </div>

                            <center>
                                <div className="grid" style={{ width: 300, marginTop: '0%' }}>
                                    <div className="col"><Button label="Annuler" icon="pi pi-ban" onClick={() => this.annule_compte()} /></div>
                                    <div className="col"><Button label="Valider" loading={this.state.showindicator} icon="pi pi-check" onClick={() => this.enreg_compte()} /></div>
                                </div>
                            </center>

                            <DataTable value={this.state.liste_comptes} style={{ minHeight: '500px' }} selectionMode="single"
                                alwaysShowPaginator={false} onRowClick={e => this.setState({ selectedCompte: e.data })} onRowSelect={e => this.setState({ selectedCompte: e.data })}
                                selection={this.state.selectedCompte} dataKey="numUtil" className="p-datatable-gridlines" showGridlines responsiveLayout="scroll" paginator rows={50}>
                                <Column header="Action" body={this.actionTemplate.bind(this)} style={{ width: '14%' }} />
                                <Column field="compte" header="Compte" style={{ width: '15%' }} filter={true} filterMatchMode='contains' />
                                <Column field="profilu" header="Profil" style={{ width: '15%' }} filter={true} filterMatchMode='contains' />
                                <Column field="matricule" header="Matricule" style={{ width: '12%' }} filter={true} filterMatchMode='contains' />
                                <Column field="nomTuteur" body={(rowData) => {
                                    return <div>
                                        <span>{rowData.nomTuteur}</span>
                                    </div>
                                }} header="Tuteur" style={{ width: '25%' }} filter={true} filterMatchMode='contains' />
                                <Column field="typecmpte" body={(rowData) => {
                                    return <div>
                                        <span>{rowData.typecmpte}</span>
                                    </div>
                                }} header="Type" tyle={{ width: '10%' }} filter={true} filterMatchMode='contains' />
                                <Column field="activer" header="Activé" style={{ width: '10%' }} filter={true} filterMatchMode='contains' />
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}