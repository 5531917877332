import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';
import GestGemsysService from '../service/GestGemsysService';
import './styles.css';
import { SplitButton } from 'primereact/splitbutton';
import { ProgressSpinner } from 'primereact/progressspinner';

export class EditionResultatSondage extends Component {
    constructor() {
        super();
        this.state = {
            ridprog: 'Tous',
            rcible: 'Tous',
            liste_programme: [],
            liste_cible: [],
            liste_data: [],
            libelle: '',
            msg: '',
            items: [
                {
                    label: 'Format PDF',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.conf_print('PDF');
                    }
                },
                {
                    label: 'Format XLS',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.conf_print('XLS');
                    }
                },
                {
                    label: 'Format CSV',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.dt.exportCSV();
                    }
                }
            ],
            list: [
                { name: "Fortement d’accord", key: 4 },
                { name: "Plutôt d’accord", key: 3 },
                { name: "Plutôt en désaccord", key: 2 },
                { name: "Fortement en désaccord", key: 1 },
                { name: "Non notée", key: 0 },
            ],
            showindicator: false,
        };
        this.gemsysservice = new GestGemsysService();
        this.conf_print = this.conf_print.bind(this);
    }

    conf_print(format) {
        if (this.state.ridprog == "" || this.state.ridprog == "Tous" || this.state.ridprog == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner un programme' });
        } else {
            this.gemsysservice.generer_resultat_sondage(this.state.ridprog , format);
        }
    }
    
    componentDidMount() {
        this.get_liste_progeval();
    }

    get_liste_progeval() {
        this.gemsysservice.getProgEval().then(data => {
            if (data != null && data != undefined && data.length > 0) {
                let liste = data.map(x => {
                    return { value: x.idProg, label: x.libProg }
                });
                this.setState({ liste_programme: liste });
            }
        });
    }

    get_liste_data() {
        this.setState({ showindicator: true });
        this.gemsysservice.get_liste_eval_sondage(this.state.ridprog, 'Tous').then(data => {
            this.setState({ liste_data: data, showindicator: false });
        });
    }

    render() {
        return (
            <div className="p-fluid">
                <Toast ref={(el) => this.toast = el} />
                <i className="pi pi-search"></i>
                <span style={{ fontSize: 14, marginLeft: 10, color: '#999999' }}>Options de recherche</span>
                <hr />
                <div className="col-12">
                    <div className="grid">
                        <div className="col-12 md:col-3">
                            <Dropdown value={this.state.ridprog} placeholder='Programme' options={[{ value: 'Tous', label: 'Programme' }].concat(this.state.liste_programme)} onChange={(e) => this.setState({ ridprog: e.target.value })} autoWidth={false} filter filterBy='label' filterMatchMode='contains' panelStyle={{ width: "400px" }} />
                        </div>
                        <div className='col-12 md:col-3'>
                            <SplitButton label="Rechercher" style={{ width: '170px' }} onClick={(e) => this.get_liste_data()} icon="pi pi-search" model={this.state.items} />
                        </div>
                        <div className='col-12 md:col-2'>
                            {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                        </div>
                    </div>
                    <DataTable value={this.state.liste_data} alwaysShowPaginator={false} paginator={true} rows={50} paginatorPosition="top" ref={(el) => { this.dt = el; }}
                        dataKey="idEval" className="p-datatable-gridlines" showGridlines responsiveLayout="scroll" style={{ minHeight: '600px' }} >
                        <Column field="dateEval" header="Date" style={{ width: '8%' }} filter={true} filterMatchMode='contains' sortable={true} />
                        <Column field="idProgrammer.idProg.libProg" header="Programme" style={{ width: '15%' }} filter={true} filterMatchMode='contains' sortable={true} />
                        <Column field="idProgcible.matrCible.prenomPers" body={(rowData, column) => {
                            return <div>
                                <span> {rowData.idProgcible.matrCible.prenomPers + " " + rowData.idProgcible.matrCible.nomPers} </span>
                            </div>
                        }} header="Personnel" style={{ width: '20%' }} filter={true} filterMatchMode='contains' sortable={true} />
                        <Column field="idProgrammer.idQuest.libQuest" header="Questionnaire" style={{ width: '30%' }} filter={true} filterMatchMode='contains' sortable={true} />
                        <Column field="repEval" header="Note" style={{ width: '7%' }} filter={true} filterMatchMode='contains' sortable={true} />
                        <Column field="cmtEval" header="Commentaire" style={{ width: '20%' }} filter={true} filterMatchMode='contains' sortable={true} />
                    </DataTable>
                </div>
            </div>
        );
    }
}