import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import GestGemsysService from '../service/GestGemsysService';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';

export class FormQuestionnaire extends Component {
    constructor() {
        super();
        this.state = {
            idQuest: "",
            libQuest: "",
            actQuest: "Oui",
            liste_quest: [],
            selectedQuest: null,
            msg: '',
            modif: false,
            visible: false,
            showindicator: false,
        };
        this.edit_quest = this.edit_quest.bind(this);
        this.conf_delete_quest = this.conf_delete_quest.bind(this);
        this.gemsysService = new GestGemsysService();

    }
    componentDidMount() {
        this.get_liste_quests();
    }
    get_liste_quests() {
        this.setState({showindicator: true});
        this.gemsysService.getQuestionnaire().then(data => this.setState({ liste_quest: data,showindicator: false }));
    }
    resultat(code, contenu) {
        if (code == '200') {
            this.toast.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_quest();
            this.componentDidMount();
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({showindicator: false});
    }

    edit_quest(data) {
        if (data != null && data != undefined) {
            this.setState({ idQuest: data.idQuest });
            this.setState({ libQuest: data.libQuest });
            this.setState({ actQuest: data.actQuest });
            this.setState({ modif: true });
            this.setState({ selectedQuest: data });
            window.scrollTo(0, 0);
        }
    }

    annule_quest() {
        this.setState({ idQuest: '' });
        this.setState({ libQuest: "" });
        this.setState({ actQuest: "Oui" });
        this.setState({ modif: false });
        this.forceUpdate();
    }

    enreg_quest() {
        if (this.state.libQuest == "") {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else {
            this.setState({showindicator: true});
            if (this.state.modif == false) {
                let quest = {
                    idQuest: '0',
                    libQuest: this.state.libQuest,
                    actQuest: this.state.actQuest,
                    anneeQuest: this.gemsysService.get_rannee()
                }
                this.gemsysService.AddQuestionnaire(quest).then(data => this.resultat(data.code, data.contenu));
            } else {
                let quest = this.state.selectedQuest;
                quest.libQuest = this.state.libQuest;
                quest.actQuest = this.state.actQuest;
                this.gemsysService.UpdateQuestionnaire(quest).then(data => this.resultat(data.code, data.contenu));
            }
        }
    }

    actionTemplate(rowData, column) {
        return <div className="grid" style={{ width: 90 }}>
            <div className="col"><Button onClick={(e) => this.edit_quest(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
            <div className="col"><Button onClick={(e) => this.conf_delete_quest(rowData)} className="p-button-danger" style={{ width: 25, height: 25, marginLeft: -20 }} icon="pi pi-times" /></div>
        </div>
    }

    delete_quest() {
        if (this.state.selectedQuest == null || this.state.selectedQuest == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.setState({showindicator: true});
            this.gemsysService.DeleteQuestionnaire(this.state.selectedQuest).then(data => this.resultat(data.code, data.contenu));
        }
    }
    conf_delete_quest(data) {
        if (data !== null && data !== undefined) {
            this.setState({ selectedQuest: data });
            this.setState({ msg: data.idQuest });
            this.setState({ visible: true });
        }
    }

    render() {
        let actions = [
            { label: 'Oui', value: 'Oui' },
            { label: 'Non', value: 'Non' }
        ];

        const dialogFooter = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible: false });
                    this.delete_quest();
                }} label="Oui" loading={this.state.showindicator} />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );
        return (
            <div className="p-fluid">
                <Toast ref={(el) => this.toast = el} />
                <Dialog header="Confirmation" visible={this.state.visible} style={{ width: '250px' }} modal={true} footer={dialogFooter} onHide={(e) => this.setState({ visible: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>
                <div className="grid">
                    <div className="col-12">
                        <div className="card card-w-title">
                            <div className="grid " >
                                <div className="col-3 md:col-2">
                                    <label htmlFor="libQuest">Libellé *</label>
                                </div>
                                <div className="col-9 md:col-4" >
                                    <InputTextarea rows={2} value={this.state.libQuest} onChange={(e) => this.setState({ libQuest: e.target.value })} />
                                </div>
                                <div className="col-3 md:col-2">
                                    <label htmlFor="actQuest">Actif *</label>
                                </div>
                                <div className="col-9 md:col-4" >
                                    <Dropdown options={actions} value={this.state.actQuest} onChange={(e) => this.setState({ actQuest: e.target.value })} autoWidth={false} />
                                </div>
                            </div>
                            <center>
                                <div className="grid" style={{ width: 300, marginTop: '0%' }}>
                                    <div className="col"><Button label="Annuler" icon="pi pi-ban" onClick={(e) => this.annule_quest()} /></div>
                                    <div className="col"><Button label="Valider" loading={this.state.showindicator} icon="pi pi-check" onClick={(e) => this.enreg_quest()} /></div>
                                </div>
                            </center>

                            <DataTable value={this.state.liste_quest} selectionMode="single" alwaysShowPaginator={false} 
                                onRowClick={e => this.setState({ selectedQuest: e.data })} onRowSelect={e => this.setState({ selectedQuest: e.data })}
                                responsive={true} selection={this.state.selectedQuest} style={{ minHeight: '500px' }} dataKey="idQuest" className="p-datatable-gridlines" showGridlines responsiveLayout="scroll" paginator rows={100}>
                                <Column header="Actions" body={this.actionTemplate.bind(this)} style={{ width: '15%' }} />
                                <Column field="libQuest" header="Libellé" style={{ width: '55%' }} filter={true} filterMatchMode='contains'/>
                                <Column field="actQuest" header="Actif" style={{ width: '15%' }} filter={true} filterMatchMode='contains'/>
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}