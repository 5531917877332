import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import  GestGemsysService from '../service/GestGemsysService';
import './styles.css';
import { Panel } from 'primereact/panel';
import { InputMask } from 'primereact/inputmask';
export class FormEmploiTemps extends Component {
    constructor() {
        super();
        this.state = {
            idEmploi: "",
            libelle: "Cours normal",
            idEns: null,
            codesalle: null,
            horaire: "",
            jour: "Lundi",
            iduser: "1",
            idEtabl: 1,
            d1h: "",
            d2h: "",
            rmatrPers: '',
            rcodeClasse: '',
            rcodemat: '',
            rjour: '',
            rhoraire: "",
            rJours: [
                { label: 'Jour', value: 'Tous' },
                { label: 'Lundi', value: 'Lundi' },
                { label: 'Mardi', value: 'Mardi' },
                { label: 'Mercredi', value: 'Mercredi' },
                { label: 'Jeudi', value: 'Jeudi' },
                { label: 'Vendredi', value: 'Vendredi' },
                { label: 'Samedi', value: 'Samedi' },
                { label: 'Dimmanche', value: 'Dimmanche' }
            ],
            classe: null,
            cours: null,
            lcours: [],
            liste_emplois: [],
            liste_salles: [],
            liste_personnels: [],
            liste_cours: [],
            liste_enseigner: [],
            enseigners: [],
            liste_classes: [],
            selectedEmploi: null,
            msg: '',
            modif: false,
            visible: false,
            recherche: false,
            showindicator: false
        };
        this.edit_emploi = this.edit_emploi.bind(this);
        this.conf_delete_emploi = this.conf_delete_emploi.bind(this);
        this.gemsysService = new GestGemsysService();

    }
    componentDidMount() {
        this.gemsysService.getSalle().then(data => {
            if (data !== null && data !== undefined) {
                let liste = data.map(x => {
                    return { value: x.codesalle, label: x.libsalle }
                });
                this.setState({ liste_salles: liste });
            }
        });
        this.gemsysService.getClasse().then(data => {
            if (data !== null && data !== undefined) {
                let liste = data.map(x => {
                    return { value: x.codeClasse, label: x.libClasse + " " + x.optClasse }
                });
                this.setState({ liste_classes: liste });
            }
        });
        this.gemsysService.getPersonnel().then(data => {
            if (data !== null && data !== undefined) {
                let liste = data.map(x => {
                    return { value: x.matrPers, label: x.prenomPers + " " + x.nomPers }
                });
                this.setState({ liste_personnels: liste });
            }
        });
        this.get_liste_emploi();
    }

    get_liste_matiere_by_classe(codeClasse) {
        this.gemsysService.getMatiere_by_classe(codeClasse).then(data => this.setState({ liste_cours: data }, () => {
            if (this.state.liste_cours != null && this.state.liste_cours != undefined) {
                let liste = this.state.liste_cours.map(x => {
                    return { value: x.codemat, label: x.libmat }
                });
                this.setState({ lcours: liste });
            }
        }));
    }

    get_liste_enseigner_by_classe(codeClasse) {
        this.gemsysService.getEnseigner_by_classe(codeClasse).then(data => {
            if (data !== null && data !== undefined) {
                let liste = data.map(x => {
                    return { value: x.idEns, label: x.codemat.libmat, prof: x.matrPers.prenomPers + " " + x.matrPers.nomPers }
                });
                this.setState({ liste_enseigner: liste });
            }
        });
    }

    resultat(code, contenu) {
        if (code == '200') {
            this.toast.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_emploi();
            this.componentDidMount();
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({showindicator: false});
    }

    edit_emploi(data) {
        if (data != null && data != undefined) {
            this.setState({ libelle: data.libelle });
            this.setState({ classe: data.idEns.codemat.codeClasse.codeClasse }, () => {
                this.get_liste_enseigner_by_classe(data.idEns.codemat.codeClasse.codeClasse);
                this.setState({ idEns: data.idEns.idEns });
            });
            this.setState({ codesalle: data.codesalle.codesalle });
            this.setState({ horaire: data.horaire });
            this.setState({ jour: data.jour });
            this.setState({ modif: true });
            this.setState({ selectedEmploi: data });
            window.scrollTo(0, 0);
        }
    }

    annule_emploi() {
        this.setState({ idEmploi: '' });
        this.setState({ libelle: '' });
        this.setState({ dateEmp: '' });
        this.setState({ anneeEmp: '' });
        this.setState({ denregEmp: '' });
        this.setState({ idEns: null });
        this.setState({ codesalle: null });
        this.setState({ horaire: '' });
        this.setState({ jour: 'Lundi' });
        this.setState({ modif: false });
        this.forceUpdate();
    }

    enreg_emploi() {
        if (this.state.idEns == "" || this.state.codesalle == "" || this.state.libelle == "" || this.state.jour == "" || this.state.horaire == "") {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else {
            this.setState({showindicator: true});
            if (this.state.modif == false) {
                let emploi = {
                    idEmploi: this.state.idEmploi,
                    libelle: this.state.libelle,
                    anneeEmp: this.gemsysService.get_rannee(),
                    idEns: { idEns: this.state.idEns },
                    codesalle: { codesalle: this.state.codesalle },
                    horaire: this.state.horaire,
                    jour: this.state.jour
                }
                this.gemsysService.AddEmploi(emploi).then(data => this.resultat(data.code, data.contenu));
            } else {
                let emploi = this.state.selectedEmploi;
                emploi.libelle = this.state.libelle;
                emploi.horaire = this.state.horaire;
                emploi.idEns = { idEns: this.state.idEns };
                emploi.codesalle = { codesalle: this.state.codesalle };
                emploi.jour = this.state.jour;
                this.gemsysService.UpdateEmploi(emploi).then(data => this.resultat(data.code, data.contenu));
            }
        }
    }

    get_liste_emploi() {
        let rmatrPers = 'Tous';
        let rcodeClasse = 'Tous';
        let rcodemat = 'Tous';
        let rjour = 'Tous';
        if (this.state.rmatrPers !== "" && this.state.rmatrPers !== null && this.state.rmatrPers !== undefined && this.state.rmatrPers !== "Tous") {
            rmatrPers = this.state.rmatrPers;
        }
        if (this.state.rcodeClasse !== "" && this.state.rcodeClasse !== null && this.state.rcodeClasse !== undefined && this.state.rcodeClasse !== "Tous") {
            rcodeClasse = this.state.rcodeClasse;
        }
        if (this.state.rcodemat !== "" && this.state.rcodemat !== null && this.state.rcodemat !== undefined && this.state.rcodemat !== "Tous") {
            rcodemat = this.state.rcodemat;
        }
        if (this.state.rjour !== "" && this.state.rjour !== null && this.state.rjour !== undefined && this.state.rjour !== "Tous") {
            rjour = this.state.rjour;
        }
        this.setState({showindicator: true});
        this.gemsysService.getEmploi_by_criticera(rmatrPers, rcodeClasse, rcodemat, rjour).then(data => this.setState({ liste_emplois: data,showindicator: false }));
    }

    actionTemplate(rowData, column) {
        return <div className="grid" style={{ width: 90 }}>
            <div className="col"><Button onClick={(e) => this.edit_emploi(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
            <div className="col"><Button onClick={(e) => this.conf_delete_emploi(rowData)} className="p-button-danger" style={{ width: 25, height: 25, marginLeft: -20 }} icon="pi pi-times" /></div>
        </div>
    }

    fileUpload(file, namefile) {
        this.gemsysService.upload_file(file, namefile, 'photos/').then(data => {
            if (data != null && data != undefined && data.code == '200') {
                this.toast.show({ severity: 'success', summary: 'Success', detail: 'Chargement effectué avec succès' });
            } else {
                this.toast.show({ severity: 'error', summary: 'Error', detail: 'Echec upload' });
            }
        });
    }

    photoTemplate(rowData, column) {
        let fic = 'nophoto.png'
        if (rowData.idEns.matrPers !== undefined && rowData.idEns.matrPers !== null) {
            fic = rowData.idEns.matrPers.photo;
        }
        return <img src={this.gemsysService.download_file(fic, 'photos/')} alt='Photo' style={{ width: 45, height: 45 }} />
    }

    delete_emploi() {
        if (this.state.selectedEmploi == null || this.state.selectedEmploi == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.setState({showindicator: true});
            this.gemsysService.DeleteEmploi(this.state.selectedEmploi).then(data => this.resultat(data.code, data.contenu));
        }
    }

    conf_delete_emploi(data) {
        if (data !== null && data !== undefined) {
            this.setState({ selectedEmploi: data });
            this.setState({ msg: data.idEmploi });
            this.setState({ visible: true });
        }
    }

    render() {
        let jour = [
            { label: 'Lundi', value: 'Lundi' },
            { label: 'Mardi', value: 'Mardi' },
            { label: 'Mercredi', value: 'Mercredi' },
            { label: 'Jeudi', value: 'Jeudi' },
            { label: 'Vendredi', value: 'Vendredi' },
            { label: 'Samedi', value: 'Samedi' },
            { label: 'Dimmanche', value: 'Dimmanche' }
        ];
        let libelle = [
            { label: 'Cours normal', value: 'Cours normal' },
            { label: 'Cours rattrapage', value: 'Cours rattrapage' },
            { label: 'Cours supplémentaire', value: 'Cours supplémentaire' },
            { label: 'Evaluation', value: 'Evaluation' },
            { label: 'Conférence', value: 'Conférence' },
            { label: 'Autre', value: 'Autre' }
        ];
        const dialogFooter = (
            <div>
                <Button icon="pi pi-times" onClick={() => this.setState({ visible: false })} label="Annuler" className="p-button-secondary" />
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible: false });
                    this.delete_emploi();
                }} label="Oui" className="p-button-danger" loading={this.state.showindicator} />
            </div>
        );
        return (
            <div className="p-fluid">
                <Toast ref={(el) => this.toast = el} />
                <Dialog header="Confirmation" visible={this.state.visible} style={{ width: '250px' }} modal={true} footer={dialogFooter} onHide={(e) => this.setState({ visible: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer la ligne sélectionnée ?
                    </div>
                </Dialog>
                <div className="grid">
                    <div className="col-12">
                        <div className="card card-w-title">
                            <Panel>
                                <div className="grid " >
                                    <div className="col-12 md:col-3">
                                        <label htmlFor="classe">Classe *</label>
                                    </div>
                                    <div className="col-12 md:col-3" >
                                        <Dropdown options={this.state.liste_classes} optionLabel='label' filter={true} filterBy='label' value={this.state.classe} onChange={(e) => {
                                            this.setState({ classe: e.value }, () => {
                                                this.get_liste_enseigner_by_classe(this.state.classe);
                                            })
                                        }} autoWidth={false} filterMatchMode='contains' panelStyle={{ width: '400px' }} />
                                    </div>
                                    <div className="col-12 md:col-3">
                                        <label htmlFor="cours">Cours *</label>
                                    </div>
                                    <div className="col-12 md:col-3" >
                                        <Dropdown options={this.state.liste_enseigner} value={this.state.idEns} panelStyle={{ width: '300px' }}
                                            onChange={(e) => { this.setState({ idEns: e.value }) }} itemTemplate={(option) => {
                                                if (!option.value) {
                                                    return option.label;
                                                }
                                                else {
                                                    return (
                                                        <div className="grid" >
                                                            <div className="col-12 md:col-6">
                                                                <label>{option.label}</label>
                                                            </div>
                                                            <div className="col-12 md:col-6">
                                                                <label>{option.prof}</label>
                                                            </div>
                                                        </div>
                                                    );
                                                }
                                            }} autoWidth={false} optionLabel='label' filter={true} filterBy='label,prof' filterMatchMode='contains' panelStyle={{ width: '500px' }} />
                                    </div>
                                    <div className="col-12 md:col-3">
                                        <label htmlFor="typeDep">Salle *</label>
                                    </div>
                                    <div className="col-12 md:col-3" >
                                        <Dropdown options={this.state.liste_salles} value={this.state.codesalle} optionLabel="label" onChange={(e) => this.setState({ codesalle: e.target.value })} autoWidth={false} filter={true} filterBy='label' filterMatchMode='contains' />
                                    </div>
                                    <div className="col-12 md:col-3">
                                        <label htmlFor="jour">Jour *</label>
                                    </div>
                                    <div className="col-12 md:col-3" >
                                        <Dropdown options={jour} value={this.state.jour} onChange={(e) => this.setState({ jour: e.target.value })} autoWidth={false} />
                                    </div>
                                    <div className="col-12 md:col-3">
                                        <label htmlFor="HeureDeb">Horaire *</label>
                                    </div>
                                    <div className="col-12 md:col-3" >
                                        <InputMask mask="99:99 - 99:99" value={this.state.horaire} onChange={e => this.setState({ horaire: e.target.value })} />
                                    </div>

                                    <div className="col-12 md:col-3">
                                        <label htmlFor="libelle">Libellé *</label>
                                    </div>
                                    <div className="col-12 md:col-3" >
                                        <Dropdown options={libelle} value={this.state.libelle} onChange={(e) => this.setState({ libelle: e.target.value })} autoWidth={false} />
                                    </div>
                                </div>
                            </Panel>
                            <center>
                                <div className="grid" style={{ width: 300, marginTop: '0%' }}>
                                    <div className="col"><Button label="Annuler" icon="pi pi-ban" onClick={(e) => this.annule_emploi()} /></div>
                                    <div className="col"><Button loading={this.state.showindicator} label="Valider" icon="pi pi-check" onClick={(e) => this.enreg_emploi()} /></div>
                                </div>
                            </center>
                            <i className="pi pi-search"></i>
                            <span style={{ fontSize: 14, marginLeft: 10, color: '#999999' }}>Options de recherche</span>
                            <div className="col-12">
                                <div className="grid">
                                    <div className="col-12 md:col-3" >
                                        <Dropdown value={this.state.rmatrPers} placeholder='Enseignant' filter={true} options={[{ value: 'Tous', label: 'Enseignant' }].concat(this.state.liste_personnels)} onChange={(e) => this.setState({ rmatrPers: e.target.value })}
                                            itemTemplate={(option) => {
                                                if (!option.value) {
                                                    return option.label;
                                                }
                                                else {
                                                    return (
                                                        <div className="grid" >
                                                            <div className="col-12 md:col-3">
                                                                <label>{option.value}</label>
                                                            </div>
                                                            <div className="col-12 md:col-9">
                                                                <label>{option.label}</label>
                                                            </div>
                                                        </div>
                                                    );
                                                }
                                            }} autoWidth={false} optionLabel='label' filter={true} filterBy='label,value' filterMatchMode='contains' panelStyle={{ width: '500px' }} />
                                    </div>
                                    <div className="col-12 md:col-3" >
                                        <Dropdown value={this.state.rcodeClasse} optionLabel='label' placeholder='Classe' filterMatchMode='contains' filter={true} options={[{ value: 'Tous', label: 'Classe' }].concat(this.state.liste_classes)} onChange={(e) => {
                                            this.setState({ rcodeClasse: e.value }, () => {
                                                this.get_liste_matiere_by_classe(e.value);
                                            })
                                        }} autoWidth={false}  panelStyle={{ width: '400px' }} />
                                    </div>
                                    <div className="col-12 md:col-2" >
                                        <Dropdown value={this.state.rcodemat} optionLabel='label' placeholder='Matière' filter={true} options={[{ value: 'Tous', label: 'Matière' }].concat(this.state.lcours)} onChange={(e) => this.setState({ rcodemat: e.target.value })} autoWidth={false}  panelStyle={{ width: '400px' }} filterMatchMode='contains' />
                                    </div>
                                    <div className="col-12 md:col-2" >
                                        <Dropdown value={this.state.rjour} optionLabel='label' placeholder='Jour' filter={true} options={this.state.rJours} onChange={(e) => this.setState({ rjour: e.target.value })} autoWidth={false} />
                                    </div>

                                    <div className='col-12 md:col-2' >
                                        <Button icon='pi pi-search' loading={this.state.showindicator} label='Rechercher' onClick={(e) => this.get_liste_emploi()} />
                                    </div>
                                </div>
                            </div>
                            <DataTable value={this.state.liste_emplois} paginatorPosition="top" selectionMode="single" paginator={true} rows={50}
                                alwaysShowPaginator={false} onRowClick={e => this.setState({ selectedEmploi: e.data })} onRowSelect={e => this.setState({ selectedEmploi: e.data })}
                                selection={this.state.selectedEmploi} style={{ minHeight: '400px' }} dataKey="idEmploi" className="p-datatable-gridlines" showGridlines responsiveLayout="scroll">
                                <Column header="Actions" body={this.actionTemplate.bind(this)} style={{ width: '7%' }} />
                                <Column body={this.photoTemplate.bind(this)} header="Photo" style={{ width: '5%' }} />
                                <Column field="idEns.matrPers.matrPers" header="Matricule" style={{ width: '8%' }} filter={true} filterMatchMode='contains' />
                                <Column field="idEns.matrPers.prenomPers" body={(rowData) => {
                                    return <div>
                                        <span> {rowData.idEns.matrPers !== undefined ? rowData.idEns.matrPers.nomPers + " " + rowData.idEns.matrPers.prenomPers : ''} </span>
                                    </div>
                                }} header="Enseignant" style={{ width: '15%' }} filter={true} filterMatchMode='contains' />
                                <Column field="jour" header="Jour" style={{ width: '8%' }} filter={true} filterMatchMode='contains' />
                                <Column field="horaire" header="Horaire" style={{ width: '10%' }} filter={true} filterMatchMode='contains' />
                                <Column field="idEns.codemat.libmat" header="Matière" style={{ width: '17%' }} filter={true} filterMatchMode='contains' />
                                <Column field="idEns.codemat.codeClasse.libClasse" body={(rowData) => {
                                    return <div>
                                        <span> {rowData.idEns.codemat !== undefined ? rowData.idEns.codemat.codeClasse.libClasse + " " + rowData.idEns.codemat.codeClasse.optClasse : ''} </span>
                                    </div>
                                }} header="Classe" style={{ width: '20%' }} filter={true} filterMatchMode='contains' />
                                <Column field="libelle" header="Libellé" style={{ width: '10%' }} filter={true} filterMatchMode='contains' />
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}