import React, { Component } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { ProgressSpinner } from 'primereact/progressspinner';
import { InputNumber } from 'primereact/inputnumber';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import GestGemsysService from '../service/GestGemsysService';
import { Panel } from 'primereact/panel';
import { InputText } from 'primereact/inputtext';
import './styles.css';
import Papa from "papaparse";


export class FormSaisieNote extends Component {
    constructor() {
        super();
        this.state = {
            module: 'Module 1',
            codemat: 'Tous',
            codeClasse: null,
            matricule: 'Tous',
            liste_notes: [],
            liste_classes: [],
            liste_matieres: [],
            liste_eleves: [],
            selectedNotes: [],
            msg: '',
            modif: false,
            visible: false,
            visible_import: false,
            maxNote: 20,
            showindicator: false,
            selectedEleve: null,
            selectedNote: null,
            file: null,

            ne1: 0,
            ne2: 0,
            ne3: 0,
            ne4: 0,
            ne5: 0,
            ne6: 0,
            ne7: 0,
            ne8: 0,
            ne9: 0,
            no1: 0,
            no2: 0,
            no3: 0,
            no4: 0,
            no5: 0,
            no6: 0,
            no7: 0,
            no8: 0,
            no9: 0,

            noteCours: 0,
            noteCompo: 0,
            note3: 0,

            ncrsM1: 0,
            ncrsM2: 0,
            ncmpM1: 0,
            ncmpM2: 0,

        };
        this.edit_note = this.edit_note.bind(this);
        this.onIndexTemplate = this.onIndexTemplate.bind(this);
        this.load_note = this.load_note.bind(this);

        this.noteEditor = this.noteEditor.bind(this);
        this.gestGemsysService = new GestGemsysService();

    }

    static readUploadedFileAsText = inputFile => {
        const temporaryFileReader = new FileReader();
        return new Promise((resolve, reject) => {
            temporaryFileReader.onerror = () => {
                temporaryFileReader.abort();
                reject(new DOMException("Problem parsing input file."));
            };

            temporaryFileReader.onload = () => {
                resolve(temporaryFileReader.result);
            };
            temporaryFileReader.readAsText(inputFile);
        });
    };

    load_note = async () => {
        if (this.state.liste_notes.length <= 0) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez rechercher les étudiants au préalable' });
        } else if (this.state.file == null && this.state.file == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélecitonner le fichier CSV ' });
        } else {
            try {
                const csv = await FormSaisieNote.readUploadedFileAsText(this.state.file);
                var lines = csv.split("\n");
                var result = [];
                Array.prototype.insert = function (index) {
                    this.splice.apply(this, [index, 0].concat(this.slice.call(arguments, 1)));
                };
                for (var i = 1; i < lines.length; i++) {
                    var currentline = lines[i].split(";");
                    if (currentline.length >= 7) {
                        let sn1 = currentline[4].replace(',', '.');
                        let sn2 = currentline[5].replace(',', '.');
                        let sn3 = currentline[6].replace(',', '.');
                        var obj = {
                            matricule: currentline[1],
                            n1: parseFloat(sn1),
                            n2: parseFloat(sn2),
                            n3: parseFloat(sn3),
                        };
                        result.insert(i, obj);
                    }
                }
                if (result.length > 0) {
                    let nb = 0;
                    let liste = this.state.liste_notes;
                    for (var i = liste.length; i--;) {
                        let matricule = liste[i].idInsc.matricule.matricule;
                        let note = result.filter(function (el) {
                            return el.matricule === matricule;
                        });
                        if (note !== undefined) {
                            if (note.length == 1) {
                                liste[i].noteCours = note[0].n1;
                                liste[i].noteCompo = note[0].n2;
                                liste[i].note3 = note[0].n3;
                                nb++;
                            }
                        }
                    }
                    this.toast.show({ severity: 'success', summary: 'Success', detail: 'Notes de ' + nb + ' étudiante(s) importées avec succès' });
                    this.setState({ liste_notes: liste, showindicator: false,visible_import: false });
                }

            } catch (error) {
                this.toast.show({ severity: 'error', summary: 'Error', detail: error });
                console.error(error);
            }
        }

    }

    onIndexTemplate(data, props) {
        return props.rowIndex + 1;
    }

    onEditorValueChange(props, value) {
        let updateds = [...props.value];
        updateds[props.rowIndex][props.field] = value;
        this.setState({ liste_notes: updateds });
    }

    inputNumberEditor(props, field) {
        return <InputNumber mode="decimal" minFractionDigits={2} maxFractionDigits={2} max={this.state.maxNote} value={props.rowData[field]} onChange={(e) => {
            if (e.value > this.state.maxNote) {
                this.onEditorValueChange(props, this.state.maxNote)
            } else {
                this.onEditorValueChange(props, e.value)
            }
        }} />;
    }

    onCellEditComplete(e) {
        let { rowData, newValue, field, originalEvent: event } = e;
        let val = newValue + '';
        if (val.length > 0) {
            rowData[field] = newValue;
        } else {
            event.preventDefault();
        }
    }

    noteEditor(options) {
        return <InputText value={options.value} keyfilter="pnum" onChange={(e) => options.editorCallback(e.target.value)} />
    }


    componentDidMount() {
        this.gestGemsysService.getClasse().then(data => {
            if (data !== null && data !== undefined) {
                let lc = this.gestGemsysService.get_userconnected().idEtabl.typeEtabl == 'Université' ? 'S' : '';
                let liste = data.map(x => {
                    return { value: x.codeClasse, label: lc + x.libClasse + " " + x.optClasse }
                });
                this.setState({ liste_classes: liste });
            }
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.typeOp !== this.props.match.params.typeOp) {
            this.setState({
                module: 'Module 1',
                codemat: 'Tous',
                codeClasse: null,
                matricule: 'Tous',
                liste_notes: [],
                selectedNotes: [],
            })
        }
    }

    get_liste_matiere_by_classe(codeClasse) {
        this.gestGemsysService.getMatiere_by_classe(codeClasse).then(data => {
            if (data != null && data != undefined) {
                let liste = data.map(x => {
                    return { value: x.codemat, label: x.libmat }
                });
                this.setState({ liste_matieres: liste });
            }
        });
    }

    get_liste_inscrit_by_classe(codeClasse) {
        this.setState({ showindicator: true });
        this.gestGemsysService.getliste_eleve_by_inscrit(codeClasse).then(data => {
            if (data != null && data != undefined) {
                let liste = data.map(x => {
                    return { value: x.matricule.matricule, label: x.matricule.nomEleve + " " + x.matricule.prenomEleve, photo: x.matricule.photo, codeClasse: x.codeClasse }
                });
                this.setState({ liste_eleves: liste });
            }
            this.setState({ showindicator: false });
        });
    }

    get_liste_notes_by_eleve() {
        let rval = 0;
        if (this.get_type_operation() == 'validation') {
            rval = 1;
        }
        this.setState({ showindicator: true });
        this.gestGemsysService.getListe_notes(this.state.matricule, 'Tous', 'Tous', rval, this.state.module).then(data => {
            this.setState({ liste_notes: data, showindicator: false });
        });
    }
    get_liste_notes() {
        let rval = 0;
        if (this.get_type_operation() == 'validation') {
            rval = 1;
        }
        this.setState({ showindicator: true, liste_notes: [] });
        this.gestGemsysService.getListe_notes(this.state.matricule, this.state.codeClasse, this.state.codemat, rval, this.state.module).then(data => {
            this.setState({ liste_notes: data, showindicator: false });
        });
    }

    resultat(code, contenu) {
        if (code == '200') {
            this.toast.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.get_liste_notes();
            this.annuler_noter();
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({ showindicator: false });
    }

    edit_note(data) {
        if (data != null && data != undefined) {
            this.setState({ ne1: data.ne1 });
            this.setState({ ne2: data.ne2 });
            this.setState({ ne3: data.ne3 });
            this.setState({ ne4: data.ne4 });
            this.setState({ ne5: data.ne5 });
            this.setState({ ne6: data.ne6 });
            this.setState({ ne7: data.ne7 });
            this.setState({ ne8: data.ne8 });
            this.setState({ ne9: data.ne9 });
            this.setState({ no1: data.no1 });
            this.setState({ no2: data.no2 });
            this.setState({ no3: data.no3 });
            this.setState({ no4: data.no4 });
            this.setState({ no5: data.no5 });
            this.setState({ no6: data.no6 });
            this.setState({ no7: data.no7 });
            this.setState({ no8: data.no8 });
            this.setState({ no9: data.no9 });

            this.setState({ noteCours: data.noteCours });
            this.setState({ noteCompo: data.noteCompo });
            this.setState({ note3: data.note3 });

            this.setState({ ncrsM1: data.ncrsM1 });
            this.setState({ ncrsM2: data.ncrsM2 });
            this.setState({ ncmpM1: data.ncmpM1 });
            this.setState({ ncmpM2: data.ncmpM2 });

            this.setState({ selectedNote: data });
            this.setState({ modif: true, visible: true });

        }
    }

    get_type_operation() {
        let mode = 'saisie';
        if (this.props.match.params.typeOp != null && this.props.match.params.typeOp != undefined) {
            mode = this.props.match.params.typeOp;
        }
        return mode;
    }

    annuler_noter() {
        this.setState({ visible: false, modif: false });
    }

    enreg_noter() {
        if (this.state.modif == true) {
            if (this.gestGemsysService.get_userconnected().idEtabl.typeEtabl == 'Professionnel') {
                if (this.state.ncrsM1 == null || this.state.ncrsM2 == null || this.state.ncmpM1 == null || this.state.ncmpM2 == null) {
                    this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir les notes Professionnel' });
                    return;
                }
            } else if (this.gestGemsysService.get_userconnected().idEtabl.typeEtabl == 'Pré-universitaire') {
                if (this.state.no1 == null || this.state.no2 == null || this.state.no3 == null || this.state.no4 == null || this.state.no5 == null || this.state.no6 == null || this.state.no7 == null || this.state.no8 == undefined || this.state.no9 == null || this.state.ne1 == null || this.state.ne2 == null || this.state.ne3 == null || this.state.ne4 == null || this.state.ne5 == null || this.state.ne6 == null || this.state.ne7 == null || this.state.ne8 == null || this.state.ne9 == null) {
                    this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir les notes' });
                    return;
                }
            } else {
                if (this.state.noteCours == null || this.state.noteCompo == null || this.state.note3 == null) {
                    this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir les notes' });
                    return;
                }
            }
            this.setState({ showindicator: true });
            this.state.selectedNote.note3 = this.state.note3;
            this.state.selectedNote.noteCompo = this.state.noteCompo;
            this.state.selectedNote.noteCours = this.state.noteCours;
            this.state.selectedNote.ncmpM1 = this.state.ncmpM1;
            this.state.selectedNote.ncmpM2 = this.state.ncmpM2;
            this.state.selectedNote.ncrsM1 = this.state.ncrsM1;
            this.state.selectedNote.ncrsM2 = this.state.ncrsM2;
            this.state.selectedNote.no1 = this.state.no1;
            this.state.selectedNote.no2 = this.state.no2;
            this.state.selectedNote.no3 = this.state.no3;
            this.state.selectedNote.no4 = this.state.no4;
            this.state.selectedNote.no5 = this.state.no5;
            this.state.selectedNote.no6 = this.state.no6;
            this.state.selectedNote.no7 = this.state.no7;
            this.state.selectedNote.no8 = this.state.no8;
            this.state.selectedNote.no9 = this.state.no9;
            this.state.selectedNote.ne1 = this.state.ne1;
            this.state.selectedNote.ne2 = this.state.ne2;
            this.state.selectedNote.ne3 = this.state.ne3;
            this.state.selectedNote.ne4 = this.state.ne4;
            this.state.selectedNote.ne5 = this.state.ne5;
            this.state.selectedNote.ne6 = this.state.ne6;
            this.state.selectedNote.ne7 = this.state.ne7;
            this.state.selectedNote.ne8 = this.state.ne8;
            this.state.selectedNote.ne9 = this.state.ne9;
            this.gestGemsysService.UpdateNoter(this.state.selectedNote).then(data => {
                if (data != null && data != undefined && data != '') {
                    this.resultat(data.code, data.contenu);
                }
            });
        } else {
            if (this.state.selectedNotes == null || this.state.selectedNotes == undefined || this.state.selectedNotes.length <= 0) {
                this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner les lignes' });
            } else {
                let liste = this.state.selectedNotes;
                let modif = this.get_type_operation() == 'saisie' ? 1 : 2;
                for (var i = liste.length; i--;) {
                    liste[i].iduser = this.gestGemsysService.get_userconnected().numUtil;
                    if (this.state.module == 'Module 1') {
                        liste[i].modif1 = modif;
                    } else if (this.state.module == 'Module 2') {
                        liste[i].modif2 = modif;
                    } else {
                        liste[i].modif3 = modif;
                    }
                }
                this.setState({ showindicator: true });
                this.gestGemsysService.UpdateNoterBloc(this.state.selectedNotes).then(data => {
                    if (data != null && data != undefined && data != '') {
                        this.resultat(data.code, data.contenu);
                    }
                });
            }
        }

    }

    photoTemplate(rowData, column) {
        let fic = rowData.photo;
        if (fic === undefined || fic === null || fic === '') {
            fic = 'nophoto.png';
        }
        return <img src={this.gestGemsysService.download_file(fic, 'photos/')} alt='Photo' style={{ width: 45, height: 45 }} />
    }

    actionTemplate(rowData) {
        return <Button disabled={this.gestGemsysService.get_userconnected().profilu !== 'Administrateur' && this.gestGemsysService.get_userconnected().profilu !== 'Recteur' && this.gestGemsysService.get_userconnected().profilu !== 'DG'} onClick={() => this.edit_note(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-pencil" />
    }

    get_tableNote() {
        if (this.gestGemsysService.get_userconnected().idEtabl.typeEtabl == 'Professionnel') {
            if (this.state.module == 'Module 1') {
                return <DataTable value={this.state.liste_notes} style={{ minHeight: '700px' }}
                    onSelectionChange={e => this.setState({ selectedNotes: e.value })}
                    selection={this.state.selectedNotes} className="p-datatable-gridlines" showGridlines responsiveLayout="scroll" paginator rows={300}>
                    <Column selectionMode="multiple" style={{ width: '5%' }} editMode="cell" dataKey='idNoter' />
                    <Column body={this.onIndexTemplate} header="No" style={{ width: '5%' }} />
                    <Column field="idInsc.matricule.matricule" header="Matricule" style={{ width: '10%' }} filter={true} filterMatchMode='contains' />
                    <Column field="idInsc.matricule.prenomEleve" body={(rowData) => {
                        return <div>
                            <span> {rowData.idInsc.matricule.nomEleve + " " + rowData.idInsc.matricule.prenomEleve} </span>
                        </div>
                    }} header="Nom" style={{ width: '25%' }} filter={true} filterMatchMode='contains' />
                    <Column field="codemat.libmat" header="Matière" style={{ width: '30%' }} filter={true} filterMatchMode='contains' />
                    <Column field="ncrsM1" body={(rowData, column) => {
                        return <div>
                            <span style={{ color: rowData.ncrsM1 <= 20 ? 'black' : 'red' }}>{rowData.ncrsM1}</span>
                        </div>
                    }} header="Cours" style={{ width: '9%' }} sortable={true} editor={(options) => this.noteEditor(options)} onCellEditComplete={this.onCellEditComplete} />
                    <Column field="ncmpM1" body={(rowData, column) => {
                        return <div>
                            <span style={{ color: rowData.ncmpM1 <= 20 ? 'black' : 'red' }}>{rowData.ncmpM1}</span>
                        </div>
                    }} header="Compo" style={{ width: '9%' }} sortable={true} editor={(options) => this.noteEditor(options)} onCellEditComplete={this.onCellEditComplete} />
                    <Column body={(rowData, column) => {
                        let moy = this.gestGemsysService.formatDecimal(parseFloat(rowData.codemat.coeff) * (parseFloat(rowData.ncrsM1) + 2 * parseFloat(rowData.ncmpM1)) / 3);
                        return <div>
                            <span style={{ fontWeight: 'bold' }}>{moy}</span>
                        </div>
                    }} header="Moy" style={{ width: '7%' }} filter={true} filterMatchMode='contains' sortable={true} />
                </DataTable>
            } else {
                return <DataTable value={this.state.liste_notes} style={{ minHeight: '700px' }}
                    onSelectionChange={e => this.setState({ selectedNotes: e.value })} editMode="cell" dataKey='idNoter'
                    selection={this.state.selectedNotes} className="p-datatable-gridlines" showGridlines responsiveLayout="scroll" paginator rows={300}>
                    <Column selectionMode="multiple" style={{ width: '5%' }} />
                    <Column body={this.onIndexTemplate} header="No" style={{ width: '5%' }} />
                    <Column field="idInsc.matricule.matricule" header="Matricule" style={{ width: '10%' }} filter={true} filterMatchMode='contains' />
                    <Column field="idInsc.matricule.prenomEleve" body={(rowData) => {
                        return <div>
                            <span> {rowData.idInsc.matricule.nomEleve + " " + rowData.idInsc.matricule.prenomEleve} </span>
                        </div>
                    }} header="Nom" style={{ width: '25%' }} filter={true} filterMatchMode='contains' sortable={true} />
                    <Column field="codemat.libmat" header="Matière" style={{ width: '30%' }} filter={true} filterMatchMode='contains' />
                    <Column field="ncrsM2" body={(rowData, column) => {
                        return <div>
                            <span style={{ color: rowData.ncrsM2 <= 20 ? 'black' : 'red' }}>{rowData.ncrsM2}</span>
                        </div>
                    }} header="Cours" style={{ width: '9%' }} sortable={true} editor={(options) => this.noteEditor(options)} onCellEditComplete={this.onCellEditComplete} />
                    <Column field="ncmpM2" body={(rowData, column) => {
                        return <div>
                            <span style={{ color: rowData.ncmpM2 <= 20 ? 'black' : 'red' }}>{rowData.ncmpM2}</span>
                        </div>
                    }} header="Compo" style={{ width: '9%' }} sortable={true} editor={(options) => this.noteEditor(options)} onCellEditComplete={this.onCellEditComplete} />
                    <Column body={(rowData, column) => {
                        let moy = this.gestGemsysService.formatDecimal(parseFloat(rowData.codemat.coeff) * (parseFloat(rowData.ncrsM2) + 2 * parseFloat(rowData.ncmpM2)) / 3);
                        return <div>
                            <span style={{
                                //  color: moy < 10 ? 'red' : '#000',
                                fontWeight: 'bold'
                            }}>{moy}</span>
                        </div>
                    }} header="Moy" style={{ width: '7%' }} filter={true} filterMatchMode='contains' sortable={true} />
                </DataTable>
            }
        } else if (this.gestGemsysService.get_userconnected().idEtabl.typeEtabl == 'Pré-universitaire') {
            if (this.state.module == 'Module 1') {
                return <DataTable value={this.state.liste_notes} style={{ minHeight: '700px' }}
                    onSelectionChange={e => this.setState({ selectedNotes: e.value })}
                    selection={this.state.selectedNotes} className="p-datatable-gridlines" showGridlines responsiveLayout="scroll" paginator rows={300}>
                    <Column selectionMode="multiple" style={{ width: '4%' }} />
                    <Column body={this.onIndexTemplate} header="No" style={{ width: '4%' }} />
                    <Column field="idInsc.matricule.matricule" header="Matricule" style={{ width: '10%' }} filter={true} filterMatchMode='contains' />
                    <Column field="idInsc.matricule.prenomEleve" body={(rowData) => {
                        return <div>
                            <span> {rowData.idInsc.matricule.nomEleve + " " + rowData.idInsc.matricule.prenomEleve} </span>
                        </div>
                    }} header="Nom" filter={true} filterMatchMode='contains' />
                    {this.state.codemat == 'Tous' ?
                        <Column field="codemat.libmat" header="Matière" style={{ width: '11%' }} />
                        : null}
                    <Column field="no1" header="NO Oct" style={{ width: '8%' }} editor={(options) => this.noteEditor(options)} onCellEditComplete={this.onCellEditComplete} />
                    <Column field="ne1" header="NE Oct" style={{ width: '8%' }} editor={(options) => this.noteEditor(options)} onCellEditComplete={this.onCellEditComplete} />
                    <Column field="no2" header="NO Nov" style={{ width: '9%' }} editor={(options) => this.noteEditor(options)} onCellEditComplete={this.onCellEditComplete} />
                    <Column field="ne2" header="NE Nov" style={{ width: '8%' }} editor={(options) => this.noteEditor(options)} onCellEditComplete={this.onCellEditComplete} />
                    <Column field="no3" header="NO Dec" style={{ width: '8%' }} editor={(options) => this.noteEditor(options)} onCellEditComplete={this.onCellEditComplete} />
                    <Column field="ne3" header="NE Dec" style={{ width: '8%' }} editor={(options) => this.noteEditor(options)} onCellEditComplete={this.onCellEditComplete} />
                    <Column body={(rowData, column) => {
                        let moy = this.gestGemsysService.formatDecimal(((parseFloat(rowData.codemat.coeff) * (parseFloat(rowData.no1) + 2 * parseFloat(rowData.ne1)) / 3) + (parseFloat(rowData.codemat.coeff) * (parseFloat(rowData.no2) + 2 * parseFloat(rowData.ne2)) / 3) + (parseFloat(rowData.codemat.coeff) * (parseFloat(rowData.no3) + 2 * parseFloat(rowData.ne3)) / 3)) / 3);
                        return <div>
                            <span style={{ color: moy < 10 ? 'red' : '#000' }}>{moy}</span>
                        </div>
                    }} header="Moy" style={{ width: '7%' }} sortable={true} />
                </DataTable>
            } else if (this.state.module == 'Module 2') {
                return <DataTable value={this.state.liste_notes} style={{ minHeight: '700px' }}
                    onSelectionChange={e => this.setState({ selectedNotes: e.value })}
                    selection={this.state.selectedNotes} className="p-datatable-gridlines" showGridlines responsiveLayout="scroll" paginator rows={300}>
                    <Column selectionMode="multiple" style={{ width: '4%' }} />
                    <Column body={this.onIndexTemplate} header="No" style={{ width: '4%' }} />
                    <Column field="idInsc.matricule.matricule" header="Matricule" style={{ width: '10%' }} filter={true} filterMatchMode='contains' />
                    <Column field="idInsc.matricule.prenomEleve" body={(rowData) => {
                        return <div>
                            <span> {rowData.idInsc.matricule.nomEleve + " " + rowData.idInsc.matricule.prenomEleve} </span>
                        </div>
                    }} header="Nom" filter={true} filterMatchMode='contains' />
                    {this.state.codemat == 'Tous' ?
                        <Column field="codemat.libmat" header="Matière" style={{ width: '11%' }} />
                        : null}
                    <Column field="no4" header="NO Jan" style={{ width: '8%' }} editor={(options) => this.noteEditor(options)} onCellEditComplete={this.onCellEditComplete} />
                    <Column field="ne4" header="NE Jan" style={{ width: '8%' }} editor={(options) => this.noteEditor(options)} onCellEditComplete={this.onCellEditComplete} />
                    <Column field="no5" header="NO Fev" style={{ width: '9%' }} editor={(options) => this.noteEditor(options)} onCellEditComplete={this.onCellEditComplete} />
                    <Column field="ne5" header="NE Fev" style={{ width: '8%' }} editor={(options) => this.noteEditor(options)} onCellEditComplete={this.onCellEditComplete} />
                    <Column field="no6" header="NO Mar" style={{ width: '8%' }} editor={(options) => this.noteEditor(options)} onCellEditComplete={this.onCellEditComplete} />
                    <Column field="ne6" header="NE Mar" style={{ width: '8%' }} editor={(options) => this.noteEditor(options)} onCellEditComplete={this.onCellEditComplete} />
                    <Column body={(rowData, column) => {
                        let moy = this.gestGemsysService.formatDecimal(((parseFloat(rowData.codemat.coeff) * (parseFloat(rowData.no4) + 2 * parseFloat(rowData.ne4)) / 3) + (parseFloat(rowData.codemat.coeff) * (parseFloat(rowData.no5) + 2 * parseFloat(rowData.ne5)) / 3) + (parseFloat(rowData.codemat.coeff) * (parseFloat(rowData.no6) + 2 * parseFloat(rowData.ne6)) / 3)) / 3);
                        return <div>
                            <span style={{ color: moy < 10 ? 'red' : '#000' }}>{moy}</span>
                        </div>
                    }} header="Moy" style={{ width: '7%' }} sortable={true} />
                </DataTable>
            } else {
                return <DataTable value={this.state.liste_notes} style={{ minHeight: '700px' }}
                    onSelectionChange={e => this.setState({ selectedNotes: e.value })}
                    selection={this.state.selectedNotes} className="p-datatable-gridlines" showGridlines responsiveLayout="scroll" paginator rows={300}>
                    <Column selectionMode="multiple" style={{ width: '4%' }} />
                    <Column body={this.onIndexTemplate} header="No" style={{ width: '4%' }} />
                    <Column field="idInsc.matricule.matricule" header="Matricule" style={{ width: '10%' }} filter={true} filterMatchMode='contains' />
                    <Column field="idInsc.matricule.prenomEleve" body={(rowData) => {
                        return <div>
                            <span> {rowData.idInsc.matricule.nomEleve + " " + rowData.idInsc.matricule.prenomEleve} </span>
                        </div>
                    }} header="Nom" filter={true} filterMatchMode='contains' />
                    {this.state.codemat == 'Tous' ?
                        <Column field="codemat.libmat" header="Matière" style={{ width: '11%' }} />
                        : null}
                    <Column field="no7" header="NO Avr" style={{ width: '8%' }} editor={(options) => this.noteEditor(options)} onCellEditComplete={this.onCellEditComplete} />
                    <Column field="ne7" header="NE Avr" style={{ width: '8%' }} editor={(options) => this.noteEditor(options)} onCellEditComplete={this.onCellEditComplete} />
                    <Column field="no8" header="NO Mai" style={{ width: '9%' }} editor={(options) => this.noteEditor(options)} onCellEditComplete={this.onCellEditComplete} />
                    <Column field="ne8" header="NE Mai" style={{ width: '8%' }} editor={(options) => this.noteEditor(options)} onCellEditComplete={this.onCellEditComplete} />
                    <Column field="no9" header="NO Jun" style={{ width: '8%' }} editor={(options) => this.noteEditor(options)} onCellEditComplete={this.onCellEditComplete} />
                    <Column field="ne9" header="NE Jun" style={{ width: '8%' }} editor={(options) => this.noteEditor(options)} onCellEditComplete={this.onCellEditComplete} />
                    <Column body={(rowData, column) => {
                        let moy = this.gestGemsysService.formatDecimal(((parseFloat(rowData.codemat.coeff) * (parseFloat(rowData.no7) + 2 * parseFloat(rowData.ne7)) / 3) + (parseFloat(rowData.codemat.coeff) * (parseFloat(rowData.no8) + 2 * parseFloat(rowData.ne8)) / 3) + (parseFloat(rowData.codemat.coeff) * (parseFloat(rowData.no9) + 2 * parseFloat(rowData.ne9)) / 3)) / 3);
                        return <div>
                            <span style={{ color: moy < 10 ? 'red' : '#000' }}>{moy}</span>
                        </div>
                    }} header="Moy" style={{ width: '7%' }} sortable={true} />
                </DataTable>
            }
        } else {
            return <DataTable value={this.state.liste_notes} style={{ minHeight: '700px' }}
                onSelectionChange={e => this.setState({ selectedNotes: e.value })} editMode="cell" dataKey='idNoter'
                selection={this.state.selectedNotes} className="p-datatable-gridlines" showGridlines responsiveLayout="scroll" paginator rows={300}
                header={
                    <Button icon="pi pi-download" onClick={() => {
                        this.setState({visible_import: true});
                    }} label="Importer les notes" style={{ width: 180 }}
                    disabled={this.state.liste_notes.length<=0} />
                }
            >
                <Column selectionMode="multiple" style={{ width: '3%' }} />
                <Column body={this.onIndexTemplate} header="No" style={{ width: '5%' }} />
                <Column field="idInsc.matricule.matricule" header="MATRICULE" style={{ width: '10%' }} filter={true} filterMatchMode='contains' />
                <Column field="idInsc.matricule.prenomEleve" body={(rowData) => {
                    return <div>
                        <span> {rowData.idInsc.matricule.nomEleve + " " + rowData.idInsc.matricule.prenomEleve} </span>
                    </div>
                }} header="Nom" filter={true} filterMatchMode='contains' />
                {this.state.codemat == 'Tous' ?
                    <Column field="codemat.libmat" header="Matière" style={{ width: '11%' }} />
                    : null}
                <Column field="noteCours" header="N1" style={{ width: '7%' }} sortable={true} editor={(options) => this.noteEditor(options)} onCellEditComplete={this.onCellEditComplete} />
                <Column field="noteCompo" header="N2" style={{ width: '7%' }} sortable={true} editor={(options) => this.noteEditor(options)} onCellEditComplete={this.onCellEditComplete} />
                <Column field="note3" header="N3" style={{ width: '7%' }} sortable={true} editor={(options) => this.noteEditor(options)} onCellEditComplete={this.onCellEditComplete} />
                <Column body={(rowData, column) => {
                    let moy = this.gestGemsysService.formatDecimal(parseFloat(rowData.codemat.pd1) * parseFloat(rowData.noteCours) + parseFloat(rowData.codemat.pd2) * parseFloat(rowData.noteCompo) + parseFloat(rowData.codemat.pd3) * parseFloat(rowData.note3));
                    return <div>
                        <span style={{ color: moy < 10 ? 'red' : '#000' }}>{moy}</span>
                    </div>
                }} header="Moy" style={{ width: '7%' }} sortable={true} />
            </DataTable>
        }
    }

    get_tableNote_modif() {
        if (this.gestGemsysService.get_userconnected().idEtabl.typeEtabl == 'Professionnel') {
            return <DataTable value={this.state.liste_notes} style={{ minHeight: '700px' }} selectionMode="single"
                onSelectionChange={e => this.setState({ selectedNote: e.value })} alwaysShowPaginator={false}
                selection={this.state.selectedNote} className="p-datatable-gridlines" showGridlines responsiveLayout="scroll" paginator rows={10}>
                <Column header="#" body={this.actionTemplate.bind(this)} style={{ width: '5%' }} />
                <Column body={this.onIndexTemplate} header="No" style={{ width: '4%' }} />
                <Column field="codemat.codeClasse.libClasse" header="Classe" style={{ width: '14%' }} filter={true} filterMatchMode='contains' />
                <Column field="codemat.libmat" header="Matière" style={{ width: '54%' }} filter={true} filterMatchMode='contains' />
                <Column field="moyM1" body={(rowData, column) => {
                    return <div>
                        <span style={{ color: rowData.moyM1 < 10 ? 'red' : '#000' }}>{rowData.moyM1}</span>
                    </div>
                }} header="M1" style={{ width: '9%' }} sortable={true} />
                <Column field="moyM2" body={(rowData, column) => {
                    return <div>
                        <span style={{ color: rowData.moyM2 < 10 ? 'red' : '#000' }}>{rowData.moyM2}</span>
                    </div>
                }} header="M2" style={{ width: '9%' }} sortable={true} />
                <Column field="moyG" body={(rowData, column) => {
                    return <div>
                        <span style={{ color: rowData.moyG < 10 ? 'red' : '#000' }}>{rowData.moyG}</span>
                    </div>
                }} header="Moy" style={{ width: '9%' }} sortable={true} />
            </DataTable>

        } else if (this.gestGemsysService.get_userconnected().idEtabl.typeEtabl == 'Pré-universitaire') {
            return <DataTable value={this.state.liste_notes} style={{ minHeight: '700px' }} selectionMode="single"
                onSelectionChange={e => this.setState({ selectedNote: e.value })} alwaysShowPaginator={false}
                selection={this.state.selectedNote} className="p-datatable-gridlines" showGridlines responsiveLayout="scroll" paginator rows={15}>
                <Column header="#" body={this.actionTemplate.bind(this)} style={{ width: '4%' }} />
                <Column body={this.onIndexTemplate} header="No" style={{ width: '7%' }} />
                <Column field="codemat.codeClasse.libClasse" header="Classe" style={{ width: '14%' }} filter={true} filterMatchMode='contains' />
                <Column field="codemat.libmat" header="Matière" filter={true} filterMatchMode='contains' />
                <Column field="moyM1" body={(rowData, column) => {
                    return <div>
                        <span style={{ color: rowData.moyM1 < 10 ? 'red' : '#000' }}>{rowData.moyM1}</span>
                    </div>
                }} header="M1" style={{ width: '11%' }} sortable={true} />
                <Column field="moyM2" body={(rowData, column) => {
                    return <div>
                        <span style={{ color: rowData.moyM2 < 10 ? 'red' : '#000' }}>{rowData.moyM2}</span>
                    </div>
                }} header="M2" style={{ width: '11%' }} sortable={true} />
                <Column field="moyM3" body={(rowData, column) => {
                    return <div>
                        <span style={{ color: rowData.moyM3 < 10 ? 'red' : '#000' }}>{rowData.moyM2}</span>
                    </div>
                }} header="M3" style={{ width: '11%' }} sortable={true} />
                <Column field="moyG" body={(rowData, column) => {
                    return <div>
                        <span style={{ color: rowData.moyG < 10 ? 'red' : '#000' }}>{rowData.moyG}</span>
                    </div>
                }} header="Moy" style={{ width: '13%' }} sortable={true} />
            </DataTable>
        } else {
            return <DataTable value={this.state.liste_notes} style={{ minHeight: '700px' }} selectionMode="single"
                onSelectionChange={e => this.setState({ selectedNote: e.value })}
                selection={this.state.selectedNote} className="p-datatable-gridlines" showGridlines responsiveLayout="scroll" paginator rows={10}>
                <Column header="#" body={this.actionTemplate.bind(this)} style={{ width: '5%' }} />
                <Column body={this.onIndexTemplate} header="No" style={{ width: '7%' }} />
                <Column field="codemat.libmat" header="Matière" filter={true} filterMatchMode='contains' />
                <Column field="noteCours" header="N1" style={{ width: '9%' }} />
                <Column field="noteCompo" header="N2" style={{ width: '9%' }} />
                <Column field="note3" header="N3" style={{ width: '9%' }} />
                <Column field="moyG" body={(rowData, column) => {
                    return <div>
                        <span style={{ color: rowData.moyG < 10 ? 'red' : '#000' }}>{rowData.moyG}</span>
                    </div>
                }} header="Moy" style={{ width: '13%' }} sortable />
            </DataTable>
        }
    }

    render() {
        let liste_module = [];
        if (this.gestGemsysService.get_userconnected().idEtabl.typeEtabl == 'Professionnel') {
            liste_module = [
                { value: 'Module 1', label: 'Module 1' },
                { value: 'Module 2', label: 'Module 2' },
            ];
        } else if (this.gestGemsysService.get_userconnected().idEtabl.typeEtabl == 'Pré-universitaire') {
            liste_module = [
                { value: 'Module 1', label: 'Module 1' },
                { value: 'Module 2', label: 'Module 2' },
                { value: 'Module 3', label: 'Module 3' }
            ];
        } else {
            liste_module = [
                { value: 'Module 1', label: '1ère évaluation' },
                { value: 'Module 2', label: 'Session' },
                { value: 'Module 3', label: 'Reprise' }
            ];
        }
        const header = <div className="grid">
            <div className="col-11">
                <span className="p-float-label">
                    <Dropdown inputId="dropdown" options={this.state.liste_classes} placeholder="Classe" value={this.state.codeClasse} onChange={(e) => this.setState({ codeClasse: e.target.value }, () => {
                        this.get_liste_inscrit_by_classe(this.state.codeClasse);
                    })} filter={true} filterBy='label' filterMatchMode='contains' panelStyle={{ width: '400px' }} />
                    <label htmlFor="dropdown">Classe</label>
                </span>
            </div>
            <div className="col-1">
                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px', float: 'right' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
            </div>
        </div>

        const dialogFooter = (
            <div>
                <Button icon="pi pi-ban" onClick={() => this.annuler_noter()} label="Annuler" className="p-button-secondary" />
                <Button label="Enregistrer" icon="pi pi-check" onClick={(e) => this.enreg_noter()} loading={this.state.showindicator} />
            </div>
        );

        return (
            <div className="p-fluid">
                <Toast ref={(el) => this.toast = el} />
                <Dialog header="Modification" visible={this.state.visible} style={{ width: '70%' }} modal={true} onHide={(e) => this.setState({ visible: false })} footer={dialogFooter}>
                    <div className="col-12">
                        {this.gestGemsysService.get_userconnected().idEtabl.typeEtabl == 'Université' ?
                            <div className="grid">

                                <div className="col-12 md:col-4">
                                    <label >Note 1 * </label>
                                    <InputNumber mode="decimal" minFractionDigits={2} maxFractionDigits={2} max={10} value={this.state.noteCours} onChange={(e) => { this.setState({ noteCours: e.value }) }} />
                                </div>

                                <div className="col-12 md:col-4">
                                    <label >Note 2 * </label>
                                    <InputNumber mode="decimal" minFractionDigits={2} maxFractionDigits={2} max={10} value={this.state.noteCompo} onChange={(e) => { this.setState({ noteCompo: e.value }) }} />
                                </div>

                                <div className="col-12 md:col-4">
                                    <label >Note 3 * </label>
                                    <InputNumber mode="decimal" minFractionDigits={2} maxFractionDigits={2} max={10} value={this.state.note3} onChange={(e) => { this.setState({ note3: e.value }) }} />
                                </div>
                            </div> : null}

                        {this.gestGemsysService.get_userconnected().idEtabl.typeEtabl == 'Professionnel' ?
                            <div className="grid">
                                <div className="col-12 md:col-6">
                                    <Panel header="Module 1">
                                        <div className="grid">
                                            <div className="col-6 md:col-6">
                                                <label >Note cours * </label>
                                                <InputNumber mode="decimal" minFractionDigits={2} maxFractionDigits={2} max={20} value={this.state.ncrsM1} onChange={(e) => { this.setState({ ncrsM1: e.value }) }} />
                                            </div>

                                            <div className="col-6 md:col-6">
                                                <label >Note compo * </label>
                                                <InputNumber mode="decimal" minFractionDigits={2} maxFractionDigits={2} max={20} value={this.state.ncmpM1} onChange={(e) => { this.setState({ ncmpM1: e.value }) }} />
                                            </div>
                                        </div>
                                    </Panel>
                                </div>
                                <div className="col-12 md:col-6">
                                    <Panel header="Module 2">
                                        <div className="grid">
                                            <div className="col-6 md:col-6">
                                                <label >Note cours * </label>
                                                <InputNumber mode="decimal" minFractionDigits={2} maxFractionDigits={2} max={20} value={this.state.ncrsM2} onChange={(e) => { this.setState({ ncrsM2: e.value }) }} />
                                            </div>
                                            <div className="col-6 md:col-6">
                                                <label >Note compo * </label>
                                                <InputNumber mode="decimal" minFractionDigits={2} maxFractionDigits={2} max={20} value={this.state.ncmpM2} onChange={(e) => { this.setState({ ncmpM2: e.value }) }} />
                                            </div>
                                        </div>
                                    </Panel>
                                </div>
                            </div>

                            : null}


                        {this.gestGemsysService.get_userconnected().idEtabl.typeEtabl == 'Pré-universitaire' ?
                            <>
                                <div className="grid">
                                    <div className="col-12 md:col-4">
                                        <Panel header="Octobre">
                                            <div className="grid">
                                                <div className="col-12 md:col-6">
                                                    <label >N orale * </label>
                                                    <InputNumber mode="decimal" minFractionDigits={2} maxFractionDigits={2} max={this.state.maxNote} value={this.state.no1} onChange={(e) => { this.setState({ no1: e.value }) }} size={10} />
                                                </div>
                                                <div className="col-12 md:col-6">
                                                    <label >N écrite * </label>
                                                    <InputNumber mode="decimal" minFractionDigits={2} maxFractionDigits={2} max={this.state.maxNote} value={this.state.ne1} onChange={(e) => { this.setState({ ne1: e.value }) }} size={10} />
                                                </div>
                                            </div>
                                        </Panel>
                                    </div>
                                    <div className="col-12 md:col-4">
                                        <Panel header="Novembre">
                                            <div className="grid">
                                                <div className="col-12 md:col-6">
                                                    <label >N orale * </label>
                                                    <InputNumber mode="decimal" minFractionDigits={2} maxFractionDigits={2} max={this.state.maxNote} value={this.state.no2} onChange={(e) => { this.setState({ no2: e.value }) }} size={10} />
                                                </div>
                                                <div className="col-12 md:col-6">
                                                    <label >N écrite * </label>
                                                    <InputNumber mode="decimal" minFractionDigits={2} maxFractionDigits={2} max={this.state.maxNote} value={this.state.ne2} onChange={(e) => { this.setState({ ne2: e.value }) }} size={10} />
                                                </div>
                                            </div>
                                        </Panel>
                                    </div>
                                    <div className="col-12 md:col-4">
                                        <Panel header="Décembre">
                                            <div className="grid">
                                                <div className="col-12 md:col-6">
                                                    <label >N orale * </label>
                                                    <InputNumber mode="decimal" minFractionDigits={2} maxFractionDigits={2} max={this.state.maxNote} value={this.state.no3} onChange={(e) => { this.setState({ no3: e.value }) }} size={10} />
                                                </div>
                                                <div className="col-12 md:col-6">
                                                    <label >N écrite * </label>
                                                    <InputNumber mode="decimal" minFractionDigits={2} maxFractionDigits={2} max={this.state.maxNote} value={this.state.ne3} onChange={(e) => { this.setState({ ne3: e.value }) }} size={10} />
                                                </div>
                                            </div>
                                        </Panel>
                                    </div>

                                </div>

                                <div className="grid">
                                    <div className="col-12 md:col-4">
                                        <Panel header="Janvier">
                                            <div className="grid">
                                                <div className="col-12 md:col-6">
                                                    <label >N orale * </label>
                                                    <InputNumber mode="decimal" minFractionDigits={2} maxFractionDigits={2} max={this.state.maxNote} value={this.state.no4} onChange={(e) => { this.setState({ no4: e.value }) }} size={10} />
                                                </div>
                                                <div className="col-12 md:col-6">
                                                    <label >N écrite * </label>
                                                    <InputNumber mode="decimal" minFractionDigits={2} maxFractionDigits={2} max={this.state.maxNote} value={this.state.ne4} onChange={(e) => { this.setState({ ne4: e.value }) }} size={10} />
                                                </div>
                                            </div>
                                        </Panel>
                                    </div>
                                    <div className="col-12 md:col-4">
                                        <Panel header="Février">
                                            <div className="grid">
                                                <div className="col-12 md:col-6">
                                                    <label >N orale * </label>
                                                    <InputNumber mode="decimal" minFractionDigits={2} maxFractionDigits={2} max={this.state.maxNote} value={this.state.no5} onChange={(e) => { this.setState({ no5: e.value }) }} size={10} />
                                                </div>
                                                <div className="col-12 md:col-6">
                                                    <label >N écrite * </label>
                                                    <InputNumber mode="decimal" minFractionDigits={2} maxFractionDigits={2} max={this.state.maxNote} value={this.state.ne5} onChange={(e) => { this.setState({ ne5: e.value }) }} size={10} />
                                                </div>
                                            </div>
                                        </Panel>
                                    </div>
                                    <div className="col-12 md:col-4">
                                        <Panel header="Mars">
                                            <div className="grid">
                                                <div className="col-12 md:col-6">
                                                    <label >N orale * </label>
                                                    <InputNumber mode="decimal" minFractionDigits={2} maxFractionDigits={2} max={this.state.maxNote} value={this.state.no6} onChange={(e) => { this.setState({ no6: e.value }) }} size={10} />
                                                </div>
                                                <div className="col-12 md:col-6">
                                                    <label >N écrite * </label>
                                                    <InputNumber mode="decimal" minFractionDigits={2} maxFractionDigits={2} max={this.state.maxNote} value={this.state.ne6} onChange={(e) => { this.setState({ ne6: e.value }) }} size={10} />
                                                </div>
                                            </div>
                                        </Panel>
                                    </div>

                                </div>
                                <div className="grid">
                                    <div className="col-12 md:col-4">
                                        <Panel header="Avril">
                                            <div className="grid">
                                                <div className="col-12 md:col-6">
                                                    <label >N orale * </label>
                                                    <InputNumber mode="decimal" minFractionDigits={2} maxFractionDigits={2} max={this.state.maxNote} value={this.state.no7} onChange={(e) => { this.setState({ no7: e.value }) }} size={10} />
                                                </div>
                                                <div className="col-12 md:col-6">
                                                    <label >N écrite * </label>
                                                    <InputNumber mode="decimal" minFractionDigits={2} maxFractionDigits={2} max={this.state.maxNote} value={this.state.ne7} onChange={(e) => { this.setState({ ne7: e.value }) }} size={10} />
                                                </div>
                                            </div>
                                        </Panel>
                                    </div>
                                    <div className="col-12 md:col-4">
                                        <Panel header="Mai">
                                            <div className="grid">
                                                <div className="col-12 md:col-6">
                                                    <label >N orale * </label>
                                                    <InputNumber mode="decimal" minFractionDigits={2} maxFractionDigits={2} max={this.state.maxNote} value={this.state.no8} onChange={(e) => { this.setState({ no8: e.value }) }} size={10} />
                                                </div>
                                                <div className="col-12 md:col-6">
                                                    <label >N écrite * </label>
                                                    <InputNumber mode="decimal" minFractionDigits={2} maxFractionDigits={2} max={this.state.maxNote} value={this.state.ne8} onChange={(e) => { this.setState({ ne8: e.value }) }} size={10} />
                                                </div>
                                            </div>
                                        </Panel>
                                    </div>
                                    <div className="col-12 md:col-4">
                                        <Panel header="Juin">
                                            <div className="grid">
                                                <div className="col-12 md:col-6">
                                                    <label >N orale * </label>
                                                    <InputNumber mode="decimal" minFractionDigits={2} maxFractionDigits={2} max={this.state.maxNote} value={this.state.no9} onChange={(e) => { this.setState({ no9: e.value }) }} size={10} />
                                                </div>
                                                <div className="col-12 md:col-6">
                                                    <label >N écrite * </label>
                                                    <InputNumber mode="decimal" minFractionDigits={2} maxFractionDigits={2} max={this.state.maxNote} value={this.state.ne9} onChange={(e) => { this.setState({ ne9: e.value }) }} size={10} />
                                                </div>
                                            </div>
                                        </Panel>
                                    </div>

                                </div>
                            </>
                            : null}

                    </div>
                </Dialog >
                <Dialog header="Importation note" visible={this.state.visible_import} style={{ width: '450px' }} modal={true} onHide={() => this.setState({ visible_import: false })}>
                    <div className='card' style={{backgroundColor: '#EBF5FB'}}>
                        <label htmlFor="libPromo">Fichier CSV des notes *</label><br/> 
                        <hr/>
                        <span style={{color: 'red', fontWeight: 'bold'}}>
                            1- Ne garder aucune autre information dans le fichier à part les notes<br/> 
                            2- La 1ère ligne doit être l'entête dans l'ordre suivant: <br /> No, MATRICULE, NOM, PRENOM, N1, N2, N3 <br/> 
                            3- Le séparateur du fichier CSV doit être le ";"
                        </span>
                        <br />
                        <hr/>
                        <input type="file" onChange={e => this.setState({ file: e.target.files[0] })} />
                        <br /> <br />
                    </div>
                    <center>
                    <Button icon="pi pi-check" onClick={() => {
                            this.load_note();
                        }} label="Valider" style={{ width: 100 }} loading={this.state.showindicator} />
                    </center>
                </Dialog>

                {
                    this.get_type_operation() == 'modification' ?
                        <div className="card card-w-title">
                            <div className="grid">
                                <div className="col-12 md:col-5">
                                    <DataTable value={this.state.liste_eleves} style={{ minHeight: '570px' }}
                                        selectionMode="single" dataKey="value" className="p-datatable-gridlines" showGridlines responsiveLayout="scroll" paginator rows={10}
                                        onRowClick={e => this.setState({ selectedEleve: e.data }, () => {
                                            this.setState({ maxNote: this.state.selectedEleve.codeClasse.codeEnseign.libeEnseign == 'Primaire' ? 10 : 20, codemat: 'Tous', module: 'Tous', matricule: this.state.selectedEleve.value }, () => {
                                                this.get_liste_notes_by_eleve();
                                            })
                                        })} header={header} selection={this.state.selectedEleve} >
                                        <Column body={this.onIndexTemplate} header="No" style={{ width: '9%' }} />
                                        <Column body={this.photoTemplate.bind(this)} header="Photo" style={{ width: '13%' }} />
                                        <Column field="value" header="Matricule" style={{ width: '25%' }} filter={true} filterMatchMode='contains' />
                                        <Column field="label" body={(data) => {
                                            return <div>
                                                {data.label}
                                            </div>
                                        }} header="Nom" style={{ width: '54%' }} filter={true} filterMatchMode='contains' />
                                    </DataTable>
                                </div>
                                <div className="col-12 md:col-7">
                                    {this.get_tableNote_modif()}
                                </div>
                            </div>

                        </div>
                        :
                        <div className="card card-w-title">
                            <i className="pi pi-search"></i>
                            <span style={{ fontSize: 14, marginLeft: 10, color: '#999999' }}>Options de recherche</span>
                            <div className="grid">
                                <div className="col-12 md:col-3">
                                    <Dropdown options={this.state.liste_classes} placeholder="Classe" value={this.state.codeClasse} onChange={(e) => this.setState({ codeClasse: e.target.value, liste_notes: [] }, () => {
                                        this.get_liste_matiere_by_classe(this.state.codeClasse);
                                    })} filter={true} filterBy='label' filterMatchMode='contains' panelStyle={{ width: '400px' }} />
                                </div>
                                <div className="col-12 md:col-3">
                                    <Dropdown options={[{ value: 'Tous', label: 'Matière' }].concat(this.state.liste_matieres)} placeholder="Matière" value={this.state.codemat} onChange={(e) => this.setState({ codemat: e.value }, () => {
                                        this.get_liste_inscrit_by_classe(this.state.codeClasse);
                                        this.get_liste_notes();
                                    })} filter={true} filterBy='label' filterMatchMode='contains' panelStyle={{ width: '300px' }} />
                                </div>
                                <div className="col-12 md:col-2">
                                    <Dropdown options={[{ value: 'Tous', label: 'Elève/Etudiant' }].concat(this.state.liste_eleves)} placeholder="Elève/Etudiant" value={this.state.matricule} onChange={(e) => this.setState({ matricule: e.value }, () => {
                                        this.get_liste_notes();
                                    })}
                                        itemTemplate={(option) => {
                                            if (!option.value) {
                                                return option.label;
                                            }
                                            else {
                                                return (
                                                    <div className="grid" >
                                                        <div className="col-12 md:col-3">
                                                            <label>{option.value}</label>
                                                        </div>
                                                        <div className="col-12 md:col-9">
                                                            <label>{option.label}</label>
                                                        </div>
                                                    </div>
                                                );
                                            }
                                        }} autoWidth={false} optionLabel='label' filter={true} filterBy='label,value' filterMatchMode='contains' panelStyle={{ width: '500px' }} />
                                </div>
                                <div className="col-12 md:col-2">
                                    <Dropdown options={liste_module} placeholder="Module" value={this.state.module} onChange={(e) => this.setState({ module: e.value }, () => {
                                        this.get_liste_notes()
                                        this.forceUpdate();
                                    })} autoWidth={false} />
                                </div>
                                <div className="col-12 md:col-2">
                                    {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> :
                                        <Button label="Rechercher" icon="pi pi-search" style={{ width: 120 }} onClick={() => this.get_liste_notes()} loading={this.state.showindicator} />}
                                </div>
                            </div>
                            {this.get_tableNote()}
                            <br />
                            <center>
                                <Button label="Valider" loading={this.state.showindicator} style={{ width: 150 }} icon="pi pi-check" onClick={() => this.enreg_noter()} />
                                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                            </center>
                        </div>
                }


            </div >

        );
    }
}
