import React, { Component } from 'react';
import GestGemsysService from '../service/GestGemsysService';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Editor } from 'primereact/editor';
import 'moment';
import 'moment/locale/fr';
import moment from 'moment';
import { ProgressSpinner } from 'primereact/progressspinner';

export class FormTheme extends Component {
    constructor() {
        super();
        this.state = {
            rpd1: new Date(),
            rpd2: new Date(),
            retat: 'Tous',
            rstatut: 'Tous',
            file: [],
            idTheme: 0,
            iduTheme: '',
            etatTheme: 'Oui',
            contenuTheme: '',
            urlFicTheme: '',
            statutTheme: '',
            dateExpTheme: '',
            actTheme: 'Oui',
            liste_theme: [],
            liste_commentaire: [],
            selectedTheme: null,
            selectedComment: null,
            showindicator: false,
            msg: '',
            modif: false,
            visible_cmt: false,
            visible: false,
        };
        this.edit_theme = this.edit_theme.bind(this);
        this.conf_delete_theme = this.conf_delete_theme.bind(this);
        this.conf_delete_cmt = this.conf_delete_cmt.bind(this);
        this.export = this.export.bind(this);
        this.gemsysService = new GestGemsysService();
    }

    componentDidMount() {
        this.get_liste_themes();
    }

    get_liste_themes() {
        let spd1 = moment(this.state.rpd1).format('DD/MM/YYYY');
        let spd2 = moment(this.state.rpd2).format('DD/MM/YYYY');
        this.setState({ showindicator: true });
        this.gemsysService.getTheme(spd1, spd2).then(data => this.setState({ liste_theme: data, showindicator: false }));
    }


    get_liste_cmt(data) {
        if (data !== null && data !== undefined) {
            this.setState({ showindicator: true });
            this.gemsysService.getCommentaire(data.idTheme).then(data => this.setState({ liste_commentaire: data, showindicator: false }));
        }
    }


    resultat(code, contenu) {
        if (code == '200') {
            this.toast.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_theme();
            this.componentDidMount();
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({ showindicator: false });
    }

    export() {
        this.dt.exportCSV();
    }

    footer_theme() {
        return <Button label="Exporter" style={{ width: 120 }} icon="pi pi-external-link" onClick={this.export} />
    }
    edit_theme(data) {
        if (data != null && data != undefined) {
            this.setState({ idTheme: data.idTheme });
            this.setState({ iduTheme: data.iduTheme });
            this.setState({ etatTheme: data.etatTheme });
            this.setState({ contenuTheme: data.contenuTheme });
            this.setState({ urlFicTheme: data.urlFicTheme });
            this.setState({ statutTheme: data.statutTheme });
            this.setState({ anneeTheme: data.anneeTheme });
            this.setState({ dateExpTheme: new Date(moment(data.dateExpTheme, 'DD/MM/YYYY').format('DD/MM/YYYY')) });
            this.setState({ actTheme: data.actTheme });
            this.setState({ modif: true });
            this.setState({ selectedTheme: data });
            window.scrollTo(0, 0);
        }
    }
    annule_theme() {
        this.setState({ idTheme: "" });
        this.setState({ iduTheme: "" });
        this.setState({ dateTheme: "" });
        this.setState({ etatTheme: "Oui" });
        this.setState({ contenuTheme: "" });
        this.setState({ urlFicTheme: "" });
        this.setState({ statutTheme: "" });
        this.setState({ dateExpTheme: "" });
        this.setState({ actTheme: "Culture & sport" });
        this.setState({ modif: false });
        this.forceUpdate();
    }
    enreg_theme() {
        if (this.state.dateExpTheme == "" || this.state.contenuTheme == "") {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else {
            this.setState({ showindicator: true });
            if (this.state.modif == false) {
                let theme = {
                    idTheme: '0',
                    iduTheme: this.gemsysService.get_userconnected(),
                    actTheme: this.state.actTheme,
                    contenuTheme: this.state.contenuTheme,
                    anneeTheme: this.gemsysService.get_rannee(),
                    dateExpTheme: moment(this.state.dateExpTheme, 'DD/MM/YYYY').format('DD/MM/YYYY')
                };
                this.gemsysService.AddTheme(theme).then(data => {
                    if (data != undefined && data != null) {
                        this.resultat(data.code, data.contenu);
                    }
                });
            } else {
                let theme = this.state.selectedTheme;
                theme.dateExpTheme = moment(this.state.dateExpTheme, 'DD/MM/YYYY').format('DD/MM/YYYY');
                theme.contenuTheme = this.state.contenuTheme;
                theme.actTheme = this.state.actTheme;
                this.gemsysService.UpdateTheme(theme).then(data => {
                    if (data != undefined && data != null) {
                        this.resultat(data.code, data.contenu);
                    }
                });
            }
        }
    }

    actionTemplate(rowData) {
        return <div className="grid" style={{ width: 90 }}>
            <div className="col"><Button onClick={() => this.edit_theme(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
            <div className="col"><Button onClick={() => this.conf_delete_theme(rowData)} className="p-button-danger" style={{ width: 25, height: 25, marginLeft: -20 }} icon="pi pi-times" /></div>
        </div>
    }

    actionTemplateCmt(rowData) {
        return <Button onClick={() => this.conf_delete_cmt(rowData)} className="p-button-danger" style={{ width: 25, height: 25 }} icon="pi pi-times" />
    }

    delete_theme() {
        if (this.state.selectedTheme == null || this.state.selectedTheme == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.setState({ showindicator: true });
            this.gemsysService.DeleteTheme(this.state.selectedTheme).then(data => this.resultat(data.code, data.contenu));
        }
    }

    delete_cmt() {
        if (this.state.selectedComment == null || this.state.selectedComment == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.setState({ showindicator: true });
            this.gemsysService.DeleteCommentaire(this.state.selectedComment).then(data => this.resultat(data.code, data.contenu));
        }
    }


    conf_delete_theme(data) {
        if (data !== null && data !== undefined) {
            this.setState({ selectedTheme: data });
            this.setState({ msg: data.iduTheme });
            this.setState({ visible: true });
        }
    }
    conf_delete_cmt(data) {
        if (data !== null && data !== undefined) {
            this.setState({ selectedComment: data });
            this.setState({ visible: true });
        }
    }
    render() {
        let actifs = [
            { label: 'Oui', value: 'Oui' },
            { label: 'Non', value: 'Non' }
        ];
        const dialogFooter = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible: false });
                    this.delete_theme();
                }} label="Oui" loading={this.state.showindicator} />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );
        const dialogFooterCmt = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible_cmt: false });
                    this.delete_cmt();
                }} label="Oui" loading={this.state.showindicator} />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible_cmt: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );
        return (
            <div className="p-fluid">
                <Toast ref={(el) => this.toast = el} />
                <Dialog header="Confirmation" visible={this.state.visible} style={{ width: '250px' }} modal={true} footer={dialogFooter} onHide={() => this.setState({ visible: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer la ligne sélectionnée ?
                    </div>
                </Dialog>
                <Dialog header="Confirmation" visible={this.state.visible_cmt} style={{ width: '250px' }} modal={true} footer={dialogFooterCmt} onHide={() => this.setState({ visible_cmt: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer la ligne sélectionnée ?
                    </div>
                </Dialog>
                <div className="grid">
                    <div className="col-12">
                        <div className="card card-w-title" >
                            <div className="grid " >
                                <div className="col-12 md:col-3">
                                    <label htmlFor="dateExpTheme">Date expiration *</label>
                                </div>
                                <div className="col-12 md:col-3" >
                                    <Calendar id="dateExpTheme" dateFormat="dd/mm/yy" value={this.state.dateExpTheme} onChange={(e) => this.setState({ dateExpTheme: e.target.value })} />
                                </div>
                                <div className="col-12 md:col-3">
                                    <label htmlFor="etatTheme">Actif *</label>
                                </div>
                                <div className="col-12 md:col-3" >
                                    <Dropdown options={actifs} value={this.state.actTheme} onChange={(e) => this.setState({ actTheme: e.target.value })} autoWidth={false} />
                                </div>
                                <div className="col-12 md:col-12" >
                                    <label htmlFor="iduTheme">Contenu *</label>
                                    <Editor style={{ height: '200px' }} value={this.state.contenuTheme} onTextChange={(e) => this.setState({ contenuTheme: e.htmlValue })} />
                                </div>
                            </div>
                            <center>
                                <div className="grid" style={{ width: 300, marginTop: '0%' }}>
                                    <div className="col"><Button label="Annuler" icon="pi pi-ban" onClick={() => this.annule_theme()} /></div>
                                    <div className="col"><Button label="Valider" loading={this.state.showindicator} icon="pi pi-check" onClick={() => this.enreg_theme()} /></div>
                                </div>
                            </center>
                            <div className="grid">
                                <i className="pi pi-search"></i>
                                <span style={{ fontSize: 14, marginLeft: 10, color: '#999999' }}>Options de recherche</span>
                                <hr />
                                <div className="col-12">
                                    <div className="grid">
                                        <div className="col-12 md:col-3">
                                            <Calendar id="rpd1" placeholder={moment(new Date()).format('DD/MM/YYYY')} dateFormat="dd/mm/yy" value={this.state.rpd1} onChange={(e) => this.setState({ rpd1: e.target.value })} />
                                        </div>
                                        <div className="col-12 md:col-3">
                                            <Calendar id="rpd2" placeholder={moment(new Date()).format('DD/MM/YYYY')} dateFormat="dd/mm/yy" value={this.state.rpd2} onChange={(e) => this.setState({ rpd2: e.target.value })} />
                                        </div>
                                        <div className="col-12 md:col-3">
                                            <Button label="Rechercher" icon="pi pi-search" onClick={() => this.get_liste_themes()} />
                                        </div>
                                        <div className="col-12 md:col-2">
                                            {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <DataTable value={this.state.liste_theme} selectionMode="single" style={{ minHeight: "250px" }}
                                alwaysShowPaginator={false} onRowClick={e => this.setState({ selectedTheme: e.data }, () => {
                                    this.get_liste_cmt(this.state.selectedTheme);
                                })}
                                responsive={true} selection={this.state.selectedTheme}
                                dataKey="idTheme" className="p-datatable-gridlines" showGridlines responsiveLayout="scroll" paginator rows={10}>
                                <Column header="Action" body={this.actionTemplate.bind(this)} style={{ width: '8%' }} />
                                <Column field="dateExpTheme" header="Date" style={{ width: '8%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="iduTheme.compte" header="Utilisateur" style={{ width: '10%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="contenuTheme" header="Contenu" style={{ width: '58%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="actTheme" header="Actif" style={{ width: '8%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="dateExpTheme" header="Expiration" style={{ width: '8%' }} filter={true} filterMatchMode='contains' sortable={true} />
                            </DataTable>

                            <div className="grid">
                                <i className="pi pi-comments"></i>
                                <span style={{ fontSize: 14, marginLeft: 10, color: '#999999' }}>Commentaires</span>

                                <div className="col-12">
                                    <div className="card card-w-title">
                                        <div className="grid">
                                            <div className="col-12">
                                                <DataTable value={this.state.liste_commentaire} paginatorPosition="top" selectionMode="single" paginatorLeft={this.footer_theme()} ref={(el) => { this.dt = el; }} exportFilename="liste_des_themes" csvSeparator=';'
                                                    alwaysShowPaginator={false} paginator={true} rows={50} onRowClick={e => this.setState({ selectedComment: e.data })} onRowSelect={e => this.setState({ selectedTheme: e.data })}
                                                    responsive={true} selection={this.state.selectedComment} dataKey="idCmt" className="p-datatable-gridlines" showGridlines responsiveLayout="scroll" >
                                                    <Column header="Action" body={this.actionTemplateCmt.bind(this)} style={{ width: '8%' }} />
                                                    <Column field="dateCmt" header="Date" style={{ width: '8%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                                    <Column field="iduCmt.compte" header="Utilisateur" style={{ width: '10%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                                    <Column field="contenuCmt" header="Commentaire" style={{ width: '74' }} filter={true} filterMatchMode='contains' sortable={true} />
                                                </DataTable>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}