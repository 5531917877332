import React, { Component } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import './styles.css';
import { SplitButton } from 'primereact/splitbutton';
import GestGemsysService  from '../service/GestGemsysService';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Row } from 'primereact/row';
import { ColumnGroup } from 'primereact/columngroup';
 
export class StatEffectif extends Component {
    constructor() {
        super();
        this.state = {
            rcode_enseign: 'Tous',
            rcode_fac: 'Tous',
            liste_fac: [{ label: 'Faculte', value: 'Tous' }],
            liste_data: [],
            nb: 0,
            nbf: 0,
            nbg: 0,
            liste_enseignement_all:[],
            liste_enseignement: [{ label: 'Enseignement', value: 'Tous',codefac: 'Tous' }],
            showindicator: false,
            items: [
                {
                    label: 'Format PDF',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.conf_print('PDF');
                    }
                },
                {
                    label: 'Format XLS',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.conf_print('XLS');
                    }
                },
                {
                    label: 'Format CSV',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.dt.exportCSV();
                    }
                }
            ],
        };
        this.conf_print = this.conf_print.bind(this);
        this.gemsysService = new GestGemsysService();
    }

    componentDidMount() {
        this.get_liste_fac();
        this.get_liste_enseignement();
        this.get_liste_data();
    }


    get_liste_enseignement() {
        this.setState({ showindicator: true });
        this.gemsysService.getEnseignement().then(data => {
            this.setState({ showindicator: false });
            if (data !== null && data !== undefined && data !== '') {
                if (data.length > 0) {
                    let liste = data.map(x => {
                        return { value: x.codeEnseign, label: x.libEnseign,codefac: x.codeFac.codefac }
                    });
                    this.setState({liste_enseignement_all: liste, liste_enseignement: this.state.liste_enseignement.concat(liste) });
                }
            }
        });
    }

    get_liste_fac() {
        this.setState({ showindicator: true });
        this.gemsysService.getFaculte().then(data => {
            this.setState({ showindicator: false });
            if (data !== null && data !== undefined && data !== '') {
                if (data.length > 0) {
                    let liste = data.map(x => {
                        return { value: x.codefac, label: x.libFac }
                    });
                    this.setState({ liste_fac: this.state.liste_fac.concat(liste)});
                }
            }
        });
    }


    select_enseignement() {
        if (this.state.rcode_fac !== null || this.state.rcode_fac !== 'Tous') {
            let codefac=this.state.rcode_fac;
            let liste =this.state.liste_enseignement_all.filter(function (el) {
                return el.codefac === codefac;
            });
            this.setState({liste_enseignement: liste });
        } 
    }

    get_liste_data() {
        let rcode_enseign = 'Tous';
        let rcode_fac = 'Tous';
        if (this.state.rcode_enseign !== undefined && this.state.rcode_enseign !== null && this.state.rcode_enseign !== 'Tous') {
            rcode_enseign = this.state.rcode_enseign;
        }
        if (this.state.rcode_fac !== undefined && this.state.rcode_fac !== null && this.state.rcode_fac !== 'Tous') {
            rcode_fac = this.state.rcode_fac;
        }
        this.setState({
            showindicator: true,
            nb: 0,
            nbf: 0,
            nbg: 0,
        });
        this.gemsysService.get_liste_stat_effectif(rcode_fac,rcode_enseign, this.state.rmodule).then(data => {
            this.setState({ liste_data: data, showindicator: false });
            if (data !== null && data !== undefined) {
                if (data.length > 0) {
                    let initialValue = 0;
                    let nbg = data.reduce(function (accumulator, currentValue) {
                        return accumulator + currentValue.nbg
                    }, initialValue);
                    let nb = data.reduce(function (accumulator, currentValue) {
                        return accumulator + currentValue.nb
                    }, initialValue);
                    let nbf = data.reduce(function (accumulator, currentValue) {
                        return accumulator + currentValue.nbf
                    }, initialValue);
                    this.setState({
                        nb: nb,
                        nbf: nbf,
                        nbg: nbg
                    });
                }
            }
        });
    }

    conf_print(format) {
        let rcode_enseign = 'Tous';
        let rcode_fac = 'Tous';
        if (this.state.rcode_enseign !== undefined && this.state.rcode_enseign !== null && this.state.rcode_enseign !== 'Tous') {
            rcode_enseign = this.state.rcode_enseign;
        }
        if (this.state.rcode_fac !== undefined && this.state.rcode_fac !== null && this.state.rcode_fac !== 'Tous') {
            rcode_fac = this.state.rcode_fac;
        }
        this.gemsysService.generer_stat_effectif(rcode_fac,rcode_enseign,format);
    }

  
    render() {
        let footerGroup = <ColumnGroup>
            <Row>
                <Column footer="TOTAUX" colSpan={2} footerStyle={{ textAlign: 'center', fontWeight: 'bold' }} />
                <Column footer={this.state.nb} style={{ color: '#000000', fontWeight: 'bold' }} />
                <Column footer={this.state.nbf} style={{ color: '#000000', fontWeight: 'bold' }} />
                <Column footer={this.state.nbg} style={{ color: '#000000', fontWeight: 'bold' }} />
            </Row>
        </ColumnGroup>;
 
        return (
            <div className="p-fluid">
                <div className="grid">
                    <div className="col-12">
                        <div className="card card-w-title">
                            <i className="pi pi-search"></i>
                            <span style={{ fontSize: 14, marginLeft: 10, color: '#999999' }}>Options de recherche</span>
                            <div className="grid">
                                <div className="col-12 md:col-4">
                                    <Dropdown options={this.state.liste_fac} placeholder="Faculté" value={this.state.rcode_fac} onChange={(e) => this.setState({ rcode_fac: e.value },()=>{
                                        this.select_enseignement();
                                        this.get_liste_data();
                                    })} autoWidth={false} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' />
                                </div>
                                <div className="col-12 md:col-4">
                                    <Dropdown options={this.state.liste_enseignement} placeholder="Département" value={this.state.rcode_enseign} onChange={(e) => this.setState({ rcode_enseign: e.value },()=>{
                                         this.get_liste_data();
                                    })} autoWidth={false} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' /> 
                                </div>
                                <div className="col-12 md:col-3">
                                    <SplitButton label="Rechercher" style={{ width: '170px' }} onClick={(e) => this.get_liste_data()} icon="pi pi-search" model={this.state.items} />
                                </div>
                                <div className="col-12 md:col-2">
                                    {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                </div>
                            </div>
                            <DataTable value={this.state.liste_data} style={{ minHeight: '700px' }} ref={(el) => { this.dt = el; }}
                                 sortMode="single" sortField="dept" sortOrder={1} rowGroupMode="rowspan" groupRowsBy="dept"
                                 footerColumnGroup={footerGroup} dataKey="ordre" className="p-datatable-gridlines" showGridlines responsiveLayout="scroll" paginator rows={20} alwaysShowPaginator={false}>
                                <Column field="dept" header="Département"  />
                                <Column field="niveau" header="Niveau"  sortable/>
                                <Column field="nb" header="TT" style={{ width: '10%' }} sortable />
                                <Column field="nbf" header="TF" style={{ width: '10%' }} sortable />
                                <Column field="nbg" header="TG" style={{ width: '10%' }} sortable />
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}