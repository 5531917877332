import React, { Component } from 'react';
import GestGemsysService from '../service/GestGemsysService';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import moment from 'moment';
import 'moment';
import { InputNumber } from 'primereact/inputnumber';

export class FormMission extends Component {
    constructor() {
        super();
        this.state = {
            pd1: new Date(),
            pd2: new Date(),
            rmatrPers: { matrPers: 'Tous', nomPers: 'Personnel' },
            rlibelle: '',
            dateDebutMission: new Date(),
            dateFinMission: new Date(),
            libelleMission: '',
            lieuMission: '',
            motifMission: '',
            matrPers: null,
            fraisMission: 0,
            dateEnregMission: new Date(),
            moyDeplMission: 'Voiture de service',
            liste_missions: [],
            selectedMission: null,
            personnels: null,
            msg: '',
            modif: false,
            visible: false,
            showindicator: false
        };
        this.edit_mission = this.edit_mission.bind(this);
        this.conf_delete_mission = this.conf_delete_mission.bind(this);
        this.gestGemsysService = new GestGemsysService();

    }
    componentDidMount() {
        this.gestGemsysService.getPersonnel().then(data => this.setState({ personnels: data }));
        this.get_liste_missions();
    }

    resultat(code, contenu) {
        if (code == '200') {
            this.toast.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_mission();
            this.get_liste_missions();
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({showindicator: false});
    }

    get_liste_missions() {
        this.setState({showindicator: true});
        this.gestGemsysService.get_liste_gnle_missions().then(data => this.setState({ liste_missions: data,showindicator: false }));
    }

    edit_mission(data) {
        if (data != null && data != undefined) {
            this.setState({ idMission: data.idMission });
            this.setState({ dateDebutMission: new Date(moment(data.dateDebutMission, 'DD/MM/YYYY').format('YYYY-MM-DD')) });
            this.setState({ dateFinMission: new Date(moment(data.dateFinMission, 'DD/MM/YYYY').format('YYYY-MM-DD')) });
            this.setState({ libelleMission: data.libelleMission });
            this.setState({ lieuMission: data.lieuMission });
            this.setState({ motifMission: data.motifMission });
            this.setState({ anMission: data.anMission });
            this.setState({ matrPers: data.matrPers.matrPers });
            this.setState({ fraisMission: data.fraisMission });
            this.setState({ moyDeplMission: data.moyDeplMission });
            this.setState({ modif: true });
            this.setState({ selectedMission: data });
            window.scrollTo(0, 0);
        }
    }

    annule_mission() {
        this.setState({ idMission: '' });
        this.setState({ dateDebutMission: new Date() });
        this.setState({ dateFinMission: '' });
        this.setState({ libelleMission: '' });
        this.setState({ lieuMission: '' });
        this.setState({ motifMission: '' });
        this.setState({ anMission: '' });
        this.setState({ matrPers: null });
        this.setState({ fraisMission: 0 });
        this.setState({ moyDeplMission: 'Voiture de service' });
        this.setState({ modif: false });
        this.forceUpdate();
    }

    enreg_mission() {
        if (this.state.dateDebutMission == "" || this.state.dateFinMission == "" || this.state.libelleMission == "" || this.state.lieuMission == "" || this.state.motifMission == "" || this.state.matrPers == "" || this.state.matrPers == null || this.state.moyDeplMission == "") {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else {
            this.setState({showindicator: true});
            if (this.state.modif == false) {
                let mission = {
                    idMission: '0',
                    dateDebutMission: moment(this.state.dateDebutMission, 'DD/MM/YYYY').format('DD/MM/YYYY'),
                    dateFinMission: moment(this.state.dateFinMission, 'DD/MM/YYYY').format('DD/MM/YYYY'),
                    libelleMission: this.state.libelleMission,
                    lieuMission: this.state.lieuMission,
                    motifMission: this.state.motifMission,
                    anMission: this.gestGemsysService.get_rannee(),
                    matrPers: { matrPers: this.state.matrPers },
                    fraisMission: this.state.fraisMission,
                    moyDeplMission: this.state.moyDeplMission
                };
                this.gestGemsysService.AddMission(mission).then(data => this.resultat(data.code, data.contenu));
            } else {
                let mission = this.state.selectedMission;
                mission.dateDebutMission = moment(this.state.dateDebutMission, 'DD/MM/YYYY').format('DD/MM/YYYY');
                mission.dateFinMission = moment(this.state.dateFinMission, 'DD/MM/YYYY').format('DD/MM/YYYY');
                mission.libelleMission = this.state.libelleMission;
                mission.lieuMission = this.state.lieuMission;
                mission.motifMission = this.state.motifMission;
                mission.fraisMission = this.state.fraisMission;
                mission.moyDeplMission = this.state.moyDeplMission;
                mission.matrPers = { matrPers: this.state.matrPers };
                this.gestGemsysService.UpdateMission(mission).then(data => this.resultat(data.code, data.contenu));
            }
        }
    }

    actionTemplate(rowData) {
        return <div className="grid" style={{ width: 90 }}>
            <div className="col"><Button onClick={() => this.edit_mission(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
            <div className="col"><Button onClick={() => this.conf_delete_mission(rowData)} className="p-button-danger" style={{ width: 25, height: 25, marginLeft: -20 }} icon="pi pi-times" /></div>
        </div>
    }

    delete_mission() {
        if (this.state.selectedMission == null || this.state.selectedMission == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.setState({showindicator: true});
            this.gestGemsysService.DeleteMission(this.state.selectedMission).then(data => this.resultat(data.code, data.contenu));
        }
    }
    conf_delete_mission(data) {
        if (data !== null && data !== undefined) {
            this.setState({ selectedMission: data });
            this.setState({ msg: data.idMission });
            this.setState({ visible: true });
        }
    }
    render() {
        let listepersonnels = null;
        let rlistepersonnels = [{ matrPers: 'Tous', nomPers: 'Personnel' }];
        if (this.state.personnels != null && this.state.personnels != undefined) {
            listepersonnels = this.state.personnels.map(x => {
                return { value: x.matrPers, label: x.prenomPers + " " + x.nomPers, categoriePers: x.categorie }
            });
            rlistepersonnels = rlistepersonnels.concat(listepersonnels);
        }
        let moyDeplMissions = [
            { label: 'Voiture de service', value: 'Voiture de service' },
            { label: 'Transport en commun', value: 'Transport en commun' },
            { label: 'Avion', value: 'Avion' }
        ];
        const dialogFooter = (
            <div>
                <Button icon="pi pi-times" onClick={() => this.setState({ visible: false })} label="Annuler" className="p-button-secondary" />
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible: false });
                    this.delete_mission();
                }} label="Oui" className="p-button-danger" loading={this.state.showindicator}/>
            </div>
        );
        return (
            <div className="p-fluid">
                <Toast ref={(el) => this.toast = el} />
                <Dialog header="Confirmation" visible={this.state.visible} style={{ width: '250px' }} modal={true} footer={dialogFooter} onHide={() => this.setState({ visible: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer la ligne sélectionnée ?
                    </div>
                </Dialog>
                <div className="grid">
                    <div className="col-12">
                        <div className="card card-w-title">
                            <div className="grid " >
                                <div className="col-12 md:col-3">
                                    <label htmlFor="libelleMission">Libellé *</label>
                                </div>
                                <div className="col-12 md:col-3" >
                                    <Dropdown options={[
                                        { value: 'Formation', label: 'Formation' },
                                        { value: 'Examen', label: 'Examen' },
                                        { value: 'Réunion', label: 'Réunion' },
                                        { value: 'Autre', label: 'Autre' },
                                    ]} optionLabel='label' value={this.state.libelleMission} onChange={e => this.setState({ libelleMission: e.value })} autoWidth={false}  />
                                </div>
                                <div className="col-12 md:col-3">
                                    <label htmlFor="motifMission">Description *</label>
                                </div>
                                <div className="col-12 md:col-3" >
                                    <InputText id="motifMission" value={this.state.motifMission} onChange={(e) => this.setState({ motifMission: e.target.value })} />
                                </div>
                                <div className="col-12 md:col-3">
                                    <label htmlFor="lieuMission">Lieu *</label>
                                </div>
                                <div className="col-12 md:col-3" >
                                    <InputText id="lieuMission" value={this.state.lieuMission} onChange={(e) => this.setState({ lieuMission: e.target.value })} />
                                </div>
                                <div className="col-12 md:col-3">
                                    <label htmlFor="dateDebutMission">Date début *</label>
                                </div>
                                <div className="col-12 md:col-3">
                                    <Calendar id="dateDebutMission" dateFormat="dd/mm/yy" value={this.state.dateDebutMission} onChange={(e) => this.setState({ dateDebutMission: e.target.value })}></Calendar>
                                </div>
                                <div className="col-12 md:col-3">
                                    <label htmlFor="dateFinMission">Date fin *</label>
                                </div>
                                <div className="col-12 md:col-3">
                                    <Calendar id="dateFinMission" dateFormat="dd/mm/yy" value={this.state.dateFinMission} onChange={e => this.setState({ dateFinMission: e.target.value })}></Calendar>
                                </div>
                                <div className="col-12 md:col-3">
                                    <label htmlFor="fraisMission">Frais Mission *</label>
                                </div>
                                <div className="col-6 md:col-3">
                                    <InputNumber value={this.state.fraisMission} onChange={(e) => this.setState({ fraisMission: e.value })} />
                                </div>
                                <div className="col-12 md:col-3">
                                    <label htmlFor="matrPers">Personnel *</label>
                                </div>
                                <div className="col-12 md:col-3" >
                                    <Dropdown options={listepersonnels} optionLabel="nomPers" value={this.state.matrPers} onChange={(e) => this.setState({ matrPers: e.target.value })}
                                        itemTemplate={(option) => {
                                            if (!option.value) {
                                                return option.label;
                                            }
                                            else {
                                                return (
                                                    <div className="grid" >
                                                        <div className="col-12 md:col-3">
                                                            <label>{option.value}</label>
                                                        </div>
                                                        <div className="col-12 md:col-9">
                                                            <label>{option.label}</label>
                                                        </div>
                                                    </div>
                                                );
                                            }
                                        }} autoWidth={false}  filter={true} filterBy='label,value' filterMatchMode='contains' panelStyle={{ width: '500px' }} />
                                </div>
                                <div className="col-12 md:col-3">
                                    <label htmlFor="moyDeplMission">Moyen de déplacement</label>
                                </div>
                                <div className="col-12 md:col-3" >
                                    <Dropdown options={moyDeplMissions} value={this.state.moyDeplMission} onChange={(e) => this.setState({ moyDeplMission: e.target.value })} autoWidth={false} />
                                </div>
                            </div>
                            <center>
                                <div className="grid" style={{ width: 300, marginTop: '0%' }}>
                                    <div className="col"><Button label="Annuler" icon="pi pi-ban" onClick={() => this.annule_mission()} /></div>
                                    <div className="col"><Button label="Valider" loading={this.state.showindicator} icon="pi pi-check" onClick={() => this.enreg_mission()} /></div>
                                </div>
                            </center>
                            <DataTable value={this.state.liste_missions} style={{ minHeight: '500px' }} paginatorPosition="top" selectionMode="single" paginator={true} rows={50}
                                alwaysShowPaginator={false} onRowClick={e => this.setState({ selectedMission: e.data })} onRowSelect={e => this.setState({ selectedMission: e.data })}
                                responsive={true} selection={this.state.selectedMission} dataKey="idMission" className="p-datatable-gridlines" showGridlines responsiveLayout="scroll">
                                <Column header="Actions" body={this.actionTemplate.bind(this)} style={{ width: '7%' }} />
                                <Column field="libelleMission" header="Libellé" style={{ width: '10%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="motifMission" header="Description" style={{ width: '15%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="lieuMission" header="Lieu" style={{ width: '9%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="dateDebutMission" body={(rowData, Column) => {
                                    return <div>
                                        <span>{rowData.dateDebutMission} - {rowData.dateFinMission}</span>
                                    </div>
                                }} header="Periode" style={{ width: '16%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="fraisMission" body={(rowData, column) => {
                                    return <div>
                                        <span >{this.gestGemsysService.formaterValueSep(parseFloat(rowData.fraisMission))}</span>
                                    </div>
                                }} header="Montant" style={{ width: '9%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="moyDeplMission" header="Moyen" style={{ width: '10%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="" body={(rowData, column) => {
                                    return <span>{rowData.matrPers.prenomPers + " " + rowData.matrPers.nomPers}</span>
                                }} header="Personnel" style={{ width: '20%' }} filter={true} filterMatchMode='contains' sortable={true} />
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}