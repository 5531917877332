import React, { Component } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import './styles.css';
import { SplitButton } from 'primereact/splitbutton';
import GestGemsysService from '../service/GestGemsysService';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import moment from 'moment';

export class EditionJournalTrace extends Component {
    constructor() {
        super();
        this.state = {
            pd1: new Date(),
            pd2: new Date(),
            rlibelle: 'Tous',
            liste_data: [],
            raction: 'Tous',
            rlogin: '',
            rref: '',
            liste_action: [
                { label: 'Action', value: 'Tous' },
                { label: 'Ajout', value: 'Ajout' },
                { label: 'Annulation', value: 'Annulation' },
                { label: 'Connexion', value: 'Connexion' },
                { label: 'Déconnexion', value: 'Déconnexion' },
                { label: 'Modification', value: 'Modification' },
                { label: 'Suppression', value: 'Suppression' },
                { label: 'Transfert', value: 'Transfert' },
                { label: 'Validation', value: 'Validation' },
            ],
            liste_cible: [
                { label: "Libellé", value: "Tous" },
                { label: "Filière", value: "Filière" },
                { label: "Dossier", value: "Dossier" },
                { label: "Document", value: "Document" },
                { label: "Etudiant", value: "Etudiant" },
                { label: "Emargement", value: "Emargement" },
                { label: "Emploi de temps", value: "Emploi de temps" },
                { label: "Versement", value: "Versement" },
                { label: "Matière", value: "Matière" },
                { label: "Semestre", value: "Semestre" },
                { label: "Note", value: "Note" },
                { label: "Inscription", value: "Inscription" },
                { label: "Personnel", value: "Personnel" },
                { label: "Paie", value: "Paie" },
                { label: "Utilisateur", value: "Utilisateur" },
            ],
            showindicator: false,
            items: [
                {
                    label: 'Exporter CSV',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.dt.exportCSV();
                    }
                }
            ],
        };
        this.gemsysService = new GestGemsysService();
    }


    get_liste_data() {
        let rlogin = 'Tous';
        let rref = 'Tous';
        let spd1 = moment(this.state.pd1).format('DD/MM/YYYY');
        let spd2 = moment(this.state.pd2).format('DD/MM/YYYY');
        if (this.state.rlogin !== undefined && this.state.rlogin !== null && this.state.rlogin !== 'Tous' && this.state.rlogin !== '') {
            rlogin = this.state.rlogin;
        }

        if (this.state.rref !== undefined && this.state.rref !== null && this.state.rref !== 'Tous' && this.state.rref !== '') {
            rref = this.state.rref;
        }

        this.setState({
            showindicator: true,
        });
        this.gemsysService.get_liste_trace(spd1, spd2, this.state.raction, this.state.rlibelle, rref, rlogin).then(data => {
            this.setState({ liste_data: data, showindicator: false });
        });
    }



    componentDidMount() {
        this.get_liste_data();
    }


    render() {
        return (
            <div className="p-fluid">
                <div className="grid">
                    <div className="col-12">
                        <div className="card card-w-title">
                            <i className="pi pi-search"></i>
                            <span style={{ fontSize: 14, marginLeft: 10, color: '#999999' }}>Options de recherche</span>
                            <div className="grid">
                                <div className="col-12 md:col-2" >
                                    <Calendar id="pd1" placeholder="Date début" dateFormat="dd/mm/yy" value={this.state.pd1} onChange={(e) => this.setState({ pd1: e.target.value })} />
                                </div>
                                <div className="col-12 md:col-2" >
                                    <Calendar id="pd2" placeholder="Date fin" value={this.state.pd2} onChange={e => this.setState({ pd2: e.target.value })} />
                                </div>
                                <div className="col-12 md:col-2" >
                                    <Dropdown options={this.state.liste_action} placeholder="Action" value={this.state.raction} onChange={(e) => this.setState({ raction: e.value })} autoWidth={false} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' />
                                </div>
                                <div className="col-12 md:col-2" >
                                    <Dropdown options={this.state.liste_cible} placeholder="Libelle" value={this.state.rlibelle} onChange={(e) => this.setState({ rlibelle: e.value })} autoWidth={false} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' />
                                </div>
                                <div className="col-12 md:col-2" >
                                    <InputText placeholder="Référence" value={this.state.rref} onChange={(e) => this.setState({ rref: e.target.value })} />
                                </div>
                                <div className="col-12 md:col-2" >
                                    <InputText placeholder="Login" value={this.state.rlogin} onChange={(e) => this.setState({ rlogin: e.target.value })} />
                                </div>
                            </div>

                            <center>
                                <SplitButton label="Rechercher" style={{ width: '180px' }} onClick={(e) => this.get_liste_data()} icon="pi pi-search" model={this.state.items} />
                                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                            </center>

                            <DataTable value={this.state.liste_data} style={{ minHeight: '600px',marginTop: 10 }}  ref={(el) => { this.dt = el; }}
                                dataKey="idTr" className="p-datatable-gridlines" showGridlines responsiveLayout="scroll" paginator rows={50}>
                                <Column field="dateTr" header="Date" style={{ width: '8%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="iduTr.compte" header="Login" style={{ width: '10%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="iduTr.matricule" header="Matricule" style={{ width: '10%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="actTr" header="Action" style={{ width: '10%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="refTr" header="Ref" style={{ width: '12%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="detailTr" header="Détail" style={{ width: '50%' }} filter={true} filterMatchMode='contains' sortable={true} />
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}