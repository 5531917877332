import React, { Component } from 'react';
import GestGemsysService from '../service/GestGemsysService';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import 'moment';
import 'moment/locale/fr';
import moment from 'moment';
import { InputNumber } from 'primereact/inputnumber';
import { SplitButton } from 'primereact/splitbutton';

export class FormEncaissement extends Component {
    constructor() {
        super();
        this.state = {
            pd1: new Date(),
            pd2: new Date(),
            rlibelle: 'Tous',
            rclasse: 'Tous',
            rrefVers: 'Tous',
            liste_type: [],
            libelles: [
                { label: 'Frais scolarité', value: 'Frais scolarité' },
                { label: 'Frais inscription', value: 'Frais inscription' },
                { label: 'Frais réinscription', value: 'Frais réinscription' },
                { label: 'Frais badge', value: 'Frais badge' },
                { label: 'Frais APEAE', value: 'Frais APEAE' },
                { label: 'Frais Cyber', value: 'Frais Cyber' },
                { label: 'Ancien bon', value: 'Ancien bon' },
                { label: 'Subvention', value: 'Subvention' },
                { label: 'Autre recette', value: 'Autre recette' }
            ],
            rlibelles: [
                { label: 'Libelle', value: 'Tous' },
                { label: 'Frais scolarité', value: 'Frais scolarité' },
                { label: 'Frais inscription', value: 'Frais inscription' },
                { label: 'Frais réinscription', value: 'Frais réinscription' },
                { label: 'Frais badge', value: 'Frais badge' },
                { label: 'Frais APEAE', value: 'Frais APEAE' },
                { label: 'Frais Cyber', value: 'Frais Cyber' },
                { label: 'Ancien bon', value: 'Ancien bon' },
                { label: 'Subvention', value: 'Subvention' },
                { label: 'Autre recette', value: 'Autre recette' }
            ],
            numRecu: '',
            montVers: '',
            typeVers: '',
            dateVers: new Date(),
            libVers: 'Frais scolarité',
            heureVers: '',
            idTypeVers: null,
            idInsc: '',
            cmtVers: '',
            idEtabl: '',
            iduser: '',
            refVers: '',
            classe: null,
            source: null,
            inscrit: [],
            msg: '',
            modif: false,
            visible: false,
            recherche: false,
            liste_versements: null,
            semestres: [],
            selectedVers: null,
            showindicator: false,
            liste_etudiants_by_classe: [],
            retudiants: [],
            total: '0'
        };
        this.edit_encaissement = this.edit_encaissement.bind(this);
        this.conf_delete_encaissement = this.conf_delete_encaissement.bind(this);
        this.gemsysService = new GestGemsysService();
        this.export = this.export.bind(this);
    }
 

    componentDidMount() {
        this.get_liste_versements();
        this.get_liste_type_recette();
        this.gemsysService.getClasse().then(data => this.setState({ semestres: data }));
    }

    /* Récupération de la liste globale des types */
    get_liste_type_recette() {
        this.setState({ showindicator: true , liste_type: []});
        this.gemsysService.get_liste_codification('TYPE_RECETTE').then(data => {
            this.setState({ showindicator: false });
            if (data !== undefined && data !== null) {
                if (data.length > 0) {
                    let liste = data.map(x => {
                        return { value: x.id, label: x.libelleCodif, id: x.id }
                    });
                    this.setState({ liste_type: liste });
                }
            }
        });
    }

    get_liste_versements() {
        let rclasse = "Tous";
        let rrefVers = "Tous";
        let rlibelle = "Tous";

        let spd1 = moment(this.state.pd1).format('DD/MM/YYYY');
        let spd2 = moment(this.state.pd2).format('DD/MM/YYYY');
        if (this.state.rlibelle !== 'Tous' && this.state.rlibelle !== "" && this.state.rlibelle !== undefined) {
            rlibelle = this.state.rlibelle;
        }
        if (this.state.rclasse !== 'Tous' && this.state.rclasse !== "" && this.state.rclasse !== undefined) {
            rclasse = this.state.rclasse;
        }
        if (this.state.rrefVers !== 'Tous' && this.state.rrefVers !== "" && this.state.rrefVers !== undefined) {
            rrefVers = this.state.rrefVers;
        }
        this.setState({ showindicator: true });
        this.gemsysService.get_liste_versements_by_criteria(spd1, spd2, rlibelle, rclasse, rrefVers).then(data => this.setState({ liste_versements: data }, () => {
            let initialValue = 0;
            let tt = 0;
            if (data !== null && data !== undefined) {
                tt = data.reduce(function (accumulator, currentValue) {
                    return accumulator + currentValue.montVers
                }, initialValue);
            }
            this.setState({ showindicator: false, total: this.gemsysService.formaterValueSep(parseFloat(tt)) });
        }));
    }

    resultat(code, contenu) {
        if (code == '200') {
            this.toast.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_encaissement();
            this.get_liste_versements();
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({ showindicator: false });
    }

    edit_encaissement(data) {
        if (data != null && data != undefined) {
            this.setState({ numRecu: data.numRecu });
            this.setState({ montVers: data.montVers });
            this.setState({ typeVers: data.typeVers });
            this.setState({ dateVers: new Date(moment(data.dateVers, 'DD/MM/YYYY').format('YYYY-MM-DD')) });
            this.setState({ libVers: data.libVers });
            this.setState({ idTypeVers: data.idTypeVers });
            this.setState({ cmtVers: data.cmtVers });
            this.setState({ modif: true });
            this.setState({ selectedVers: data });
            window.scrollTo(0, 0);
        }
    }

    annule_encaissement() {
        this.setState({ numRecu: "" });
        this.setState({ montVers: "" });
        this.setState({ typeVers: "" });
        this.setState({ anneeVers: "" });
        this.setState({ idTypeVers: null });
        this.setState({ libVers: "" });
        this.setState({ idInsc: "" });
        this.setState({ cmtVers: "" });
        this.setState({ source: null });
        this.setState({ classe: null });
        this.setState({ modif: false });
        this.forceUpdate();
    }

    enreg_encaissement() {
        alert(this.state.libVers);
        if (this.state.idTypeVers == null ||this.state.montVers == "" || this.state.libVers == "" || this.state.source == null || this.state.source == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else {
            if (this.state.modif == false) {
                let check = false;
                let mtRestant = 0;
                if (this.state.libVers == 'Frais scolarité') {
                    mtRestant = this.gemsysService.getSelectedData(this.state.liste_etudiants_by_classe, this.state.source).scolRestant;
                    check = true;
                } else if (this.state.libVers == 'Frais inscription' || this.state.libVers == 'Frais réinscription') {
                    mtRestant = this.gemsysService.getSelectedData(this.state.liste_etudiants_by_classe, this.state.source).inscRestant;
                    check = true;
                }
                if (check == true && this.state.montVers > mtRestant) {
                    this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Le montant saisi est supérieur au montant restant' });
                    return;
                }
                let encaissement = {
                    numRecu: '0',
                    montVers: this.state.montVers,
                    typeVers: this.state.typeVers,
                    dateVers: moment(this.state.dateVers, 'DD/MM/YYYY').format('DD/MM/YYYY'),
                    anneeVers: this.gemsysService.get_rannee(),
                    libVers: this.state.libVers,
                    idTypeVers: {id: this.state.idTypeVers},
                    idInsc: this.state.source,
                    cmtVers: this.state.cmtVers,
                    refVers: this.state.refVers
                };
                this.setState({ showindicator: true });
                this.gemsysService.AddVersement(encaissement).then(reponse => {
                    if (reponse != undefined && reponse != null) {
                        this.resultat(reponse.code, reponse.contenu);
                        if (reponse.code == '200') {
                            this.gemsysService.generer_liste_recu(reponse.id);
                        }
                    }
                });
            } else {
                let encaissement = this.state.selectedVers;
                encaissement.montVers = this.state.montVers;
                encaissement.typeVers = this.state.typeVers;
                encaissement.dateVers = moment(this.state.dateVers, 'DD/MM/YYYY').format('DD/MM/YYYY');
                encaissement.libVers = this.state.libVers;
                encaissement.cmtVers = this.state.cmtVers;
                encaissement.idTypeVers= {id: this.state.idTypeVers};
                this.setState({ showindicator: true });
                this.gemsysService.UpdateVersement(encaissement).then(reponse => {
                    if (reponse != undefined && reponse != null) {
                        this.resultat(reponse.code, reponse.contenu);
                    }
                });
            }
        }
    }

    get_id_inscrit(codeClasse, matricule) {
        this.gemsysService.get_id_inscrit_by_codeClasseAndMatricule(codeClasse, matricule).then(data => this.setState({ inscrit: data }, () => {
            if (this.state.inscrit != null && this.state.inscrit != undefined) {
                let lIdInscrit = this.state.inscrit.map(x => {
                    return { idInsc: x.idInsc, label: x.typeInsc }
                });
                this.setState({ idInsc: lIdInscrit[0] });
            }
        }));
    }

    export() {
        this.dt.exportCSV();
    }
    header_encaissement() {
        return <div>
            <Button style={{ width: 120 }} label="Exporter" className="p-button-help" icon="pi pi-external-link" onClick={this.export} />
        </div>
    }

    header_total() {
        return <div>
            <label style={{ fontSize: 18, color: 'green', fontWeight: 'bold' }} >{this.state.total} GNF</label>
        </div>
    }
    actionTemplate(rowData) {
        const items = [
            {
                label: 'Modifier',
                icon: 'pi pi-pencil',
                command: (e) => {
                    this.edit_encaissement(rowData);
                }
            },
            {
                label: 'Supprimer',
                icon: 'pi pi-times',
                command: (e) => {
                    this.conf_delete_encaissement(rowData);
                }
            },
            {
                label: 'Editer reçu',
                icon: 'pi pi-print',
                command: (e) => {
                    this.setState({ selectedVers: rowData, showindicator: true });
                    this.gemsysService.generer_liste_recu(rowData.numRecu).then(reponse => {
                        this.setState({ showindicator: false });
                    });
                }
            }
        ]
        return <SplitButton loading={this.state.showindicator} style={{ width: 95 }} label="Reçu" icon="pi pi-print" onClick={(e) => {
            this.setState({ selectedVers: rowData, showindicator: true });
            this.gemsysService.generer_liste_recu(rowData.numRecu).then(reponse => {
                this.setState({ showindicator: false });
            });
        }} model={items} />


    }

    get_liste_etudiants_by_classe(codeClasse) {
        this.gemsysService.getliste_inscrit_for_versement(codeClasse).then(data => {
            if (data !== null && data !== undefined) {
                let liste = data.map(x => {
                    return { value: x.idInsc, label: x.matricule.nomEleve + ' ' + x.matricule.prenomEleve, scolRestant: x.fraisScolRestant, inscRestant: x.fraisInscRestant, matricule: x.matricule.matricule }
                });
                let liste_etudiants_by_classe = [{ value: '-1', label: 'Non applicable', scolRestant: 0, inscRestant: 0, matricule: '-1' }].concat(liste);
                let retudiants = [{ value: 'Tous', label: 'Elève' }].concat(liste);

                this.setState({
                    liste_etudiants_by_classe: liste_etudiants_by_classe,
                    retudiants: retudiants,
                })

            }
        });
    }

    delete_encaissement() {
        if (this.state.selectedVers == null || this.state.selectedVers == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.setState({ showindicator: true });
            this.gemsysService.DeleteVersement(this.state.selectedVers).then(data => this.resultat(data.code, data.contenu));
        }
    }

    conf_delete_encaissement(data) {
        if (data !== null && data !== undefined) {
            this.setState({ selectedVers: data });
            this.setState({ msg: data.numRecu });
            this.setState({ visible: true });
        }
    }
    render() {
        let semestres = null;

        if (this.state.semestres != null && this.state.semestres != undefined) {
            let lc = this.gemsysService.get_userconnected().idEtabl.typeEtabl == 'Université' ? 'S' : '';
            semestres = this.state.semestres.map(x => {
                return { value: x.codeClasse, label: lc + x.libClasse + " " + x.optClasse }
            });
        }


        let rclasses = [{ value: 'Tous', label: 'Classe' }].concat(semestres);

        const dialogFooter = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible: false });
                    this.delete_encaissement();
                }} label="Oui" />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );
        return (
            <div className="p-fluid">
                <Toast ref={(el) => this.toast = el} />
                <Dialog header="Confirmation" visible={this.state.visible} style={{ width: '250px' }} modal={true} footer={dialogFooter} onHide={() => this.setState({ visible: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>
                <div className="grid">
                    <div className="col-12">
                        <div className="card card-w-title">
                            <div className="grid " >
                                <div className="col-12 md:col-3">
                                    <label htmlFor="montVers">Date *</label>
                                </div>
                                <div className="col-12 md:col-3">
                                    <Calendar placeholder={moment(new Date()).format('DD/MM/YYYY')} dateFormat="dd/mm/yy" value={this.state.dateVers} onChange={(e) => this.setState({ dateVers: e.target.value })} />
                                </div>
                                <div className="col-12 md:col-3">
                                    <label htmlFor="montVers">Montant *</label>
                                </div>
                                <div className="col-12 md:col-3">
                                    <InputNumber id="montVers" value={this.state.montVers} onChange={(e) => this.setState({ montVers: e.value })} />
                                </div>
                                <div className="col-12 md:col-3">
                                    <label htmlFor="mntant">Libellé *</label>
                                </div>
                                <div className="col-12 md:col-3" >
                                    <Dropdown options={this.state.liste_type} value={this.state.idTypeVers} onChange={(e) => this.setState({ idTypeVers: e.target.value, typeVers: 'Espèce' },()=>{
                                        this.setState({libVers: this.gemsysService.getSelectedData(this.state.liste_type,this.state.idTypeVers).label})
                                    })} autoWidth={false} filter={true} filterBy='label' filterMatchMode='contains' panelStyle={{ width: '300px' }} />
                                </div>
                                <div className="col-12 md:col-3">
                                    <label htmlFor="classe">Classe *</label>
                                </div>
                                <div className="col-12 md:col-3" >
                                    <Dropdown options={semestres} filter={true} optionLabel='label' value={this.state.classe} onChange={(e) => {
                                        this.setState({ classe: e.value }, () => {
                                            this.get_liste_etudiants_by_classe(this.state.classe);
                                        })
                                    }} autoWidth={false} filterBy='label' filterMatchMode='contains' panelStyle={{ width: '300px' }} />
                                </div>
                                <div className="col-12 md:col-3">
                                    <label htmlFor="etudiant">Source *</label>
                                </div>
                                <div className="col-12 md:col-3" >
                                    <Dropdown options={this.state.liste_etudiants_by_classe} optionLabel="label" filter={true} value={this.state.source} onChange={(e) => {
                                        this.setState({ source: e.target.value })
                                    }} autoWidth={false} filterBy='label' filterMatchMode='contains' panelStyle={{ width: '550px' }}
                                        itemTemplate={(option) => {
                                            if (!option.value) {
                                                return option.label;
                                            } else {
                                                return (
                                                    <div className="grid" >
                                                        <div className="col-12 md:col-3">
                                                            <label>{option.matricule}</label>
                                                        </div>
                                                        <div className="col-12 md:col-5">
                                                            <label>{option.label}</label>
                                                        </div>
                                                        <div className="col-12 md:col-2">
                                                            <label style={{ fontWeight: 'bold' }}>{this.gemsysService.formaterValueSep(option.scolRestant)}</label>
                                                        </div>
                                                        <div className="col-12 md:col-2">
                                                            <label style={{ fontWeight: 'bold' }}>{this.gemsysService.formaterValueSep(option.inscRestant)}</label>
                                                        </div>
                                                    </div>
                                                );
                                            }
                                        }} />
                                </div>
                                <div className="col-12 md:col-3">
                                    <label htmlFor="cmtVers">Commentaire </label>
                                </div>
                                <div className="col-12 md:col-3" >
                                    <InputText id="cmtVers" value={this.state.cmtVers} onChange={(e) => this.setState({ cmtVers: e.target.value })} />
                                </div>
                            </div>

                            <center>
                                <div className="grid" style={{ width: 300, marginTop: '0px' }}>
                                    <div className="col"><Button label="Annuler" icon="pi pi-ban" onClick={() => this.annule_encaissement()} /></div>
                                    <div className="col"><Button loading={this.state.showindicator} label="Valider" icon="pi pi-check" onClick={() => this.enreg_encaissement()} /></div>
                                </div>
                            </center>
                            <div className="grid">
                                <i className="pi pi-search"></i>
                                <span style={{ fontSize: 14, marginLeft: 10, color: '#999999' }}>Options de recherche</span>
                                <div className="col-12">
                                    <div className="grid">
                                        <div className="col-12 md:col-2">
                                            <Calendar id="pd1" placeholder={moment(new Date()).format('DD/MM/YYYY')} dateFormat="dd/mm/yy" value={this.state.pd1} onChange={(e) => this.setState({ pd1: e.target.value })} />
                                        </div>
                                        <div className="col-12 md:col-2">
                                            <Calendar id="pd2" placeholder={moment(new Date()).format('DD/MM/YYYY')} dateFormat="dd/mm/yy" value={this.state.pd2} onChange={(e) => this.setState({ pd2: e.target.value })} />
                                        </div>
                                        <div className="col-12 md:col-2" >
                                            <Dropdown options={this.state.rlibelles} value={this.state.rlibelle} placeholder="Selectionner libelle" filter={true} onChange={(e) => this.setState({ rlibelle: e.target.value })} autoWidth={false} />
                                        </div>
                                        <div className="col-12 md:col-2" >
                                            <Dropdown options={rclasses} value={this.state.rclasse} optionLabel="label" placeholder="Selectionner classe" filter={true} onChange={(e) => {
                                                this.setState({ rclasse: e.value }, () => {
                                                    this.get_liste_versements();
                                                    this.get_liste_etudiants_by_classe(this.state.rclasse);
                                                })
                                            }} autoWidth={false} />
                                        </div>
                                        <div className="col-12 md:col-2" >
                                            <Dropdown options={this.state.retudiants} value={this.state.rrefVers} placeholder="Non applicable" optionLabel="label" filter={true} onChange={(e) => this.setState({ rrefVers: e.target.value })} autoWidth={false} />
                                        </div>
                                        <div className='col-12 md:col-2' >
                                            <Button icon='pi pi-search' loading={this.state.showindicator} label='Rechercher' onClick={() => this.get_liste_versements()} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <DataTable value={this.state.liste_versements} paginator={true} rows={20} alwaysShowPaginator={true} paginatorPosition="top" selectionMode="single" paginatorLeft={this.header_encaissement()} paginatorRight={this.header_total()}
                                onRowClick={e => this.setState({ selectedVers: e.data })} onRowSelect={e => this.setState({ selectedVers: e.data })} style={{ minHeight: '500px' }}
                                responsive={true} selection={this.state.selectedVers} ref={(el) => { this.dt = el; }} exportFilename="liste_des_encaissements" csvSeparator=';'
                                className="p-datatable-gridlines" showGridlines responsiveLayout="scroll">
                                <Column header="Action" body={this.actionTemplate.bind(this)} style={{ width: '7%' }} />
                                <Column field="dateVers" header="Date" style={{ width: '8%' }} sortable={true} />
                                <Column field="montVers" body={(rowData, column) => {
                                    return <div>
                                        <span style={{ color: 'green' }}>{this.gemsysService.formaterValueSep(parseFloat(rowData.montVers))}</span>
                                    </div>
                                }} header="Montant" style={{ width: '10%' }} sortable={true} />
                                <Column field="libVers" header="Libellé " style={{ width: '13%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="matrVers" header="Matricule" style={{ width: '10%' }} filter={true} filterMatchMode='contains' />
                                <Column field="nomVers" body={(rowData) => {
                                    return <div>
                                        <span>{rowData.nomVers}</span>
                                    </div>
                                }} header="Nom" style={{ width: '20%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="classeVers" body={(rowData) => {
                                    return <div>
                                        <span>{rowData.classeVers}</span>
                                    </div>
                                }} header="Classe" style={{ width: '17%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="cmtVers" header="Commentaire" style={{ width: '15%' }} filter={true} filterMatchMode='contains' />
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}