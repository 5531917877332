import React, { Component } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import './styles.css';
import { SplitButton } from 'primereact/splitbutton';
import GestGemsysService from '../service/GestGemsysService';
import { ProgressSpinner } from 'primereact/progressspinner';

export class EditionAssignation extends Component {
    constructor() {
        super();
        this.state = {
            pd1: new Date(),
            pd2: new Date(),
            rcode_enseign: 'Tous',
            liste_data: [],
            rcode_classe: null,
            rmatr_pers: null,
            liste_enseignement: [{ label: 'Enseignement', value: 'Tous' }],
            liste_classes: [],
            liste_pers: [{ label: 'Personnel', value: 'Tous' }],
            showindicator: false,
            items: [
                {
                    label: 'Format PDF',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.conf_print('PDF');
                    }
                },
                {
                    label: 'Format XLS',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.conf_print('XLS');
                    }
                },
                {
                    label: 'Format CSV',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.dt.exportCSV();
                    }
                }
            ],
        };
        this.conf_print = this.conf_print.bind(this);
        this.gemsysService = new GestGemsysService();
    }

    get_liste_enseignement() {
        this.setState({ showindicator: true });
        this.gemsysService.getEnseignement().then(data => {
            this.setState({ showindicator: false });
            if (data !== null && data !== undefined && data !== '') {
                if (data.length > 0) {
                    let liste = data.map(x => {
                        return { value: x.codeEnseign, label: x.libEnseign }
                    });
                    this.setState({ liste_enseignement: this.state.liste_enseignement.concat(liste) });
                }
            }
        });
    }

    get_liste_personnel() {
        this.setState({ showindicator: true });
        this.gemsysService.getPersonnel().then(data => {
            this.setState({ showindicator: false });
            if (data !== null && data !== undefined && data !== '') {
                if (data.length > 0) {
                    let liste = data.map(x => {
                        return { value: x.matrPers, label: x.prenomPers + " " + x.nomPers, categoriePers: x.categorie }
                    });
                    this.setState({ liste_pers: this.state.liste_pers.concat(liste) });
                }
            }
        });
    }

    get_liste_data() {
        let rcode_enseign = 'Tous';
        let rcode_classe = 'Tous';
        let rmatr_pers = 'Tous';
        if (this.state.rcode_enseign !== undefined && this.state.rcode_enseign !== null && this.state.rcode_enseign !== 'Tous') {
            rcode_enseign = this.state.rcode_enseign;
        }
        if (this.state.rcode_classe !== undefined && this.state.rcode_classe !== null && this.state.rcode_classe !== 'Tous') {
            rcode_classe = this.state.rcode_classe;
        }
        if (this.state.rmatr_pers !== undefined && this.state.rmatr_pers !== null && this.state.rmatr_pers !== 'Tous') {
            rmatr_pers = this.state.rmatr_pers;
        }
        this.setState({
            showindicator: true,
        });
        this.gemsysService.get_liste_assignation(rcode_enseign, rcode_classe, rmatr_pers).then(data => {
            this.setState({ liste_data: data, showindicator: false });
        });
    }

    conf_print(format) {
        let rcode_enseign = 'Tous';
        let rcode_classe = 'Tous';
        let rmatr_pers = 'Tous';
        if (this.state.rcode_enseign !== undefined && this.state.rcode_enseign !== null && this.state.rcode_enseign !== 'Tous') {
            rcode_enseign = this.state.rcode_enseign;
        }
        if (this.state.rcode_classe !== undefined && this.state.rcode_classe !== null && this.state.rcode_classe !== 'Tous') {
            rcode_classe = this.state.rcode_classe;
        }
        if (this.state.rmatr_pers !== undefined && this.state.rmatr_pers !== null && this.state.rmatr_pers !== 'Tous') {
            rmatr_pers = this.state.rmatr_pers;
        }
        this.gemsysService.generer_liste_assignation(rcode_enseign, rcode_classe, rmatr_pers, format);
    }

    componentDidMount() {
        this.get_liste_enseignement();
        this.get_liste_personnel();
        this.get_liste_data();
    }

    get_liste_classe(codeEnseign) {
        this.gemsysService.getClasse().then(data => {
            if (data !== null && data !== undefined) {
                let liste = data.map(x => {
                    return { value: x.codeClasse, label: x.libClasse + " " + x.optClasse, codeEnseign: x.codeEnseign.codeEnseign }
                }).filter(function (el) {
                    return el.codeEnseign == codeEnseign;
                });
                this.setState({ liste_classes: liste });
            }
        });
    }


    render() {
        return (
            <div className="p-fluid">
                <div className="grid">
                    <div className="col-12">
                        <div className="card card-w-title">
                            <i className="pi pi-search"></i>
                            <span style={{ fontSize: 14, marginLeft: 10, color: '#999999' }}>Options de recherche</span>
                            <div className="grid">
                                <div className="col-12 md:col-3" >
                                    <Dropdown options={this.state.liste_enseignement} placeholder="Sélectionner" value={this.state.rcode_enseign} onChange={(e) => this.setState({ rcode_enseign: e.value }, () => {
                                        this.get_liste_classe(this.state.rcode_enseign);
                                    })} autoWidth={false} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' />
                                </div>
                                <div className="col-12 md:col-3" >
                                    <Dropdown inputId="dropdown" options={this.state.liste_classes} placeholder="Classe" value={this.state.rcode_classe} onChange={(e) => this.setState({ rcode_classe: e.target.value }, () => {
                                        this.get_liste_data();
                                    })} filter={true} filterBy='label' filterMatchMode='contains' panelStyle={{ width: '400px' }} />
                                </div>
                                <div className="col-12 md:col-3">
                                    <Dropdown options={this.state.liste_pers} value={this.state.rmatr_pers} optionLabel='label' filter={true} filterBy='label,value' filterMatchMode='contains' onChange={(e) => this.setState({ rmatr_pers: e.target.value }, () => {
                                        this.get_liste_data();
                                    })} placeholder="Personnel"  itemTemplate={(option) => {
                                        if (!option.value) {
                                            return option.label;
                                        }
                                        else {
                                            return (
                                                <div className="grid" >
                                                    <div className="col-12 md:col-3">
                                                        <label>{option.value}</label>
                                                    </div>
                                                    <div className="col-12 md:col-9">
                                                        <label>{option.label}</label>
                                                    </div>
                                                </div>
                                            );
                                        }
                                    }} autoWidth={false} panelStyle={{ width: '500px' }} />
                                </div>
                                <div className="col-12 md:col-2">
                                    <SplitButton label="Rechercher" style={{ width: '140px' }} onClick={(e) => this.get_liste_data()} icon="pi pi-search" model={this.state.items} />
                                </div>
                                <div className="col-12 md:col-1">
                                    {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                </div>
                            </div>
                            <DataTable value={this.state.liste_data} style={{ minHeight: '600px' }} ref={(el) => { this.dt = el; }}
                                dataKey="ordre" className="p-datatable-gridlines" showGridlines responsiveLayout="scroll" paginator rows={10} >
                                <Column field="ordre" header="#" style={{ width: '4%' }} />
                                <Column field="matrPers" header="Matricule" style={{ width: '10%' }} filter={true} filterMatchMode='contains' />
                                <Column field="nomEns" header="Nom" style={{ width: '10%' }} filter={true} filterMatchMode='contains' />
                                <Column field="prenomEns" header="Prénom" style={{ width: '17%' }} filter={true} filterMatchMode='contains' />
                                <Column field="classe" header="Classe" style={{ width: '20%' }} filter={true} filterMatchMode='contains'  />
                                <Column field="matiere" header="Matiere" style={{ width: '20%' }} filter={true} filterMatchMode='contains'  />
                                <Column field="nbEns" header="NbH" style={{ width: '7%' }} sortable={true} />
                                <Column field="dateDebEns" body={(rowData, Column) => {
                                    return <div>
                                        <span>{rowData.dateDebEns + " " + rowData.dateFinEns}</span>
                                    </div>
                                }} header="Periode" style={{ width: '12%' }} filter={true} filterMatchMode='contains'  />
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}