import React, { useEffect } from 'react';
import { classNames } from 'primereact/utils';
import { RadioButton } from 'primereact/radiobutton';
import { InputSwitch } from 'primereact/inputswitch';
import { Button } from 'primereact/button';
import GestGemsysService from './service/GestGemsysService';

const AppConfig = (props) => {


    const componentThemes = [
        { name: "blue", color: "#42A5F5" },
        { name: "green", color: "#009a76" },
        { name: "lightgreen", color: "#9CCC65" },
        { name: "purple", color: "#AB47BC" },
        { name: "deeppurple", color: "#7E57C2" },
        { name: "indigo", color: "#5C6BC0" },
        { name: "orange", color: "#FFA726" },
        { name: "cyan", color: "#26C6DA" },
        { name: "pink", color: "#EC407A" },
        { name: "teal", color: "#26A69A" },
    ];

    const appservice = new GestGemsysService();
    var menuThemes = appservice.get_managed_etabl();

    const onConfigButtonClick = (event) => {
        props.onConfigButtonClick(event);
        event.preventDefault();
    };

    const getMenuThemes = () => {
        if (props.colorScheme === 'light') {
            return (
                <div className="layout-themes">
                    {
                        menuThemes.map(theme => {
                            const checkStyle = theme.name === 'white' ? 'black' : 'white';
                            return (
                                <div key={theme.name}>
                                    <button type="button" className="p-link" style={{ cursor: 'pointer', 'backgroundColor': theme.color, marginRight: 25 }} onClick={() => {
                                        props.changeMenuTheme(theme.name, theme.logoColor, theme.componentTheme);
                                        appservice.switch_etabl(theme);
                                    }} title={theme.idEtabl.codeEtabl}>
                                        {props.menuTheme === 'layout-sidebar-' + theme.name && <span className="check p-d-flex p-ai-center p-jc-center">
                                            <i className="pi pi-check" style={{ color: checkStyle }}></i>
                                            <br />
                                            <br />
                                            <label style={{ marginTop: 30, fontWeight: 'bold' }}>{theme.idEtabl.codeEtabl}</label>
                                        </span>}
                                    </button>
                                </div>
                            )
                        })
                    }
                </div>
            );
        }

        return (
            <div>
                <p>Menu themes are only available in light mode and static, slim, overlay menu modes by design as large surfaces can emit too much brightness in dark mode.</p>
            </div>
        );
    };

    const getComponentThemes = () => {
        return (
            <div className="layout-themes">
                {
                    componentThemes.map(theme => {
                        return (
                            <div key={theme.name}>
                                <button type="button" className="p-link" style={{ cursor: 'pointer', 'backgroundColor': theme.color }} onClick={() => props.changeComponentTheme(theme.name)} title={theme.name}>
                                    {props.componentTheme === theme.name && <span className="check flex align-items-center justify-content-center">
                                        <i className="pi pi-check" style={{ color: 'white' }}></i>
                                    </span>}
                                </button>
                            </div>
                        )
                    })
                }
            </div>
        );
    };

    useEffect(() => {
        let theme = null;
        let userconnected=appservice.get_userconnected();
        if (userconnected.idEtabl.typeEtabl == 'Université') {
            theme = {
                name: "darkgray",
                color: "#343a40",
                logoColor: "white",
                componentTheme: "blue",
                idEtabl: userconnected.idEtabl,
                idUser: userconnected.idUser
            };
        } else if (userconnected.idEtabl.typeEtabl == 'Pré-universitaire') {
            theme={
                name: "blue",
                color: "#1976D2",
                logoColor: "white",
                componentTheme: "blue",
                idEtabl: userconnected.idEtabl,
                idUser: userconnected.idUser
            };
        } else {
            theme={
                name: "green",
                color: "#0097a7",
                logoColor: "white",
                componentTheme: "teal",
                idEtabl: userconnected.idEtabl,
                idUser: userconnected.idUser
            };
        }
        props.changeMenuTheme(theme.name, theme.logoColor, theme.componentTheme);
    }, []);

    const componentThemesElement = getComponentThemes();
    const menuThemesElement = getMenuThemes();
    const configClassName = classNames('layout-config', { 'layout-config-active': props.configActive })
    return (
        <div id="layout-config">
            <Button className="layout-config-button" icon="pi pi-cog p-button-icon" type="button" onClick={onConfigButtonClick}></Button>
            <div className={configClassName} onClick={props.onConfigClick}>
                <br />
                <center>
                    <i className="pi pi-check-circle" style={{ color: '#003366', fontSize: 50 }}></i>
                    <br />
                    <h5><b>Etablissements</b></h5>
                </center>
                <hr />
                <p>Sélectionner l'établissement dans lequel vous souhaitez basculer</p>
                {menuThemesElement}
            </div>
        </div>
    );
}

export default AppConfig;
