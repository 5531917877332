import Numeral from 'numeral';
import 'moment';
import 'moment/locale/fr';
import moment from 'moment';
export default class GestGemsysService {
    constructor() {
        this.state = { 
          //  wsurl: 'http://localhost:8170/',
            wsurl: 'https://opt-api224.com/ws_gemsys/',

           // wsurl: 'http://10.200.1.250:9077/ws_gemsys/',
           // wsurl: 'http://localhost:9077/ws_gemsys/',

            userconnected: JSON.parse(localStorage.getItem('userconnected')),
            liste_dossier: JSON.parse(localStorage.getItem('dossiers')),
            liste_etabl: JSON.parse(localStorage.getItem('etablissements')),
            rannee: localStorage.getItem('rannee'),
            typeEtabl: localStorage.getItem('type_etabl'),
        };
    } 
 
    getSelectedData(liste, id) {
        return liste.filter(function (el) {
            return el.value === id;
        })[0];
    }

    get_userconnected() {
        return JSON.parse(localStorage.getItem('userconnected'));
    }
    get_rannee() {
        return localStorage.getItem('rannee');
    }
    get_type_etabl() {
        return this.state.userconnected.idEtabl.typeEtabl;
    }

    get_categorie_etabl() {
        return this.state.userconnected.idEtabl.categorieEtabl;
    }

    formaterValueSep(value) {
        return Numeral(parseFloat(value)).format('0,0');
    }
    formatDecimal(value) {
        return Math.round(value * 100) / 100;
    }

    validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }


    // Affectation des variables de state
    onInputChange = (e, name, typeInput, data) => {
        const val = (e.target && e.target.value || e.value) || '';
        if (typeInput == 'fk') {
            data[`${name}`]['id'] = val;
        } else {
            data[`${name}`] = val;
        }
        return data;
    }

    // Affectation des variables de state
    onCalendarChange = (e, name, data) => {
        const val = (e.target.value) || '';
        data[`${name}`] = val;
        return data;
    }


    wsurl(){
        return this.state.wsurl;
    }

    validateTelephone(value) {
        return value.match(/\d/g).length == 9 && value[0] === '6';
    }

   
    switch_etabl(data) {
        localStorage.setItem('userconnected', JSON.stringify(data.idUser));
        this.getDossier().then(dossiers => {
            if (dossiers !== null && dossiers != undefined && dossiers !== '' && dossiers.length > 0) {
                let liste = dossiers.map(x => {
                    return { value: x.annee, label: x.annee }
                });
                localStorage.setItem('dossiers', JSON.stringify(liste));
                localStorage.setItem('rannee', liste[liste.length - 1].value);
            }
        });
        window.location = "#/dashboard/" + data.idEtabl.idEtabl;
    }
    get_managed_etabl() {
        return this.state.liste_etabl;
    }

    get_liste_annee() {
        return this.state.liste_dossier;
    }

    generer_resultat_sondage(ridProg, format) {
        var lien = this.state.wsurl + 'etats/generer_resultat_sondage?ridProg=' + ridProg + '&format=' + format + '&rannee=' + this.get_rannee() + '&iduser=' + this.get_userconnected().numUtil;
        window.location = lien;
    }

    get_liste_titulaire(rtype, rref) {
        var lien = this.state.wsurl + 'etats/get_liste_titulaire?rtype=' + rtype + '&rref=' + rref + '&rannee=' + this.get_rannee() + '&iduser=' + this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_dashboard(rid_etabl) {
        var lien = this.state.wsurl + 'etats/get_dashboard?rid_etabl=' + rid_etabl + '&rannee=' + this.get_rannee() + '&iduser=' + this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    generer_fiche_notes_vierge(rcodemat, rcode_classe, rmodule) {
        var lien = this.state.wsurl + 'etats/generer_fiche_notes_vierge?rcodemat=' + rcodemat + '&rmodule=' + rmodule + '&rcode_classe=' + rcode_classe + '&rannee=' + this.get_rannee() + '&iduser=' + this.get_userconnected().numUtil;
        window.location = lien;
    }

    generer_fiche_notes_vierge_xls(rcodemat, rcode_classe, rmodule) {
        var lien = this.state.wsurl + 'etats/generer_fiche_notes_vierge_xls?rcodemat=' + rcodemat + '&rmodule=' + rmodule + '&rcode_classe=' + rcode_classe + '&rannee=' + this.get_rannee() + '&iduser=' + this.get_userconnected().numUtil;
        window.location = lien;
    }

    generer_fiche_notes(rcodemat, rstatut, rcode_classe, rmodule) {
        var lien = this.state.wsurl + 'etats/generer_fiche_notes?rcodemat=' + rcodemat + '&rmodule=' + rmodule + '&rstatut=' + rstatut + '&rcode_classe=' + rcode_classe + '&rannee=' + this.get_rannee() + '&iduser=' + this.get_userconnected().numUtil;
        window.location = lien;
    }

    get_liste_notes(rcodemat, rstatut, rcode_classe, rmodule) {
        var lien = this.state.wsurl + 'etats/get_liste_notes?rmodule=' + rmodule + '&rcode_classe=' + rcode_classe + '&rcodemat=' + rcodemat + '&rstatut=' + rstatut + '&rannee=' + this.get_rannee() + '&iduser=' + this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }


    generer_resultat_classe(rcode_classe, rmodule, format) {
        var lien = this.state.wsurl + 'etats/generer_resultat_classe?rmodule=' + rmodule + '&rcode_classe=' + rcode_classe + '&rannee=' + this.get_rannee() + '&iduser=' + this.get_userconnected().numUtil + '&format=' + format;
        window.location = lien;
    }

    get_resultat_classe(rcode_classe, rmodule) {
        var lien = this.state.wsurl + 'etats/get_resultat_classe?rmodule=' + rmodule + '&rcode_classe=' + rcode_classe + '&rannee=' + this.get_rannee() + '&iduser=' + this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }


    generer_resultat_cycle(ids, rcode_classe, format) {
        var lien = this.state.wsurl + 'etats/generer_resultat_cycle?ids=' + ids + '&rcode_classe=' + rcode_classe + '&rannee=' + this.get_rannee() + '&iduser=' + this.get_userconnected().numUtil + '&format=' + format;
        window.location = lien;
    }

    generer_attestation_selection(ids) {
        var lien = this.state.wsurl + 'etats/generer_attestation_selection?ids=' + ids + '&rannee=' + this.get_rannee() + '&iduser=' + this.get_userconnected().numUtil;
        window.location = lien;
    }

    generer_diplome(ids, rlib, rcode_classe) {
        var lien = this.state.wsurl + 'etats/generer_diplome?ids=' + ids + '&rlib=' + rlib + '&rcode_classe=' + rcode_classe + '&rannee=' + this.get_rannee() + '&iduser=' + this.get_userconnected().numUtil;
        window.location = lien;
    }

    generer_liste_diplome(ids, rcode_classe,format) {
        var lien = this.state.wsurl + 'etats/generer_liste_diplome?ids=' + ids +  '&rcode_classe=' + rcode_classe + '&format=' + format + '&rannee=' + this.get_rannee() + '&iduser=' + this.get_userconnected().numUtil;
        window.location = lien;
    }

    generer_attestation_niveau(ids, rlib, rcode_classe) {
        var lien = this.state.wsurl + 'etats/generer_attestation_niveau?ids=' + ids + '&rlib=' + rlib + '&rcode_classe=' + rcode_classe + '&rannee=' + this.get_rannee() + '&iduser=' + this.get_userconnected().numUtil;
        window.location = lien;
    }
    generer_attestation_cycle(ids, rlib, rcode_classe) {
        var lien = this.state.wsurl + 'etats/generer_attestation_cycle?ids=' + ids + '&rlib=' + rlib + '&rcode_classe=' + rcode_classe + '&rannee=' + this.get_rannee() + '&iduser=' + this.get_userconnected().numUtil;
        window.location = lien;
    }

    generer_bulletin_note(ids, rlib, rcode_classe, format) {
        var lien = this.state.wsurl + 'etats/generer_bulletin_note?ids=' + ids + '&format=' + format + '&rlib=' + rlib + '&rcode_classe=' + rcode_classe + '&rannee=' + this.get_rannee() + '&iduser=' + this.get_userconnected().numUtil;
        window.location = lien;
    }
    generer_releve_note(ids, rlib, rcode_classe, format) {
        var lien = this.state.wsurl + 'etats/generer_releve_note?ids=' + ids + '&format=' + format + '&rlib=' + rlib + '&rcode_classe=' + rcode_classe + '&rannee=' + this.get_rannee() + '&iduser=' + this.get_userconnected().numUtil;
        window.location = lien;
    }

    generer_etat_paie_boursier(rcode_enseign, rcode_classe, rsexe, rstac, rstaf, rval, rsit_matrimonial, rgrpsang, titreDoc, format) {
        var lien = this.state.wsurl + 'etats/generer_etat_paie_boursier?rcode_enseign=' + rcode_enseign + '&rcode_classe=' + rcode_classe + '&rsexe=' + rsexe + '&rstac=' + rstac + '&rstaf=' + rstaf + '&rsit_matrimonial=' + rsit_matrimonial + '&rval=' + rval + '&rgrpsang=' + rgrpsang + '&titreDoc=' + titreDoc + '&format=' + format + '&rannee=' + this.get_rannee() + '&iduser=' + this.get_userconnected().numUtil;
        window.location = lien;
    }
     // Impression fiche paie
     generer_fiche_paie(ids) {
        var lien = this.state.wsurl + 'etats/generer_fiche_paie?ids=' + ids + '&rannee=' + this.get_rannee() + '&iduser=' + this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                // 'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(function(t) {
            return t.blob().then((b)=>{
                var a = document.createElement("a");
                a.href = URL.createObjectURL(b);
                a.setAttribute("download", "FICHE_PAIE_"+ moment(new Date()).format('DDMMYYYYhhmmss')+".pdf");
                a.click();
            }
            );
        });
    }
     
/* 
    generer_fiche_paie(ids) {
        var lien = this.state.wsurl + 'etats/generer_fiche_paie?ids=' + ids + '&rannee=' + this.get_rannee() + '&iduser=' + this.get_userconnected().numUtil;
        window.location = lien;
    } */

     // Impression fiche abonné
     generer_fiche_insc(ids) {
        var lien = this.state.wsurl + 'etats/generer_fiche_insc?ids=' + ids + '&rannee=' + this.get_rannee() + '&iduser=' + this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
            }
        }).then(function(t) {
            return t.blob().then((b)=>{
                var a = document.createElement("a");
                a.href = URL.createObjectURL(b);
                a.setAttribute("download", "FICHE_INSCRIPTION_"+ moment(new Date()).format('DDMMYYYYhhmmss')+".pdf");
                a.click();
            }
            );
        });
    }

/* 
    generer_fiche_insc(ids) {
        var lien = this.state.wsurl + 'etats/generer_fiche_insc?ids=' + ids + '&rannee=' + this.get_rannee() + '&iduser=' + this.get_userconnected().numUtil;
        window.location = lien;
    }
 */

    generer_attestation_insc(ids) {
        var lien = this.state.wsurl + 'etats/generer_attestation_insc?ids=' + ids + '&rannee=' + this.get_rannee() + '&iduser=' + this.get_userconnected().numUtil;
        window.location = lien;
    }
    generer_certificat_scol(ids) {
        var lien = this.state.wsurl + 'etats/generer_certificat_scol?ids=' + ids + '&rannee=' + this.get_rannee() + '&iduser=' + this.get_userconnected().numUtil;
        window.location = lien;
    }


    generer_liste_eleve_brute(rcode_enseign, rcode_classe, rsexe, rstac, rstaf, rval, rsit_matrimonial, rgrpsang, titreDoc, format) {
        var lien = this.state.wsurl + 'etats/generer_liste_eleve_brute?rcode_enseign=' + rcode_enseign + '&rcode_classe=' + rcode_classe + '&rsexe=' + rsexe + '&rstac=' + rstac + '&rstaf=' + rstaf + '&rsit_matrimonial=' + rsit_matrimonial + '&rval=' + rval + '&rgrpsang=' + rgrpsang + '&titreDoc=' + titreDoc + '&format=' + format + '&rannee=' + this.get_rannee() + '&iduser=' + this.get_userconnected().numUtil;
        window.location = lien;
    }
    generer_liste_eleve_photo(rcode_enseign, rcode_classe, rsexe, rstac, rstaf, rval, rsit_matrimonial, rgrpsang, titreDoc, format) {
        var lien = this.state.wsurl + 'etats/generer_liste_eleve_photo?rcode_enseign=' + rcode_enseign + '&rcode_classe=' + rcode_classe + '&rsexe=' + rsexe + '&rstac=' + rstac + '&rstaf=' + rstaf + '&rsit_matrimonial=' + rsit_matrimonial + '&rval=' + rval + '&rgrpsang=' + rgrpsang + '&titreDoc=' + titreDoc + '&format=' + format + '&rannee=' + this.get_rannee() + '&iduser=' + this.get_userconnected().numUtil;
        window.location = lien;
    }

    generer_badge_eleve(ids) {
        var lien = this.state.wsurl + 'etats/generer_badge_eleve?ids=' + ids + '&rannee=' + this.get_rannee() + '&iduser=' + this.get_userconnected().numUtil;
        window.location = lien;
    }

    generer_liste_eleve(rcode_enseign, rcode_classe, rsexe, rstac, rstaf, rval, rsit_matrimonial, rgrpsang, titreDoc, format) {
        var lien = this.state.wsurl + 'etats/generer_liste_eleve?rcode_enseign=' + rcode_enseign + '&rcode_classe=' + rcode_classe + '&rsexe=' + rsexe + '&rstac=' + rstac + '&rstaf=' + rstaf + '&rsit_matrimonial=' + rsit_matrimonial + '&rval=' + rval + '&rgrpsang=' + rgrpsang + '&titreDoc=' + titreDoc + '&format=' + format + '&rannee=' + this.get_rannee() + '&iduser=' + this.get_userconnected().numUtil;
        window.location = lien;
    }

    get_liste_eleve_edition(rcode_enseign, rcode_classe, rsexe, rstac, rstaf, rval, rsit_matrimonial, rgrpsang) {
        var lien = this.state.wsurl + 'etats/get_liste_eleve_edition?rcode_enseign=' + rcode_enseign + '&rcode_classe=' + rcode_classe + '&rsexe=' + rsexe + '&rstac=' + rstac + '&rstaf=' + rstaf + '&rsit_matrimonial=' + rsit_matrimonial + '&rval=' + rval + '&rgrpsang=' + rgrpsang + '&rannee=' + this.get_rannee() + '&iduser=' + this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }




    generer_bilan_annuel(id) {
        var lien = this.state.wsurl + 'etats/generer_bilan_annuel?id=' + id + '&rannee=' + this.get_rannee() + '&iduser=' + this.get_userconnected().numUtil;
        window.location = lien;
    }
    get_bilan() {
        var lien = this.state.wsurl + 'etats/get_bilan_annuel?rannee=' + this.get_rannee() + '&iduser=' + this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }


    generer_ordre_mission(id) {
        var lien = this.state.wsurl + 'etats/generer_ordre_mission?id=' + id + '&rannee=' + this.get_rannee() + '&iduser=' + this.get_userconnected().numUtil;
        window.location = lien;
    }


    generer_bulletin_paie(id) {
        var lien = this.state.wsurl + 'etats/generer_bulletin_paie?id=' + id + '&rannee=' + this.get_rannee() + '&iduser=' + this.get_userconnected().numUtil;
        window.location = lien;
    }

    generer_attestation_travail(ids, fonctionPers) {
        var lien = this.state.wsurl + 'etats/generer_attestation_travail?ids=' + ids + '&fonctionPers=' + fonctionPers + '&rannee=' + this.get_rannee() + '&iduser=' + this.get_userconnected().numUtil;
        window.location = lien;
    }

    generer_badge_personnel(ids) {
        var lien = this.state.wsurl + 'etats/generer_badge_personnel?ids=' + ids + '&rannee=' + this.get_rannee() + '&iduser=' + this.get_userconnected().numUtil;
        window.location = lien;
    }

    generer_liste_personnel(format) {
        var lien = this.state.wsurl + 'etats/generer_liste_personnel?format=' + format + '&iduser=' + this.get_userconnected().numUtil;
        window.location = lien;
    }

    generer_titre_absence(ids, format) {
        var lien = this.state.wsurl + 'etats/generer_titre_absence?ids=' + ids + '&format=' + format + '&rannee=' + this.get_rannee() + '&iduser=' + this.get_userconnected().numUtil;
        window.location = lien;
    }

    generer_liste_absence(pd1, pd2, rcode_classe, rid_insc, rtype, format) {
        var lien = this.state.wsurl + 'etats/generer_liste_absence?pd1=' + pd1 + '&pd2=' + pd2 + '&rcode_classe=' + rcode_classe + '&rid_insc=' + rid_insc + '&rtype=' + rtype + '&format=' + format + '&rannee=' + this.get_rannee() + '&iduser=' + this.get_userconnected().numUtil;
        window.location = lien;
    }

    get_liste_absence(pd1, pd2, rcode_classe, rid_insc, rtype) {
        var lien = this.state.wsurl + 'etats/get_liste_absence?pd1=' + pd1 + '&pd2=' + pd2 + '&rcode_classe=' + rcode_classe + '&rid_insc=' + rid_insc + '&rtype=' + rtype + '&rannee=' + this.get_rannee() + '&iduser=' + this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }



    generer_etat_paie(rmatr_pers, rmois, retat_paie, rlib_paie, rfiltre_cpb, format) {
        var lien = this.state.wsurl + 'etats/generer_etat_paie?rmatr_pers=' + rmatr_pers + '&rmois=' + rmois + '&retat_paie=' + retat_paie + '&rlib_paie=' + rlib_paie + '&rfiltre_cpb=' + rfiltre_cpb + '&format=' + format + '&rannee=' + this.get_rannee() + '&iduser=' + this.get_userconnected().numUtil;
        window.location = lien;
    }

    get_liste_etat_paie(rmatr_pers, rmois, retat_paie, rlib_paie, rfiltre_cpb) {
        var lien = this.state.wsurl + 'etats/get_liste_etat_paie?rmatr_pers=' + rmatr_pers + '&rmois=' + rmois + '&retat_paie=' + retat_paie + '&rlib_paie=' + rlib_paie + '&rfiltre_cpb=' + rfiltre_cpb + '&rannee=' + this.get_rannee() + '&iduser=' + this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    generer_situation_frais(rcode_enseign, rcode_classe, rlibelle, rstac, rstaf, rval, rcondition, titreDoc, format) {
        var lien = this.state.wsurl + 'etats/generer_situation_frais?rcode_enseign=' + rcode_enseign + '&rcode_classe=' + rcode_classe + '&rlibelle=' + rlibelle + '&rstac=' + rstac + '&rstaf=' + rstaf + '&rcondition=' + rcondition + '&rval=' + rval + '&titreDoc=' + titreDoc + '&format=' + format + '&rannee=' + this.get_rannee() + '&iduser=' + this.get_userconnected().numUtil;
        window.location = lien;
    }

    get_situation_frais(rcode_enseign, rcode_classe, rlibelle, rstac, rstaf, rval, rcondition, titreDoc) {
        var lien = this.state.wsurl + 'etats/get_situation_frais?rcode_enseign=' + rcode_enseign + '&rcode_classe=' + rcode_classe + '&rlibelle=' + rlibelle + '&rstac=' + rstac + '&rstaf=' + rstaf + '&rcondition=' + rcondition + '&rval=' + rval + '&titreDoc=' + titreDoc + '&rannee=' + this.get_rannee() + '&iduser=' + this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }





    generer_journal_vers(spd1, spd2, rlibelle, format) {
        var lien = this.state.wsurl + 'etats/generer_journal_vers?pd1=' + spd1 + '&pd2=' + spd2 + '&rlibelle=' + rlibelle + '&format=' + format + '&rannee=' + this.get_rannee() + '&iduser=' + this.get_userconnected().numUtil;
        window.location = lien;
    }

    get_journal_vers(pd1, pd2, rlibelle) {
        var lien = this.state.wsurl + 'etats/get_journal_vers?pd1=' + pd1 + '&pd2=' + pd2 + '&rlibelle=' + rlibelle + '&rannee=' + this.get_rannee() + '&iduser=' + this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

   /*  generer_liste_recu(ids) {
        var lien = this.state.wsurl + 'etats/generer_liste_recu?ids=' + ids + '&rannee=' + this.get_rannee() + '&iduser=' + this.get_userconnected().numUtil;
        window.location = lien;
    } */
    generer_liste_recu(ids) {
        var lien = this.state.wsurl + 'etats/generer_liste_recu?ids=' + ids + '&rannee=' + this.get_rannee() + '&iduser=' + this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                // 'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(function(t) {
            return t.blob().then((b)=>{
                var a = document.createElement("a");
                a.href = URL.createObjectURL(b);
                a.setAttribute("download", "REÇU_"+ moment(new Date()).format('DDMMYYYYhhmmss')+".pdf");
                a.click();
            }
            );
        });
    }


    get_liste_recu(pd1, pd2, rcode_classe, rmatricule, rlibelle) {
        var lien = this.state.wsurl + 'etats/get_liste_recu?pd1=' + pd1 + '&pd2=' + pd2 + '&rcode_classe=' + rcode_classe + '&rmatricule=' + rmatricule + '&rlibelle=' + rlibelle + '&rannee=' + this.get_rannee() + '&iduser=' + this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }





    generer_emploi(ids, format) {
        var lien = this.state.wsurl + 'etats/generer_emploi?ids=' + ids + '&format=' + format + '&rannee=' + this.get_rannee() + '&iduser=' + this.get_userconnected().numUtil;
        window.location = lien;
    }

    generer_liste_assignation(rcode_enseign, rcode_classe, rmatr_pers, format) {
        var lien = this.state.wsurl + 'etats/generer_liste_assignation?rcode_classe=' + rcode_classe + '&rmatr_pers=' + rmatr_pers + '&rcode_enseign=' + rcode_enseign + '&format=' + format + '&rannee=' + this.get_rannee() + '&iduser=' + this.get_userconnected().numUtil;
        window.location = lien;
    }

    get_liste_assignation(rcode_enseign, rcode_classe, rmatr_pers) {
        var lien = this.state.wsurl + 'etats/get_liste_assignation?rcode_classe=' + rcode_classe + '&rmatr_pers=' + rmatr_pers + '&rcode_enseign=' + rcode_enseign + '&rannee=' + this.get_rannee() + '&iduser=' + this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }



    generer_stat_effectif(rcode_fac, rcode_enseign, format) {
        var lien = this.state.wsurl + 'etats/generer_stat_effectif?rcode_enseign=' + rcode_enseign + '&rcode_fac=' + rcode_fac + '&format=' + format + '&rannee=' + this.get_rannee() + '&iduser=' + this.get_userconnected().numUtil;
        window.location = lien;
    }

    get_liste_stat_effectif(rcode_fac, rcode_enseign) {
        var lien = this.state.wsurl + 'etats/get_stat_effectif?rcode_enseign=' + rcode_enseign + '&rcode_fac=' + rcode_fac + '&rannee=' + this.get_rannee() + '&iduser=' + this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }


    generer_stat_evaluation(rcode_enseign, rcode_classe, format) {
        var lien = this.state.wsurl + 'etats/generer_stat_evaluation?rcode_enseign=' + rcode_enseign + '&rcode_classe=' + rcode_classe + '&format=' + format + '&rannee=' + this.get_rannee() + '&iduser=' + this.get_userconnected().numUtil;
        window.location = lien;
    }

    get_stat_evaluation(rcode_enseign, rcode_classe) {
        var lien = this.state.wsurl + 'etats/get_stat_evaluation?rcode_enseign=' + rcode_enseign + '&rcode_classe=' + rcode_classe + '&rannee=' + this.get_rannee() + '&iduser=' + this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }



    generer_stat_resultat(rcode_enseign, rmodule, format) {
        var lien = this.state.wsurl + 'etats/generer_stat_resultat?rcode_enseign=' + rcode_enseign + '&rmodule=' + rmodule + '&format=' + format + '&rannee=' + this.get_rannee() + '&iduser=' + this.get_userconnected().numUtil;
        window.location = lien;
    }

    get_liste_stat_resultat(rcode_enseign, rmodule) {
        var lien = this.state.wsurl + 'etats/get_liste_stat_resultat?rcode_enseign=' + rcode_enseign + '&rmodule=' + rmodule + '&rannee=' + this.get_rannee() + '&iduser=' + this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    generer_stat_depense(spd1, spd2, format) {
        var lien = this.state.wsurl + 'etats/generer_stat_depense?pd1=' + spd1 + '&pd2=' + spd2 + '&format=' + format + '&rannee=' + this.get_rannee() + '&iduser=' + this.get_userconnected().numUtil;
        window.location = lien;
    }

    get_liste_stat_depense(pd1, pd2) {
        var lien = this.state.wsurl + 'etats/get_liste_stat_depense?pd1=' + pd1 + '&pd2=' + pd2 + '&rannee=' + this.get_rannee() + '&iduser=' + this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }



    generer_stat_cours_matiere(spd1, spd2, rcode_classe, format) {
        var lien = this.state.wsurl + 'etats/generer_stat_cours_matiere?pd1=' + spd1 + '&pd2=' + spd2 + '&rcode_classe=' + rcode_classe + '&format=' + format + '&rannee=' + this.get_rannee() + '&iduser=' + this.get_userconnected().numUtil;
        window.location = lien;
    }

    get_liste_stat_cours_matiere(pd1, pd2, rcode_classe) {
        var lien = this.state.wsurl + 'etats/get_liste_stat_cours_matiere?pd1=' + pd1 + '&pd2=' + pd2 + '&rcode_classe=' + rcode_classe + '&rannee=' + this.get_rannee() + '&iduser=' + this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }



    generer_stat_cours_classe(spd1, spd2, rcode_enseign, format) {
        var lien = this.state.wsurl + 'etats/generer_stat_cours_classe?pd1=' + spd1 + '&pd2=' + spd2 + '&rcode_enseign=' + rcode_enseign + '&format=' + format + '&rannee=' + this.get_rannee() + '&iduser=' + this.get_userconnected().numUtil;
        window.location = lien;
    }

    get_liste_stat_cours_classe(pd1, pd2, rcode_enseign) {
        var lien = this.state.wsurl + 'etats/get_liste_stat_cours_classe?pd1=' + pd1 + '&pd2=' + pd2 + '&rcode_enseign=' + rcode_enseign + '&rannee=' + this.get_rannee() + '&iduser=' + this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }


    // ---------------------------------------------------Abscece_Personne-----------------------------------------------------------------
    AddAbsPersonne(absPers) {
        var lien = "";
        lien = this.state.wsurl + 'absencePers/add'
        return fetch(lien, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            },
            body: JSON.stringify(absPers),
        }).then(reponse => {
            return reponse.json();
        }).catch((error) => {
            return error;
        });
    }

    UpdateAbsPersonne(absPers, idAbsp) {
        var lien = this.state.wsurl + 'absencePers/update/';
        return fetch(lien + idAbsp, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json ; charset=UTF-8',
            },
            body: JSON.stringify(absPers),
        }).then(reponse => {
            return reponse.json();
        }).catch((error) => {
            return error
        });
    }

    DeleteAbsPersonne(idAbsp) {
        var lien = this.state.wsurl + 'absencePers/delete/';
        return fetch(lien + idAbsp, {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json ; charset=UTF-8',
            },
        }).then(response => {
            return response.json();
        }).catch((error) => {
            return error
        });
    }
    getAbsPersonne() {
        var lien = this.state.wsurl + 'absencePers/gnle'
        return fetch(lien)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                }
            }).then(data => {
                return data;
            }).catch((error) => {
                return error
            })
    }

    // ---------------------------------------------------Absence-----------------------------------------------------------------
    AddAbsence(data) {
        var lien = this.state.wsurl + 'absence/add';
        data.idEtabl = this.get_userconnected().idEtabl.idEtabl;
        data.iduser = this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            },
            body: JSON.stringify(data),
        }).then(reponse => {
            return reponse.json();
        }).catch((error) => {
            return error;
        });
    }

    UpdateAbsence(data) {
        var lien = this.state.wsurl + 'absence/update';
        data.iduser = this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json ; charset=UTF-8',
            },
            body: JSON.stringify(data),
        }).then(reponse => {
            return reponse.json();
        }).catch((error) => {
            return error
        });
    }

    DeleteAbsence(data) {
        var lien = this.state.wsurl + 'absence/delete';
        data.iduser = this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json ; charset=UTF-8',
            },
            body: JSON.stringify(data)
        }).then(response => {
            return response.json();
        }).catch((error) => {
            return error
        });
    }
    get_liste_absence_by_matricule_eleve(matricule) {
        var lien = this.state.wsurl + 'absence/liste_absence_by_matricule_eleve?rmatricule=' + matricule + '&rannee=' + this.get_rannee();
        return fetch(lien)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
            }).then(data => {
                return data;
            }).catch((error) => {
                return error
            })
    }

    get_liste_absences_by_criteria(pd1, pd2, rtypeAbs, rcodeClasse, rmatricule) {
        var lien = this.state.wsurl + 'absence/get_by_criteria?pd1=' + pd1 + '&pd2=' + pd2 + '&rtypeAbs=' + rtypeAbs + '&rcodeClasse=' + rcodeClasse + '&rmatricule=' + rmatricule + '&rannee=' + this.get_rannee() + '&iduser=' + this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }


    // ---------------------------------------------------Classe-----------------------------------------------------------------
    AddClasse(data) {
        var lien = "";
        lien = this.state.wsurl + 'classe/add';
        data.idEtabl = this.get_userconnected().idEtabl.idEtabl;
        data.iduser = this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            },
            body: JSON.stringify(data),
        }).then(reponse => {
            return reponse.json();
        }).catch((error) => {
            return error;
        });
    }

    UpdateClasse(data) {
        var lien = this.state.wsurl + 'classe/update';
        data.iduser = this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json ; charset=UTF-8',
            },
            body: JSON.stringify(data),
        }).then(reponse => {
            return reponse.json();
        }).catch((error) => {
            return error
        });

    }

    DeleteClasse(data) {
        var lien = this.state.wsurl + 'classe/delete';
        data.iduser = this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json ; charset=UTF-8',
            },
            body: JSON.stringify(data)
        }).then(response => {
            return response.json();
        }).catch((error) => {
            return error
        });
    }

    getClasse() {
        var lien = this.state.wsurl + 'classe/gnle?rannee=' + this.get_rannee() + '&iduser=' + this.get_userconnected().numUtil;
        return fetch(lien)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                }
            }).then(data => {
                return data;
            }).catch((error) => {
                return error
            })
    }

    getClasseByAnnee(rannee) {
        var lien = this.state.wsurl + 'classe/gnle?rannee=' + rannee + '&iduser=' + this.get_userconnected().numUtil;
        return fetch(lien)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                }
            }).then(data => {
                return data;
            }).catch((error) => {
                return error
            })
    }

    // ---------------------------------------------------Commentaire-----------------------------------------------------------------
    AddCommentaire(data) {
        var lien = this.state.wsurl + 'commentaire/add';
        data.idEtabl = this.get_userconnected().idEtabl.idEtabl;
        data.iduser = this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'POST', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            },
            body: JSON.stringify(data),
        }).then(reponse => {
            return reponse.json();
        }).catch((error) => {
            return error;
        });
    }

    UpdateCommentaire(data) {
        var lien = this.state.wsurl + 'commentaire/update';
        data.iduser = this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'PUT',
            mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json ; charset=UTF-8',
                'url': 'localhost:3000/#/commentaires'
            },
            body: JSON.stringify(data),
        }).then(reponse => {
            return reponse.json();
        }).catch((error) => {
            return error
        });
    }

    DeleteCommentaire(data) {
        var lien = this.state.wsurl + 'commentaire/delete';
        data.iduser = this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'DELETE',
            mode: 'cors',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json ; charset=UTF-8',
            },
            body: JSON.stringify(data),
        }).then(response => {
            return response.json();
        }).catch((error) => {
            return error
        });
    }

    getCommentaire(idTheme) {
        var lien = this.state.wsurl + 'commentaire/get_liste_cmt?idTheme=' + idTheme + '&iduser=' + this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    // ---------------------------------------------------Cuser-----------------------------------------------------------------
    AddCuser(cuser) {
        var lien = "", msg = "reussi";
        lien = this.state.wsurl + 'cuser/add'
        return fetch(lien, {
            method: 'POST', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            },
            body: JSON.stringify(cuser),
        })
            .then(reponse => {
                return reponse.json();

            })
            .catch((error) => {

                return error;
            });

    }

    UpdateCuser(cuser, idCmpte) {
        var lien = this.state.wsurl + 'cuser/update/';
        return fetch(lien + idCmpte, {
            method: 'PUT',
            mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json ; charset=UTF-8',
                'url': 'localhost:3000/#/cusers'
            },
            body: JSON.stringify(cuser),
        })
            .then(reponse => {
                return reponse.json();

            })
            .catch((error) => {
                return error
            });

    }

    DeleteCuser(idCmpte) {
        var lien = this.state.wsurl + 'cuser/delete/';
        return fetch(lien + idCmpte, {
            method: 'DELETE',
            mode: 'cors',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json ; charset=UTF-8',
            },
        }).then(response => {
            return response.json();

        })
            .catch((error) => {
                return error
            });

    }
    getCuser() {
        var lien = this.state.wsurl + 'cuser/gnle'
        return fetch(lien)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {

                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {

                return error
            })
    }
    // ---------------------------------------------------Depenses-----------------------------------------------------------------
    AddDepenses(data) {
        var lien = this.state.wsurl + 'depenses/add';
        data.idEtabl = this.get_userconnected().idEtabl.idEtabl;
        data.iduser = this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            },
            body: JSON.stringify(data),
        }).then(reponse => {
            return reponse.json();
        }).catch((error) => {
            return error;
        });
    }

    UpdateDepenses(data) {
        var lien = this.state.wsurl + 'depenses/update';
        data.iduser = this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json ; charset=UTF-8',
            },
            body: JSON.stringify(data),
        }).then(reponse => {
            return reponse.json();
        }).catch((error) => {
            return error
        });
    }

    DeleteDepenses(data) {
        var lien = this.state.wsurl + 'depenses/delete';
        data.iduser = this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json ; charset=UTF-8',
            },
            body: JSON.stringify(data)
        }).then(response => {
            return response.json();
        }).catch((error) => {
            return error
        });
    }

    getDepenses() {
        var lien = this.state.wsurl + 'depenses/gnle?rannee=' + this.get_rannee() + '&iduser=' + this.get_userconnected().numUtil;
        return fetch(lien)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                }
            }).then(data => {
                return data;
            }).catch((error) => {
                return error
            })
    }

    // ---------------------------------------------------Document-----------------------------------------------------------------
    AddDocument(data) {
        var lien = this.state.wsurl + 'document/add';
        data.idEtabl = this.get_userconnected().idEtabl.idEtabl;
        data.iduser = this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            },
            body: JSON.stringify(data),
        }).then(reponse => {
            return reponse.json();
        }).catch((error) => {
            return error;
        });
    }

    UpdateDocument(data) {
        var lien = this.state.wsurl + 'document/update';
        data.iduser = this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json ; charset=UTF-8',
            },
            body: JSON.stringify(data),
        }).then(reponse => {
            return reponse.json();
        }).catch((error) => {
            return error
        });
    }

    DeleteDocument(data) {
        var lien = this.state.wsurl + 'document/delete';
        data.iduser = this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json ; charset=UTF-8',
            },
            body: JSON.stringify(data)
        }).then(response => {
            return response.json();
        }).catch((error) => {
            return error
        });
    }

    get_liste_docs_by_criteria(pd1, pd2, rlibDoc, r_refDoc, ridsrcDoc) {
        var lien = this.state.wsurl + 'document/get_by_criteria?pd1=' + pd1 + '&pd2=' + pd2 + '&rlibDoc=' + rlibDoc + '&r_refDoc=' + r_refDoc + '&ridsrcDoc=' + ridsrcDoc + '&rannee=' + this.get_rannee() + '&iduser=' + this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    // ---------------------------------------------------Dossier-----------------------------------------------------------------
    AddDossier(data) {
        var lien = this.state.wsurl + 'dossier/add';
        data.idEtabl = this.get_userconnected().idEtabl.idEtabl;
        data.iduser = this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            },
            body: JSON.stringify(data),
        }).then(reponse => {
            return reponse.json();
        }).catch((error) => {
            return error;
        });
    }

    UpdateDossier(data) {
        var lien = this.state.wsurl + 'dossier/update';
        data.iduser = this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json ; charset=UTF-8'
            },
            body: JSON.stringify(data),
        }).then(reponse => {
            return reponse.json();
        }).catch((error) => {
            return error
        });
    }

    DeleteDossier(data) {
        var lien = this.state.wsurl + 'dossier/delete';
        data.iduser = this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json ; charset=UTF-8',
            },
            body: JSON.stringify(data)
        }).then(response => {
            return response.json();
        }).catch((error) => {
            return error
        });
    }
    getDossier() {
        var lien = this.state.wsurl + 'dossier/gnle?iduser=' + this.get_userconnected().numUtil;
        return fetch(lien)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                }
            }).then(data => {
                return data;
            }).catch((error) => {
                return error
            })
    }

    // ---------------------------------------------------Eleve-----------------------------------------------------------------
    AddEleve(eleve) {
        eleve.idEtabl = this.get_userconnected().idEtabl;
        eleve.iduser = this.get_userconnected().numUtil;
        var lien = "";
        lien = this.state.wsurl + 'eleve/add'
        return fetch(lien, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            },
            body: JSON.stringify(eleve),
        }).then(reponse => {
            return reponse.json();
        }).catch((error) => {
            return error;
        });
    }

    UpdateEleve(data) {
        data.iduser = this.get_userconnected().numUtil;
        var lien = this.state.wsurl + 'eleve/update';
        return fetch(lien, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json ; charset=UTF-8',
            },
            body: JSON.stringify(data),
        }).then(reponse => {
            return reponse.json();
        }).catch((error) => {
            return error
        });
    }

    DeleteEleve(data) {
        var lien = this.state.wsurl + 'eleve/delete';
        data.iduser = this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json ; charset=UTF-8',
            },
            body: JSON.stringify(data)
        }).then(response => {
            return response.json();
        }).catch((error) => {
            return error
        });
    }
    getEleve() {
        var lien = this.state.wsurl + 'eleve/gnle'
        return fetch(lien)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                }
            }).then(data => {
                return data;
            }).catch((error) => {
                return error
            })
    }

    getElevesForUpdateInscrit(rcodeClasse, rnomEleve, rmatricule, rsexe, rsituationMatrimoniale) {
        var lien = this.state.wsurl + 'updateinscription/' + rcodeClasse + '/' + rnomEleve + '/' + rmatricule + '/' + rsexe + '/' + rsituationMatrimoniale;
        return fetch(lien)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                }
            }).then(data => {
                return data;
            }).catch((error) => {
                return error
            })
    }

    get_liste_eleve(rcodeClasse, rnomEleve, rmatricule, rsexe, rsituationMatrimoniale) {
        var lien = this.state.wsurl + 'eleve/get_liste_eleve?rcodeClasse=' + rcodeClasse + '&rnomEleve=' + rnomEleve + '&rmatricule=' + rmatricule + '&rsexe=' + rsexe + '&rsituationMatrimoniale=' + rsituationMatrimoniale + '&iduser=' + this.get_userconnected().numUtil;
        return fetch(lien)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                }
            }).then(data => {
                return data;
            }).catch((error) => {
                return error
            })
    }

    // ---------------------------------------------------Emargement-----------------------------------------------------------------
    AddEmargement(data) {
        var lien = "";
        lien = this.state.wsurl + 'emargement/add';
        data.idEtabl = this.get_userconnected().idEtabl.idEtabl;
        data.iduser = this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            },
            body: JSON.stringify(data),
        }).then(reponse => {
            return reponse.json();
        }).catch((error) => {
            return error;
        });
    }

    UpdateEmargement(data) {
        var lien = this.state.wsurl + 'emargement/update';
        data.iduser = this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json ; charset=UTF-8',
            },
            body: JSON.stringify(data),
        }).then(reponse => {
            return reponse.json();
        }).catch((error) => {
            return error
        });
    }

    DeleteEmargement(data) {
        var lien = this.state.wsurl + 'emargement/delete';
        data.iduser = this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json ; charset=UTF-8',
            },
            body: JSON.stringify(data)
        }).then(response => {
            return response.json();
        }).catch((error) => {
            return error
        });
    }

    get_liste_emargements_by_criteria(pd1, pd2, rmatrPers, rcodeClasse, rcodemat) {
        var lien = this.state.wsurl + 'emargement/get_by_criteria?pd1=' + pd1 + '&pd2=' + pd2 + '&rmatrPers=' + rmatrPers + '&rcodeClasse=' + rcodeClasse + '&rcodemat=' + rcodemat + '&rannee=' + this.get_rannee() + '&iduser=' + this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_nbh_restant(rcodemat) {
        var lien = this.state.wsurl + 'emargement/get_nbh_restant?rcodemat=' + rcodemat + '&iduser=' + this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    // ---------------------------------------------------Emploi-----------------------------------------------------------------
    AddEmploi(data) {
        var lien = "";
        lien = this.state.wsurl + 'emploi/add';
        data.idEtabl = this.get_userconnected().idEtabl.idEtabl;
        data.iduser = this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            },
            body: JSON.stringify(data),
        }).then(reponse => {
            return reponse.json();
        }).catch((error) => {
            return error;
        });
    }

    UpdateEmploi(data) {
        var lien = this.state.wsurl + 'emploi/update';
        data.iduser = this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json ; charset=UTF-8',
            },
            body: JSON.stringify(data),
        }).then(reponse => {
            return reponse.json();
        }).catch((error) => {
            return error
        });
    }
    DeleteEmploi(data) {
        var lien = this.state.wsurl + 'emploi/delete';
        data.iduser = this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json ; charset=UTF-8',
            },
            body: JSON.stringify(data)
        }).then(response => {
            return response.json();
        }).catch((error) => {
            return error
        });
    }

    getEmploi_by_criticera(rmatrPers, rcodeClasse, rcodemat, rjour) {
        var lien = this.state.wsurl + "emploi/get_by_criteria?rmatrPers=" + rmatrPers + "&rcodeClasse=" + rcodeClasse + "&rcodemat=" + rcodemat + "&rjour=" + rjour + '&rannee=' + this.get_rannee() + '&iduser=' + this.get_userconnected().numUtil;
        return fetch(lien)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                }
            }).then(data => {
                return data;
            }).catch((error) => {
                return error
            })
    }

    // ---------------------------------------------------Emprunt-----------------------------------------------------------------
    AddEmprunt(data) {
        var lien = this.state.wsurl + 'emprunt/add';
        data.idEtabl = this.get_userconnected().idEtabl.idEtabl;
        data.iduser = this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'POST', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            },
            body: JSON.stringify(data),
        }).then(reponse => {
            return reponse.json();

        }).catch((error) => {
            return error;
        });

    }

    UpdateEmprunt(data) {
        var lien = this.state.wsurl + 'emprunt/update';
        data.iduser = this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json ; charset=UTF-8',
            },
            body: JSON.stringify(data),
        }).then(reponse => {
            return reponse.json();
        }).catch((error) => {
            return error
        });
    }

    DeleteEmprunt(data) {
        var lien = this.state.wsurl + 'emprunt/delete';
        data.iduser = this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json ; charset=UTF-8',
            },
            body: JSON.stringify(data)
        }).then(response => {
            return response.json();
        }).catch((error) => {
            return error
        });
    }

    get_liste_emprunts_by_criteria(pd1, pd2, rmatrPers, retat) {
        var lien = this.state.wsurl + 'emprunt/get_by_criteria?pd1=' + pd1 + '&pd2=' + pd2 + '&rmatrPers=' + rmatrPers + '&retat=' + retat + '&rannee=' + this.get_rannee() + '&iduser=' + this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    // ---------------------------------------------------Enseignement-----------------------------------------------------------------
    AddEnseignement(data) {
        var lien = "";
        lien = this.state.wsurl + 'enseignement/add';
        data.idEtabl = this.get_userconnected().idEtabl.idEtabl;
        data.iduser = this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            },
            body: JSON.stringify(data),
        }).then(reponse => {
            return reponse.json();
        }).catch((error) => {
            return error;
        });

    }

    UpdateEnseignement(data) {
        var lien = this.state.wsurl + 'enseignement/update';
        data.iduser = this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json ; charset=UTF-8',
            },
            body: JSON.stringify(data),
        }).then(reponse => {
            return reponse.json();
        }).catch((error) => {
            return error
        });
    }

    DeleteEnseignement(data) {
        var lien = this.state.wsurl + 'enseignement/delete';
        data.iduser = this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json ; charset=UTF-8',
            },
            body: JSON.stringify(data)
        }).then(response => {
            return response.json();
        }).catch((error) => {
            return error
        });
    }
    getEnseignement() {
        var lien = this.state.wsurl + 'enseignement/gnle?iduser=' + this.get_userconnected().numUtil;
        return fetch(lien)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                }
            }).then(data => {
                return data;
            }).catch((error) => {
                return error
            })
    }

    getEnseignementByFac(codeFac) {
        var lien = this.state.wsurl + 'enseignement/liste_by_fac?codeFac=' + codeFac + '&iduser=' + this.get_userconnected().numUtil;
        return fetch(lien)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                }
            }).then(data => {
                return data;
            }).catch((error) => {
                return error
            })
    }

    // ---------------------------------------------------Enseigner-----------------------------------------------------------------
    AddEnseigner(data) {
        var lien = this.state.wsurl + 'enseigner/add';
        data.idEtabl = this.get_userconnected().idEtabl.idEtabl;
        data.iduser = this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            },
            body: JSON.stringify(data),
        }).then(reponse => {
            return reponse.json();
        }).catch((error) => {
            return error;
        });
    }

    UpdateEnseigner(data) {
        var lien = this.state.wsurl + 'enseigner/update';
        data.iduser = this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json ; charset=UTF-8',
            },
            body: JSON.stringify(data),
        }).then(reponse => {
            return reponse.json();
        }).catch((error) => {
            return error
        });
    }

    DeleteEnseigner(data) {
        var lien = this.state.wsurl + 'enseigner/delete';
        data.iduser = this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json ; charset=UTF-8',
            },
            body: JSON.stringify(data)
        }).then(response => {
            return response.json();
        }).catch((error) => {
            return error
        });
    }
    getEnseigner() {
        var lien = this.state.wsurl + 'enseigner/gnle'
        return fetch(lien)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                }
            }).then(data => {
                return data;
            }).catch((error) => {
                return error
            })
    }
    getEnseigner_by_matiere(codemat) {
        var lien = this.state.wsurl + 'enseigner/enseigner_by_matiere?codemat=' + codemat;
        return fetch(lien).then(response => {
            if (response.ok) {
                return response.json();
            } else {
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }
    getEnseigner_by_matrPersAndCodemat(matrPers, codemat) {
        var lien = this.state.wsurl + 'enseigner/enseigner_by_matrPersAndCodemat/' + matrPers + '/' + codemat
        return fetch(lien).then(response => {
            if (response.ok) {
                return response.json();
            } else {
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }
    // ---------------------------------------------------Etablissement-----------------------------------------------------------------
    AddEtablissement(etablissement) {
        var lien = "", msg = "reussi";
        lien = this.state.wsurl + 'etablissement/add'
        return fetch(lien, {
            method: 'POST', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            },
            body: JSON.stringify(etablissement),
        })
            .then(reponse => {
                return reponse.json();

            })
            .catch((error) => {

                return error;
            });

    }

    UpdateEtablissement(etablissement, idEtabl) {
        var lien = this.state.wsurl + 'etablissement/update/';
        return fetch(lien + idEtabl, {
            method: 'PUT',
            mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json ; charset=UTF-8',
                'url': 'localhost:3000/#/etablissements'
            },
            body: JSON.stringify(etablissement),
        })
            .then(reponse => {
                return reponse.json();

            })
            .catch((error) => {
                return error
            });

    }

    DeleteEtablissement(idEtabl) {
        var lien = this.state.wsurl + 'etablissement/delete/';
        return fetch(lien + idEtabl, {
            method: 'DELETE',
            mode: 'cors',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json ; charset=UTF-8',
            },
        }).then(response => {
            return response.json();

        })
            .catch((error) => {
                return error
            });
    }

    getEtablissement() {
        var lien = this.state.wsurl + 'etablissement/get_liste_etabl?iduser=' + this.get_userconnected().numUtil + '&keyGerer=' + this.get_userconnected().keyGerer;
        return fetch(lien)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
            }).then(data => {
                return data;
            }).catch((error) => {
                return error
            })
    }

    // ---------------------------------------------------Evaluation-----------------------------------------------------------------
    AddEvaluations(data) {
        var lien = this.state.wsurl + 'evaluations/add_bloc';
        return fetch(lien, {
            method: 'POST', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            },
            body: JSON.stringify(data),
        }).then(reponse => {
            return reponse.json();
        }).catch((error) => {
            return error;
        });
    }


    get_liste_eval_sondage(ridprog, ridProgcible) {
        var lien = this.state.wsurl + 'evaluations/get_liste_eval_sondage?ridprog=' + ridprog + '&ridProgcible=' + ridProgcible + '&rannee=' + this.get_rannee() + '&iduser=' + this.get_userconnected().numUtil;
        return fetch(lien)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
            }).then(data => {
                return data;
            }).catch((error) => {
                return error
            })
    }

    get_liste_for_sondage(ridprog, ridProgcible) {
        var lien = this.state.wsurl + 'evaluations/get_liste_for_sondage?ridprog=' + ridprog + '&ridProgcible=' + ridProgcible + '&iduser=' + this.get_userconnected().numUtil;
        return fetch(lien)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
            }).then(data => {
                return data;
            }).catch((error) => {
                return error
            })
    }


    // ---------------------------------------------------Evenements et messages-----------------------------------------------------------------
    AddEvenements(data) {
        var lien = this.state.wsurl + 'evenements/add';
        data.idEtabl = this.get_userconnected().idEtabl.idEtabl;
        data.iduser = this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            },
            body: JSON.stringify(data),
        }).then(reponse => {
            return reponse.json();
        }).catch((error) => {
            return error;
        });
    }

    UpdateEvenements(data) {
        var lien = this.state.wsurl + 'evenements/update';
        data.iduser = this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'PUT',
            mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json ; charset=UTF-8',
            },
            body: JSON.stringify(data),
        }).then(reponse => {
            return reponse.json();
        }).catch((error) => {
            return error
        });
    }

    DeleteEvenements(data) {
        var lien = this.state.wsurl + 'evenements/delete';
        data.iduser = this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json ; charset=UTF-8',
            },
            body: JSON.stringify(data)
        }).then(response => {
            return response.json();
        }).catch((error) => {
            return error
        });
    }


    get_liste_events() {
        var lien = this.state.wsurl + 'evenements/get_all?iduser=' + this.get_userconnected().numUtil + '&rannee=' + this.get_rannee();
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }


    AddFileMsg(data) {
        var lien = this.state.wsurl + 'fileMsg/add_bloc';
        return fetch(lien, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            },
            body: JSON.stringify(data),
        }).then(reponse => {
            return reponse.json();
        }).catch((error) => {
            return error;
        });
    }

    UpdateFileMsgBloc(data) {
        var lien = this.state.wsurl + 'fileMsg/update_bloc';
        return fetch(lien, {
            method: 'PUT',
            mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json ; charset=UTF-8',
            },
            body: JSON.stringify(data),
        }).then(reponse => {
            return reponse.json();
        }).catch((error) => {
            return error
        });
    }

    UpdateFileMsg(data) {
        var lien = this.state.wsurl + 'fileMsg/update';
        data.iduser = this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'PUT',
            mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json ; charset=UTF-8',
            },
            body: JSON.stringify(data),
        }).then(reponse => {
            return reponse.json();
        }).catch((error) => {
            return error
        });
    }

    DeleteFileMsg(data) {
        var lien = this.state.wsurl + 'fileMsg/delete_bloc';
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json ; charset=UTF-8',
            },
            body: JSON.stringify(data)
        }).then(response => {
            return response.json();
        }).catch((error) => {
            return error
        });
    }

    get_liste_fileMsg(pd1, pd2, rtype, rcodeClasse, rstatut) {
        var lien = this.state.wsurl + 'fileMsg/get_liste_fileMsg?pd1=' + pd1 + '&pd2=' + pd2 + '&rtype=' + rtype + '&rcodeClasse=' + rcodeClasse + '&rstatut=' + rstatut + '&rannee=' + this.get_rannee() + '&iduser=' + this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }


    // ---------------------------------------------------Exercer-----------------------------------------------------------------
    AddExercer(data) {
        var lien = "";
        data.idEtabl = this.get_userconnected().idEtabl.idEtabl;
        data.iduser = this.get_userconnected().numUtil;
        lien = this.state.wsurl + 'exercer/add';
        return fetch(lien, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            },
            body: JSON.stringify(data),
        })
            .then(reponse => {
                return reponse.json();

            })
            .catch((error) => {

                return error;
            });

    }

    UpdateExercer(data) {
        var lien = this.state.wsurl + 'exercer/update';
        data.iduser = this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json ; charset=UTF-8',
            },
            body: JSON.stringify(data),
        })
            .then(reponse => {
                return reponse.json();

            })
            .catch((error) => {
                return error
            });

    }

    DeleteExercer(data) {
        var lien = this.state.wsurl + 'exercer/delete';
        data.iduser = this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'DELETE',
            mode: 'cors',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json ; charset=UTF-8',
            },
            body: JSON.stringify(data)
        }).then(response => {
            return response.json();

        })
            .catch((error) => {
                return error
            });

    }
    getExercer() {
        var lien = this.state.wsurl + 'exercer/gnle'
        return fetch(lien)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {

                }
            }).then(data => {
                return data;
            }).catch((error) => {
                return error
            })
    }

    getExercerByNumFonction(numFonc) {
        var lien = this.state.wsurl + 'exercer/gnle?numFonc=' + numFonc;
        return fetch(lien)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {

                }
            }).then(data => {
                return data;
            }).catch((error) => {
                return error
            })
    }

    get_liste_depenses_by_criteria(pd1, pd2, rtypeDep) {
        var lien = this.state.wsurl + 'depenses/get_by_criteria?pd1=' + pd1 + '&pd2=' + pd2 + '&rtypeDep=' + rtypeDep + '&rannee=' + this.get_rannee() + '&iduser=' + this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    // ---------------------------------------------------Fonction-----------------------------------------------------------------
    AddFonction(data) {
        let lien = this.state.wsurl + 'fonction/add';
        data.idEtabl = this.get_userconnected().idEtabl.idEtabl;
        data.iduser = this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            },
            body: JSON.stringify(data),
        })
            .then(reponse => {
                return reponse.json();

            })
            .catch((error) => {

                return error;
            });

    }

    UpdateFonction(data) {
        data.iduser = this.get_userconnected().numUtil;
        var lien = this.state.wsurl + 'fonction/update';
        return fetch(lien, {
            method: 'PUT',
            mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json ; charset=UTF-8',
                'url': 'localhost:3000/#/fonctions'
            },
            body: JSON.stringify(data),
        })
            .then(reponse => {
                return reponse.json();

            })
            .catch((error) => {
                return error
            });

    }

    DeleteFonction(data) {
        data.iduser = this.get_userconnected().numUtil;
        var lien = this.state.wsurl + 'fonction/delete';
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json ; charset=UTF-8',
            },
            body: JSON.stringify(data)
        }).then(response => {
            return response.json();

        })
            .catch((error) => {
                return error
            });

    }
    getFonction() {
        var lien = this.state.wsurl + 'fonction/gnle?iduser=' + this.get_userconnected().numUtil;
        return fetch(lien)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {

                return error
            })
    }

    // ---------------------------------------------------Inscrit-----------------------------------------------------------------
    AddInscription(data) {
        var lien = this.state.wsurl + 'inscrit/inscription';
        data.idEtabl = this.get_userconnected().idEtabl.idEtabl;
        data.iduser = this.get_userconnected().numUtil;
        data.matricule.idEtabl = this.get_userconnected().idEtabl.idEtabl;
        data.matricule.iduser = this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            },
            body: JSON.stringify(data),
        }).then(reponse => {
            return reponse.json();
        }).catch((error) => {
            return error;
        });
    }
    TransfertInscrit(data) {
        var lien = this.state.wsurl + 'inscrit/transfert';
        data.iduser = this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            },
            body: JSON.stringify(data),
        }).then(reponse => {
            return reponse.json();
        }).catch((error) => {
            return error;
        });
    }
    AddInscrit(data) {
        var lien = this.state.wsurl + 'inscrit/add';
        data.iduser = this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            },
            body: JSON.stringify(data),
        }).then(reponse => {
            return reponse.json();
        }).catch((error) => {
            return error;
        });
    }

    ReinscriptionBloc(data) {
        var lien = this.state.wsurl + 'inscrit/reinscription_bloc';
        return fetch(lien, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            },
            body: JSON.stringify(data),
        }).then(reponse => {
            return reponse.json();
        }).catch((error) => {
            return error;
        });
    }

    ValiderInscrit(data) {
        var lien = this.state.wsurl + 'inscrit/valider';
        return fetch(lien, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json ; charset=UTF-8',
            },
            body: JSON.stringify(data),
        }).then(reponse => {
            return reponse.json();
        }).catch((error) => {
            return error
        });
    }

    UpdateInscrit(data) {
        var lien = this.state.wsurl + 'inscrit/update';
        data.iduser = this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json ; charset=UTF-8',
            },
            body: JSON.stringify(data),
        }).then(reponse => {
            return reponse.json();
        }).catch((error) => {
            return error
        });
    }
    DeleteInscritBloc(data) {
        var lien = this.state.wsurl + 'inscrit/delete_bloc';
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json ; charset=UTF-8',
            },
            body: JSON.stringify(data)
        }).then(response => {
            return response.json();
        }).catch((error) => {
            return error
        });
    }

    DeleteInscrit(data) {
        var lien = this.state.wsurl + 'inscrit/delete';
        data.iduser = this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json ; charset=UTF-8',
            },
            body: JSON.stringify(data)
        }).then(response => {
            return response.json();
        }).catch((error) => {
            return error
        });
    }
    getInscrit() {
        var lien = this.state.wsurl + 'inscrit/gnle'
        return fetch(lien)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                }
            }).then(data => {
                return data;
            }).catch((error) => {
                return error
            })
    }

    getliste_inscrit_by_eleve(matricule) {
        var lien = this.state.wsurl + 'inscrit/inscrit_by_matricule?matricule=' + matricule + '&iduser=' + this.get_userconnected().numUtil;
        return fetch(lien)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                }
            }).then(data => {
                return data;
            }).catch((error) => {
                return error
            })
    }

    getListe_inscrit_for_validation(codeClasse) {
        var lien = this.state.wsurl + 'inscrit/liste_inscrit_for_validation?codeClasse=' + codeClasse + '&iduser=' + this.get_userconnected().numUtil;
        return fetch(lien)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                }
            }).then(data => {
                return data;
            }).catch((error) => {
                return error
            })
    }

    getliste_inscrit_for_versement(codeClasse) {
        var lien = this.state.wsurl + 'inscrit/getliste_inscrit_for_versement?codeClasse=' + codeClasse + '&iduser=' + this.get_userconnected().numUtil;
        return fetch(lien)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                }
            }).then(data => {
                return data;
            }).catch((error) => {
                return error
            })
    }


    getliste_eleve_by_inscrit(codeClasse) {
        var lien = this.state.wsurl + 'inscrit/liste_inscrit_by_classe?codeClasse=' + codeClasse + '&iduser=' + this.get_userconnected().numUtil;
        return fetch(lien)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                }
            }).then(data => {
                return data;
            }).catch((error) => {
                return error
            })
    }


    getliste_inscrit_by_classe_for_reinsc(codeClasse) {
        var lien = this.state.wsurl + 'inscrit/liste_inscrit_by_classe_for_reinsc?codeClasse=' + codeClasse + '&iduser=' + this.get_userconnected().numUtil;
        return fetch(lien)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                }
            }).then(data => {
                return data;
            }).catch((error) => {
                return error
            })
    }

    get_id_inscrit_by_codeClasseAndMatricule(codeClasse, matricule) {
        var lien = this.state.wsurl + 'inscrit/' + codeClasse + '/' + matricule;
        return fetch(lien)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                }
            }).then(data => {
                return data;
            }).catch((error) => {
                return error
            })
    }


    getliste_oriente(rine, rpvb, recole, rsession) {
        var lien = this.state.wsurl + 'tborientation/getliste_oriente?rine=' + rine + '&rpvb=' + rpvb + '&recole=' + recole + '&rsession=' + rsession + '&iduser=' + this.get_userconnected().numUtil;
        return fetch(lien)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                }
            }).then(data => {
                return data;
            }).catch((error) => {
                return error
            })
    }

    AddOriente(data) {
        var lien = this.state.wsurl + 'tborientation/add_bloc';
        data.idEtabl = this.get_userconnected().idEtabl.idEtabl;
        data.iduser = this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            },
            body: JSON.stringify(data),
        }).then(reponse => {
            return reponse.json();
        }).catch((error) => {
            return error;
        });
    }

    // ---------------------------------------------------Matiere-----------------------------------------------------------------
    AddMatiere(data) {
        var lien = this.state.wsurl + 'matiere/add';
        data.idEtabl = this.get_userconnected().idEtabl.idEtabl;
        data.iduser = this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            },
            body: JSON.stringify(data),
        }).then(reponse => {
            return reponse.json();
        }).catch((error) => {
            return error;
        });
    }

    UpdateMatiere(data) {
        var lien = this.state.wsurl + 'matiere/update';
        data.iduser = this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json ; charset=UTF-8',
            },
            body: JSON.stringify(data),
        }).then(reponse => {
            return reponse.json();
        }).catch((error) => {
            return error
        });
    }

    DeleteMatiere(data) {
        var lien = this.state.wsurl + 'matiere/delete';
        data.iduser = this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json ; charset=UTF-8',
            },
            body: JSON.stringify(data)
        }).then(response => {
            return response.json();
        }).catch((error) => {
            return error
        });
    }

    getMatiere() {
        var lien = this.state.wsurl + 'matiere/gnle?rannee=' + this.get_rannee() + '&iduser=' + this.get_userconnected().numUtil;
        return fetch(lien).then(response => {
            if (response.ok) {
                return response.json();
            } else {
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }


    getMatiere_by_classe(codeClasse) {
        var lien = this.state.wsurl + 'matiere/liste_matiere_classe?codeClasse=' + codeClasse
        return fetch(lien).then(response => {
            if (response.ok) {
                return response.json();
            } else {
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }
    getMatiere_by_personnel(matrPers) {
        var lien = this.state.wsurl + 'enseigner/matiere_by_personnel?matrPers=' + matrPers
        return fetch(lien).then(response => {
            if (response.ok) {
                return response.json();
            } else {
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    getEnseigner_by_prof(matrPers) {
        var lien = this.state.wsurl + 'enseigner/enseigner_by_prof?matrPers=' + matrPers + '&rannee=' + this.get_rannee();
        return fetch(lien).then(response => {
            if (response.ok) {
                return response.json();
            } else {
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    getEnseigner_by_classe(codeClasse) {
        var lien = this.state.wsurl + 'enseigner/enseigner_by_classe?codeClasse=' + codeClasse
        return fetch(lien).then(response => {
            if (response.ok) {
                return response.json();
            } else {
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    // ---------------------------------------------------Mission-----------------------------------------------------------------
    AddMission(data) {
        var lien = this.state.wsurl + 'mission/add';
        data.idEtabl = this.get_userconnected().idEtabl.idEtabl;
        data.iduser = this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            },
            body: JSON.stringify(data),
        }).then(reponse => {
            return reponse.json();

        }).catch((error) => {

            return error;
        });

    }

    UpdateMission(data) {
        var lien = this.state.wsurl + 'mission/update';
        data.iduser = this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'PUT',
            mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json ; charset=UTF-8',
            },
            body: JSON.stringify(data),
        }).then(reponse => {
            return reponse.json();

        }).catch((error) => {
            return error
        });

    }

    DeleteMission(data) {
        var lien = this.state.wsurl + 'mission/delete';
        data.iduser = this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json ; charset=UTF-8',
            },
            body: JSON.stringify(data)
        }).then(response => {
            return response.json();

        }).catch((error) => {
            return error
        });

    }

    get_liste_gnle_missions() {
        var lien = this.state.wsurl + 'mission/liste_gnle?rannee=' + this.get_rannee() + '&iduser=' + this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_liste_mission_by_pers(rmatrPers) {
        var lien = this.state.wsurl + 'mission/liste_mission_by_pers?rmatrPers=' + rmatrPers + '&rannee=' + this.get_rannee() + '&iduser=' + this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }



    get_liste_missions_by_criteria(pd1, pd2, rmatricule, rlibelle) {
        var lien = this.state.wsurl + 'mission/get_by_criteria?pd1=' + pd1 + '&pd2=' + pd2 + '&rmatricule=' + rmatricule + '&rlibelle=' + rlibelle + '&rannee=' + this.get_rannee() + '&iduser=' + this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }


    // ---------------------------------------------------Nationnalite-----------------------------------------------------------------
    AddNationnalite(nationnalite) {
        var lien = "", msg = "reussi";
        lien = this.state.wsurl + 'nationnalite/add'
        return fetch(lien, {
            method: 'POST', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            },
            body: JSON.stringify(nationnalite),
        })
            .then(reponse => {
                return reponse.json();

            })
            .catch((error) => {

                return error;
            });

    }

    UpdateNationnalite(nationnalite, numNation) {
        var lien = this.state.wsurl + 'nationnalite/update/';
        return fetch(lien + numNation, {
            method: 'PUT',
            mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json ; charset=UTF-8',
                'url': 'localhost:3000/#/nationnalites'
            },
            body: JSON.stringify(nationnalite),
        })
            .then(reponse => {
                return reponse.json();

            })
            .catch((error) => {
                return error
            });

    }

    DeleteNationnalite(numNation) {
        var lien = this.state.wsurl + 'nationnalite/delete/';
        return fetch(lien + numNation, {
            method: 'DELETE',
            mode: 'cors',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json ; charset=UTF-8',
            },
        }).then(response => {
            return response.json();

        })
            .catch((error) => {
                return error
            });

    }
    getNationnalite() {
        var lien = this.state.wsurl + 'nationnalite/gnle?iduser=' + this.get_userconnected().numUtil;
        return fetch(lien)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {

                return error
            })
    }

    // ---------------------------------------------------Noter-----------------------------------------------------------------


    UpdateNoter(data) {
        var lien = this.state.wsurl + 'noter/update';
        data.iduser = this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'PUT',
            mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json ; charset=UTF-8',
                'url': 'localhost:3000/#/noters'
            },
            body: JSON.stringify(data),
        }).then(reponse => {
            return reponse.json();

        }).catch((error) => {
            return error
        });
    }

    UpdateNoterBloc(data) {
        var lien = this.state.wsurl + 'noter/update_bloc';
        return fetch(lien, {
            method: 'PUT',
            mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json ; charset=UTF-8',
                'url': 'localhost:3000/#/noters'
            },
            body: JSON.stringify(data),
        }).then(reponse => {
            return reponse.json();
        }).catch((error) => {
            return error
        });
    }

    getListe_notes(rmatricule, rcodeClasse, rcodemat, rval, rmodule) {
        var lien = this.state.wsurl + 'noter/getListe_notes?rmatricule=' + rmatricule + '&rcodeClasse=' + rcodeClasse + '&rval=' + rval + '&rcodemat=' + rcodemat + '&rmodule=' + rmodule + '&iduser=' + this.get_userconnected().numUtil;
        return fetch(lien).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    // ---------------------------------------------------OrdreStage-----------------------------------------------------------------
    AddOrdreStage(ordreStage) {
        var lien = "", msg = "reussi";
        lien = this.state.wsurl + 'ordreStage/add'
        return fetch(lien, {
            method: 'POST', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            },
            body: JSON.stringify(ordreStage),
        })
            .then(reponse => {
                return reponse.json();

            })
            .catch((error) => {

                return error;
            });

    }

    UpdateOrdreStage(ordreStage, idOstage) {
        var lien = this.state.wsurl + 'ordreStage/update/';
        return fetch(lien + idOstage, {
            method: 'PUT',
            mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json ; charset=UTF-8',
                'url': 'localhost:3000/#/ordreStages'
            },
            body: JSON.stringify(ordreStage),
        })
            .then(reponse => {
                return reponse.json();

            })
            .catch((error) => {
                return error
            });

    }

    DeleteOrdreStage(idOstage) {
        var lien = this.state.wsurl + 'ordreStage/delete/';
        return fetch(lien + idOstage, {
            method: 'DELETE',
            mode: 'cors',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json ; charset=UTF-8',
            },
        }).then(response => {
            return response.json();

        })
            .catch((error) => {
                return error
            });

    }
    getOrdreStage() {
        var lien = this.state.wsurl + 'ordreStage/gnle'
        return fetch(lien)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {

                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {

                return error
            })
    }

    // ---------------------------------------------------Paie-----------------------------------------------------------------
    AddPaie(data) {
        var lien = this.state.wsurl + 'paie/add';
        data.idEtabl = this.get_userconnected().idEtabl.idEtabl;
        data.iduser = this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            },
            body: JSON.stringify(data),
        }).then(reponse => {
            return reponse.json();
        }).catch((error) => {
            return error;
        });
    }

    UpdatePaie(data) {
        var lien = this.state.wsurl + 'paie/update';
        data.iduser = this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json ; charset=UTF-8',
            },
            body: JSON.stringify(data),
        }).then(reponse => {
            return reponse.json();
        }).catch((error) => {
            return error
        });
    }

    DeletePaie(data) {
        var lien = this.state.wsurl + 'paie/delete';
        data.iduser = this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json ; charset=UTF-8',
            },
            body: JSON.stringify(data)
        }).then(response => {
            return response.json();
        }).catch((error) => {
            return error
        });
    }

    get_liste_paies_by_criteria(rmois, rmatrPers, rlibPaie, retat) {
        var lien = this.state.wsurl + 'paie/get_by_criteria?rmois=' + rmois + '&rmatrPers=' + rmatrPers + '&rlibPaie=' + rlibPaie + '&retat=' + retat + '&rannee=' + this.get_rannee() + '&iduser=' + this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            } else {
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    // ---------------------------------------------------PartMission-----------------------------------------------------------------
    AddPartMission(partMission) {
        var lien = "", msg = "reussi";
        lien = this.state.wsurl + 'partMission/add'
        return fetch(lien, {
            method: 'POST', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            },
            body: JSON.stringify(partMission),
        })
            .then(reponse => {
                return reponse.json();

            })
            .catch((error) => {

                return error;
            });

    }

    UpdatePartMission(partMission, idPmis) {
        var lien = this.state.wsurl + 'partMission/update/';
        return fetch(lien + idPmis, {
            method: 'PUT',
            mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json ; charset=UTF-8',
                'url': 'localhost:3000/#/partMissions'
            },
            body: JSON.stringify(partMission),
        })
            .then(reponse => {
                return reponse.json();

            })
            .catch((error) => {
                return error
            });

    }

    DeletePartMission(idPmis) {
        var lien = this.state.wsurl + 'partMission/delete/';
        return fetch(lien + idPmis, {
            method: 'DELETE',
            mode: 'cors',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json ; charset=UTF-8',
            },
        }).then(response => {
            return response.json();

        })
            .catch((error) => {
                return error
            });

    }
    getPartMission() {
        var lien = this.state.wsurl + 'partMission/gnle'
        return fetch(lien)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {

                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {

                return error
            })
    }

    // ---------------------------------------------------Personnel-----------------------------------------------------------------
    AddPersonnel(data) {
        let lien = this.state.wsurl + 'personnel/add';
        data.idEtabl = this.get_userconnected().idEtabl.idEtabl;
        data.iduser = this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        }).then(response => {
            return response.json();
        }).catch(error => {
            return error;
        })
    }

    UpdatePersonnel(data) {
        var lien = this.state.wsurl + 'personnel/update';
        data.iduser = this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'PUT',
            mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json ; charset=UTF-8'
            },
            body: JSON.stringify(data),
        }).then(reponse => {
            return reponse.json();
        }).catch((error) => {
            return error
        });
    }

    DeletePersonnel(data) {
        var lien = this.state.wsurl + 'personnel/delete';
        data.iduser = this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'DELETE',
            mode: 'cors',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json ; charset=UTF-8',
            },
            body: JSON.stringify(data),
        }).then(response => {
            return response.json();
        }).catch((error) => {
            return error
        });
    }

    getPersonnel() {
        var lien = this.state.wsurl + 'personnel/gnle?iduser=' + this.get_userconnected().numUtil;
        return fetch(lien)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
            }).then(data => {
                return data;
            }).catch((error) => {
                return error
            })
    }

    get_liste_pers_not_prog(ridProg) {
        var lien = this.state.wsurl + 'personnel/get_liste_pers_not_prog?iduser=' + this.get_userconnected().numUtil + '&ridProg=' + ridProg;
        return fetch(lien)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
            }).then(data => {
                return data;
            }).catch((error) => {
                return error
            })
    }
    // ---------------------------------------------------ProgCible-----------------------------------------------------------------
    AddProgCible(data) {
        var lien = this.state.wsurl + 'progCible/add_bloc';
        data.idEtabl = this.get_userconnected().idEtabl.idEtabl;
        data.iduser = this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'POST', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        }).then(reponse => {
            return reponse.json();
        }).catch((error) => {
            return error;
        });
    }

    DeleteProgCible(data) {
        var lien = this.state.wsurl + 'progCible/delete_bloc';
        return fetch(lien, {
            method: 'DELETE',
            mode: 'cors',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json ; charset=UTF-8',
            },
            body: JSON.stringify(data),
        }).then(response => {
            return response.json();
        }).catch((error) => {
            return error
        });

    }

    getProgCible(ridProg) {
        var lien = this.state.wsurl + 'progCible/get_liste_by_prog?iduser=' + this.get_userconnected().numUtil + '&ridProg=' + ridProg;
        return fetch(lien)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
            }).then(data => {
                return data;
            }).catch((error) => {
                return error
            })
    }

    // ---------------------------------------------------ProgEval-----------------------------------------------------------------
    AddProgEval(data) {
        var lien = this.state.wsurl + 'progEval/add';
        data.idEtabl = this.get_userconnected().idEtabl.idEtabl;
        data.iduser = this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'POST', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            },
            body: JSON.stringify(data),
        }).then(reponse => {
            return reponse.json();
        }).catch((error) => {
            return error;
        });

    }

    UpdateProgEval(data) {
        var lien = this.state.wsurl + 'progEval/update/';
        return fetch(lien, {
            method: 'PUT',
            mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json ; charset=UTF-8',
                'url': 'localhost:3000/#/progEvals'
            },
            body: JSON.stringify(data),
        }).then(reponse => {
            return reponse.json();
        }).catch((error) => {
            return error
        });
    }

    DeleteProgEval(data) {
        var lien = this.state.wsurl + 'progEval/delete';
        data.iduser = this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'DELETE',
            mode: 'cors',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json ; charset=UTF-8',
            },
            body: JSON.stringify(data),
        }).then(response => {
            return response.json();
        }).catch((error) => {
            return error
        });

    }
    getProgEval() {
        var lien = this.state.wsurl + 'progEval/gnle?iduser=' + this.get_userconnected().numUtil + '&rannee=' + this.get_rannee();
        return fetch(lien)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
            }).then(data => {
                return data;
            }).catch((error) => {
                return error
            })
    }

    // ---------------------------------------------------ProgrammerEval-----------------------------------------------------------------
    AddProgrammerEval(data) {
        var lien = this.state.wsurl + 'programmerEval/add_bloc';
        return fetch(lien, {
            method: 'POST', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            },
            body: JSON.stringify(data),
        }).then(reponse => {
            return reponse.json();
        }).catch((error) => {
            return error;
        });
    }

    DeleteProgrammerEval(data) {
        var lien = this.state.wsurl + 'programmerEval/delete_bloc';
        return fetch(lien, {
            method: 'DELETE',
            mode: 'cors',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json ; charset=UTF-8',
            },
            body: JSON.stringify(data),
        }).then(response => {
            return response.json();
        }).catch((error) => {
            return error
        });
    }

    getProgrammerEval(ridProg) {
        var lien = this.state.wsurl + 'programmerEval/get_liste_by_prog?iduser=' + this.get_userconnected().numUtil + '&ridProg=' + ridProg;
        return fetch(lien)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
            }).then(data => {
                return data;
            }).catch((error) => {
                return error
            })
    }

    // ---------------------------------------------------Promotion-----------------------------------------------------------------
    AddPromotion(data) {
        var lien = this.state.wsurl + 'promotion/add'
        data.idEtabl = this.get_userconnected().idEtabl.idEtabl;
        data.iduser = this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'POST', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            },
            body: JSON.stringify(data),
        }).then(reponse => {
            return reponse.json();
        }).catch((error) => {
            return error;
        });
    }

    UpdatePromotion(data) {
        var lien = this.state.wsurl + 'promotion/update';
        data.iduser = this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'PUT',
            mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json ; charset=UTF-8',
                'url': 'localhost:3000/#/promotions'
            },
            body: JSON.stringify(data),
        })
            .then(reponse => {
                return reponse.json();

            })
            .catch((error) => {
                return error
            });

    }

    DeletePromotion(data) {
        var lien = this.state.wsurl + 'promotion/delete';
        data.iduser = this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json ; charset=UTF-8',
            },
            body: JSON.stringify(data)
        }).then(response => {
            return response.json();
        }).catch((error) => {
            return error
        });
    }

    getPromotion() {
        var lien = this.state.wsurl + 'promotion/gnle?iduser=' + this.get_userconnected().numUtil;
        return fetch(lien)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {

                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {

                return error
            })
    }

    // ---------------------------------------------------Questionnaire-----------------------------------------------------------------
    AddQuestionnaire(data) {
        var lien = this.state.wsurl + 'questionnaire/add';
        data.idEtabl = this.get_userconnected().idEtabl.idEtabl;
        data.iduser = this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            },
            body: JSON.stringify(data),
        }).then(reponse => {
            return reponse.json();
        }).catch((error) => {
            return error;
        });
    }

    UpdateQuestionnaire(data) {
        var lien = this.state.wsurl + 'questionnaire/update';
        data.iduser = this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json ; charset=UTF-8',
            },
            body: JSON.stringify(data),
        }).then(reponse => {
            return reponse.json();
        }).catch((error) => {
            return error
        });
    }
    DeleteQuestionnaire(data) {
        var lien = this.state.wsurl + 'questionnaire/delete';
        data.iduser = this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json ; charset=UTF-8',
            },
            body: JSON.stringify(data)
        }).then(response => {
            return response.json();
        }).catch((error) => {
            return error
        });
    }
    getQuestionnaire() {
        var lien = this.state.wsurl + 'questionnaire/gnle?iduser=' + this.get_userconnected().numUtil;
        return fetch(lien).then(response => {
            if (response.ok) {
                return response.json();
            } else {
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_liste_quest_not_prog(ridProg) {
        var lien = this.state.wsurl + 'questionnaire/get_liste_quest_not_prog?iduser=' + this.get_userconnected().numUtil + '&ridProg=' + ridProg;
        return fetch(lien)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
            }).then(data => {
                return data;
            }).catch((error) => {
                return error
            })
    }

    // ---------------------------------------------------Salle-----------------------------------------------------------------
    AddSalle(data) {
        data.idEtabl = this.get_userconnected().idEtabl.idEtabl;
        data.iduser = this.get_userconnected().numUtil;
        var lien = this.state.wsurl + 'salle/add'
        return fetch(lien, {
            method: 'POST',
            headers: {
                'Accept': '*/*',
                'Content-Type': 'application/json; charset=UTF-8',
            },
            body: JSON.stringify(data),
        })
            .then(reponse => {
                return reponse.json();
            })
            .catch((error) => {
                return error;
            });

    }

    UpdateSalle(data) {
        var lien = this.state.wsurl + 'salle/update';
        data.iduser = this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json ; charset=UTF-8',
            },
            body: JSON.stringify(data),
        }).then(reponse => {
            return reponse.json();
        }).catch((error) => {
            return error
        });
    }

    DeleteSalle(data) {
        var lien = this.state.wsurl + 'salle/delete';
        data.iduser = this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json ; charset=UTF-8',
            },
            body: JSON.stringify(data)
        }).then(response => {
            return response.json();
        })
            .catch((error) => {
                return error
            });
    }
    getSalle() {
        var lien = this.state.wsurl + 'salle/gnle?iduser=' + this.get_userconnected().numUtil;
        return fetch(lien)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                }
            }).then(data => {
                return data;
            }).catch((error) => {
                return error
            })
    }

    // ---------------------------------------------------Faculte-----------------------------------------------------------------
    AddFaculte(data) {
        var lien = "";
        lien = this.state.wsurl + 'faculte/add'
        data.idEtabl = this.get_userconnected().idEtabl.idEtabl;
        data.iduser = this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'POST',
            headers: {
                'Accept': '*/*',
                'Content-Type': 'application/json; charset=UTF-8',
            },
            body: JSON.stringify(data),
        })
            .then(reponse => {
                return reponse.json();
            })
            .catch((error) => {
                return error;
            });
    }

    UpdateFaculte(data) {
        var lien = this.state.wsurl + 'faculte/update';
        data.iduser = this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json ; charset=UTF-8',
            },
            body: JSON.stringify(data),
        }).then(reponse => {
            return reponse.json();
        }).catch((error) => {
            return error
        });
    }

    DeleteFaculte(data) {
        var lien = this.state.wsurl + 'faculte/delete';
        data.iduser = this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json ; charset=UTF-8',
            },
            body: JSON.stringify(data)
        }).then(response => {
            return response.json();
        }).catch((error) => {
            return error
        });
    }
    getFaculte() {
        var lien = this.state.wsurl + 'faculte/gnle?iduser=' + this.get_userconnected().numUtil;
        return fetch(lien)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                }
            }).then(data => {
                return data;
            }).catch((error) => {
                return error
            })
    }

    // ---------------------------------------------------Statut-----------------------------------------------------------------
    AddStatut(data) {
        var lien = "", msg = "reussi";
        data.idEtabl = this.get_userconnected().idEtabl.idEtabl;
        data.iduser = this.get_userconnected().numUtil;
        lien = this.state.wsurl + 'statut/add'
        return fetch(lien, {
            method: 'POST', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            },
            body: JSON.stringify(data),
        })
            .then(reponse => {
                return reponse.json();

            })
            .catch((error) => {

                return error;
            });

    }

    UpdateStatut(data) {
        var lien = this.state.wsurl + 'statut/update';
        data.iduser = this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json ; charset=UTF-8',
            },
            body: JSON.stringify(data),
        })
            .then(reponse => {
                return reponse.json();

            })
            .catch((error) => {
                return error
            });

    }

    DeleteStatut(data) {
        var lien = this.state.wsurl + 'statut/delete';
        data.iduser = this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json ; charset=UTF-8',
            },
            body: JSON.stringify(data)
        }).then(response => {
            return response.json();

        })
            .catch((error) => {
                return error
            });

    }
    getStatut() {
        var lien = this.state.wsurl + 'statut/gnle?iduser=' + this.get_userconnected().numUtil;
        return fetch(lien)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {

                }
            })
            .then(data => {
                return data;
            }).catch((error) => {

                return error
            })
    }

    // ---------------------------------------------------Theme-----------------------------------------------------------------
    AddTheme(data) {
        var lien = this.state.wsurl + 'theme/add';
        data.iduser = this.get_userconnected().numUtil;
        data.idEtabl = this.get_userconnected().idEtabl.idEtabl;
        return fetch(lien, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            },
            body: JSON.stringify(data),
        }).then(reponse => {
            return reponse.json();
        }).catch((error) => {
            return error;
        });
    }

    UpdateTheme(data) {
        var lien = this.state.wsurl + 'theme/update';
        data.iduser = this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json ; charset=UTF-8'
            },
            body: JSON.stringify(data),
        }).then(reponse => {
            return reponse.json();
        }).catch((error) => {
            return error
        });
    }

    DeleteTheme(data) {
        var lien = this.state.wsurl + 'theme/delete';
        data.iduser = this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json ; charset=UTF-8',
            },
            body: JSON.stringify(data)
        }).then(response => {
            return response.json();
        }).catch((error) => {
            return error
        });
    }

    getTheme(pd1, pd2) {
        var lien = this.state.wsurl + 'theme/get_liste_theme?pd1=' + pd1 + '&pd2=' + pd2 + '&rannee=' + this.get_rannee() + '&iduser=' + this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }
    // ---------------------------------------------------Trace-----------------------------------------------------------------

    get_liste_trace(pd1, pd2, raction_trace, rcible_trace, rref_trace, rlogin_user) {
        var lien = this.state.wsurl + 'traces/liste_trace?pd1=' + pd1 + '&pd2=' + pd2 + '&rlogin_user=' + rlogin_user + '&rref_trace=' + rref_trace + '&raction_trace=' + raction_trace + '&rcible_trace=' + rcible_trace + '&iduser=' + this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    // ---------------------------------------------------TuteurApart-----------------------------------------------------------------
    AddTuteurApart(tuteurApart) {
        var lien = "";
        lien = this.state.wsurl + 'tuteurApart/add'
        return fetch(lien, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            },
            body: JSON.stringify(tuteurApart),
        }).then(reponse => {
            return reponse.json();
        }).catch((error) => {
            return error;
        });
    }

    Add_batchTuteurApart(tuteurApart) {
        var lien = "";
        lien = this.state.wsurl + 'tuteurApart/add_batch'
        return fetch(lien, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            },
            body: JSON.stringify(tuteurApart),
        }).then(reponse => {
            return reponse.json();
        }).catch((error) => {
            return error;
        });
    }

    UpdateTuteurApart(tuteurApart, idTutetud) {
        var lien = this.state.wsurl + 'tuteurApart/update/';
        return fetch(lien + idTutetud, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json ; charset=UTF-8',
            },
            body: JSON.stringify(tuteurApart),
        }).then(reponse => {
            return reponse.json();
        }).catch((error) => {
            return error
        });
    }

    DeleteTuteurApart(tut) {
        var lien = this.state.wsurl + 'tuteurApart/delete/';
        return fetch(lien + tut.idTutetud, {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json ; charset=UTF-8',
            },
            body: JSON.stringify(tut)
        }).then(response => {
            return response.json();
        }).catch((error) => {
            return error
        });
    }
    getTuteurApart() {
        var lien = this.state.wsurl + 'tuteurApart/gnle'
        return fetch(lien).then(response => {
            if (response.ok) {
                return response.json();
            } else {
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }
    get_liste_tuteurs_by_matricule_eleve(rmatricule) {
        var lien = this.state.wsurl + 'tuteurApart/matricule?matricule=' + rmatricule
        return fetch(lien).then(response => {
            if (response.ok) {
                return response.json();
            } else {
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }
    // ---------------------------------------------------Tuteur-----------------------------------------------------------------
    AddTuteur(tuteur) {
        var lien = "", msg = "reussi";
        lien = this.state.wsurl + 'tuteur/add'
        return fetch(lien, {
            method: 'POST', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            },
            body: JSON.stringify(tuteur),
        }).then(reponse => {
            return reponse.json();
        }).catch((error) => {
            return error;
        });
    }
    UpdateTuteur(tuteur, idTut) {
        var lien = this.state.wsurl + 'tuteur/update/';
        return fetch(lien + idTut, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json ; charset=UTF-8',
            },
            body: JSON.stringify(tuteur),
        }).then(reponse => {
            return reponse.json();
        }).catch((error) => {
            return error
        });
    }
    DeleteTuteur(tuteur) {
        var lien = this.state.wsurl + 'tuteur/delete/';
        return fetch(lien + tuteur.idTut, {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json ; charset=UTF-8',
            },
            body: JSON.stringify(tuteur)
        }).then(response => {
            return response.json();
        }).catch((error) => {
            return error
        });
    }
    get_liste_tuteurs(rtelTut, remailTut, rnomTut) {
        var lien = this.state.wsurl + 'tuteur/get_by_criteria/' + rtelTut + '/' + remailTut + '/' + rnomTut;
        return fetch(lien).then(response => {
            if (response.ok) {
                return response.json();
            } else {
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }
    getTuteur() {
        var lien = this.state.wsurl + 'tuteur/gnle'
        return fetch(lien).then(response => {
            if (response.ok) {
                return response.json();
            } else {
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }
    // ---------------------------------------------------Utilisateur-----------------------------------------------------------------
    AddUser(data) {
        var lien = "";
        data.iduser = this.get_userconnected().numUtil;
        data.idEtabl = this.get_userconnected().idEtabl.idEtabl;
        lien = this.state.wsurl + 'utilisateur/add';
        return fetch(lien, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            },
            body: JSON.stringify(data),
        }).then(reponse => {
            return reponse.json();
        }).catch((error) => {
            return error;
        });
    }

    UpdateUser(data) {
        var lien = this.state.wsurl + 'utilisateur/update';
        data.iduser = this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json ; charset=UTF-8',
            },
            body: JSON.stringify(data),
        }).then(reponse => {
            return reponse.json();
        }).catch((error) => {
            return error
        });
    }
    DeleteUser(compte) {
        var lien = this.state.wsurl + 'utilisateur/delete';
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json ; charset=UTF-8',
            },
            body: JSON.stringify(compte)
        }).then(response => {
            return response.json();
        }).catch((error) => {
            return error
        });
    }
    getUser() {
        var lien = this.state.wsurl + 'utilisateur/liste_cpusers?iduser=' + this.get_userconnected().numUtil;
        return fetch(lien)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                }
            }).then(data => {
                return data;
            }).catch((error) => {
                return error
            })
    }

    get_identification(loginUser, pwdUser) {
        var lien = this.state.wsurl + 'utilisateur/identification';
        let formData = {
            numUtil: '10001',
            compte: loginUser,
            mp: pwdUser,
            profilu: 'NA',
            activer: 'Oui',
            ch: 1,
            matricule: '10001',
            typecmpte: 'NA',
            iduser: '0010'
        }
        return fetch(lien, {
            method: 'POST', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            },
            body: JSON.stringify(formData),
        }).then(reponse => {
            return reponse.json();
        }).catch((error) => {
            return error;
        });
    }

    // ---------------------------------------------------Versement-----------------------------------------------------------------
    AddVersement(data) {
        var lien = this.state.wsurl + 'versement/add';
        data.idEtabl = this.get_userconnected().idEtabl.idEtabl;
        data.iduser = this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            },
            body: JSON.stringify(data),
        }).then(reponse => {
            return reponse.json();
        }).catch((error) => {
            return error;
        });
    }

    UpdateVersement(data) {
        var lien = this.state.wsurl + 'versement/update';
        data.iduser = this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json ; charset=UTF-8',
            },
            body: JSON.stringify(data),
        }).then(reponse => {
            return reponse.json();
        }).catch((error) => {
            return error
        });
    }

    DeleteVersement(data) {
        var lien = this.state.wsurl + 'versement/delete';
        data.iduser = this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json ; charset=UTF-8',
            },
            body: JSON.stringify(data)
        }).then(response => {
            return response.json();
        })
            .catch((error) => {
                return error
            });
    }

    get_liste_versements_by_criteria(pd1, pd2, rlibelle, rclasse, rinsc) {
        var lien = this.state.wsurl + 'versement/get_by_criteria?pd1=' + pd1 + '&pd2=' + pd2 + '&rlibelle=' + rlibelle + '&rclasse=' + rclasse + '&rinsc=' + rinsc + '&rannee=' + this.get_rannee() + '&iduser=' + this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_liste_versements_by_matricule_eleve(rmatricule) {
        var lien = this.state.wsurl + 'versement/liste_by_eleve?matricule=' + rmatricule + '&rannee=' + this.get_rannee()
        return fetch(lien).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }



    get_liste_notes_by_matricule_eleve(rmatricule) {
        var lien = this.state.wsurl + 'noter/matricule?matricule=' + rmatricule
        return fetch(lien).then(response => {
            if (response.ok) {
                return response.json();
            } else {
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    download_fichier_joint(namefile, destination) {
        var lien = this.state.wsurl + 'utils/download_fichier_joint?namefile=' + namefile + '&destination=' + destination + '&iduser=' + this.get_userconnected().numUtil;
        window.location = lien;
    }

    download_file(namefile, destination) {
        var lien = this.state.wsurl + 'utils/download_file?namefile=' + namefile + '&destination=' + destination + '&iduser=' + this.get_userconnected().numUtil;
        return lien
    }

    upload_file(file, namefile, destination) {
        var methode = 'POST';
        var lien = `${this.state.wsurl}utils/upload_file`;
        const formData = new FormData();
        formData.append('file', file);
        formData.append('namefile', namefile);
        formData.append('destination', destination);
        formData.append('iduser', this.get_userconnected().numUtil);
        return fetch(lien, {
            method: methode,
            body: formData
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }




    // ---------------------------------------------------Gerer enseignement-----------------------------------------------------------------
    enreg_gerer_enseignement(data) {
        var lien = this.state.wsurl + 'gerer_enseignement/add';
        data.iduser = this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            },
            body: JSON.stringify(data),
        }).then(reponse => {
            return reponse.json();
        }).catch((error) => {
            return error;
        });
    }


    delete_gerer_enseignement(data) {
        var lien = this.state.wsurl + 'gerer_enseignement/delete';
        data.iduser = this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json ; charset=UTF-8',
            },
            body: JSON.stringify(data)
        }).then(response => {
            return response.json();
        }).catch((error) => {
            return error
        });
    }

    get_liste_gerer_enseignement(rid_user) {
        var lien = this.state.wsurl + 'gerer_enseignement/get_liste_by_user?rid_user=' + rid_user + '&iduser=' + this.get_userconnected().numUtil;
        return fetch(lien).then(response => {
            if (response.ok) {
                return response.json();
            } else {
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }


     /*###################  ENDPOINTS DE GESTION DES CODIFICATIONS            ################### */

    //Ajouter et modifier une codification
    save_codification(data) {
        var lien = "";
        let methode = 'POST';
        if (data.id == 0) {
            lien = this.wsurl() + '/codification/add';
        } else {
            lien = this.wsurl() + '/codification/update';
            methode = 'PUT';
        }
        data.idEtabl = this.get_userconnected().idEtabl.idEtabl;
        data.iduser = this.get_userconnected().numUtil;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                // 'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    // Retourne la liste générale des codifications
    get_liste_codification(paramCodif) {
        var lien = this.wsurl() + '/codification/get_liste_codif?paramCodif=' + paramCodif+ '&iduser=' + this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                // 'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    //Suppression d'une codification
    delete_codification(data) {
        var lien = this.wsurl() + '/codification/delete';
        data.iduser = this.get_userconnected().numUtil;
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                // 'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    /*###################  FIN ENDPOINTS DE GESTION DES CODIFICATIONS         ################### */






}