import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import GestGemsysService from '../service/GestGemsysService';
import { InputText } from 'primereact/inputtext';
import moment from 'moment';
import './styles.css';
import { InputMask } from 'primereact/inputmask';

export class FormPersonnel extends Component {
    constructor() {
        super();
        this.state = {
            rnom: '',
            rcategorie: '',
            rgrade: '',
            ractif: '',
            matrPers: '',
            nomPers: '',
            prenomPers: '',
            categorie: 'Administratif',
            telpers: '',
            emailpers: '',
            quartierpers: '',
            estEnFonction: 'Oui',
            nocompte: '',
            sexe: 'M',
            hierarchie: 'A',
            grade: 'Assistant',
            photo: 'nophoto.png',
            file: null,
            liste_personnel: [],
            selectedPersonnel: null,
            msg: '',
            modif: false,
            url_photo: '',
            visible: false,
            showindicator: false
        };
        this.edit_personnel = this.edit_personnel.bind(this);
        this.conf_delete_personnel = this.conf_delete_personnel.bind(this);
        this.gemsysservice = new GestGemsysService();
    }

    componentDidMount() {
        this.setState({showindicator: true});
        this.gemsysservice.getPersonnel().then(data => this.setState({ liste_personnel: data,showindicator: false }));
    }

    resultat(code, contenu) {
        if (code == '200') {
            this.toast.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_personnel();
            this.componentDidMount();
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({showindicator: false});
    }
    
    edit_personnel(data) {
        if (data != null && data != undefined) {
            this.setState({ matrPers: data.matrPers });
            this.setState({ nomPers: data.nomPers });
            this.setState({ prenomPers: data.prenomPers });
            this.setState({ categorie: data.categorie });
            this.setState({ telpers: data.telpers });
            this.setState({ emailpers: data.emailpers });
            this.setState({ quartierpers: data.quartierpers });
            this.setState({ estEnFonction: data.estEnFonction });
            this.setState({ nocompte: data.nocompte });
            this.setState({ sexe: data.sexe });
            this.setState({ hierarchie: data.hierarchie });
            this.setState({ grade: data.grade });
            this.setState({ photo: data.photo });
            this.setState({ modif: true });
            this.setState({ selectedPersonnel: data });
            window.scrollTo(0, 0);
        }
    }

    annule_personnel() {
        this.setState({ matrPers: '0' });
        this.setState({ nomPers: '' });
        this.setState({ prenomPers: '' });
        this.setState({ categorie: 'Administratif' });
        this.setState({ telpers: '' });
        this.setState({ emailpers: '' });
        this.setState({ quartierpers: '' });
        this.setState({ estEnFonction: 'Oui' });
        this.setState({ nocompte: '' });
        this.setState({ sexe: 'M' });
        this.setState({ hierarchie: 'A' });
        this.setState({ grade: 'Assistant' });
        this.setState({ modif: false });
        this.forceUpdate();
    }

    enreg_personnel() {
        if (this.state.nomPers == "" || this.state.prenomPers == "" || this.state.categorie == "" || this.state.telpers == "" ||this.state.estEnFonction == "" ||this.state.sexe == "") {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else {
            this.setState({showindicator: true});
            let upload = false;
            let nomfic = this.state.photo;
            if (this.state.file != null && this.state.file != undefined) {
                let name = this.state.file.name;
                nomfic = 'P'+this.gemsysservice.get_userconnected().numUtil + moment(new Date()).format('DDMMYYYYHHmmss') + '.' + name.slice((name.lastIndexOf(".") - 1 >>> 0) + 2);
                upload = true;
            }
            if (this.state.modif == false) {
                let personnel = {
                    matrPers: '0',
                    nomPers: this.state.nomPers,
                    prenomPers: this.state.prenomPers,
                    categorie: this.state.categorie,
                    telpers: this.state.telpers,
                    emailpers: this.state.emailpers,
                    quartierpers: this.state.quartierpers,
                    estEnFonction: this.state.estEnFonction,
                    nocompte: this.state.nocompte,
                    sexe: this.state.sexe,
                    hierarchie: this.state.hierarchie,
                    grade: this.state.grade,
                    photo: nomfic
                }
                this.gemsysservice.AddPersonnel(personnel).then(data => {
                    if (data != undefined && data != null) {
                        if (upload === true) {
                            this.fileUpload(this.state.file, nomfic);
                        }
                        this.resultat(data.code, data.contenu);
                    }
                });
            } else {
                let personnel=this.state.selectedPersonnel;
                personnel.nomPers=this.state.nomPers;
                personnel.prenomPers=this.state.prenomPers;
                personnel.categorie=this.state.categorie;
                personnel.telpers=this.state.telpers;
                personnel.emailpers=this.state.emailpers;
                personnel.quartierpers=this.state.quartierpers;
                personnel.nocompte=this.state.nocompte;
                personnel.sexe=this.state.sexe;
                personnel.hierarchie=this.state.hierarchie;
                personnel.estEnFonction=this.state.estEnFonction;
                personnel.photo=nomfic;
                personnel.grade=this.state.grade;
                this.gemsysservice.UpdatePersonnel(personnel).then(data => {
                    if (data != undefined && data != null) {
                        if (upload === true) {
                            this.fileUpload(this.state.file, nomfic);
                        }
                        this.resultat(data.code, data.contenu);
                    }
                });
            }
        }
    }

    actionTemplate(rowData, column) {
        return <div className="grid" style={{ width: 90 }}>
            <div className="col"><Button onClick={(e) => this.edit_personnel(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
            <div className="col"><Button onClick={(e) => this.conf_delete_personnel(rowData)} className="p-button-danger" style={{ width: 25, height: 25,marginLeft: -20 }} icon="pi pi-times" /></div>
        </div>
    }

    fileUpload(file, namefile) {
        this.gemsysservice.upload_file(file, namefile, 'photos/').then(data => {
            if (data != null && data != undefined && data.code == '200') {
                this.toast.show({ severity: 'success', summary: 'Success', detail: 'Chargement effectué avec succès' });
            } else {
                this.toast.show({ severity: 'error', summary: 'Error', detail: 'Echec upload' });
            }
        });
    }

    photoTemplate(rowData, column) {
        let fic = rowData.photo;
        if (fic === undefined || fic === null || fic === '') {
            fic = 'nophoto.png';
        }
        return <img src={this.gemsysservice.download_file(fic, 'photos/')} alt='Photo' style={{ width: 45, height: 45 }} />
    }

    nomTemplate(rowData, column) {
        return <div>
            <span>{rowData.prenomPers + " " + rowData.nomPers}</span>
        </div>
    }

    delete_personnel() {
        if (this.state.selectedPersonnel == null || this.state.selectedPersonnel == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.setState({showindicator: true});
            this.gemsysservice.DeletePersonnel(this.state.selectedPersonnel).then(data => this.resultat(data.code, data.contenu));
        }
    }
    conf_delete_personnel(data) {
        if (data !== null && data !== undefined) {
            this.setState({ selectedPersonnel: data });
            this.setState({ msg: data.matrPers });
            this.setState({ visible: true });
        }
    }

    render() {
        let categories = [
            { label: 'Administratif', value: 'Administratif' },
            { label: 'Vacataire', value: 'Vacataire' },
            { label: 'Contractuel', value: 'Contractuel' }
        ];
        let actifs = [
            { label: 'Oui', value: 'Oui' },
            { label: 'Non', value: 'Non' }
        ];
        let genres = [
            { label: 'Masculin', value: 'M' },
            { label: 'Féminin', value: 'F' }
        ];
        let hierarchies = [
            { label: 'A', value: 'A' },
            { label: 'B', value: 'B' },
            { label: 'C', value: 'C' }
        ];
        let grades = [
            { label: 'Assistant', value: 'Assistant' },
            { label: 'Inconnu', value: 'X' },
            { label: 'Maitre assistant', value: 'Maitre assistant' },
            { label: 'Maitre de conférence', value: 'Maitre de conférence' },
            { label: 'Professeur', value: 'Professeur' },
        ];
        const dialogFooter = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible: false });
                    this.delete_personnel();
                }} label="Oui" loading={this.state.showindicator} />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );
        return (
            <div className="p-fluid">
                <Toast ref={(el) => this.toast = el} />
                <Dialog header="Confirmation" visible={this.state.visible} style={{ width: '250px' }} modal={true} footer={dialogFooter} onHide={(e) => this.setState({ visible: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>
                <div className="grid">
                    <div className="col-12">
                        <div className="card card-w-title">
                                <div className="grid " >
                                    <div className="col-12 md:col-3">
                                        <label htmlFor="nomPers">Nom  *</label>
                                    </div>
                                    <div className="col-12 md:col-3">
                                        <InputText id="nomPers" value={this.state.nomPers} onChange={(e) => this.setState({ nomPers: e.target.value })} />
                                    </div>
                                    <div className="col-12 md:col-3">
                                        <label htmlFor="prenomPers">Prénom *</label>
                                    </div>
                                    <div className="col-12 md:col-3">
                                        <InputText id="prenomPers" value={this.state.prenomPers} onChange={(e) => this.setState({ prenomPers: e.target.value })} />
                                    </div>
                                    <div className="col-12 md:col-3">
                                        <label htmlFor="categorie">Catégorie *</label>
                                    </div>
                                    <div className="col-12 md:col-3" >
                                        <Dropdown options={categories} value={this.state.categorie} onChange={(e) => this.setState({ categorie: e.target.value })} autoWidth={false} />
                                    </div>
                                    <div className="col-12 md:col-3">
                                        <label htmlFor="telpers">Téléphone *</label>
                                    </div>
                                    <div className="col-12 md:col-3" >
                                        <InputMask mask="999999999" id="telpers" value={this.state.telpers} onChange={(e) => this.setState({ telpers: e.target.value })} />
                                    </div>
                                    <div className="col-12 md:col-3">
                                        <label htmlFor="emailpers">Email</label>
                                    </div>
                                    <div className="col-12 md:col-3" >
                                        <InputText id="emailpers" value={this.state.emailpers} onChange={(e) => this.setState({ emailpers: e.target.value })} />
                                    </div>
                                    <div className="col-12 md:col-3">
                                        <label htmlFor="quartierpers">Quartier *</label>
                                    </div>
                                    <div className="col-12 md:col-3" >
                                        <InputText id="quartierpers" value={this.state.quartierpers} onChange={(e) => this.setState({ quartierpers: e.target.value })} />
                                    </div>
                                    <div className="col-12 md:col-3">
                                        <label htmlFor="estEnFonction">Actif *</label>
                                    </div>
                                    <div className="col-12 md:col-3" >
                                        <Dropdown options={actifs} value={this.state.estEnFonction} onChange={(e) => this.setState({ estEnFonction: e.target.value })} autoWidth={false} />
                                    </div>
                                    <div className="col-12 md:col-3">
                                        <label htmlFor="nocompte">N° Compte </label>
                                    </div>
                                    <div className="col-12 md:col-3" >
                                        <InputText id="nocompte" value={this.state.nocompte} onChange={(e) => this.setState({ nocompte: e.target.value })} />
                                    </div>
                                    <div className="col-12 md:col-3">
                                        <label htmlFor="sexe">Sexe *</label>
                                    </div>
                                    <div className="col-12 md:col-3" >
                                        <Dropdown options={genres} value={this.state.sexe} onChange={(e) => this.setState({ sexe: e.target.value })} autoWidth={false} />
                                    </div>
                                    <div className="col-12 md:col-3">
                                        <label htmlFor="hierarchie">Hierarchie </label>
                                    </div>
                                    <div className="col-12 md:col-3" >
                                        <Dropdown options={hierarchies} value={this.state.hierarchie} onChange={(e) => this.setState({ hierarchie: e.target.value })} autoWidth={false} />
                                    </div>
                                    <div className="col-12 md:col-3">
                                        <label htmlFor="grade">Grade </label>
                                    </div>
                                    <div className="col-12 md:col-3" >
                                        <Dropdown options={grades} value={this.state.grade} onChange={(e) => this.setState({ grade: e.target.value })} autoWidth={false} />
                                    </div>
                                    <div className="col-12 md:col-3">
                                        <label htmlFor="img">Photo</label>
                                    </div>
                                    <div className="col-12 md:col-3" >
                                        <input type="file" onChange={e => this.setState({ file: e.target.files[0] })} />
                                    </div>
                                </div>
                            <center>
                                <div className="grid" style={{ width: 300, marginTop: '0%' }}>
                                    <div className="col"><Button label="Annuler" icon="pi pi-ban" onClick={(e) => this.annule_personnel()} /></div>
                                    <div className="col"><Button label="Valider" loading={this.state.showindicator} icon="pi pi-check" onClick={(e) => this.enreg_personnel()} /></div>
                                </div>
                            </center>
                           
                            <DataTable value={this.state.liste_personnel} style={{ minHeight: '300px' }}
                                selectionMode="single" dataKey="matrPers" className="p-datatable-gridlines" showGridlines responsiveLayout="scroll" paginator rows={50}
                                onRowClick={e => this.setState({ selectedPersonnel: e.data })} onRowSelect={e => this.setState({ selectedPersonnel: e.data })}
                                selection={this.state.selectedPersonnel} >
                                <Column header="Actions" body={this.actionTemplate.bind(this)} style={{ width: '7%' }} />
                                <Column body={this.photoTemplate.bind(this)} header="Photo" style={{ width: '5%' }} />
                                <Column field="matrPers" header="Matricule" style={{ width: '8%' }} filter={true} filterMatchMode='contains' />
                                <Column field="nomPers"  filterMatchMode='contains' header="Nom" style={{ width: '10%' }} filter={true} />
                                <Column field="prenomPers" filterMatchMode='contains' header="Prénom" style={{ width: '15%' }} filter={true}  />
                                <Column field="sexe" header="Sexe" style={{ width: '5%' }} filter={true} filterMatchMode='contains' />
                                <Column field="grade" header="Grade" style={{ width: '8%' }} filter={true} filterMatchMode='contains' />
                                <Column field="categorie" header="Catégorie" style={{ width: '10%' }} filter={true} filterMatchMode='contains' />
                                <Column field="telpers" header="Contacts" style={{ width: '8%' }} filter={true} filterMatchMode='contains' />
                                <Column field="estEnFonction" header="Actif"  style={{ width: '8%' }} filter={true} filterMatchMode='contains' />
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}