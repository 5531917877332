import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import GestGemsysService from '../service/GestGemsysService';
import { InputText } from 'primereact/inputtext';

export class FormFacDep extends Component {
    constructor() {
        super();
        this.state = {
            codefac: "",
            libFac: "",
            typedept: "",
            matrPers: null,

            selectedFac: null,
            selectedDep: null,
            facultes: null,
            departements: null,
            personnels: null,

            codeEnseign: "",
            libEnseign: "",
            matrPers1: null,

            msg: '',
            modif: false,
            modifDep: false,
            visible: false,
            visibleDep: false, 
            showindicator: false
        };
        this.edit_fac = this.edit_fac.bind(this);
        this.edit_depart = this.edit_depart.bind(this);
        this.conf_delete_fac = this.conf_delete_fac.bind(this);
        this.enreg_fac = this.enreg_fac.bind(this);
        this.enreg_depart = this.enreg_depart.bind(this);
        this.conf_delete_depart = this.conf_delete_depart.bind(this);
        this.gemsysService = new GestGemsysService();
    }

    componentDidMount() {
        this.gemsysService.getPersonnel().then(data => {
            if (data !== null && data !== undefined) {
                this.setState({ personnels: data.filter(function (el) { return el.estEnFonction === 'Oui'}) })
            }
        });
        this.gemsysService.getFaculte().then(data => this.setState({ facultes: data }));
        this.get_liste_deprtement();
    }

    get_liste_deprtement() {
        if(this.gemsysService.get_userconnected().idEtabl.typeEtabl == 'Université'){
            if(this.state.selectedFac!==null && this.state.selectedFac!=='' && this.state.selectedFac!==undefined  ){
                this.gemsysService.getEnseignementByFac(this.state.selectedFac.codefac).then(data => this.setState({ departements: data }));
            }
        }else{
            this.gemsysService.getEnseignement().then(data => this.setState({ departements: data }));
        }
    }

    resultat(code, contenu) {
        if (code == '200') {
            this.toast.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annuler_fac();
            this.componentDidMount();
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({showindicator: false});
    }
    resultatDep(code, contenu) {
        if (code == '200') {
            this.toast.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annuler_depart();
            this.componentDidMount();
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({showindicator: false});
    }
    edit_fac(data) {
        if (data != null && data != undefined) {
            this.setState({ codefac: data.codefac });
            this.setState({ libFac: data.libFac });
            this.setState({ matrPers: data.matrPers.matrPers });
            this.setState({ selectedFac: data });
            this.setState({ modif: true });
            window.scrollTo(0, 0);
        }
    } 
    edit_depart(data) {
        if (data != null && data != undefined) {
            this.setState({ codeEnseign: data.codeEnseign });
            this.setState({ libEnseign: data.libEnseign });
            this.setState({ denregEnseign: new Date() });
            this.setState({ matrPers1: data.matrPers.matrPers });
            this.setState({ selectedDep: data });
            this.setState({ typedept: data.typeEnseign });
            this.setState({ modifDep: true });
        }
    }
    annuler_fac() {
        this.setState({ codefac: "" });
        this.setState({ libFac: "" });
        this.setState({ matrPers: null });
        this.setState({ modif: false });
        this.forceUpdate();
    }

    annuler_depart() {
        this.setState({ codeEnseign: "" });
        this.setState({ libEnseign: "" });
        this.setState({ matrPers1: null });
        this.setState({ modifDep: false });
        this.forceUpdate();
    }

    enreg_fac() {
        if (this.state.libFac === "" || this.state.matrPers === "" || this.state.matrPers === null
            || this.state.matrPers === undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else {
            this.setState({showindicator: true});
            if (this.state.modif == false) {
                let fac = {
                    codefac: this.state.codefac,
                    libFac: this.state.libFac,
                    matrPers: { matrPers: this.state.matrPers }
                }
                this.gemsysService.AddFaculte(fac).then(data => this.resultat(data.code, data.contenu));
            } else {
                let fac = this.state.selectedFac;
                fac.libFac = this.state.libFac;
                fac.matrPers = { matrPers: this.state.matrPers };
                this.gemsysService.UpdateFaculte(fac).then(data => this.resultat(data.code, data.contenu));
            }
        }
    }

    enreg_depart() {
        if (this.state.libEnseign == "" || this.state.matrPers1 == "" || this.state.matrPers1 == undefined
            || this.state.matrPers1 == null||this.state.typedept == "") {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else {
            this.setState({showindicator: true});
            if (this.state.modifDep == false) {
                let depart = {
                    codeEnseign: this.state.codeEnseign,
                    libEnseign: this.state.libEnseign,
                    typeEnseign: this.state.typedept,
                    matrPers: { matrPers: this.state.matrPers1 },
                    codeFac: this.state.selectedFac,
                }
                this.gemsysService.AddEnseignement(depart).then(data => this.resultatDep(data.code, data.contenu));
            } else {
                let depart = this.state.selectedDep;
                depart.libEnseign = this.state.libEnseign;
                depart.typeEnseign = this.state.typedept;
                depart.matrPers = { matrPers: this.state.matrPers1 };
                depart.codeFac= this.state.selectedFac;
                this.gemsysService.UpdateEnseignement(depart).then(data => this.resultatDep(data.code, data.contenu));
            }
        }
    }
    actionTemplate(rowData) {
        return <div className="grid" style={{ width: 90 }}>
            <div className="col"><Button onClick={() => this.edit_fac(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
            <div className="col"><Button onClick={() => this.conf_delete_fac(rowData)} className="p-button-danger" style={{ width: 25, height: 25, marginLeft: -20 }} icon="pi pi-times" /></div>
        </div>
    }

    actionTemplateDep(rowData) {
        return <div className="grid" style={{ width: 90 }}>
            <div className="col"><Button onClick={() => this.edit_depart(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
            <div className="col"><Button onClick={() => this.conf_delete_depart(rowData)} className="p-button-danger" style={{ width: 25, height: 25, marginLeft: -20 }} icon="pi pi-times" /></div>
        </div>
    }
    delete_fac() {
        if (this.state.selectedFac === null || this.state.selectedFac === undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.setState({showindicator: true});
            this.gemsysService.DeleteFaculte(this.state.selectedFac).then(data => this.resultat(data.code, data.contenu));
        }
    }

    delete_depart() {
        if (this.state.selectedDep === null || this.state.selectedDep === undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.setState({showindicator: true});
            this.gemsysService.DeleteEnseignement(this.state.selectedDep).then(data => this.resultatDep(data.code, data.contenu));
        }
    }
    conf_delete_fac(data) {
        if (data != null && data != undefined) {
            this.setState({ selectedFac: data });
            this.setState({ visible: true });
        }
    }

    conf_delete_depart(data) {
        if (data != null && data != undefined) {
            this.setState({ selectedDep: data });
            this.setState({ visibleDep: true });
        }
    }
    render() {
        let listepersonnels = null;
        if (this.state.personnels != null && this.state.personnels != undefined) {
            listepersonnels = this.state.personnels.map(x => {
                return { value: x.matrPers, label: x.prenomPers + " " + x.nomPers, categoriePers: x.categorie }
            });
        }

        let typesdeps = [];

        if (this.gemsysService.get_userconnected().idEtabl.typeEtabl == 'Université') {
            typesdeps = [
                { label: "Sces fondamentales", value: "Sces fondamentales" },
                { label: "Sces appliquées", value: "Sces appliquées" },
                { label: "Sces humaines", value: "Sces humaines" },
                { label: "Médecine", value: "Médecine" },
                { label: "BTS", value: "BTS" },
                { label: "Santé", value: "Santé" }
            ];
        }else if (this.gemsysService.get_userconnected().idEtabl.typeEtabl == 'Professionnel') {
            typesdeps = [
                { label: "BTS", value: "BTS" },
                { label: "Santé", value: "Santé" }
            ];
        }else{
            typesdeps = [
                { label: "Enseignement préuniversitaire", value: "Enseignement préuniversitaire" },
            ];
        }

        const dialogFooter = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible: false });
                    this.delete_fac();
                }} label="Oui" loading={this.state.showindicator} />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );
        const dialogFooterDep = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible: false });
                    this.delete_depart();
                }} label="Oui" loading={this.state.showindicator} />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );
        return (
            <div className="p-fluid">
                <Toast ref={(el) => this.toast = el} />
                <Dialog header="Confirmation" visible={this.state.visible} style={{ width: '250px' }} modal={true} footer={dialogFooter} onHide={() => this.setState({ visible: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>
                <Dialog header="Confirmation" visible={this.state.visibleDep} style={{ width: '250px' }} modal={true} footer={dialogFooterDep} onHide={() => this.setState({ visibleDep: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>
                <div className="grid">
                    <div className="col-12">
                        <div className="card card-w-title">
                            {this.gemsysService.get_userconnected().idEtabl.typeEtabl == 'Université' ?
                                <>
                                    <i className="pi pi-sitemap"></i> Gestion des facultés<hr />
                                    <div className="grid">
                                        <div className="col-12 md:col-2">
                                            <label htmlFor="libFac">Faculté *</label>
                                        </div>
                                        <div className="col-12 md:col-4">
                                            <InputText id="libFac" value={this.state.libFac} onChange={(e) => this.setState({ libFac: e.target.value })} />
                                        </div>
                                        <div className="col-12 md:col-2">
                                            <label htmlFor="matrPers">Doyen *</label>
                                        </div>
                                        <div className="col-12 md:col-4">
                                            <Dropdown options={listepersonnels} value={this.state.matrPers} optionLabel='label' filter={true} filterBy='label,value' filterMatchMode='contains' onChange={(e) => this.setState({ matrPers: e.target.value })} itemTemplate={(option) => {
                                                if (!option.value) {
                                                    return option.label;
                                                }
                                                else {
                                                    return (
                                                        <div className="grid" >
                                                            <div className="col-12 md:col-2">
                                                                <label>{option.value}</label>
                                                            </div>
                                                            <div className="col-12 md:col-7">
                                                                <label>{option.label}</label>
                                                            </div>
                                                            <div className="col-12 md:col-3">
                                                                <label>{option.categoriePers}</label>
                                                            </div>
                                                        </div>
                                                    );
                                                }
                                            }} autoWidth={false} panelStyle={{ width: '500px' }} />
                                        </div>
                                    </div>
                                    <center>
                                        <div className="grid" style={{ width: 300 }}>
                                            <div className="col"><Button label="Annuler" icon="pi pi-ban" onClick={() => this.annuler_fac()} /></div>
                                            <div className="col"><Button label="Valider" loading={this.state.showindicator} icon="pi pi-check" onClick={() => this.enreg_fac()} /></div>
                                        </div>
                                    </center>
                                    <DataTable value={this.state.facultes} selectionMode="single" alwaysShowPaginator={false}
                                        onRowClick={e => {
                                            this.setState({ selectedFac: e.data }, () => {
                                                this.get_liste_deprtement();
                                            });
                                        }}
                                         selection={this.state.selectedFac}  style={{ minHeight: '200px' }} 
                                         className="p-datatable-gridlines" showGridlines responsiveLayout="scroll" paginator rows={5}>
                                        <Column header="Actions" body={this.actionTemplate.bind(this)} style={{ width: '8%' }} />
                                        <Column field="libFac" header="Faculté" style={{ width: '35%' }} />
                                        <Column field="matrPers.matrPers" header="Matricule" style={{ width: '17%' }} />
                                        <Column field="" body={(rowData, Column) => {
                                            return <div>
                                                <span>{rowData.matrPers.prenomPers + " " + rowData.matrPers.nomPers}</span>
                                            </div>
                                        }} header="Nom doyen" style={{ width: '40%' }} />
                                    </DataTable>

                                </>
                                : null} 
                            <i className="pi pi-sitemap"></i> Gestion des  {this.gemsysService.get_userconnected().idEtabl.typeEtabl == 'Université' ? 'départements' : 'fillières'}<hr />
                            <DataTable value={this.state.departements} selectionMode="single" 
                                alwaysShowPaginator={false} onRowClick={e => this.setState({ selectedDep: e.data })}
                                onRowSelect={e => this.setState({ selectedDep: e.data })} 
                                selection={this.state.selectedDep} className="p-datatable-gridlines" showGridlines responsiveLayout="scroll" paginator rows={5}>
                                <Column header="#" body={this.actionTemplateDep.bind(this)} style={{ width: '6%' }} filter={true} filterMatchMode='contains'   sortable={true} />
                                <Column field="libEnseign" header="Libellé" style={{ width: '22%' }} filter={true} filterMatchMode='contains'   sortable={true} />
                                <Column field="typeEnseign" header="Type" style={{ width: '15%' }} filter={true} filterMatchMode='contains'   sortable={true} />
                                <Column field="matrPers.matrPers" header="Matricule" style={{ width: '13%' }} filter={true} filterMatchMode='contains'   sortable={true} />
                                <Column field="" body={(rowData, Column) => {
                                    return <div>
                                        <span>{rowData.matrPers.prenomPers + " " + rowData.matrPers.nomPers}</span>
                                    </div>
                                }} header="Nom responsable" style={{ width: '22%' }} filter={true} filterMatchMode='contains'   sortable={true} />
                                <Column field="codeEnseign" header="Code" style={{ width: '12%' }} filter={true} filterMatchMode='contains'   sortable={true} />
                            </DataTable>
                            <hr />
                            <div className="p-fluid p-formgrid grid">
                                <div className="p-field col-12 md:col-4">
                                    <label htmlFor="firstname2">Libellé *</label>
                                    <InputText id="libEnseign" value={this.state.libEnseign} onChange={(e) => this.setState({ libEnseign: e.target.value })} />
                                </div>

                                <div className="p-field col-12 md:col-4">
                                    <label htmlFor="firstname2">Type  *</label>
                                    <Dropdown id="typedept" options={typesdeps} value={this.state.typedept} onChange={e => this.setState({ typedept: e.value })} autoWidth={false} />
                                </div>
 
                                <div className="p-field col-12 md:col-4">
                                    <label htmlFor="firstname2">Responsable *</label>
                                    <Dropdown options={listepersonnels} value={this.state.matrPers1} optionLabel='label' filter={true} filterBy='label,value' filterMatchMode='contains' onChange={(e) => this.setState({ matrPers1: e.target.value })} itemTemplate={(option) => {
                                        if (!option.value) {
                                            return option.label;
                                        }
                                        else {
                                            return (
                                                <div className="grid" style={{margin: 5}}>
                                                    <div className="col-12 md:col-4">
                                                        <label>{option.value}</label>
                                                    </div>
                                                    <div className="col-12 md:col-8">
                                                        <label>{option.label}</label>
                                                    </div>
                                                </div>
                                            );
                                        }
                                    }} autoWidth={false} panelStyle={{ width: '500px' }} />
                                </div>
                            </div>
                            <center>
                                <div className="grid" style={{ width: 300 }}>
                                    <div className="col"><Button label="Annuler" icon="pi pi-ban" onClick={() => this.annuler_depart()} /></div>
                                    <div className="col"><Button label="Valider" loading={this.state.showindicator} icon="pi pi-check" onClick={() => this.enreg_depart()} /></div>
                                </div>
                            </center>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}