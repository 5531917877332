import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import GestGemsysService from '../service/GestGemsysService';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import moment from 'moment';
import './styles.css';
import { InputNumber } from 'primereact/inputnumber';

export class FormEmprunt extends Component {
    constructor() {
        super();
        this.state = {
            pd1: new Date(),
            pd2: new Date(),
            retat: 'Tous',
            rdateRegl: '',
            rmatrPers: 'Tous',
            montant: 0,
            libEmprunt: 'Avance sur salaire',
            typeEmprunt: 'Avance sur salaire',
            cause: '',
            solde: 'Non soldé',
            debutRegl: '',
            tranchRegl: 0,
            dateFinRegl: '',
            matrPers: null,
            liste_emprunts: [],
            liste_personnel: [],
            selectedEmprunt: null,
            msg: '',
            modif: false,
            visible: false,
            showindicator: false
        };
        this.edit_emprunt = this.edit_emprunt.bind(this);
        this.conf_delete_emprunt = this.conf_delete_emprunt.bind(this);
        this.export = this.export.bind(this);
        this.gemsysservice = new GestGemsysService();

    }
    componentDidMount() {
        this.get_full_personnel();
        this.get_liste_emprunts();
    }

    get_full_personnel() {
        this.setState({ liste_personnel: [] });
        this.gemsysservice.getPersonnel().then(data => {
            if (data !== undefined && data !== null) {
                if (data.length > 0) {
                    let liste = data.map(x => {
                        return { value: x.matrPers, label: x.nomPers + " " + x.prenomPers, categoriePers: x.categorie }
                    });
                    this.setState({ liste_personnel: liste });
                }
            }
        });
    }
    resultat(code, contenu) {
        if (code == '200') {
            this.toast.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_emprunt();
            this.componentDidMount();
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({ showindicator: false });
    }
    edit_emprunt(data) {
        if (data != null && data != undefined) {
            this.setState({ numMont: data.numMont });
            this.setState({ montant: data.montant });
            this.setState({ libEmprunt: data.libEmprunt });
            this.setState({ cause: data.cause });
            this.setState({ solde: data.solde });
            this.setState({ debutRegl: new Date(moment(data.debutRegl, 'DD/MM/YYYY').format('YYYY-MM-DD')) });
            this.setState({ tranchRegl: data.tranchRegl });
            this.setState({ matrPers: data.matrPers.matrPers });
            this.setState({ modif: true });
            this.setState({ selectedEmprunt: data });
            window.scrollTo(0, 0);
        }
    }

    annule_emprunt() {
        this.setState({ numMont: '' });
        this.setState({ montant: 0 });
        this.setState({ libEmprunt: 'Avance sur salaire' });
        this.setState({ typeEmprunt: 'Avance sur salaire' });
        this.setState({ cause: '' });
        this.setState({ solde: 'Non soldé' });
        this.setState({ debutRegl: '' });
        this.setState({ tranchRegl: 0 });
        this.setState({ dateFinRegl: null });
        this.setState({ matrPers: null });
        this.setState({ modif: false });
        this.forceUpdate();
    }

    enreg_emprunt() {
        if (this.state.montant == "" || this.state.libEmprunt == "" || this.state.debutRegl == "" || this.state.tranchRegl == "" || this.state.matrPers == null) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else {
            this.setState({ showindicator: true });
            if (this.state.modif == false) {
                let emprunt = {
                    numMont: '0',
                    montant: this.state.montant,
                    libEmprunt: this.state.libEmprunt,
                    cause: this.state.cause,
                    anneeUniv: this.gemsysservice.get_rannee(),
                    solde: 'Non',
                    debutRegl: moment(this.state.debutRegl, 'DD/MM/YYYY').format('DD/MM/YYYY'),
                    tranchRegl: this.state.tranchRegl,
                    matrPers: { matrPers: this.state.matrPers }
                };
                this.gemsysservice.AddEmprunt(emprunt).then(data => this.resultat(data.code, data.contenu));
            } else {
                let emprunt = this.state.selectedEmprunt;
                emprunt.montant = this.state.montant;
                emprunt.libEmprunt = this.state.libEmprunt;
                emprunt.cause = this.state.cause;
                emprunt.debutRegl = moment(this.state.debutRegl, 'DD/MM/YYYY').format('DD/MM/YYYY');
                emprunt.tranchRegl = this.state.tranchRegl;
                emprunt.matrPers = { matrPers: this.state.matrPers };
                this.gemsysservice.UpdateEmprunt(emprunt).then(data => this.resultat(data.code, data.contenu));
            }
        }
    }
    export() {
        this.dt.exportCSV();
    }
    header_emprunt() {
        return <div>
            <div className="col"><Button label="Exporter" icon="pi pi-external-link" onClick={this.export} /></div>
        </div>
    }
    actionTemplate(rowData, column) {
        return <div className="grid" style={{ width: 90 }}>
            <div className="col"><Button onClick={(e) => this.edit_emprunt(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
            <div className="col"><Button onClick={(e) => this.conf_delete_emprunt(rowData)} className="p-button-danger" style={{ width: 25, height: 25, marginLeft: -20 }} icon="pi pi-times" /></div>
        </div>
    }
    delete_emprunt() {
        if (this.state.selectedEmprunt == null || this.state.selectedEmprunt == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.setState({ showindicator: true });
            this.gemsysservice.DeleteEmprunt(this.state.selectedEmprunt).then(data => this.resultat(data.code, data.contenu));
        }
    }
    get_liste_emprunts() {
        let rmatr_Pers = 'Tous';
        let retat = 'Tous';
        let spd1 = moment(this.state.pd1).format('DD/MM/YYYY');
        let spd2 = moment(this.state.pd2).format('DD/MM/YYYY');
        if (this.state.rmatrPers !== undefined && this.state.rmatrPers !== null && this.state.rmatrPers !== 'Tous') {
            rmatr_Pers = this.state.rmatrPers;
        }
        if (this.state.retat !== undefined && this.state.retat !== null && this.state.retat !== 'Tous') {
            retat = this.state.retat;
        }
        this.setState({ showindicator: true });
        this.gemsysservice.get_liste_emprunts_by_criteria(spd1, spd2, rmatr_Pers, retat).then(data => this.setState({ liste_emprunts: data, showindicator: false }));
    }

    conf_delete_emprunt(data) {
        if (data !== null && data !== undefined) {
            this.setState({ selectedEmprunt: data });
            this.setState({ msg: data.numMont });
            this.setState({ visible: true });
        }
    }

    render() {
       
        let typeEmprunts = [
            { label: 'Avance sur salaire', value: 'Avance sur salaire' },
            { label: 'Pret', value: 'Pret' },
            { label: 'Autre', value: 'Autre' }
        ];

      
        let retats = [
            { label: 'Etat', value: 'Tous' },
            { label: 'Soldé', value: 'Oui' },
            { label: 'Non soldé', value: 'Non' }
        ];
        const dialogFooter = (
            <div>
                <Button icon="pi pi-times" onClick={() => this.setState({ visible: false })} label="Annuler" className="p-button-secondary" />
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible: false });
                    this.delete_emprunt();
                }} label="Oui" loading={this.state.showindicator} className="p-button-danger" />
            </div>
        );
        return (
            <div className="p-fluid">
                <Toast ref={(el) => this.toast = el} />
                <Dialog header="Confirmation" visible={this.state.visible} style={{ width: '250px' }} modal={true} footer={dialogFooter} onHide={(e) => this.setState({ visible: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>
                <div className="grid">
                    <div className="col-12">
                        <div className="card card-w-title">
                            <div className="grid " >
                                <div className="col-12 md:col-3">
                                    <label htmlFor="montant">Montant  *</label>
                                </div>
                                <div className="col-12 md:col-3">
                                    <InputNumber value={this.state.montant} onChange={(e) => this.setState({ montant: e.value })} />
                                </div>
                                <div className="col-12 md:col-3">
                                    <label htmlFor="typeEmprunt">Libellé *</label>
                                </div>
                                <div className="col-12 md:col-3" >
                                    <Dropdown options={typeEmprunts} value={this.state.libEmprunt} onChange={(e) => this.setState({ libEmprunt: e.target.value })} autoWidth={false} />
                                </div>
                                <div className="col-12 md:col-3">
                                    <label htmlFor="cause">Motif *</label>
                                </div>
                                <div className="col-12 md:col-3" >
                                    <InputText id="cause" value={this.state.cause} onChange={(e) => this.setState({ cause: e.target.value })} />
                                </div>
                                <div className="col-12 md:col-3">
                                    <label htmlFor="debutRegl">Début Règlement *</label>
                                </div>
                                <div className="col-12 md:col-3" >
                                    <Calendar id="debutRegl" dateFormat="dd/mm/yy" value={this.state.debutRegl} onChange={(e) => this.setState({ debutRegl: e.target.value })}></Calendar>
                                </div>
                                <div className="col-12 md:col-3">
                                    <label htmlFor="tranchRegl">Tranche règlement *</label>
                                </div>
                                <div className="col-12 md:col-3" >
                                    <InputNumber value={this.state.tranchRegl} onChange={(e) => this.setState({ tranchRegl: e.value })} />
                                </div>
                                <div className="col-12 md:col-3">
                                    <label htmlFor="matrPers">Personnel *</label>
                                </div>
                                <div className="col-12 md:col-3" >
                                    <Dropdown options={this.state.liste_personnel} value={this.state.matrPers} optionLabel='label' filter={true} filterBy='label,value' filterMatchMode='contains' onChange={(e) => this.setState({ matrPers: e.target.value })} itemTemplate={(option) => {
                                        if (!option.value) {
                                            return option.label;
                                        }
                                        else {
                                            return (
                                                <div className="grid" >
                                                    <div className="col-12 md:col-3">
                                                        <label>{option.value}</label>
                                                    </div>
                                                    <div className="col-12 md:col-9">
                                                        <label>{option.label}</label>
                                                    </div>
                                                </div>
                                            );
                                        }
                                    }} autoWidth={false} panelStyle={{ width: '450px' }} />
                                </div>
                            </div>
                            <center>
                                <div className="grid" style={{ width: 300, marginTop: '0%' }}>
                                    <div className="col"><Button label="Annuler" icon="pi pi-ban" onClick={(e) => this.annule_emprunt()} /></div>
                                    <div className="col"><Button label="Valider" loading={this.state.showindicator} icon="pi pi-check" onClick={(e) => this.enreg_emprunt()} /></div>
                                </div>
                            </center>
                            <i className="pi pi-search"></i>
                            <span style={{ fontSize: 14, marginLeft: 10, color: '#999999' }}>Options de recherche</span>
                            <div className="col-12">
                                <div className="grid"> 
                                    <div className="col-12 md:col-2" >
                                        <Calendar dateFormat="dd/mm/yy" placeholder="Date début" value={this.state.pd1} onChange={(e) => this.setState({ pd1: e.target.value })}></Calendar>
                                    </div>
                                    <div className="col-12 md:col-2" >
                                        <Calendar dateFormat="dd/mm/yy" placeholder="Date fin" value={this.state.pd2} onChange={(e) => this.setState({ pd2: e.target.value })}></Calendar>
                                    </div>
                                    <div className="col-12 md:col-3" >
                                        <Dropdown options={[{value: "Tous",label:"Personnel"}].concat(this.state.liste_personnel)} placeholder="Selectionner personnel" optionLabel="label" value={this.state.rmatrPers} onChange={(e) => this.setState({ rmatrPers: e.target.value })} autoWidth={false} filter={true} filterBy='label'  panelStyle={{ width: '350px' }} />
                                    </div>
                                    <div className="col-12 md:col-2" >
                                        <Dropdown options={retats} placeholder="Selectionner etat" value={this.state.retat} onChange={(e) => this.setState({ retat: e.target.value })} autoWidth={false} />
                                    </div>
                                    <div className="col-12 md:col-2" >
                                        <Button icon='pi pi-search' loading={this.state.showindicator} label='Rechercher' onClick={(e) => this.get_liste_emprunts()} />
                                    </div>
                                </div>
                            </div>
                            <DataTable value={this.state.liste_emprunts} selectionMode="single" style={{ minHeight: '450px' }} alwaysShowPaginator={false}
                                onRowClick={e => this.setState({ selectedEmprunt: e.data })} onRowSelect={e => this.setState({ selectedEmprunt: e.data })}
                                selection={this.state.selectedEmprunt} ref={(el) => { this.dt = el; }} exportFilename="liste des emprunts" csvSeparator=';' dataKey="ordre"
                                className="p-datatable-gridlines" showGridlines responsiveLayout="scroll" paginator rows={20}>
                                <Column header="Actions" body={this.actionTemplate.bind(this)} style={{ width: '7%' }} />
                                <Column field="dateEmprunt" header="Date" style={{ width: '9%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="montant" body={(rowData, column) => {
                                    return <div>
                                        <span style={{ color: 'green' }}>{this.gemsysservice.formaterValueSep(parseFloat(rowData.montant))}</span>
                                    </div>
                                }} header="Montant" style={{ width: '10%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="libEmprunt" header="Libellé" style={{ width: '14%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="matrPers.prenomPers" body={(rowData, column) => {
                                    return <div>
                                        <span> {rowData.matrPers.prenomPers + " " + rowData.matrPers.nomPers} </span>
                                    </div>
                                }} header="Personnel" style={{ width: '20%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="debutRegl" header="Début" style={{ width: '11%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="tranchRegl" body={(rowData, column) => {
                                    return <div>
                                        <span style={{ color: 'green' }}>{this.gemsysservice.formaterValueSep(parseFloat(rowData.tranchRegl))}</span>
                                    </div>
                                }} header="Tranche" style={{ width: '10%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="solde" header="Soldé" style={{ width: '9%' }} filter={true} filterMatchMode='contains' sortable={true} />
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}