import React, { Component } from 'react';
import  GestGemsysService  from '../service/GestGemsysService';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Panel } from 'primereact/panel';
import 'moment';
import 'moment/locale/fr';
import moment from 'moment';

export class FormAbsence extends Component {
    constructor() {
        super();
        this.state = {
            idAbs: '',
            dateDebutAbs: new Date(),
            dateFinAbs: '',
            motifAbs: '',
            typeAbs: "Autorisation d'absence",
            dateAbs: new Date(),
            denregAbs: new Date(),
            idInsc: null,
            idEtabl: '',
            iduser: '',

            matricule: "",
            codeClasse: "",

            rtype: 'Tous',
            rtypes: [
                { label: 'Type', value: 'Tous' },
                { label: "Autorisation d'absence", value: "Autorisation d'absence" },
                { label: 'Congé académique', value: 'Congé académique' },
                { label: "Maladie", value: "Maladie" },
                { label: 'Volontaire', value: 'Volontaire' },
            ],
            rsemestre: 'Tous',
            retudiant: 'Tous',
            retudiants: [
                { label: 'Eleve/Etudiant', value: 'Tous' },
            ],
            rpd1: new Date(),
            rpd2: new Date(),
            liste_absences: [],
            liste_classes: [],
            liste_eleves: [],
            leleves: [],
            rleleves: [],
            selectedAbsence: null,
            msg: '',
            modif: false,
            visible: false,
            showindicator: false,
            recherche: false
        };
        this.edit_absence = this.edit_absence.bind(this);
        this.conf_delete_absence = this.conf_delete_absence.bind(this);
        this.gemsysService = new GestGemsysService();

    }
    componentDidMount() {
        this.get_liste_absence();
        this.gemsysService.getClasse().then(data => this.setState({ liste_classes: data }));
    }

    get_liste_absence() {
        let rtype = "Tous";
        let rsemestre = "Tous";
        let retudiant = "Tous";
        let spd1 = moment(this.state.rpd1).format('DD-MM-YYYY');
        let spd2 = moment(this.state.rpd2).format('DD-MM-YYYY');
        if (this.state.rtype !== "" && this.state.rtype !== "Tous" && this.state.rtype !== undefined) {
            rtype = this.state.rtype;
        } 
        if (this.state.rsemestre !== "" && this.state.rsemestre !== null && this.state.rsemestre !== undefined && this.state.rsemestre !== "Tous") {
            rsemestre =this.state.rsemestre
        } 
        if (this.state.retudiant !== '' && this.state.retudiant !== null && this.state.retudiant !== undefined && this.state.retudiant !== "Tous") {
            retudiant=this.state.retudiant.matricule;
        } 
        this.setState({showindicator: true});
        this.gemsysService.get_liste_absences_by_criteria(spd1, spd2, rtype, rsemestre, retudiant).then(data => this.setState({ liste_absences: data,showindicator: false }));
    }
    resultat(code, contenu) {
        if (code == '200') {
            this.toast.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_user();
            this.get_liste_absence();
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({showindicator: false});
    }

    edit_absence(data) {
        if (data != null && data != undefined) {
            this.setState({ idAbs: data.idAbs });
            this.setState({ dateDebutAbs: new Date(moment(data.dateDebutAbs,'DD/MM/YYYY').format('YYYY-MM-DD')) });   
            this.setState({ dateFinAbs: new Date(moment(data.dateFinAbs,'DD/MM/YYYY').format('YYYY-MM-DD')) });   
            this.setState({ motifAbs: data.motifAbs });
            this.setState({ typeAbs: data.typeAbs });
            this.setState({ idInsc: data.idInsc });
            this.setState({ codeClasse: data.idInsc.codeClasse.codeClasse },()=>{
                this.get_liste_inscrit_by_classe(data.idInsc.codeClasse.codeClasse);
                this.setState({idInsc: data.idInsc.idInsc });
            });
            //this.get_liste_eleves_by_classe(data.idInsc.codeClasse.codeClasse);
            this.setState({ modif: true });
            this.setState({ selectedAbsence: data });
            window.scrollTo(0, 0);
        }
    }

    annule_user() {
        this.setState({ idAbs: '' });
        this.setState({ dateDebutAbs: '' });
        this.setState({ dateFinAbs: '' });
        this.setState({ motifAbs: '' });
        this.setState({ anAbs: '' });
        this.setState({ typeAbs: '' });
        this.setState({ dateAbs: '' });
        this.setState({ denregAbs: '' });
        this.setState({ idInsc: '' });
        this.setState({ idEtabl: '' });
        this.setState({ iduser: '' });
        this.setState({ matricule: null });
        this.setState({ codeClasse: null });
        this.setState({ modif: false });
        this.forceUpdate();
    }

    enreg_absence() {
        if (this.state.dateDebutAbs == "" || this.state.dateFinAbs == "" || this.state.motifAbs == "" || this.state.typeAbs == "" || this.state.idInsc == null || this.state.idInsc == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else {
            let dateDebut_Abs = moment(this.state.dateDebutAbs, 'DD/MM/YYYY').format('DD/MM/YYYY');
            let dateFin_Abs = moment(this.state.dateFinAbs, 'DD/MM/YYYY').format('DD/MM/YYYY');
            this.setState({showindicator: true});
            if (this.state.modif == false) {
                let absence =
                {
                    idAbs: '0',
                    dateDebutAbs: dateDebut_Abs,
                    dateFinAbs: dateFin_Abs,
                    motifAbs: this.state.motifAbs,
                    anAbs: this.gemsysService.get_rannee(),
                    typeAbs: this.state.typeAbs,
                    idInsc: { idInsc: this.state.idInsc }
                }
                this.gemsysService.AddAbsence(absence).then(reponse => this.resultat(reponse.code, reponse.contenu));
            } else {
                let absence = this.state.selectedAbsence;
                absence.dateDebutAbs = dateDebut_Abs;
                absence.dateFinAbs = dateFin_Abs;
                absence.motifAbs = this.state.motifAbs;
                absence.typeAbs = this.state.typeAbs;
                absence.idInsc = { idInsc: this.state.idInsc };
                this.gemsysService.UpdateAbsence(absence).then(data => this.resultat(data.code, data.contenu));
            }
        }
    }

    get_liste_inscrit_by_classe(codeClasse) {
        this.gemsysService.getliste_eleve_by_inscrit(codeClasse).then(data => this.setState({ liste_eleves: data }));
    }

    actionTemplate(rowData) {
        return <div className="grid" style={{ width: 90 }}>
            <div className="col"><Button onClick={() => this.edit_absence(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
            <div className="col"><Button onClick={() => this.conf_delete_absence(rowData)} className="p-button-danger" style={{ width: 25, height: 25,marginLeft: -20 }} icon="pi pi-times" /></div>
        </div>
    }

    delete_absence() {
        if (this.state.selectedAbsence == null || this.state.selectedAbsence == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.setState({showindicator: true});
            this.gemsysService.DeleteAbsence(this.state.selectedAbsence).then(data => this.resultat(data.code, data.contenu));
        }
    }

    conf_delete_absence(data) {
        if (data !== null && data !== undefined) {
            this.setState({ selectedAbsence: data });
            this.setState({ visible: true });
        }
    }

    render() {
        let semestres = [];
        if (this.state.liste_classes != null && this.state.liste_classes != undefined) {
            let lc = this.gemsysService.get_userconnected().idEtabl.typeEtabl == 'Université' ? 'S' : '';

            semestres = this.state.liste_classes.map(x => {
                return { value: x.codeClasse, label: lc+x.libClasse + " " + x.optClasse }
            });
        }
        let rsemestres = [{ value: 'Tous', label: 'Classe' }].concat(semestres);
        let types = [
            { label: "Autorisation d'absence", value: "Autorisation d'absence" },
            { label: 'Congé académique', value: 'Congé académique' },
            { label: "Maladie", value: "Maladie" },
            { label: 'Volontaire', value: 'Volontaire' }
        ];
        let leleves = null;
        let releves=[{ value: 'Tous', label: 'Elève/Etudiant' }];
        if (this.state.liste_eleves != null && this.state.liste_eleves != undefined) {

            leleves = this.state.liste_eleves.map(x => {
                return { value: x.idInsc, label: x.matricule.prenomEleve + " " + x.matricule.nomEleve,matricule: x.matricule.matricule }
            });
            let liste = this.state.liste_eleves.map(x => {
                return { value: x.matricule.matricule, label: x.matricule.prenomEleve + " " + x.matricule.nomEleve }
            });
            releves=releves.concat(liste);
        }
        const dialogFooter = (
            <div>
                <Button icon="pi pi-times" onClick={() => this.setState({ visible: false })} label="Annuler" className="p-button-secondary" />
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible: false });
                    this.delete_absence();
                }} label="Oui" loading={this.state.showindicator} className="p-button-danger"/>
            </div>
        );
        return (
            <div className="p-fluid">
                <Toast ref={(el) => this.toast = el} />
                <Dialog header="Confirmation" visible={this.state.visible} style={{ width: '260px' }} modal={true} footer={dialogFooter} onHide={() => this.setState({ visible: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer la ligne sélectionnée ?
                    </div>
                </Dialog>
                <div className="grid">
                    <div className="col-12">
                        <div className="card card-w-title" style={{ minHeight: '700px' }}>
                            <Panel>
                                <div className="grid " >
                                    <div className="col-12 md:col-3">
                                        <label htmlFor="dateDebutAbs">Date Début *</label>
                                    </div>
                                    <div className="col-12 md:col-3" >
                                        <Calendar id="dateDebutAbs" dateFormat="dd/mm/yy" value={this.state.dateDebutAbs} onChange={(e) => this.setState({ dateDebutAbs: e.target.value })} />
                                    </div>
                                    <div className="col-12 md:col-3">
                                        <label htmlFor="dateFinAbs">Date Fin *</label>
                                    </div>
                                    <div className="col-12 md:col-3" >
                                        <Calendar id="dateFinAbs" dateFormat="dd/mm/yy" value={this.state.dateFinAbs} onChange={(e) => this.setState({ dateFinAbs: e.target.value })} />
                                    </div>
                                    <div className="col-12 md:col-3">
                                        <label htmlFor="motifAbs">Motif *</label>
                                    </div>
                                    <div className="col-12 md:col-3" >
                                        <InputText id="motifAbs" value={this.state.motifAbs} onChange={(e) => this.setState({ motifAbs: e.target.value })} />
                                    </div>
                                    <div className="col-12 md:col-3">
                                        <label htmlFor="typeAbs">Type *</label>
                                    </div>
                                    <div className="col-12 md:col-3" >
                                        <Dropdown options={types} value={this.state.typeAbs} onChange={(e) => this.setState({ typeAbs: e.target.value })} autoWidth={false} />
                                    </div>
                                    <div className="col-12 md:col-3">
                                        <label htmlFor="codeClasse">Classe *</label>
                                    </div>
                                    <div className="col-12 md:col-3" >
                                        <Dropdown options={semestres} id='codeClasse' value={this.state.codeClasse} filter filterBy='label' filterMatchMode='contains'  optionLabel='label' fonChange={(e) => {
                                            this.setState({ codeClasse: e.target.value }, () => {
                                                this.get_liste_inscrit_by_classe(this.state.codeClasse);
                                            })
                                        }} autoWidth={false} />
                                    </div>
                                    <div className="col-12 md:col-3">
                                        <label htmlFor="matricule">Elève/Etudiant *</label>
                                    </div>
                                    <div className="col-12 md:col-3" >
                                        <Dropdown options={leleves} value={this.state.idInsc} onChange={(e) => {
                                            this.setState({ idInsc: e.target.value })
                                        }}  itemTemplate={(option) => {
                                            if (!option.value) {
                                                return option.label;
                                            }
                                            else {
                                                return (
                                                    <div className="grid" >
                                                        <div className="col-12 md:col-3">
                                                            <label>{option.matricule}</label>
                                                        </div>
                                                        <div className="col-12 md:col-9">
                                                            <label>{option.label}</label>
                                                        </div>
                                                    </div>
                                                );
                                            }
                                        }} autoWidth={false} optionLabel='label' filter={true} filterBy='label,matricule' filterMatchMode='contains' panelStyle={{ width: '400px' }} />
                                    </div>
                                </div>
                            </Panel>
                            <center>
                                <div className="grid" style={{ width: 300, marginTop: '0px' }}>
                                    <div className="col"><Button label="Annuler" icon="pi pi-ban" onClick={() => this.annule_user()} /></div>
                                    <div className="col"><Button label="Valider" loading={this.state.showindicator} icon="pi pi-check" onClick={() => this.enreg_absence()} /></div>
                                </div>
                            </center>
                            <i className="pi pi-search"></i>
                            <span style={{ fontSize: 14, marginLeft: 10, color: '#999999' }}>Options de recherche</span>
                            <div className="col-12">
                                <div className="grid">
                                    <div className="col-12 md:col-2">
                                        <Calendar id="rpd1" placeholder={moment(new Date()).format('DD/MM/YYYY')} dateFormat="dd/mm/yy" value={this.state.rpd1} onChange={(e) => this.setState({ rpd1: e.target.value })} />
                                    </div>
                                    <div className="col-12 md:col-2">
                                        <Calendar id="rpd2" placeholder={moment(new Date()).format('DD/MM/YYYY')} dateFormat="dd/mm/yy" value={this.state.rpd2} onChange={(e) => this.setState({ rpd2: e.target.value })} />
                                    </div>
                                    <div className="col-12 md:col-2" >
                                        <Dropdown value={this.state.rtype} id="rtype" options={this.state.rtypes} onChange={(e) => this.setState({ rtype: e.target.value })} autoWidth={false} />
                                    </div>
                                    <div className="col-12 md:col-2" >
                                        <Dropdown value={this.state.rsemestre} id="rsemestre" placeholder='selectionner une classe' optionLabel='label' filter={true} options={rsemestres} onChange={(e) => {
                                            this.setState({ rsemestre: e.target.value }, () => {
                                                this.get_liste_inscrit_by_classe(this.state.rsemestre);
                                            })
                                        }} autoWidth={false} />
                                    </div>
                                    <div className="col-12 md:col-2" >
                                        <Dropdown value={this.state.retudiant} placeholder='Elève/Etudiant' id="retudiant" optionLabel='label' filter={true} options={releves} onChange={(e) => this.setState({ retudiant: e.target.value })} autoWidth={false} />
                                    </div>
                                    <div className='col-12 md:col-2' >
                                        <Button icon='pi pi-search' label='Rechercher' loading={this.state.showindicator} onClick={() => this.get_liste_absence()} />
                                    </div>
                                </div>
                            </div>
                            <DataTable value={this.state.liste_absences} selectionMode="single"
                                alwaysShowPaginator={false} onRowClick={e => this.setState({ selectedAbsence: e.data })} onRowSelect={e => this.setState({ selectedAbsence: e.data })}
                                selection={this.state.selectedAbsence} dataKey="idAbs" className="p-datatable-gridlines" showGridlines responsiveLayout="scroll" paginator rows={50}>
                                <Column  body={this.actionTemplate.bind(this)} header="Actions" style={{ width: '7%' }} />
                                <Column field="idInsc.matricule.matricule" header="Matricule " style={{ width: '10%' }} filter={true} filterMatchMode='contains'   sortable={true}/>
                                <Column field="idInsc.matricule.prenomEleve" body={(rowData) => {
                                    return <div>
                                        <span> {rowData.idInsc.matricule!==undefined ? rowData.idInsc.matricule.prenomEleve + " " + rowData.idInsc.matricule.nomEleve: ''} </span>
                                    </div>
                                }} header="Prénoms & nom" style={{ width: '18%' }} filter={true} filterMatchMode='contains'   sortable={true}/>
                                <Column field="idInsc.codeClasse.libClasse" body={(rowData) => {
                                    return <div>
                                        <span> {rowData.idInsc.codeClasse!==undefined ? rowData.idInsc.codeClasse.libClasse + " " + rowData.idInsc.codeClasse.optClasse:''} </span>
                                    </div>
                                }} header="Classe" style={{ width: '20%' }} filter={true} filterMatchMode='contains'   sortable={true}/>
                                <Column field="dateDebutAbs" header="Début" style={{ width: '8%' }} filter={true} filterMatchMode='contains'   sortable={true}/>
                                <Column field="motifAbs" header="Motif" style={{ width: '15%' }} filter={true} filterMatchMode='contains'   sortable={true} />
                                <Column field="typeAbs" header="Type" style={{ width: '14%' }} filter={true} filterMatchMode='contains'   sortable={true}/>
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}