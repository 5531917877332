import React, { Component } from 'react';
import GestGemsysService from '../service/GestGemsysService';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import 'moment';
import 'moment/locale/fr';
import moment from 'moment';


export class FormDocument extends Component {
    constructor() {
        super();
        this.state = {
            pd1: new Date(),
            pd2: new Date(),
            rlibDoc: 'Tous',
            r_refDoc: '',
            ridSource: '',
            idDoc: '',
            libDoc: 'Attestation de niveau',
            datelDoc: new Date(),
            file: null,
            descDoc: '',
            refDoc: '',
            captureDoc: '',
            idsrcDoc: '',
            idEtabl: '',
            iduser: '',
            srcDoc: '',
            libelles: [
                { label: 'Attestation de niveau', value: 'Attestation de niveau' },
                { label: 'Attestation de réinscription', value: 'Attestation de réinscription' },
                { label: "Attestation d'inscription", value: "Attestation d'inscription" },
                { label: 'Certificat de scolarité', value: 'Certificat de scolarité' },
                { label: 'Diplome', value: 'Diplome' },
                { label: 'Etat paie', value: 'Etat paie' },
                { label: 'Bulletin de paie', value: 'Bulletin de paie' },
                { label: "Bulletin de notes", value: "Bulletin de notes" },
                { label: 'Relevés de notes', value: 'Relevés de notes' },
                { label: 'Note de service', value: 'Note de service' },
                { label: 'Fiche de notes', value: 'Fiche de notes' },
                { label: 'Résultats', value: 'Résultats' },
                { label: 'Ordre de stage', value: 'Ordre de stage' },
                { label: "Ordre de mission", value: "Ordre de mission" },
                { label: 'Bilan annuel', value: 'Bilan annuel' },
                { label: 'Fiche inscription', value: 'Fiche inscription' },
                { label: 'Extrait de naissance', value: 'Extrait de naissance' },
                { label: 'Permission abscence', value: 'Permission abscence' },
                { label: 'Rapport', value: 'Rapport' },
                { label: "Facture", value: "Facture" },
                { label: 'Autre', value: 'Autre' }
            ],
            liste_docs: [],
            selectedDoc: null,
            msg: '',
            modif: false,
            visible: false,
            showindicator: false
        };
        this.edit_doc = this.edit_doc.bind(this);
        this.conf_delete_doc = this.conf_delete_doc.bind(this);
        this.gestGemsysService = new GestGemsysService();
    }

    componentDidMount() {
        this.get_liste_docs();
    }

    resultat(code, contenu) {
        if (code == '200') {
            this.toast.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_doc();
            this.componentDidMount();
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({showindicator: false});
    }

    edit_doc(data) {
        if (data != null && data != undefined) {
            this.setState({ idDoc: data.idDoc });
            this.setState({ libDoc: data.libDoc });
            this.setState({ datelDoc: new Date(moment(data.datelDoc,'DD/MM/YYYY').format('YYYY-MM-DD')) });   
            this.setState({ descDoc: data.descDoc });
            this.setState({ refDoc: data.refDoc });
            this.setState({ captureDoc: data.captureDoc });
            this.setState({ idsrcDoc: data.idsrcDoc });
            this.setState({ srcDoc: data.srcDoc });
            this.setState({ modif: true });
            this.setState({ selectedDoc: data });
            window.scrollTo(0, 0);
        }
    }

    annule_doc() {
        this.setState({ idDoc: '' });
        this.setState({ libDoc: 'Attestation de niveau' });
        this.setState({ descDoc: '' });
        this.setState({ refDoc: '' });
        this.setState({ captureDoc: '' });
        this.setState({ idsrcDoc: '' });
        this.setState({ idEtabl: '' });
        this.setState({ iduser: '' });
        this.setState({ srcDoc: '' });
        this.setState({ file: null });
        this.setState({ modif: false });
        this.forceUpdate();
    }
    enreg_doc() {
        if (this.state.libDoc == "" || this.state.refDoc == "" || this.state.descDoc == "" || this.state.datelDoc == "" || this.state.idsrcDoc == "") {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else {
            let datel_Doc = moment(this.state.datelDoc, 'DD/MM/YYYY').format('DD/MM/YYYY');
            let upload = false;
            let nomfic = 'nofic.png';
            if (this.state.file != null && this.state.file != undefined && this.state.file != [] && this.state.file != '') {
                let name = this.state.file.name;
                upload = true;
                nomfic = this.state.libDoc + moment(new Date()).format('DDMMYYYYHHmmss') + '.' + name.slice((name.lastIndexOf(".") - 1 >>> 0) + 2);
            }
            let doc = {
                idDoc: '0',
                libDoc: this.state.libDoc,
                datelDoc: datel_Doc,
                descDoc: this.state.descDoc,
                refDoc: this.state.refDoc,
                captureDoc: nomfic,
                anneeDoc: this.gestGemsysService.get_rannee(),
                idsrcDoc: this.state.idsrcDoc,
                srcDoc: this.state.srcDoc,
            }
            if (this.state.modif == false) {
                this.gestGemsysService.AddDocument(doc).then(data => {
                    if (data != undefined && data != null) {
                        if (upload === true) {
                            this.fileUpload(this.state.file, nomfic);
                        }
                        this.resultat(data.code, data.contenu);
                    }
                });
            } else {
                this.gestGemsysService.UpdateDocument(doc).then(data => {
                    if (data != undefined && data != null) {
                        if (upload === true) {
                            this.fileUpload(this.state.file, nomfic);
                        }
                        this.resultat(data.code, data.contenu);
                    }
                });
            }
        }
    }

    fileUpload(file, namefile) {
        this.gestGemsysService.upload_file(file, namefile, 'fics').then(data => {
            if (data.code == '200') {
                this.toast.show({ severity: 'success', summary: 'Success', detail: 'Chargement effectué avec succès' });
            } else {
                this.toast.show({ severity: 'error', summary: 'Error', detail: 'Echec upload' });
            }
        });
    }

    get_liste_docs() {
        let spd1 = moment(this.state.pd1).format('DD/MM/YYYY');
        let spd2 = moment(this.state.pd2).format('DD/MM/YYYY');
        let rlibDoc = "Tous";
        let r_refDoc = "Tous";
        let ridSource = "Tous";
        if (this.state.rlibDoc !== "" && this.state.rlibDoc !== "Tous" && this.state.rlibDoc !== undefined && this.state.rlibDoc !== null) {
            rlibDoc = this.state.rlibDoc;
        } 
        if (this.state.r_refDoc !== "" && this.state.r_refDoc !== "Tous" && this.state.r_refDoc !== undefined && this.state.r_refDoc !== null) {
            r_refDoc = this.state.r_refDoc;
        } 
        if (this.state.ridSource !== "" && this.state.ridSource !== "Tous" && this.state.ridSource !== undefined && this.state.ridSource !== null) {
            ridSource = this.state.ridSource;
        } 
        this.setState({showindicator: true});
        this.gestGemsysService.get_liste_docs_by_criteria(spd1, spd2, rlibDoc, r_refDoc, ridSource).then(data => this.setState({liste_docs: data,showindicator: false }));
    }


    actionTemplate(rowData) {
        return <div className="grid">
            <div className="col"><Button onClick={() => this.edit_doc(rowData)} className="p-button-info" style={{ width: 30, height: 30 }} icon="pi pi-pencil" /></div>
            <div className="col"><Button onClick={() => this.conf_delete_doc(rowData)} className="p-button-danger" style={{ width: 30, height: 30,marginLeft: -20 }} icon="pi pi-times" /></div>
            <div className="col"><Button  className="p-button-warning" style={{ width: 30, height: 30,marginLeft: -20 }} icon="pi pi-download" /></div>
        </div>
    }

    ficTemplate(rowData) {
        this.gestGemsysService.download_fichier_joint(rowData.captureDoc, 'fics');
    }

    delete_doc() {
        if (this.state.selectedDoc == null || this.state.selectedDoc == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.setState({showindicator: true});
            this.gestGemsysService.DeleteDocument(this.state.selectedDoc).then(data => this.resultat(data.code, data.contenu));
        }
    }


    conf_delete_doc(data) {
        if (data !== null && data !== undefined) {
            this.setState({ selectedDoc: data });
            this.setState({ msg: data.idDoc });
            this.setState({ visible: true });
        }
    }

    render() {
        let rtypes = [
            { label: 'Tous les types', value: 'Tous' },
            { label: 'Attestation de niveau', value: 'Attestation de niveau' },
            { label: 'Attestation de réinscription', value: 'Attestation de réinscription' },
            { label: "Attestation d'inscription", value: "Attestation d'inscription" },
            { label: 'Certificat de scolarité', value: 'Certificat de scolarité' },
            { label: 'Diplome', value: 'Diplome' },
            { label: 'Etat paie', value: 'Etat paie' },
            { label: 'Bulletin de paie', value: 'Bulletin de paie' },
            { label: "Bulletin de notes", value: "Bulletin de notes" },
            { label: 'Relevés de notes', value: 'Relevés de notes' },
            { label: 'Note de service', value: 'Note de service' },
            { label: 'Fiche de notes', value: 'Fiche de notes' },
            { label: 'Résultats', value: 'Résultats' },
            { label: 'Ordre de stage', value: 'Ordre de stage' },
            { label: "Ordre de mission", value: "Ordre de mission" },
            { label: 'Bilan annuel', value: 'Bilan annuel' },
            { label: 'Fiche inscription', value: 'Fiche inscription' },
            { label: 'Extrait de naissance', value: 'Extrait de naissance' },
            { label: 'Permission abscence', value: 'Permission abscence' },
            { label: 'Rapport', value: 'Rapport' },
            { label: "Facture", value: "Facture" },
            { label: 'Autre', value: 'Autre' }
        ];
        const dialogFooter = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible: false });
                    this.delete_doc();
                }} label="Oui" loading={this.state.showindicator} />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );

        return (
            <div className="p-fluid">
                <Toast ref={(el) => this.toast = el} />
                <Dialog header="Confirmation" visible={this.state.visible} style={{ width: '250px' }} modal={true} footer={dialogFooter} onHide={() => this.setState({ visible: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>
                <div className="grid">
                    <div className="col-12">
                        <div className="card card-w-title">
                            <div className="grid " >
                                <div className="col-12 md:col-3">
                                    <label htmlFor="nomProd">Libellé *</label>
                                </div>
                                <div className="col-12 md:col-3" >
                                    <Dropdown options={this.state.libelles} value={this.state.libDoc} onChange={(e) => this.setState({ libDoc: e.target.value })} autoWidth={false} />
                                </div>
                                <div className="col-12 md:col-3">
                                    <label htmlFor="refDoc">N° Référence *</label>
                                </div>
                                <div className="col-12 md:col-3" >
                                    <InputText id="refDoc" value={this.state.refDoc} onChange={(e) => this.setState({ refDoc: e.target.value })} />
                                </div>
                                <div className="col-12 md:col-3">
                                    <label htmlFor="descDoc">Description *</label>
                                </div>
                                <div className="col-12 md:col-3" >
                                    <InputText id="descDoc" value={this.state.descDoc} onChange={(e) => this.setState({ descDoc: e.target.value })} />
                                </div>
                                <div className="col-12 md:col-3">
                                    <label htmlFor="datelDoc">Date *</label>
                                </div>
                                <div className="col-12 md:col-3" >
                                    <Calendar id="datelDoc" dateFormat="dd/mm/yy" value={this.state.datelDoc} onChange={(e) => this.setState({ datelDoc: e.target.value })} />                                    </div>

                                <div className="col-12 md:col-3">
                                    <label htmlFor="idsrcDoc">ID Srce *</label>
                                </div>
                                <div className="col-12 md:col-3" >
                                    <InputText id="idsrcDoc" value={this.state.idsrcDoc} onChange={(e) => this.setState({ idsrcDoc: e.target.value })} />
                                </div>
                                <div className="col-12 md:col-3">
                                    <label htmlFor="captureDoc">Capture document *</label>
                                </div>
                                <div className="col-12 md:col-3">
                                    <input type="file" onChange={(e) => this.setState({ file: e.target.files[0] })} />
                                </div>
                            </div>
                            <center>
                                <div className="grid" style={{ width: 300, marginTop: '0px' }}>
                                    <div className="col"><Button label="Annuler" icon="pi pi-ban" onClick={() => this.annule_doc()} /></div>
                                    <div className="col"><Button label="Valider" loading={this.state.showindicator} icon="pi pi-check" onClick={() => this.enreg_doc()} /></div>
                                </div>
                            </center>
                            <i className="pi pi-search"></i>
                            <span style={{ fontSize: 14, marginLeft: 10, color: '#999999' }}>Options de recherche</span>
                            <div className="col-12">
                                <div className="grid">
                                    <div className="col-12 md:col-2">
                                        <Calendar id="pd1"  dateFormat="dd/mm/yy" value={this.state.pd1} onChange={(e) => this.setState({ pd1: e.target.value })} />
                                    </div>
                                    <div className="col-12 md:col-2">
                                        <Calendar id="pd2"  dateFormat="dd/mm/yy" value={this.state.pd2} onChange={(e) => this.setState({ pd2: e.target.value })} />
                                    </div>
                                    <div className="col-12 md:col-2" >
                                        <Dropdown options={rtypes} value={this.state.rlibDoc} filter={true} onChange={(e) => this.setState({ rlibDoc: e.target.value })} autoWidth={false} />
                                    </div>
                                    <div className="col-12 md:col-2" >
                                        <InputText id="r_refDoc" value={this.state.r_refDoc} placeholder='N Référence' onChange={(e) => this.setState({ r_refDoc: e.target.value })} />
                                    </div>
                                    <div className="col-12 md:col-2" >
                                        <InputText id="ridSource" value={this.state.ridSource} placeholder='ID Source' onChange={(e) => this.setState({ ridSource: e.target.value })} />
                                    </div>
                                    <div className='col-12 md:col-2' >
                                        <Button icon='pi pi-search' loading={this.state.showindicator} label='Rechercher' onClick={() => this.get_liste_docs()} />
                                    </div>
                                </div>
                            </div>
                            <DataTable value={this.state.liste_docs} style={{ minHeight: '400px' }} selectionMode="single"
                                alwaysShowPaginator={false} onRowClick={e => this.setState({ selectedDoc: e.data })} onRowSelect={e => this.setState({ selectedDoc: e.data })}
                                selection={this.state.selectedDoc} dataKey="idDoc" className="p-datatable-gridlines" showGridlines responsiveLayout="scroll" paginator rows={20}>
                                <Column header="Actions" body={this.actionTemplate.bind(this)} style={{ width: '12%' }} />
                                <Column field="refDoc" header="N° Ref" style={{ width: '14%' }} filter={true} filterMatchMode='contains'   sortable={true}/>
                                <Column field="libDoc" header="Libellé " style={{ width: '18%' }} filter={true} filterMatchMode='contains'   sortable={true}/>
                                <Column field="datelDoc" header="Date" style={{ width: '8%' }} filter={true} filterMatchMode='contains'   sortable={true}/>
                                <Column field="idsrcDoc" header="ID Srce" style={{ width: '18%' }} filter={true} filterMatchMode='contains'   sortable={true}/>
                                <Column field="descDoc" header="Description" style={{ width: '30%' }} filter={true} filterMatchMode='contains'   sortable={true}/>
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}