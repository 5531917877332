import React, { Component } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import './styles.css';
import { SplitButton } from 'primereact/splitbutton';
import GestGemsysService  from '../service/GestGemsysService';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Row } from 'primereact/row';
import { ColumnGroup } from 'primereact/columngroup';
import { Toast } from 'primereact/toast';

export class StatNote extends Component {
    constructor() {
        super();
        this.state = {
            rcode_enseign: 'Tous',
            rcode_classe: 'Tous',
            liste_data: [],
            liste_classe: [],
            nb: 0,
            nb_admis: 0,
            nb_session: 0,
            nb_reprise: 0,
            liste_enseignement: [{ label: 'Enseignement', value: 'Tous' }],
            showindicator: false,
            items: [
                {
                    label: 'Format PDF',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.conf_print('PDF');
                    }
                },
                {
                    label: 'Format XLS',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.conf_print('XLS');
                    }
                },
                {
                    label: 'Format CSV',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.dt.exportCSV();
                    }
                }
            ],
        };
        this.conf_print = this.conf_print.bind(this);
        this.gemsysService = new GestGemsysService();
    }

    get_liste_enseignement() {
        this.setState({ showindicator: true });
        this.gemsysService.getEnseignement().then(data => {
            this.setState({ showindicator: false });
            if (data !== null && data !== undefined && data !== '') {
                if (data.length > 0) {
                    let liste = data.map(x => {
                        return { value: x.codeEnseign, label: x.libEnseign }
                    });
                    this.setState({ liste_enseignement: this.state.liste_enseignement.concat(liste) });
                }
            }
        });
    }

    get_liste_data() {
        
        let rcode_enseign = 'Tous';
        let rcode_classe = 'Tous';
        if (this.state.rcode_enseign !== undefined && this.state.rcode_enseign !== null && this.state.rcode_enseign !== 'Tous') {
            rcode_enseign = this.state.rcode_enseign;
        }
        if (this.state.rcode_classe !== undefined && this.state.rcode_classe !== null && this.state.rcode_classe !== 'Tous') {
            rcode_classe = this.state.rcode_classe;
        }
        if (rcode_enseign== "Tous" && rcode_classe== "Tous") {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner au moins une option de filtre' });
            return;
        } 
        this.setState({
            showindicator: true,
            nb: 0,
            nb_admis: 0,
            nb_session: 0,
            nb_reprise: 0,
        });
        this.gemsysService.get_stat_evaluation(rcode_enseign,rcode_classe).then(data => {
            this.setState({ liste_data: data, showindicator: false });
            if (data !== null && data !== undefined) {
                if (data.length > 0) {
                    let initialValue = 0;
                    let nb = data.reduce(function (accumulator, currentValue) {
                        return accumulator + currentValue.effectif
                    }, initialValue);
                    let nb_admis = data.reduce(function (accumulator, currentValue) {
                        return accumulator + currentValue.nb_admis
                    }, initialValue);
                    let nb_session = data.reduce(function (accumulator, currentValue) {
                        return accumulator + currentValue.nb_session
                    }, initialValue);
                    let nb_reprise = data.reduce(function (accumulator, currentValue) {
                        return accumulator + currentValue.nb_reprise
                    }, initialValue);
                   
                    this.setState({
                        nb: nb,
                        nb_admis: nb_admis,
                        nb_session: nb_session,
                        nb_reprise: nb_reprise,
                    });
                }
            }
        });
    }

    conf_print(format) {
        let rcode_enseign = 'Tous';
        let rcode_classe = 'Tous';
        if (this.state.rcode_enseign !== undefined && this.state.rcode_enseign !== null && this.state.rcode_enseign !== 'Tous') {
            rcode_enseign = this.state.rcode_enseign;
        }
        if (this.state.rcode_classe !== undefined && this.state.rcode_classe !== null && this.state.rcode_classe !== 'Tous') {
            rcode_classe = this.state.rcode_classe;
        }
        if (rcode_enseign=== "Tous" && rcode_classe=== "Tous") {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner au moins une option de filtre' });
            return;
        } 
        this.gemsysService.generer_stat_evaluation(rcode_enseign,rcode_classe, format);
    }
 
    componentDidMount() {
        this.get_liste_enseignement();
    }


    get_liste_classe(codeEnseign) {
        this.gemsysService.getClasse().then(data => {
            if (data !== null && data !== undefined) {
                let liste = data.map(x => {
                    let lc=this.gemsysService.get_userconnected().idEtabl.typeEtabl == 'Université'?'S':'';
                    return { value: x.codeClasse, label: lc+x.libClasse + " " + x.optClasse, codeEnseign: x.codeEnseign.codeEnseign}
                }).filter(function (el) {
                    return el.codeEnseign == codeEnseign;
                });
                this.setState({ liste_classe: this.state.liste_classe.concat(liste) });
            }
        });
    }

    render() {
        let footerGroup = <ColumnGroup>
            <Row>
                <Column footer="TOTAUX" colSpan={3} footerStyle={{ textAlign: 'center', fontWeight: 'bold' }} />
                <Column footer={this.state.nb} style={{ color: '#000000', fontWeight: 'bold' }} />
                <Column footer={this.state.nb_admis} style={{ color: '#000000', fontWeight: 'bold' }} />
                <Column footer={this.state.nb_session} style={{ color: '#000000', fontWeight: 'bold' }} />
                <Column footer={this.state.nb_reprise} style={{ color: '#000000', fontWeight: 'bold' }} />
            </Row>
        </ColumnGroup>;
 
        return (
            <div className="p-fluid">
                <Toast ref={(el) => this.toast = el} />
                <div className="grid">
                    <div className="col-12">
                        <div className="card card-w-title">
                            <i className="pi pi-search"></i>
                            <span style={{ fontSize: 14, marginLeft: 10, color: '#999999' }}>Options de recherche</span>
                            <div className="grid">
                                <div className="col-12 md:col-4">
                                    <Dropdown options={this.state.liste_enseignement} placeholder="Sélectionner" value={this.state.rcode_enseign} onChange={(e) => this.setState({ rcode_enseign: e.value },()=>{
                                        this.get_liste_classe(this.state.rcode_enseign);
                                    })} autoWidth={false} filter={true} filterBy='label' filterMatchMode='contains' />
                                </div>
                                <div className="col-12 md:col-4">
                                    <Dropdown options={[{value: 'Tous',label: 'Classe',codeEnseign: ''}].concat(this.state.liste_classe)} placeholder="Classe" value={this.state.rcode_classe} onChange={(e) => this.setState({ rcode_classe: e.value },()=>{
                                        this.get_liste_data();
                                    })} autoWidth={false} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' panelStyle={{ width: '400px' }} />
                                </div>
                                <div className="col-12 md:col-2">
                                    <SplitButton label="Rechercher" style={{ width: '170px' }} onClick={(e) => this.get_liste_data()} icon="pi pi-search" model={this.state.items} />
                                </div>
                                <div className="col-12 md:col-1">
                                    {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                </div>
                            </div>
                            <DataTable value={this.state.liste_data} style={{ minHeight: '700px' }} ref={(el) => { this.dt = el; }} alwaysShowPaginator={false}
                                 footerColumnGroup={footerGroup} dataKey="ordre" className="p-datatable-gridlines" showGridlines responsiveLayout="scroll" paginator rows={20}>
                                <Column field="ordre" header="#" style={{ width: '4%' }} />
                                <Column field="libmat" header="Matière"  sortable/>
                                <Column field="classe" header="Classe" style={{ width: '8%' }} sortable />
                                <Column field="effectif" header="TT" style={{ width: '8%' }} sortable />
                                <Column field="nb_admis" header="Admis" style={{ width: '8%' }} sortable />
                                <Column field="nb_session" header="Sessions" style={{ width: '8%' }} sortable />
                                <Column field="nb_reprise" header="Reprises" style={{ width: '8%' }} sortable />
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}