import React, { Component } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import './styles.css';
import { Calendar } from 'primereact/calendar';
import { SplitButton } from 'primereact/splitbutton';
import GestGemsysService  from '../service/GestGemsysService';
import { ProgressSpinner } from 'primereact/progressspinner';
import moment from 'moment';
 
export class EditionAbsence extends Component {
    constructor() {
        super();
        this.state = {
            pd1: new Date(),
            pd2: new Date(),
            rid_insc: 'Tous',
            liste_data: [],
            listeSelecteds: [],
            rcode_classe: 'Tous',
            rtype: 'Tous',
            liste_classes: [{ label: 'Classe', value: 'Tous' }],
            liste_inscrit: [{ label: 'Elève/Etudiant', value: 'Tous',matricule: '' }],
            showindicator: false,
            items: [
                {
                    label: 'Editer liste',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.conf_print_liste('PDF');
                    }
                },
                {
                    label: 'Editer titre',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.conf_print_titre('PDF');
                    }
                }
            ],
        };
        this.conf_print_liste = this.conf_print_liste.bind(this);
        this.conf_print_titre = this.conf_print_titre.bind(this);
        this.gemsysService = new GestGemsysService();
    }

    get_liste_classe() {
        this.gemsysService.getClasse().then(data => {
            if (data !== null && data !== undefined) {
                let liste = data.map(x => {
                    return { value: x.codeClasse, label: x.libClasse + " " + x.optClasse, codeEnseign: x.codeEnseign.codeEnseign }
                });
                this.setState({ liste_classes: this.state.liste_classes.concat(liste) });
            }
        });
    }

    get_liste_data() {
        let spd1 = moment(this.state.pd1).format('DD/MM/YYYY');
        let spd2 = moment(this.state.pd2).format('DD/MM/YYYY');
        this.setState({
            showindicator: true,
        });
        this.gemsysService.get_liste_absence(spd1, spd2, this.state.rcode_classe, this.state.rid_insc, this.state.rtype).then(data => {
            this.setState({ liste_data: data, showindicator: false });
        });
    }

    get_liste_inscrit_by_classe(codeClasse) {
        this.setState({
            showindicator: true,
        });
        this.gemsysService.getliste_eleve_by_inscrit(codeClasse).then(data => {
            this.setState({ showindicator: false });
            if (data != null && data != undefined) {
                let liste = data.map(x => {
                    return { value: x.idInsc, label: x.matricule.prenomEleve + " " + x.matricule.nomEleve, matricule: x.matricule.matricule }
                });
                this.setState({ liste_inscrit: this.state.liste_inscrit.concat(liste) });
            }
        });
    }

    conf_print_liste(format) {
        let spd1 = moment(this.state.pd1).format('DD/MM/YYYY');
        let spd2 = moment(this.state.pd2).format('DD/MM/YYYY');
        this.gemsysService.generer_liste_absence(spd1, spd2, this.state.rcode_classe, this.state.rid_insc, this.state.rtype, format);
    }

    conf_print_titre(format) {
        let liste = "";
        if (this.state.listeSelecteds.length > 0) {
            liste = this.state.listeSelecteds.map(x => {
                return x.idAbs
            }).join("_");
        } else {
            liste = this.state.liste_mois.map(x => {
                return x.idAbs
            }).join("_");
        };
        this.gemsysService.generer_titre_absence(liste, format);
    }

    componentDidMount() {
        this.get_liste_classe();
        this.get_liste_data();
    }


    render() {
        let liste_types = [
            { label: 'Type', value: 'Tous' },
            { label: "Autorisation d'absence", value: "Autorisation d'absence" },
            { label: 'Congé académique', value: 'Congé académique' },
            { label: "Maladie", value: "Maladie" },
            { label: 'Volontaire', value: 'Volontaire' }
        ];

        return (
            <div className="p-fluid">
                <div className="grid">
                    <div className="col-12">
                        <div className="card card-w-title">
                            <i className="pi pi-search"></i>
                            <span style={{ fontSize: 14, marginLeft: 10, color: '#999999' }}>Options de recherche</span>
                            <div className="grid">
                                <div className="col-12 md:col-2" >
                                    <Calendar id="pd1" placeholder="Date début" dateFormat="dd/mm/yy" value={this.state.pd1} onChange={(e) => this.setState({ pd1: e.target.value })} />
                                </div>
                                <div className="col-12 md:col-2" >
                                    <Calendar id="pd2" placeholder="Date fin" value={this.state.pd2} onChange={e => this.setState({ pd2: e.target.value })} />
                                </div>
                                <div className="col-12 md:col-2" >
                                    <Dropdown inputId="dropdown" options={this.state.liste_classes} placeholder="Classe" value={this.state.rcode_classe} onChange={(e) => this.setState({ rcode_classe: e.target.value }, () => {
                                        this.get_liste_inscrit_by_classe(this.state.rcode_classe);
                                    })} filter={true} filterBy='label' filterMatchMode='contains' panelStyle={{ width: '400px' }} />
                                </div>
                                <div className="col-12 md:col-2">
                                    <Dropdown options={this.state.liste_inscrit} value={this.state.rid_insc} onChange={(e) => this.setState({ rid_insc: e.value })} autoWidth={false} optionLabel='label' filter={true} filterBy='label,matricule' filterMatchMode='contains'
                                        itemTemplate={(option) => {
                                            if (!option.value) {
                                                return option.label;
                                            }
                                            else {
                                                return (
                                                    <div className="grid" >
                                                        <div className="col-12 md:col-3">
                                                            <label>{option.matricule}</label>
                                                        </div>
                                                        <div className="col-12 md:col-9">
                                                            <label>{option.label}</label>
                                                        </div>
                                                    </div>
                                                );
                                            }
                                        }} panelStyle={{ width: '400px' }} />
                                </div>
                                <div className="col-12 md:col-2" >
                                    <Dropdown options={liste_types} value={this.state.rtype} onChange={(e) => this.setState({ rtype: e.target.value })} autoWidth={false} />
                                </div>
                                <div className="col-12 md:col-2" >
                                    <>
                                        {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : 
                                        <SplitButton  label="Rechercher" style={{ width: '140px' }} onClick={(e) => this.get_liste_data()} icon="pi pi-search" model={this.state.items} />}
                                    </>
                                </div>

                            </div>
                            <DataTable value={this.state.liste_data} style={{ minHeight: '700px' }}  ref={(el) => { this.dt = el; }}
                                onSelectionChange={e => this.setState({ listeSelecteds: e.data })} selection={this.state.listeSelecteds}
                                dataKey="ordre" paginator rows={10} className="p-datatable-gridlines" showGridlines responsiveLayout="scroll">
                                <Column selectionMode="multiple" style={{ width: '5%' }} />
                                <Column field="ordre" header="#" style={{ width: '5%' }} />
                                <Column field="matrEtud" header="Matricule" style={{ width: '10%' }} filter={true} filterMatchMode='contains'   sortable={true} />
                                <Column field="nomEtud" header="Nom" style={{ width: '10%' }} filter={true} filterMatchMode='contains'   sortable={true} />
                                <Column field="prenomEtu" header="Prénom" style={{ width: '15%' }} filter={true} filterMatchMode='contains'   sortable={true} />
                                <Column field="classe" header="Classe" style={{ width: '15%' }} filter={true} filterMatchMode='contains'   sortable={true} />
                                <Column field="dateDebutAbsFormat" header="Début" style={{ width: '8%' }} filter={true} filterMatchMode='contains'   sortable={true} />
                                <Column field="dateFinAbsFormat" header="Fin" style={{ width: '8%' }} filter={true} filterMatchMode='contains'   sortable={true}  />
                                <Column field="motifAbs" header="Motif" style={{ width: '14%' }} filter={true} filterMatchMode='contains'   sortable={true} />
                                <Column field="typeAbs" header="Type" style={{ width: '10%' }} filter={true} filterMatchMode='contains'   sortable={true} />
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}