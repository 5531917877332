import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import GestGemsysService from '../service/GestGemsysService';
import { InputText } from 'primereact/inputtext';
import moment from 'moment';
import { Panel } from 'primereact/panel';

export class FormPromo extends Component {
    constructor() {
        super();
        this.state = {
            idPromo: '',
            libPromo: '',
            anneePromo: new Date().getFullYear(),
            denregPromo: new Date(),
            idEtabl: 1,
            iduser: '1',
            selectedPromo: null,
            promotions: null,
            msg: '',
            modif: false,
            visible: false,
            showindicator: false

        };
        this.edit_promotion = this.edit_promotion.bind(this);
        this.conf_delete_promotion = this.conf_delete_promotion.bind(this);
        this.enreg_promotion = this.enreg_promotion.bind(this);
        this.conf_delete_promotion = this.conf_delete_promotion.bind(this);
        this.gemsysService = new GestGemsysService();
    }
    componentDidMount() {
        this.gemsysService.getPromotion().then(data => this.setState({ promotions: data }));
    }
    resultat(code, contenu) {
        if (code == '200') {
            this.toast.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annuler_promotion();
            this.componentDidMount();
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({showindicator: false});
    }

    edit_promotion(data) {
        if (data != null && data != undefined) {
            this.setState({ idPromo: data.idPromo });
            this.setState({ libPromo: data.libPromo });
            this.setState({ anneePromo: data.anneePromo });
            this.setState({ denregPromo: data.denregPromo });
            this.setState({ selectedPromo: data });
            this.setState({ modif: true });
            window.scrollTo(0, 0);
        }
    }

    annuler_promotion() {
        this.setState({ idPromo: '' });
        this.setState({ libPromo: '' });
        this.setState({ anneePromo: 0 });
        this.setState({ denregPromo: '' });
        this.setState({ modif: false });
        this.forceUpdate();
    }

    enreg_promotion() {
        if (this.state.libPromo === "") {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else {
            this.setState({showindicator: true});
            if (this.state.modif === false) {
                let promotion = {
                    idPromo: '0',
                    libPromo: this.state.libPromo,
                    anneePromo: this.gemsysService.get_rannee()
                }
                this.gemsysService.AddPromotion(promotion).then(data => this.resultat(data.code, data.contenu));
            } else {
                let promotion = this.state.selectedPromo;
                promotion.libPromo = this.state.libPromo;
                this.gemsysService.UpdatePromotion(promotion).then(data => this.resultat(data.code, data.contenu));
            }
        }
    }
    actionTemplate(rowData) {
        return <div className="grid" style={{ width: 90 }}>
            <div className="col"><Button onClick={() => this.edit_promotion(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
            <div className="col"><Button onClick={() => this.conf_delete_promotion(rowData)} className="p-button-danger" style={{ width: 25, height: 25, marginLeft: -20 }} icon="pi pi-times" /></div>
        </div>
    }
    delete_promotion() {
        if (this.state.selectedPromo === null || this.state.selectedPromo === undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.setState({showindicator: true});
            this.gemsysService.DeletePromotion(this.state.selectedPromo).then(data => this.resultat(data.code, data.contenu));
        }
    }
    conf_delete_promotion(data) {
        if (data != null && data != undefined) {
            this.setState({ selectedPromo: data });
            this.setState({ msg: "La Promotion n° " + data.idPromo });
            this.setState({ visible: true });
        }
    }
    render() {
        const dialogFooter = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible: false });
                    this.delete_promotion();
                }} label="Oui" loading={this.state.showindicator}  />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );
        return (
            <div className="p-fluid">
                <Toast ref={(el) => this.toast = el} />
                <Dialog header="Confirmation" visible={this.state.visible} style={{ width: '250px' }} modal={true} footer={dialogFooter} onHide={() => this.setState({ visible: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>
                <div className="grid">
                    <div className="col-12">
                        <div className="card card-w-title">
                            <Panel>
                                <div className="grid">
                                    <div className="col-12 md:col-6">
                                        <label htmlFor="libPromo">Libellé *</label>
                                    </div>
                                    <div className="col-12 md:col-6">
                                        <InputText id="libPromo" value={this.state.libPromo} onChange={(e) => this.setState({ libPromo: e.target.value })} />
                                    </div>
                                </div>
                            </Panel>
                            <center>
                                <div className="grid" style={{ width: 300, marginTop: '0%' }}>
                                    <div className="col"><Button label="Annuler" icon="pi pi-ban" onClick={() => this.annuler_promotion()} /></div>
                                    <div className="col"><Button label="Valider" loading={this.state.showindicator} icon="pi pi-check" onClick={() => this.enreg_promotion()} /></div>
                                </div>
                            </center>
                            <DataTable value={this.state.promotions} paginatorPosition="top" selectionMode="single" paginator={true} rows={10}
                                alwaysShowPaginator={false} onRowClick={e => this.setState({ selectedPromo: e.data })}
                                onRowSelect={e => this.setState({ selectedPromo: e.data })} style={{ minHeight: '650px' }}
                                responsive={true} selection={this.state.selectedPromo} 
                                dataKey="idPromo" className="p-datatable-gridlines" showGridlines responsiveLayout="scroll" >
                                <Column header="Actions" body={this.actionTemplate.bind(this)} style={{ width: '17%' }} />
                                <Column field="libPromo" header="Libellé" style={{ width: '60%' }} sortable={true} filter={true} filterMatchMode='contains' />
                                <Column field="anneePromo" header="Année" style={{ width: '23%' }} sortable={true} filter={true} filterMatchMode='contains' />
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}