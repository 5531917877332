import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { classNames } from 'primereact/utils';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import GestGemsysService from '../service/GestGemsysService';
import { Dropdown } from 'primereact/dropdown';
import { SplitButton } from 'primereact/splitbutton';
import { InputTextarea } from 'primereact/inputtextarea';

/* Page de gestion des types de dépense */
export class FormTypeDepense extends Component {
    constructor() {
        super();
        this.state = {
            selectedData: null,
            liste_data: [],
            formData: {
                id: 0,
                libelleCodif: "",
                paramCodif: "TYPE_DEPENSE",
                codeCodif: "",
                descriptionCodif: ""
            },
            liste_item_export: [
                {
                    label: 'Exporter en CVS',
                    icon: 'pi pi-download',
                    command: (e) => {
                        this.exportCSV();
                    }
                },
                {
                    label: 'Exporter en XLS',
                    icon: 'pi pi-file-excel',
                    command: (e) => {
                        this.exportExcel();
                    }
                },
                {
                    label: 'Exporter en PDF',
                    icon: 'pi pi-file-pdf',
                    command: (e) => {
                        this.exportPdf();
                    }
                },
            ],
            showindicator: false,
            modif_data: false,
            submit: false,
            visible_dlg_delete: false,
            visible_dlg_form: false,
            visible_dlg_form_com: false,
            visible_dlg_detail: false,
        };
        this.edit_data = this.edit_data.bind(this);
        this.conf_delete_data = this.conf_delete_data.bind(this);
        this.toogleDlg = this.toogleDlg.bind(this);

        this.appservice = new GestGemsysService();
    }


    /* Natif react js (s'exécute au montage ou chargement de la page )*/
    componentDidMount() {
        this.get_liste_data();
    }

    /* Récupération de la liste globale des types de dépense */
    get_liste_data() {
        this.setState({ showindicator: true });
        this.appservice.get_liste_codification('TYPE_DEPENSE').then(data => {
            this.setState({ liste_data: data, showindicator: false });
        });
    }

    /* Annuler l'opération en cours et vider le formulaire */
    annuler() {
        this.setState({
            formData: {
                id: 0,
                libelleCodif: "",
                paramCodif: "TYPE_DEPENSE",
                codeCodif: "",
                descriptionCodif: ""
            }

        });

        this.setState({ showindicator: false });
        this.setState({ modif_data: false });
        this.setState({ visible_dlg_delete: false });
        this.setState({ visible_dlg_form: false });
        this.setState({ visible_dlg_form_com: false });
    }

    /* Remplissage du formulaire avec les informations de la ligne sélectionnée dans le cadre de la modification  */
    edit_data(data) {
        if (data !== null && data !== undefined) {
            this.setState({ formData: data });
            this.setState({ modif_data: true });
            this.setState({ visible_dlg_form: true });
            this.setState({ selectedData: data });
        }
    }

    /* Enregistrer les informations du formulaire offre */
    enreg_data() {
        this.setState({ submit: true });
        if (this.state.formData.libelleCodif == "") {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez renseigner tous les champs obligatoires' });
        } else {
            let form = this.state.formData;
            this.setState({ showindicator: true });
            this.appservice.save_codification(form).then(reponse => {
                if (reponse != undefined && reponse != null) {
                    this.resultat(reponse);
                    if (reponse.code == 200) {
                        this.get_liste_data();
                    }
                }
            });
        }
    }

    /* Affichage de la boite de dialogue de confirmation de suppression */
    conf_delete_data(data) {
        this.setState({ visible_dlg_delete: true });
        this.setState({ selectedData: data });
    }

    /* Affichage de la boite de dialogue pour l'ajout d'une nouvelle adresse */
    toogleDlg() {
        this.annuler();
        this.setState({ visible_dlg_form: !this.state.visible_dlg_form });
    }

    /* Zone gauche de la toolbar */
    leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Ajouter" icon="pi pi-plus" className="p-button-success mr-2" onClick={this.toogleDlg} style={{ width: 115 }} />
                    <Button label="Supprimer" disabled={this.state.selectedData == null} icon="pi pi-trash" className="p-button-danger" onClick={(e) => this.conf_delete_data(this.state.selectedData)} style={{ width: 115 }} />
                </div>
            </React.Fragment>
        )

    }

    /* Zone droite de la toolbar */
    rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> :
                    <SplitButton label="Exporter" icon="pi pi-download" className="p-button-help" onClick={this.exportExcel} model={this.state.liste_item_export} />
                }
            </React.Fragment>
        )
    }

    /* Exportation du tableau contenant la liste des quartiers en fichier CSV */
    exportCSV = () => {
        this.dt.exportCSV("Liste_type_depense");
    }


    /* Fonction d'affichage des messages retournés suite aux actions serveurs */
    resultat(rep) {
        if (rep.code == 200) {
            this.toast.show({ severity: 'success', summary: 'Success', detail: rep.contenu });
            this.annuler();
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: rep.contenu });
        }
        this.setState({ submit: false });
    }

    /* Colonne Action du tableau contenant la liste des quartiers */
    actionTemplate(rowData, column) {
        const items = [
            {
                label: 'Modifier',
                icon: 'pi pi-pencil',
                command: (e) => {
                    this.edit_data(rowData);
                }
            },
            {
                label: 'Supprimer',
                icon: 'pi pi-times',
                command: (e) => {
                    this.conf_delete_data(rowData);
                }
            },

        ]
        return <SplitButton label="Modifier" icon="pi pi-pencil" onClick={(e) => this.edit_data(rowData)} model={items}></SplitButton>
    }

    /* Fonction de suppression du quartier sélectionner */
    delete_data() {
        if (this.state.selectedData == null || this.state.selectedData == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.appservice.delete_codification(this.state.selectedData).then(reponse => {
                this.resultat(reponse);
                if (reponse.code == 200) {
                    this.get_liste_data();
                }
            });
        }
    }

    /* Exportation du tableau  en fichier PDF */
    exportPdf = () => {
        const cols = [
            { field: 'LIBELLE', header: 'LIBELLE' },
            { field: 'CODE', header: 'CODE' },
            { field: 'DESCRIPTION', header: 'DESCRIPTION' }
        ];
        let liste_data = this.state.liste_data.length > 0 ? this.state.liste_data.map(x => {
            return { 'LIBELLE': x.libelleCodif, 'CODE': x.codeCodif, 'DESCRIPTION': x.descriptionCodif }
        }) : [];
        const exportColumns = cols.map(col => ({ title: col.header, dataKey: col.field }));
        import('jspdf').then(jsPDF => {
            import('jspdf-autotable').then(() => {
                var doc = new jsPDF.default(0, 0);
                //doc.setDrawColor(255, 0, 0);
                //doc.rect(60, 20, 10, 10);
                //var img = new Image()
                //img.src = 'assets/layout/images/apollo_logo.png'
                //doc.addImage(img, 'png', 10, 5, 50, 20)
                //doc.table(10,20,liste_data,exportColumns);

                doc.autoTable(exportColumns, liste_data);
                doc.save('TYPE_DEPENSE.pdf');
            })
        })
    }

    /* Exportation du tableau  en fichier XLSX */
    exportExcel = () => {
        let liste_data = this.state.liste_data.length > 0 ? this.state.liste_data.map(x => {
            return { 'LIBELLE': x.libelleCodif, 'CODE': x.codeCodif, 'DESCRIPTION': x.descriptionCodif }
        }) : [];
        import('xlsx').then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(liste_data);
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            this.saveAsExcelFile(excelBuffer, 'TYPE_DEPENSE');
        });
    }

    /* Enregistrer le fichier sur le client */
    saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then(module => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    }




    /* Bloc visuel (propre à React) */
    render() {
        return (
            <div className="p-grid crud-demo">
                <div className="p-col-12">
                    <div className="card" style={{ height: 770 }}>

                        {/* Toast pour l'afficahge des messages de dialogue */}
                        <Toast ref={(el) => this.toast = el} />

                        {/* Toolbar contenant les boutons d'ajout, de suppression et d'exportation */}
                        <Toolbar className="p-mb-4" left={this.leftToolbarTemplate} right={this.rightToolbarTemplate}></Toolbar>
                        <br />

                        {/* Tableau contenant la liste des quartiers existants dans la base */}
                        <DataTable ref={(el) => { this.dt = el; }} value={this.state.liste_data} resizableColumns="scroll"
                            rows={10} paginator={true} className="p-datatable-customers" showGridlines
                            selection={this.state.selectedData} onRowSelect={e => this.setState({ selectedData: e.data })}
                            emptyMessage="No data found" selectionMode="single"
                            onRowClick={e => this.setState({ selectedData: e.data }, () => {
                            })}>
                            {/* <Column field="id" header="ID" filter={true} filterMatchMode='contains' /> */}
                            <Column field="Index" header="No" body={(rowData, props) => {
                                return <div>
                                    <span >{props.rowIndex + 1}</span>
                                </div>
                            }} style={{ width: '5%' }} />
                            <Column body={this.actionTemplate.bind(this)} header="Action" />
                            <Column field="libelleCodif" header="Libelle" filter={true} filterMatchMode='contains' />
                            <Column field="descriptionCodif" header="Description" filter={true} filterMatchMode='contains' />
                            <Column field="codeCodif" header="Code" filter={true} filterMatchMode='contains' />
                        </DataTable>

                        {/* Boite de dialogue d'ajout et de modification d'un quartier  */}
                        <Dialog visible={this.state.visible_dlg_form} style={{ width: '450px' }} header={this.state.modif_data == true ? "Modification" : "Ajout"} modal className="p-fluid" onHide={(e) => this.setState({ visible_dlg_form: false })}
                            footer={<>
                                <Button label="Fermer" icon="pi pi-times" className="p-button-secondary" style={{ width: 120 }} onClick={(e) => this.annuler()} />
                                <Button label="Valider" loading={this.state.showindicator} icon="pi pi-check" onClick={(e) => this.enreg_data()} style={{ width: 120 }} />
                            </>}>
                            <div className="p-fluid">

                                <div className="field">
                                    <label htmlFor="name1">Libelle *</label>
                                    <InputText id="name1" value={this.state.formData.libelleCodif} className={classNames({ 'p-invalid': this.state.submit && !this.state.formData.libelleCodif })}
                                        onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'libelleCodif', '', this.state.formData) })}
                                        required autoFocus />
                                    {this.state.submit && !this.state.formData.libelleCodif && <small className="p-invalid" style={{ color: 'red' }}>Le libelle est obligatoire.</small>}
                                </div>
                                <div className="field">
                                    <label htmlFor="name1">Code </label>
                                    <InputText id="name1" value={this.state.formData.codeCodif} onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'codeCodif', '', this.state.formData) })} />
                                </div>
                                <div className="field">
                                    <label htmlFor="email1">Description  </label>
                                    <InputTextarea id="name" value={this.state.formData.descriptionCodif} rows={2} onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'descriptionCodif', '', this.state.formData) })} />
                                </div>
                            </div>
                        </Dialog>

                        {/* Boite de dialogue de confirmation de suppression */}
                        <Dialog visible={this.state.visible_dlg_delete} style={{ width: '450px' }} header="Confirmation" modal
                            footer={
                                <>
                                    <Button label="Non" icon="pi pi-times" className="p-button-secondary" onClick={() => {
                                        this.setState({ visible_dlg_delete: false });
                                    }} />
                                    <Button label="Oui" icon="pi pi-check" className="p-button-danger" onClick={() => {
                                        this.setState({ visible_dlg_delete: false });
                                        this.delete_data();
                                    }} />
                                </>
                            } onHide={(e) => this.setState({ visible_dlg_delete: false })}>
                            <div className="flex align-items-center justify-content-center">
                                <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                                {this.state.selectedData && <span><label style={{ color: 'red', fontWeight: 'bold' }}>La suppression d'un type de dépense utilisé entraine celle des dépenses concernées, </label>voulez-vous vraiment supprimer <b>{this.state.selectedData.libelle}</b>?</span>}
                            </div>
                        </Dialog>

                    </div>
                </div>
            </div >
        );
    }
}
