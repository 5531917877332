import React, { Component } from 'react';
import GestGemsysService from '../service/GestGemsysService';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import 'moment';
import 'moment/locale/fr';
import moment from 'moment';
import { InputNumber } from 'primereact/inputnumber';
export class FormEmargement extends Component {
    constructor() {
        super();
        this.state = {
            idEmarg: '',
            dateEmarg: new Date(),
            duree: '',
            horaire: '',
            cours: '',
            idEns: null,
            idEtabl: '',
            iduser: '',
            matrPers: null,
            codemat: null,
            rpd1: new Date(),
            rpd2: new Date(),
            rmatrPers: 'Tous',
            rcodeClasse: 'Tous',
            rcodemat: 'Tous',
            enseignants: [],
            enseigners: [],
            liste_classes: [],
            liste_matieres: [],
            liste_enseigner: [],
            matiere: [],
            lmatieres: [],
            liste_emargements: [],
            selectedEmargement: null,
            totalH: 0,
            totalRestant: 0,
            msg: '',
            modif: false,
            recherche: false,
            visible: false,
            showindicator: false
        };
        this.edit_emargement = this.edit_emargement.bind(this);
        this.conf_delete_emargement = this.conf_delete_emargement.bind(this);
        this.gemsysService = new GestGemsysService();
    }

    componentDidMount() {
        this.get_liste_emargements();
        this.gemsysService.getPersonnel().then(data => this.setState({ enseignants: data }));
        this.gemsysService.getClasse().then(data => this.setState({ liste_classes: data }));
    }

    get_liste_emargements() {
        let rmatrPers = "Tous";
        let rcodeClasse = "Tous";
        let rcodemat = "Tous"
        let spd1 = moment(this.state.rpd1).format('DD/MM/YYYY');
        let spd2 = moment(this.state.rpd2).format('DD/MM/YYYY');

        if (this.state.rmatrPers !== "" && this.state.rmatrPers !== "Tous") {
            rmatrPers = this.state.rmatrPers;
        };
        if (this.state.rcodeClasse !== "" && this.state.rcodeClasse !== "Tous") {
            rcodeClasse = this.state.rcodeClasse;
        }
        if (this.state.rcodemat !== "" && this.state.rcodemat !== "Tous") {
            rcodemat = this.state.rcodemat;
        }
        this.setState({ showindicator: true });
        this.gemsysService.get_liste_emargements_by_criteria(spd1, spd2, rmatrPers, rcodeClasse, rcodemat).then(data => this.setState({ liste_emargements: data }, () => {
            let initialValue = 0;
            this.setState({ showindicator: false });
            let tt = data.reduce(function (accumulator, currentValue) {
                return accumulator + currentValue.duree
            }, initialValue);
            this.setState({ totalH: tt });
        }));
    }

    resultat(code, contenu) {
        if (code == '200') {
            this.toast.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_emargement();
            this.componentDidMount();
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({ showindicator: false });
    }

    edit_emargement(data) {
        if (data != null && data != undefined) {
            this.setState({ idEmarg: data.idEmarg });
            //this.get_liste_matieres_by_personnel(data.idEns.matrPers.matrPers);
            this.setState({ dateEmarg: new Date(moment(data.dateEmarg, 'DD/MM/YYYY').format('YYYY-MM-DD')) });
            this.setState({ idEns: data.idEns.idEns });
            this.setState({ codemat: data.idEns.codemat.codemat });
            this.setState({ codeClasse: data.idEns.codemat.codeClasse.codeClasse });
            this.setState({ duree: data.duree });
            this.setState({ horaire: data.horaire });
            this.setState({ cours: data.cours });
            this.setState({ modif: true });
            this.setState({ selectedEmargement: data });
            window.scrollTo(0, 0);
        }
    }

    annule_emargement() {
        this.setState({ idEmarg: "" });
        this.setState({ codeClasse: null });
        this.setState({ codemat: null });
        this.setState({ duree: "" });
        this.setState({ horaire: "" });
        this.setState({ cours: "" });
        this.setState({ idEns: null });
        this.setState({ modif: false });
        this.forceUpdate();
    }


    enreg_emargement() {
        if (this.state.dateEmarg == "" || this.state.duree == "" || this.state.horaire == null || this.state.idEns == null || this.state.idEns == undefined || this.state.cours == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else {
            if (this.state.modif == false) {

                let emargement = {
                    idEmarg: '0',
                    dateEmarg: moment(this.state.dateEmarg, 'DD/MM/YYYY').format('DD/MM/YYYY'),
                    duree: this.state.duree,
                    horaire: this.state.horaire,
                    cours: this.state.cours,
                    idEns: { idEns: this.state.idEns }
                };

                this.setState({ showindicator: true });
                this.gemsysService.get_nbh_restant(this.state.codemat).then(reponse => {
                    this.setState({ showindicator: false });
                    let val = parseFloat(reponse);
                    if (this.state.duree > val) {
                        this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Vous ne pouvez plus emarger plus de ' + val + 'H pour cette matière ' });
                    } else {
                        this.setState({ showindicator: true });
                        this.gemsysService.AddEmargement(emargement).then(reponse => {
                            if (reponse != undefined && reponse != null) {
                                this.resultat(reponse.code, reponse.contenu);
                            }
                        });
                    }
                });
            } else {
                let old=this.state.selectedEmargement.duree;
                let emargement = this.state.selectedEmargement;
                emargement.duree = this.state.duree;
                emargement.dateEmarg = moment(this.state.dateEmarg, 'DD/MM/YYYY').format('DD/MM/YYYY');
                emargement.horaire = this.state.horaire;
                emargement.cours = this.state.cours;
                this.setState({ showindicator: true });
                this.gemsysService.get_nbh_restant(this.state.codemat).then(reponse => {
                    this.setState({ showindicator: false });
                    let val = parseFloat(reponse);
                    let newduree=this.state.duree-old;
                    if (newduree > val) {
                        this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Vous ne pouvez plus emarger plus de ' + val + 'H pour cette matière ' });
                    } else {
                        this.setState({ showindicator: true });
                        this.gemsysService.UpdateEmargement(emargement).then(reponse => {
                            if (reponse != undefined && reponse != null) {
                                this.resultat(reponse.code, reponse.contenu);
                            }
                        });
                    }
                });



            }
        }
    }


    get_liste_matiere_by_classe(codeClasse) {
        this.gemsysService.getMatiere_by_classe(codeClasse).then(data => this.setState({ liste_matieres: data }, () => {
            if (this.state.liste_matieres != null && this.state.liste_matieres != undefined) {
                let lcours = this.state.liste_matieres.map(x => {
                    return { codemat: x.codemat, label: x.libmat }
                });
                if (this.state.recherche == true) {
                    lcours = [{ codemat: 'Tous', label: "Matière" }].concat(lcours);
                    this.setState({ lmatieres: lcours });
                } else this.setState({ lmatieres: lcours });
            }
        }));
    }
    get_liste_matieres_by_personnel(matrPers) {
        this.gemsysService.getEnseigner_by_prof(matrPers).then(data => this.setState({ liste_enseigner: data }, () => {
            if (this.state.liste_enseigner != null && this.state.liste_enseigner != undefined) {
                let lcours = this.state.liste_enseigner.map(x => {
                    return { value: x.idEns, label: x.codemat.libmat, classe: x.codemat.codeClasse.libClasse + ' ' + x.codemat.codeClasse.optClasse, codemat: x.codemat.codemat }
                });
                this.setState({ liste_enseigner: lcours });
            }
        }));
    }
    actionTemplate(rowData) {
        return <div className="grid" style={{ width: 90 }}>
            <div className="col"><Button onClick={() => this.edit_emargement(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
            <div className="col"><Button onClick={() => this.conf_delete_emargement(rowData)} className="p-button-danger" style={{ width: 25, height: 25, marginLeft: -20 }} icon="pi pi-times" /></div>
        </div>
    }
    delete_emargement() {
        if (this.state.selectedEmargement == null || this.state.selectedEmargement == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.setState({ showindicator: true });
            this.gemsysService.DeleteEmargement(this.state.selectedEmargement).then(data => this.resultat(data.code, data.contenu));
        }
    }
    conf_delete_emargement(data) {
        if (data !== null && data !== undefined) {
            this.setState({ selectedEmargement: data });
            this.setState({ msg: "L'emargement N°: " + data.idEmarg });
            this.setState({ visible: true });
        }
    }
    get_id_enseigner(matrPers, codemat) {
        this.gemsysService.getEnseigner_by_matrPersAndCodemat(matrPers, codemat).then(data => this.setState({ enseigners: data }, () => {
            if (this.state.enseigners != null && this.state.enseigners != undefined) {
                let lcours = this.state.enseigners.map(x => {
                    return { idEns: x.idEns, label: x.prixHoraireEns }
                });
                this.setState({ idEns: lcours[0] });
            }
        }));
    }

    export() {
        this.dt.exportCSV();
    }
    header_tab() {
        return <div>
            <Button style={{ width: 120 }} label="Exporter" className="p-button-help" icon="pi pi-external-link" onClick={this.export} />
        </div>
    }

    header_total() {
        return <div>
            <label style={{ fontSize: 18, color: 'red', fontWeight: 'bold' }} >{this.state.totalH} H</label>
        </div>
    }

    render() {
        let rclasses = [];
        let semestres = [];
        if (this.state.liste_classes != null && this.state.liste_classes != undefined) {
            semestres = this.state.liste_classes.map(x => {
                return { value: x.codeClasse, label: x.libClasse + " " + x.optClasse }
            });
        }
        rclasses = [{ codeClasse: 'Tous', label: 'Classe' }].concat(semestres);
        let renseignants = [];
        let enseignants = [];
        let lmatieres = [];
        if (this.state.enseignants != null && this.state.enseignants != undefined) {
            renseignants = this.state.enseignants.map(x => {
                return { value: x.matrPers, label: x.nomPers + " " + x.prenomPers, categoriePers: x.categorie, actif: x.estEnFonction }
            });
        }
        if (this.state.liste_matieres != null && this.state.liste_matieres != undefined) {
            lmatieres = this.state.liste_matieres.map(x => {
                return { value: x.codemat, label: x.libmat }
            });
        }

        enseignants = renseignants;
        renseignants = [{ matrPers: 'Tous', label: 'Professeur', categoriePers: '' }].concat(renseignants);
        lmatieres = [{ codemat: 'Tous', label: "Matière" }].concat(lmatieres);

        const dialogFooter = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible: false });
                    this.delete_emargement();
                }} label="Oui" loading={this.state.showindicator} />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );
        return (
            <div className="p-fluid">
                <Toast ref={(el) => this.toast = el} />
                <Dialog header="Confirmation" visible={this.state.visible} style={{ width: '250px' }} modal={true} footer={dialogFooter} onHide={() => this.setState({ visible: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>
                <div className="grid">
                    <div className="col-12">
                        <div className="card card-w-title" style={{ minHeight: '700px' }}>
                            <div className="grid " >
                                <div className="col-12 md:col-3">
                                    <label htmlFor="dateEmarg">Date *</label>
                                </div>
                                <div className="col-12 md:col-3" >
                                    <Calendar id="dateEmarg" dateFormat="dd/mm/yy" value={this.state.dateEmarg} onChange={(e) => this.setState({ dateEmarg: e.target.value })} />
                                </div>
                                <div className="col-12 md:col-3">
                                    <label htmlFor="duree">Durée *</label>
                                </div>
                                <div className="col-12 md:col-3" >
                                    <InputNumber id="duree" value={this.state.duree} onChange={(e) => this.setState({ duree: e.value })} />
                                </div>
                                <div className="col-12 md:col-3">
                                    <label htmlFor="horaire">Horaire *</label>
                                </div>
                                <div className="col-12 md:col-3" >
                                    <InputText id="horaire" value={this.state.horaire} onChange={(e) => this.setState({ horaire: e.target.value })} />
                                </div>
                                <div className="col-12 md:col-3">
                                    <label htmlFor="cours">Cours *</label>
                                </div>
                                <div className="col-12 md:col-3" >
                                    <InputText id="cours" value={this.state.cours} onChange={(e) => this.setState({ cours: e.target.value })} />
                                </div>
                                <div className="col-12 md:col-3">
                                    <label htmlFor="matrPers">Professeur *</label>
                                </div>
                                <div className="col-12 md:col-3" >
                                    <Dropdown options={enseignants} value={this.state.matrPers} filter filterMode='contains' optionLabel='label' onChange={(e) => {
                                        this.setState({ matrPers: e.value }, () => {
                                            this.get_liste_matieres_by_personnel(e.value);
                                        })
                                    }} itemTemplate={(option) => {
                                        if (!option.value) {
                                            return option.label;
                                        } else {
                                            return (
                                                <div className="grid" >
                                                    <div className="col-12 md:col-4">
                                                        <label>{option.value}</label>
                                                    </div>
                                                    <div className="col-12 md:col-6">
                                                        <label>{option.label}</label>
                                                    </div>
                                                    <div className="col-12 md:col-2">
                                                        <label style={{ fontWeight: 'bold' }}>{option.actif}</label>
                                                    </div>
                                                </div>
                                            );
                                        }
                                    }} autoWidth={false} panelStyle={{ width: '500px' }} />
                                </div>
                                <div className="col-12 md:col-3">
                                    <label htmlFor="codemat">Matière *</label>
                                </div>
                                <div className="col-12 md:col-3" >
                                    <Dropdown options={this.state.liste_enseigner} optionLabel='label' filter filterMode='contains' value={this.state.idEns} onChange={(e) => {
                                        this.setState({
                                            idEns: e.value,
                                            codemat: this.gemsysService.getSelectedData(this.state.liste_enseigner, e.value).codemat
                                        })
                                    }} itemTemplate={(option) => {
                                        if (!option.value) {
                                            return option.label;
                                        }
                                        else {
                                            return (
                                                <div className="grid" >
                                                    <div className="col-12 md:col-8">
                                                        <label>{option.label}</label>
                                                    </div>
                                                    <div className="col-12 md:col-4">
                                                        <label>{option.classe}</label>
                                                    </div>
                                                </div>
                                            );
                                        }
                                    }} autoWidth={false} panelStyle={{ width: '750px' }} />
                                </div>
                            </div>
                            <center>
                                <div className="grid" style={{ width: 300, marginTop: '1px' }}>
                                    <div className="col"><Button label="Annuler" icon="pi pi-ban" onClick={() => this.annule_emargement()} /></div>
                                    <div className="col"><Button loading={this.state.showindicator} label="Valider" icon="pi pi-check" onClick={() => this.enreg_emargement()} /></div>
                                </div>
                            </center>
                            <div className="grid">
                                <i className="pi pi-search"></i>
                                <span style={{ fontSize: 14, marginLeft: 10, color: '#999999' }}>Options de recherche</span>
                                <div className="col-12">
                                    <div className="grid">
                                        <div className="col-12 md:col-2">
                                            <Calendar id="rpd1" placeholder={moment(new Date()).format('DD/MM/YYYY')} dateFormat="dd/mm/yy" value={this.state.rpd1} onChange={(e) => this.setState({ rpd1: e.target.value })} />
                                        </div>
                                        <div className="col-12 md:col-2">
                                            <Calendar id="rpd2" placeholder={moment(new Date()).format('DD/MM/YYYY')} dateFormat="dd/mm/yy" value={this.state.rpd2} onChange={(e) => this.setState({ rpd2: e.target.value })} />
                                        </div>
                                        <div className="col-12 md:col-2" >
                                            <Dropdown value={this.state.rmatrPers} id="rmatrPers" filter={true} placeholder='Professeur' optionLabel='label' options={renseignants} onChange={(e) => this.setState({ rmatrPers: e.target.value }, () => {
                                            })} itemTemplate={(option) => {
                                                if (!option.value) {
                                                    return option.label;
                                                }
                                                else {
                                                    return (
                                                        <div className="grid" >
                                                            <div className="col-12 md:col-4">
                                                                <label>{option.value}</label>
                                                            </div>
                                                            <div className="col-12 md:col-6">
                                                                <label>{option.label}</label>
                                                            </div>
                                                            <div className="col-12 md:col-2">
                                                                <label style={{ fontWeight: 'bold' }}>{option.actif}</label>
                                                            </div>
                                                        </div>
                                                    );
                                                }
                                            }} autoWidth={false} panelStyle={{ width: '500px' }} />
                                        </div>
                                        <div className="col-12 md:col-2" >
                                            <Dropdown value={this.state.rcodeClasse} id="rcodeClasse" optionLabel='label' placeholder='Classe' filter={true} options={rclasses} onChange={(e) => {
                                                this.setState({ rcodeClasse: e.value }, () => {
                                                    this.setState({ recherche: true });
                                                    this.get_liste_matiere_by_classe(e.value);
                                                })
                                            }} autoWidth={false} />
                                        </div>
                                        <div className="col-12 md:col-2" >
                                            <Dropdown value={this.state.rcodemat} id="rcodemat" optionLabel='label' placeholder='Matière' filter={true} options={lmatieres} onChange={(e) => {
                                                this.setState({ rcodemat: e.value });
                                            }} autoWidth={false} />

                                        </div>
                                        <div className='col-12 md:col-2' >
                                            <Button icon='pi pi-search' loading={this.state.showindicator} label='Rechercher' onClick={() => this.get_liste_emargements()} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <DataTable value={this.state.liste_emargements} selectionMode="single" style={{ minHeight: '500px' }}
                                paginator={true} rows={50} alwaysShowPaginator={true} paginatorPosition="top" paginatorLeft={this.header_tab()} paginatorRight={this.header_total()}
                                onRowClick={e => this.setState({ selectedEmargement: e.data })} onRowSelect={e => this.setState({ selectedEmargement: e.data })}
                                responsive={true} selection={this.state.selectedEmargement} dataKey="idEmarg" className="p-datatable-gridlines" showGridlines responsiveLayout="scroll">
                                <Column header="#" body={this.actionTemplate.bind(this)} style={{ width: '7%' }} />
                                <Column field="idEns.matrPers.matrPers" body={(rowData) => {
                                    return <div>
                                        <span> {rowData.idEns.matrPers.matrPers} </span><br />
                                    </div>
                                }} header="Matricule" style={{ width: '10%' }} filter={true} filterMatchMode='contains' />
                                <Column field="idEns.matrPers.prenomPers" body={(rowData) => {
                                    return <div>
                                        <span> {rowData.idEns.matrPers.prenomPers + " " + rowData.idEns.matrPers.nomPers} </span><br />
                                    </div>
                                }} header="Professeur" style={{ width: '14%' }} filter={true} filterMatchMode='contains' />
                                <Column field="dateEmarg" header="Date" style={{ width: '8%' }} filter={true} filterMatchMode='contains' />
                                <Column field="horaire" body={(rowData) => {
                                    return <div>
                                        <span> {rowData.horaire} </span>
                                    </div>
                                }} header="Horaire" style={{ width: '8%' }} />
                                <Column field="horaire" body={(rowData) => {
                                    return <div>
                                        <span> {rowData.duree} H </span>
                                    </div>
                                }} header="Durée" style={{ width: '7%' }} />
                                <Column field="idEns.codemat.libmat" header="Matière" style={{ width: '16%' }} filter={true} filterMatchMode='contains' />
                                <Column field="idEns.codemat.codeClasse.libClasse" body={(rowData) => {
                                    return <div>
                                        <span> {rowData.idEns.codemat.codeClasse.libClasse} {rowData.idEns.codemat.codeClasse.opClasse}</span>
                                    </div>
                                }} header="Classe" style={{ width: '15%' }} filter={true} filterMatchMode='contains' />
                                <Column field="cours" header="Cours" style={{ width: '15%' }} filter={true} filterMatchMode='contains' />
                            </DataTable>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}