import React from 'react';
import { Link } from 'react-router-dom';
import AppSubmenu from './AppSubmenu';
import GestGemsysService from './service/GestGemsysService';

const AppMenu = (props) => {

    const appservice = new GestGemsysService();
    var userconnected = appservice.get_userconnected();
    var nom_user = '';
    if (userconnected !== null && userconnected !== undefined) {
        nom_user = userconnected.compte;
    }

    return (
        <div className="layout-sidebar" onClick={props.onMenuClick}>
            <Link to="/dashboard/0" className="logo">
                <img id="app-logo" className="logo-image" src="assets/layout/images/logo-white.png" alt="diamond layout" />
                <span className="app-name" style={{ fontWeight: 'bold' }}>GEMSYS</span>
                <br />
                <center>
                    <div className="card" style={{ marginTop: 10, backgroundColor: '#EAEDED', width: 140, border: '2px solid #e97811' }}>
                        <img src={appservice.download_file('logo.png', 'etats/')} alt='Logo' style={{ width: 133, height: 80, marginBottom: -21, marginTop: -16, marginLeft: -16 }} />
                    </div>
                </center>
            </Link>

            <div className="layout-menu-container">
                <AppSubmenu items={props.model} menuMode={props.menuMode} parentMenuItemActive menuActive={props.active} mobileMenuActive={props.mobileMenuActive} root onMenuitemClick={props.onMenuitemClick} onRootMenuitemClick={props.onRootMenuitemClick} />
            </div>
        </div>
    );
}

export default AppMenu;
