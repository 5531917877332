import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import GestGemsysService from '../service/GestGemsysService';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { TabView, TabPanel } from 'primereact/tabview';
import moment from 'moment';
import './styles.css';
import { SplitButton } from 'primereact/splitbutton';

export class FormPlanning extends Component {
    constructor() {
        super();
        this.state = {
            idProg: '',
            libProg: '',
            actProg: 'Oui',
            debProg: new Date(),
            finProg: new Date(),
            seletedProgeval: null,
            liste_progeval: [],

            liste_prog_cible_quest: [],
            liste_prog_cible_pers: [],
            selectedListe_prog_cible_quest: [],
            selectedListe_prog_cible_pers: [],

            liste_quest: [],
            liste_cible: [],
            selectedListe_quest: [],
            selectedListe_cible: [],
            showindicator: false,
            msg: '',
            modif: false,
            visible: false,
            visible_dlg_cible: false,
            visible_dlg_quest: false,
        };
        this.edit_progeval = this.edit_progeval.bind(this);
        this.conf_delete_progeval = this.conf_delete_progeval.bind(this);

        this.conf_delete_quest = this.conf_delete_quest.bind(this);
        this.conf_delete_cible = this.conf_delete_cible.bind(this);
        this.show_dlg_cible = this.show_dlg_cible.bind(this);
        this.show_dlg_quest = this.show_dlg_quest.bind(this);

        this.gemsysService = new GestGemsysService();

    }

    conf_delete_quest() {
        if (this.state.selectedListe_prog_cible_quest <= 0) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner les lignes à supprimer' });
        } else {
            this.setState({showindicator: true});
            this.gemsysService.DeleteProgrammerEval(this.state.selectedListe_prog_cible_quest).then(data => this.resultatQuest(data.code, data.contenu));
        }
    }

    conf_delete_cible() {
        if (this.state.selectedListe_prog_cible_pers <= 0) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner les lignes à supprimer' });
        } else {
            this.setState({showindicator: true});
            this.gemsysService.DeleteProgCible(this.state.selectedListe_prog_cible_pers).then(data => this.resultatCible(data.code, data.contenu));
        }
    }

    show_dlg_cible() {
        if (this.state.seletedProgeval == null || this.state.seletedProgeval == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner le programme' });
        } else {
            this.setState({ visible_dlg_cible: true });
            this.gemsysService.get_liste_pers_not_prog(this.state.seletedProgeval.idProg).then(data => this.setState({ liste_cible: data }));
        }
    }

    show_dlg_quest() {
        if (this.state.seletedProgeval == null || this.state.seletedProgeval == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner le programme' });
        } else {
            this.setState({ visible_dlg_quest: true });
            this.gemsysService.get_liste_quest_not_prog(this.state.seletedProgeval.idProg).then(data => this.setState({ liste_cible: data }));
        }
    }

    componentDidMount() {
        this.get_liste_progeval();
    }

    get_liste_progeval() {
        this.gemsysService.getProgEval().then(data => this.setState({ liste_progeval: data }));
    }

    get_liste_prog_cible(data) {
        this.gemsysService.getProgCible(data.idProg).then(data => this.setState({ liste_prog_cible_pers: data }));
    }

    get_liste_prog_quest(data) {
        this.gemsysService.getProgrammerEval(data.idProg).then(data => this.setState({ liste_prog_cible_quest: data }));
    }

    resultat(code, contenu) {
        if (code == '200') {
            this.toast.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_progeval();
            this.componentDidMount();
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({showindicator: false});
    }

    resultatQuest(code, contenu) {
        if (code == '200') {
            this.toast.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.get_liste_prog_quest(this.state.seletedProgeval);
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({showindicator: false});
    }

    resultatCible(code, contenu) {
        if (code == '200') {
            this.toast.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.get_liste_prog_cible(this.state.seletedProgeval);
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({showindicator: false});
    }

    edit_progeval(data) {
        if (data != null && data != undefined) {
            this.setState({ debProg: new Date(moment(data.debProg, 'DD/MM/YYYY').format('YYYY-MM-DD')) });
            this.setState({ finProg: new Date(moment(data.finProg, 'DD/MM/YYYY').format('YYYY-MM-DD')) });
            this.setState({ actProg: data.actProg });
            this.setState({ libProg: data.libProg });
            this.setState({ modif: true });
            this.setState({ seletedProgeval: data });
            window.scrollTo(0, 0);
        }
    }

    annule_progeval() {
        this.setState({ debProg: '' });
        this.setState({ finProg: '' });
        this.setState({ libProg: '' });
        this.setState({ modif: false });
        this.forceUpdate();
    }

    affecter_cible() {
        if (this.state.selectedListe_cible <= 0) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner les lignes à affecter' });
        } else if (this.state.seletedProgeval == null || this.state.seletedProgeval == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner le programme' });
        }
        else {
            this.setState({showindicator: true});
            let liste_data = [];
            for (var i = this.state.selectedListe_cible.length; i--;) {
                liste_data.push(
                    {
                        idProgcible: this.state.selectedListe_cible[i].matrPers,
                        iduser: this.gemsysService.get_userconnected().numUtil,
                        matrCible: this.state.selectedListe_cible[i],
                        idProg: this.state.seletedProgeval,
                        anneeCible: this.gemsysService.get_rannee()
                    }
                )
            }
            this.gemsysService.AddProgCible(liste_data).then(data => this.resultatCible(data.code, data.contenu));
        }
    }

    affecter_quest() {
        if (this.state.selectedListe_quest <= 0) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner les lignes à affecter' });
        } else if (this.state.seletedProgeval == null || this.state.seletedProgeval == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner le programme' });
        } else {
            this.setState({showindicator: true});
            let liste_data = [];
            for (var i = this.state.selectedListe_quest.length; i--;) {
                liste_data.push(
                    {
                        idProgrammer: this.state.selectedListe_quest[i].idQuest,
                        iduser: this.gemsysService.get_userconnected().numUtil,
                        idQuest: this.state.selectedListe_quest[i],
                        idProg: this.state.seletedProgeval,
                        anneeProgrammer: this.gemsysService.get_rannee()
                    }
                )
            }
            this.gemsysService.AddProgrammerEval(liste_data).then(data => this.resultatQuest(data.code, data.contenu));
        }
    }

    enreg_progeval() {
        if (this.state.libProg == "") {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else {
            this.setState({showindicator: true});
            let deb_Prog = moment(this.state.debProg, 'DD/MM/YYYY').format('DD/MM/YYYY');
            let fin_Prog = moment(this.state.finProg, 'DD/MM/YYYY').format('DD/MM/YYYY');
            if (this.state.modif == false) {
                let progeval = {
                    idProg: '0',
                    debProg: deb_Prog,
                    finProg: fin_Prog,
                    libProg: this.state.libProg,
                    actProg: this.state.actProg,
                    anneeProg: this.gemsysService.get_rannee()
                }
                this.gemsysService.AddProgEval(progeval).then(data => this.resultat(data.code, data.contenu));
            } else {
                let progeval = this.state.seletedProgeval;
                progeval.debProg = deb_Prog;
                progeval.finProg = fin_Prog;
                progeval.libProg = this.state.libProg;
                progeval.actProg = this.state.actProg;
                this.gemsysService.UpdateProgEval(progeval).then(data => this.resultat(data.code, data.contenu));
            }
        }
    }

    photoTemplate(rowData, column) {
        let fic = rowData.photo;
        if (fic === undefined || fic === null || fic === '') {
            fic = 'nophoto.png';
        }
        return <img src={this.gemsysService.download_file(fic, 'photos/')} alt='Photo' style={{ width: 45, height: 45 }} />
    }

    photoTemplate2(rowData, column) {
        let fic = rowData.matrCible.photo;
        if (fic === undefined || fic === null || fic === '') {
            fic = 'nophoto.png';
        }
        return <img src={this.gemsysService.download_file(fic, 'photos/')} alt='Photo' style={{ width: 45, height: 45 }} />
    }

    actionTemplate(rowData, column) {
        return <div className="grid" style={{ width: 90 }}>
            <div className="col"><Button onClick={(e) => this.edit_progeval(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
            <div className="col"><Button onClick={(e) => this.conf_delete_progeval(rowData)} className="p-button-danger" style={{ width: 25, height: 25, marginLeft: -22 }} icon="pi pi-times" /></div>
        </div>
    }

    delete_progeval() {
        if (this.state.seletedProgeval == null || this.state.seletedProgeval == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.setState({showindicator: true});
            this.gemsysService.DeleteProgEval(this.state.seletedProgeval).then(data => this.resultat(data.code, data.contenu));
        }
    }

    conf_delete_progeval(data) {
        if (data !== null && data !== undefined) {
            this.setState({ seletedProgeval: data });
            this.setState({ msg: data.idProg });
            this.setState({ visible: true });
        }
    }

    render() {
        let actions = [
            { label: 'Oui', value: 'Oui' },
            { label: 'Non', value: 'Non' }
        ];

        const dialogFooter = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible: false });
                    this.delete_progeval();
                }} label="Oui" loading={this.state.showindicator} />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );
        return (
            <div className="p-fluid">
                <Toast ref={(el) => this.toast = el} />
                <Dialog header="Confirmation" visible={this.state.visible} style={{ width: '250px' }} modal={true} footer={dialogFooter} onHide={(e) => this.setState({ visible: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>
                <Dialog header="Affectation cible" visible={this.state.visible_dlg_cible} style={{ width: '450px' }} modal={true} onHide={(e) => this.setState({ visible_dlg_cible: false })}>
                    <DataTable value={this.state.liste_cible} emptyMessage="Aucune donnée à afficher" scrollable={true} scrollHeight='200px'
                        onSelectionChange={(e) => this.setState({ selectedListe_cible: e.value })} style={{ minHeight: '250px' }}
                        footer={<Button label="Affecter la sélection >>" icon="pi pi-check" onClick={(e) => this.affecter_cible()} />}
                        responsive={true} selection={this.state.selectedListe_cible} >
                        <Column selectionMode="multiple" style={{ width: '10%' }} />
                        <Column body={this.photoTemplate.bind(this)} header="Photo" style={{ width: '20%' }} />
                        <Column field="prenomPers" body={(rowData, column) => {
                            return <div>
                                <span>{rowData.prenomPers} {rowData.nomPers}</span>
                            </div>
                        }} header="Nom cibles" style={{ width: '70%' }} filter={true} filterMatchMode='contains' />
                    </DataTable>
                </Dialog>
                <Dialog header="Affectation questionnaire" visible={this.state.visible_dlg_quest} style={{ width: '450px' }} modal={true} onHide={(e) => this.setState({ visible_dlg_quest: false })}>
                    <DataTable value={this.state.liste_cible} emptyMessage="Aucune donnée à afficher" scrollable={true} scrollHeight='200px'
                        onSelectionChange={(e) => this.setState({ selectedListe_quest: e.value })} style={{ minHeight: '250px' }}
                        footer={<Button label="Affecter la sélection >>" icon="pi pi-check" onClick={(e) => this.affecter_quest()} />}
                        responsive={true} selection={this.state.selectedListe_quest} >
                        <Column selectionMode="multiple" style={{ width: '10%' }} />
                        <Column field="libQuest" header="Questionnaire" style={{ width: '90%' }} filter={true} filterMatchMode='contains' />
                    </DataTable>
                </Dialog>
                <div className="grid">
                    <div className="col-12">
                        <div className="card card-w-title">
                            <div className="grid " >
                                <div className="col-3 md:col-3">
                                    <label htmlFor="debPlan">Date début *</label>
                                </div>
                                <div className="col-9 md:col-3">
                                    <Calendar id="debPlan" dateFormat="dd/mm/yy" value={this.state.debProg} onChange={(e) => this.setState({ debProg: e.target.value })}></Calendar>
                                </div>
                                <div className="col-3 md:col-3">
                                    <label htmlFor="finPlan">Date fin *</label>
                                </div>
                                <div className="col-9 md:col-3">
                                    <Calendar id="finPlan" dateFormat="dd/mm/yy" value={this.state.finProg} onChange={e => this.setState({ finProg: e.target.value })}></Calendar>
                                </div>
                                <div className="col-3 md:col-3">
                                    <label htmlFor="actif">Actif *</label>
                                </div>
                                <div className="col-9 md:col-3" >
                                    <Dropdown options={actions} value={this.state.actProg} onChange={(e) => this.setState({ actProg: e.target.value })} autoWidth={false} />
                                </div>
                                <div className="col-3 md:col-3">
                                    <label htmlFor="libelle">Libellé *</label>
                                </div>
                                <div className="col-9 md:col-3" >
                                    <InputText id="libelle" value={this.state.libProg} onChange={(e) => this.setState({ libProg: e.target.value })} />
                                </div>
                            </div>
                            <center>
                                <div className="grid" style={{ width: 300, marginTop: '0%' }}>
                                    <div className="col"><Button label="Annuler" icon="pi pi-ban" onClick={(e) => this.annule_progeval()} /></div>
                                    <div className="col"><Button label="Valider" loading={this.state.showindicator} icon="pi pi-check" onClick={(e) => this.enreg_progeval()} /></div>
                                </div>
                            </center>
                            <DataTable value={this.state.liste_progeval} paginatorPosition="top" selectionMode="single" paginator={true} rows={20}
                                alwaysShowPaginator={false} style={{ minHeight: '300px' }} onRowClick={e => this.setState({ seletedProgeval: e.data })}
                                onRowSelect={e => this.setState({ seletedProgeval: e.data }, () => {
                                    this.get_liste_prog_cible(e.data);
                                    this.get_liste_prog_quest(e.data);
                                })}
                                selection={this.state.seletedProgeval} dataKey="idProg" className="p-datatable-gridlines" showGridlines responsiveLayout="scroll">
                                <Column body={this.actionTemplate.bind(this)} header="Actions" style={{ width: '15%' }} />
                                <Column field="libProg" header="Libellé" style={{ width: '30%' }} filter={true} filterMatchMode='contains' />
                                <Column field="debProg" header="Début" style={{ width: '15%' }} filter={true} filterMatchMode='contains' />
                                <Column field="finProg" header="Fin" style={{ width: '15%' }} filter={true} filterMatchMode='contains' />
                                <Column field="actProg" header="Actif" style={{ width: '15%' }} filter={true} filterMatchMode='contains' />
                            </DataTable>
                            <div className="grid">
                                <div className="col-12" >
                                    <TabView activeIndex={this.state.activeIndex} onTabChange={(e) => this.setState({ activeIndex: e.index })}>
                                        <TabPanel header="Affecter questionnaires">
                                            <DataTable value={this.state.liste_prog_cible_quest} emptyMessage="Aucune donnée à afficher" selectionMode="single"
                                            dataKey="idQuest" className="p-datatable-gridlines" showGridlines responsiveLayout="scroll" paginator rows={10}
                                                onSelectionChange={(e) => this.setState({ selectedListe_prog_cible_quest: e.value })} style={{ minHeight: '200px' }}
                                                footer={<SplitButton label="Affecter" style={{ width: 200 }} icon="pi pi-sitemap"
                                                    model={[{
                                                        label: 'Supprimer la sélection',
                                                        icon: 'pi pi-times',
                                                        command: (e) => {
                                                            this.conf_delete_quest();
                                                        }
                                                    }]} onClick={(e) => this.show_dlg_quest()} />
                                                }
                                                responsive={true} selection={this.state.selectedListe_prog_cible_quest} className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers">
                                                <Column selectionMode="multiple" style={{ width: '10%' }} />
                                                <Column field="idQuest.libQuest" header="Questionnaire" style={{ width: '90%' }} filter={true} filterMatchMode='contains' />
                                            </DataTable>
                                        </TabPanel>
                                        <TabPanel header="Affecter cibles" >
                                            <DataTable value={this.state.liste_prog_cible_pers} emptyMessage="Aucune donnée à afficher"
                                               dataKey="ordre" className="p-datatable-gridlines" showGridlines responsiveLayout="scroll" paginator rows={10}
                                                footer={<SplitButton label="Affecter" style={{ width: 200 }} icon="pi pi-sitemap"
                                                    model={[{
                                                        label: 'Supprimer la sélection',
                                                        icon: 'pi pi-times',
                                                        command: (e) => {
                                                            this.conf_delete_cible();
                                                        }
                                                    }]} onClick={(e) => this.show_dlg_cible()} />
                                                } 
                                                onSelectionChange={(e) => this.setState({ selectedListe_prog_cible_pers: e.value })} style={{ minHeight: '200px' }}
                                                responsive={true} selection={this.state.selectedListe_prog_cible_pers} >
                                                <Column selectionMode="multiple" style={{ width: '10%' }} />
                                                <Column body={this.photoTemplate2.bind(this)} header="Photo" style={{ width: '13%' }} />
                                                <Column field="matrCible.prenomPers"  body={(rowData, column) => {
                                                    return <div>
                                                        <span>{rowData.matrCible.prenomPers} {rowData.matrCible.nomPers}</span>
                                                    </div>
                                                }} header="Nom cibles" style={{ width: '67%' }} filter={true} filterMatchMode='contains' />
                                            </DataTable>

                                        </TabPanel>
                                    </TabView>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}