import React, { Component } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Row } from 'primereact/row';
import { SplitButton } from 'primereact/splitbutton';
import GestGemsysService from '../service/GestGemsysService';
import { ProgressSpinner } from 'primereact/progressspinner';

import { ColumnGroup } from 'primereact/columngroup';

export class StatResultat extends Component {
    constructor() {
        super();
        this.state = {
            pd1: new Date(),
            pd2: new Date(),
            rcode_enseign: 'Tous',
            rmodule: 'Module 1',
            liste_module: [
                { value: 'Module 1', label: 'Module 1' },
                { value: 'Module 2', label: 'Module 2' },
                { value: 'Tous', label: 'Année' }
            ],

            liste_data: [],

            nb: 0,
            nbg: 0,
            nbf: 0,

            nb_admis: 0,
            nbg_admis: 0,
            nbf_admis: 0,

            nb_echec: 0,
            nbg_echec: 0,
            nbf_echec: 0,

            nbf_admis_prc: 0,
            nbg_admis_prc: 0,
            nb_admis_prc: 0,

            nbf_echec_prc: 0,
            nbg_echec_prc: 0,
            nb_echec_prc: 0,

            liste_enseignement: [{ label: 'Enseignement', value: 'Tous' }],
            showindicator: false,
            items: [
                {
                    label: 'Format PDF',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.conf_print('PDF');
                    }
                },
                {
                    label: 'Format XLS',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.exportExcel();
                    }
                },
                {
                    label: 'Format CSV',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.dt.exportCSV();
                    }
                }
            ],
        };
        this.conf_print = this.conf_print.bind(this);
        this.gemsysService = new GestGemsysService();
    }

    get_liste_enseignement() {
        this.setState({ showindicator: true });
        this.gemsysService.getEnseignement().then(data => {
            this.setState({ showindicator: false });
            if (data !== null && data !== undefined && data !== '') {
                if (data.length > 0) {
                    let liste = data.map(x => {
                        return { value: x.codeEnseign, label: x.libEnseign }
                    });
                    this.setState({ liste_enseignement: this.state.liste_enseignement.concat(liste) });
                }
            }
        });
    }


    /* Exportation du tableau  en fichier XLSX */
    exportExcel = () => {
        let liste_data = this.state.liste_data.length > 0 ? this.state.liste_data.map(x => {
            return {
                'NO': x.ordre,
                'CLASSE': x.classe,
                'NB_G': x.nbg,
                'NB_F': x.nbf,
                'TOTAL': x.nb,

                'NBF_ADMIS': x.nbf_admis,
                'PRC_F_ADMIS': x.nbf_admis_prc,
                'NB_G_ADMIS': x.nbg_admis,
                'PRC_G_ADMIS': x.nbg_admis_prc,
                'TOTAL_ADMIS': x.nb_admis,
                'PRC_ADMIS': x.nb_admis_prc,

                'NBF_ECHEC': x.nbf_echec,
                'PRC_F_ECHEC': x.nbf_echec_prc,
                'NB_G_ECHEC': x.nbg_echec,
                'PRC_G_ECHEC': x.nbg_echec_prc,
                'TOTAL_ECHEC': x.nb_echec,
                'PRC_ECHEC': x.nb_echec_prc,
            }
        }) : [];
        import('xlsx').then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(liste_data);
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            this.saveAsExcelFile(excelBuffer, 'STAT_RESULTAT_');
        });
    }

    /* Enregistrer le fichier sur le client */
    saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then(module => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    }


    get_liste_data() {

        let rcode_enseign = 'Tous';
        if (this.state.rcode_enseign !== undefined && this.state.rcode_enseign !== null && this.state.rcode_enseign !== 'Tous') {
            rcode_enseign = this.state.rcode_enseign;
        }
        this.setState({
            showindicator: true,
            nb: 0,
            nbg: 0,
            nbf: 0,

            nb_admis: 0,
            nbg_admis: 0,
            nbf_admis: 0,

            nb_echec: 0,
            nbg_echec: 0,
            nbf_echec: 0,
        });
        this.gemsysService.get_liste_stat_resultat(rcode_enseign, this.state.rmodule).then(data => {
            this.setState({ liste_data: data, showindicator: false });
            if (data !== null && data !== undefined) {
                if (data.length > 0) {
                    let nb = 0;
                    let nbg = 0;
                    let nbf = 0;

                    let nb_admis = 0;
                    let nbg_admis = 0;
                    let nbf_admis = 0;

                    let nb_echec = 0;
                    let nbg_echec = 0;
                    let nbf_echec = 0;

                    for (let i = 0; i < data.length; i++) {
                        nb += data[i].nb;
                        nbg += data[i].nbg;
                        nbf += data[i].nbf;
                        nb_admis += data[i].nb_admis;
                        nbg_admis += data[i].nbg_admis;
                        nbf_admis += data[i].nbf_admis;
                        nb_echec += data[i].nb_echec;
                        nbg_echec += data[i].nbg_echec;
                        nbf_echec += data[i].nbf_echec;
                    }

                    this.setState({
                        nb: nb,
                        nbg: nbg,
                        nbf: nbf,

                        nb_admis: nb_admis,
                        nbg_admis: nbg_admis,
                        nbf_admis: nbf_admis,

                        nb_echec: nb_echec,
                        nbg_echec: nbg_echec,
                        nbf_echec: nbf_echec,

                        nb_admis_prc: this.gemsysService.formatDecimal(100 * nb_admis / nb),
                        nb_echec_prc: this.gemsysService.formatDecimal(100 * nb_echec / nb),

                        nbg_admis_prc: this.gemsysService.formatDecimal(100 * nbg_admis / nb_admis),
                        nbf_admis_prc: this.gemsysService.formatDecimal(100 * nbf_admis / nb_admis),

                        nbf_echec_prc: this.gemsysService.formatDecimal(100 * nbf_echec / nb_echec),
                        nbg_echec_prc: this.gemsysService.formatDecimal(100 * nbg_echec / nb_echec),

                    });
                }
            }
        });
    }

    conf_print(format) {
        let rcode_enseign = 'Tous';
        if (this.state.rcode_enseign !== undefined && this.state.rcode_enseign !== null && this.state.rcode_enseign !== 'Tous') {
            rcode_enseign = this.state.rcode_enseign;
        }
        this.gemsysService.generer_stat_resultat(rcode_enseign, this.state.rmodule, format);
    }

    componentDidMount() {
        this.get_liste_enseignement();
        this.get_liste_data();
        if(this.gemsysService.get_type_etabl() == 'Pré-universitaire'){
            this.setState({
                liste_module: [
                    { value: 'Module 1', label: 'Module 1' },
                    { value: 'Module 2', label: 'Module 2' },
                    { value: 'Module 3', label: 'Module 3' },
                    { value: 'Tous', label: 'Année' }
                ],
            })
        }
    }


    render() {
        let headerGroup = <ColumnGroup>
            <Row>
                <Column header="Classe" rowSpan={2} />
                <Column header="Effectif" colSpan={3} />
                <Column header="Admis" colSpan={6} />
                <Column header="Echecs" colSpan={6} />
            </Row>
            <Row>
                <Column header='G' field="nbg" />
                <Column header='F' field="nbf" />
                <Column header='TT' field="nb" />

                <Column header='F' field="nbf_admis" />
                <Column header='%' field="nbf_admis_prc" />
                <Column header='G' field="nbg_admis" />
                <Column header='%' field="nbg_admis_prc" />
                <Column header='TT' field="nb_admis" />
                <Column header='%' field="nb_admis_prc" />

                <Column header='F' field="nbf_echec" />
                <Column header='%' field="nbf_echec_prc" />
                <Column header='G' field="nbg_echec" />
                <Column header='%' field="nbg_echec_prc" />
                <Column header='TT' field="nb_echec" />
                <Column header='%' field="nb_echec_prc" />

            </Row>
        </ColumnGroup>;

        let footerGroup = <ColumnGroup>
            <Row>
                <Column footer="TOTAUX" footerStyle={{ textAlign: 'center', fontWeight: 'bold' }} />
                <Column footer={this.state.nbg} style={{ color: '#000000', fontWeight: 'bold' }} />
                <Column footer={this.state.nbf} style={{ color: '#000000', fontWeight: 'bold' }} />
                <Column footer={this.state.nb} style={{ color: '#000000', fontWeight: 'bold' }} />

                <Column footer={this.state.nbf_admis} style={{ color: '#000000', fontWeight: 'bold' }} />
                <Column footer={this.state.nbf_admis_prc+' %'} style={{ color: '#000000', fontWeight: 'bold' }} />
                <Column footer={this.state.nbg_admis} style={{ color: '#000000', fontWeight: 'bold' }} />
                <Column footer={this.state.nbg_admis_prc+' %'} style={{ color: '#000000', fontWeight: 'bold' }} />
                <Column footer={this.state.nb_admis} style={{ color: '#000000', fontWeight: 'bold' }} />
                <Column footer={this.state.nb_admis_prc+' %'} style={{ color: '#000000', fontWeight: 'bold' }} />

                <Column footer={this.state.nbf_echec} style={{ color: '#000000', fontWeight: 'bold' }} />
                <Column footer={this.state.nbf_echec_prc+' %'} style={{ color: '#000000', fontWeight: 'bold' }} />
                <Column footer={this.state.nbg_echec} style={{ color: '#000000', fontWeight: 'bold' }} />
                <Column footer={this.state.nbg_echec_prc+' %'} style={{ color: '#000000', fontWeight: 'bold' }} />
                <Column footer={this.state.nb_echec} style={{ color: '#000000', fontWeight: 'bold' }} />
                <Column footer={this.state.nb_echec_prc+' %'} style={{ color: '#000000', fontWeight: 'bold' }} />

            </Row>
        </ColumnGroup>;

        return (
            <div className="p-fluid">
                <div className="grid">
                    <div className="col-12">
                        <div className="card card-w-title">
                            <i className="pi pi-search"></i>
                            <span style={{ fontSize: 14, marginLeft: 10, color: '#999999' }}>Options de recherche</span>
                            <div className="grid">
                                <div className="col-12 md:col-3">
                                    <Dropdown options={this.state.liste_enseignement} placeholder={this.gemsysService.get_type_etabl() == 'Université' ? "Département" : "Enseignement"} value={this.state.rcode_enseign} onChange={(e) => this.setState({ rcode_enseign: e.value })}
                                        autoWidth={false} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' panelStyle={{ width: '400px' }} />
                                </div>
                                {this.gemsysService.get_type_etabl() == 'Université' ?null :<div className="col-12 md:col-3">
                                    <Dropdown options={this.state.liste_module} placeholder="Module" value={this.state.rmodule} onChange={(e) => this.setState({ rmodule: e.value }, () => {
                                        this.get_liste_data();
                                    })} autoWidth={false} />
                                </div>}

                                
                                <div className="col-12 md:col-2">
                                    <SplitButton label="Rechercher" style={{ width: '160px' }} onClick={(e) => this.get_liste_data()} icon="pi pi-search" model={this.state.items} />
                                </div>
                                <div className="col-12 md:col-2">
                                    {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                </div>
                            </div>

                            <DataTable value={this.state.liste_data} style={{ minHeight: '700px' }} ref={(el) => { this.dt = el; }}
                                headerColumnGroup={headerGroup} footerColumnGroup={footerGroup}
                                dataKey="ordre" className="p-datatable-gridlines" showGridlines responsiveLayout="scroll" paginator rows={100}>
                                <Column field="classe" header="Classe" sortable={true} filter={true} filterMatchMode='contains' />

                                <Column field="nbf" header="F" style={{ width: '4%' }} sortable={true} />
                                <Column field="nbg" header="G" style={{ width: '4%' }} sortable={true} />
                                <Column field="nb" header="Total" style={{ width: '4%' }} sortable={true} />

                                <Column field="nbf_admis" header="F" style={{ width: '4%' }} sortable={true} />
                                <Column field="nbf_admis_prc" header="%" style={{ width: '6%' }} sortable={true} />
                                <Column field="nbg_admis" header="G" style={{ width: '4%' }} sortable={true} />
                                <Column field="nbg_admis_prc" header="%" style={{ width: '6%' }} sortable={true} />
                                <Column field="nb_admis" header="Total" style={{ width: '4%' }} sortable={true} />
                                <Column field="nb_admis_prc" header="%" style={{ width: '6%' }} sortable={true} />


                                <Column field="nbf_echec" header="F" style={{ width: '4%' }} sortable={true} />
                                <Column field="nbf_echec_prc" header="%" style={{ width: '6%' }} sortable={true} />
                                <Column field="nbg_echec" header="G" style={{ width: '4%' }} sortable={true} />
                                <Column field="nbg_echec_prc" header="%" style={{ width: '6%' }} sortable={true} />
                               
                                <Column field="nb_echec" header="Total" style={{ width: '4%' }} sortable={true} />
                                <Column field="nb_echec_prc" header="%" style={{ width: '6%' }} sortable={true} />

                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}