import React, { useState, useEffect, useRef } from 'react';
import { classNames } from 'primereact/utils';
import { Link } from 'react-router-dom';
import AppBreadcrumb from './AppBreadcrumb';
import AppMenu from './AppMenu';
import GestGemsysService from './service/GestGemsysService';
import { Dropdown } from 'primereact/dropdown';

const AppTopbar = (props) => { 
    const [annee, setAnnee] = useState(localStorage.getItem('rannee'));
    const onDossierChange = (e) => {
        setAnnee(e.value);
        localStorage.setItem('rannee', e.value);
        window.location = "#/dashboard/" + e.value.replace('-', '');
    };

    const notificationsItemClassName = classNames('notifications-item', { 'active-menuitem': props.topbarNotificationMenuActive });
    const profileItemClassName = classNames('profile-item', { 'active-menuitem fadeInDown': props.topbarUserMenuActive });

    const appservice = new GestGemsysService();
    var userconnected = appservice.get_userconnected();
    let liste_annee = appservice.get_liste_annee();
    var nom_user = '';
    if (userconnected !== null && userconnected !== undefined) {
        nom_user = userconnected.compte;
    }
    return (
        <div className="layout-topbar">
            <div className="topbar-left">
                <button type="button" className="menu-button p-link" onClick={props.onMenuButtonClick}>
                    <i className="pi pi-chevron-left"></i>
                </button>

                <Link to="/">
                    <img id="logo-horizontal" className="horizontal-logo" src="assets/layout/images/logo-white.png" alt="diamond-layout" />
                </Link>

                <span className="topbar-separator"></span>

                <div className="layout-breadcrumb viewname" style={{ textTransform: 'uppercase' }}>
                    <AppBreadcrumb routers={props.routers} />
                </div>

                <img id="logo-mobile" className="mobile-logo" src="assets/layout/images/logo-white.png" alt="diamond-layout" />
            </div>

            <AppMenu model={props.menu} menuMode={props.menuMode} active={props.menuActive} mobileMenuActive={props.staticMenuMobileActive}
                onMenuClick={props.onMenuClick} onMenuitemClick={props.onMenuitemClick} onRootMenuitemClick={props.onRootMenuitemClick}></AppMenu>

            <div className="layout-mask modal-in"></div>

            <div className="topbar-right">
                <ul className="topbar-menu">
                    <li className="search-item">
                        <button type="button" className="p-link" onClick={props.onSearchClick}>
                            <i className="pi pi-search"></i>
                        </button> 
                    </li>
                    <li className="search-item">
                        <Dropdown  options={liste_annee} value={annee} onChange={onDossierChange} autoWidth={false} filter={true}  style={{ fontWeight: 'bold', fontSize: 18, color: 'red', cursor: 'pointer',width: 150 }} filterBy='label' filterMatchMode='contains'  />
                    </li>
                   {/*  {liste_annee !== null && liste_annee.length > 0 ?
                        <li className={notificationsItemClassName}>
                            <button type="button" className="p-link" style={{ width: 100 }} onClick={props.onTopbarNotification}>
                                <label style={{ fontWeight: 'bold', fontSize: 18, color: 'red', cursor: 'pointer' }}>{appservice.get_rannee()}</label>
                            </button>
                            <ul className="notifications-menu fade-in-up">
                                {

                                     liste_annee.map(annee => {
                                         return (
                                             <li role="menuitem">
                                                 <button type="button" className="p-link" onClick={() => {
                                                     localStorage.setItem('rannee',annee.value);
                                                     window.location = "#/dashboard/"+annee.value.replace('-','');
                                                 }}>
                                                     <i className="pi pi-check-square"></i>
                                                     <div className="notification-item">
                                                         <div className="notification-summary">{annee.value}</div>
                                                     </div>
                                                 </button>
                                             </li>
                                         )
                                     })
                                }

                            </ul>
                        </li>
                        : null} */}
                    <li className={notificationsItemClassName}>
                        <button type="button" className="p-link" onClick={props.onTopbarNotification}>
                            <i className="pi pi-bell"></i>
                            <span className="topbar-badge">5</span>
                        </button>
                    </li>

                    <li className={profileItemClassName}>
                        <button type="button" className="p-link" onClick={props.onTopbarUserMenu}>
                            <img src="assets/demo/images/avatar/avatar.png" alt="diamond-layout" className="profile-image" />
                            <span  className="profile-name">{nom_user}</span>
                        </button>
                        <ul className="profile-menu fade-in-up">
                            <li>
                                <button type="button" className="p-link" onClick={(e) => {
                                    window.location = "#/chpwd";
                                }}>
                                    <i className="pi pi-cog"></i>
                                    <span>Changer mot de passe</span>
                                </button>
                            </li>
                            <li>
                                <button type="button" className="p-link" onClick={(e) => {
                                    localStorage.removeItem('userconnected');
                                    localStorage.removeItem('dossiers');
                                    localStorage.removeItem('etablissements');
                                    localStorage.removeItem('rannee');
                                    window.location = "#/login";
                                }}>
                                    <i className="pi pi-sign-out"></i>
                                    <span>Déconnexion</span>
                                </button>
                            </li>
                        </ul>
                    </li>

                    <li className="right-sidebar-item">
                        <button type="button" className="p-link" onClick={(e) => {
                            localStorage.removeItem('userconnected');
                            localStorage.removeItem('dossiers');
                            localStorage.removeItem('etablissements');
                            localStorage.removeItem('rannee');
                            window.location = "#/login";
                        }}>
                            <i className="pi pi-sign-out"></i>
                        </button>
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default AppTopbar;


