import React, { Component } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import './styles.css';
import { Calendar } from 'primereact/calendar';
import { SplitButton } from 'primereact/splitbutton';
import  GestGemsysService from '../service/GestGemsysService';
import { ProgressSpinner } from 'primereact/progressspinner';
import moment from 'moment';
import { Row } from 'primereact/row';
import { ColumnGroup } from 'primereact/columngroup';

export class EditionEncaissement extends Component {
    constructor() {
        super();
        this.state = {
            pd1: new Date(),
            pd2: new Date(),
            rlibelle: 'Tous',
            liste_data: [],
            total: 0,
            showindicator: false,
            items: [
                {
                    label: 'Format PDF',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.conf_print('PDF');
                    }
                },
                {
                    label: 'Format XLS',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.conf_print('XLS');
                    }
                },
                {
                    label: 'Format CSV',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.dt.exportCSV();
                    }
                }
            ],
        };
        this.conf_print = this.conf_print.bind(this);
        this.onIndexTemplate = this.onIndexTemplate.bind(this);
        this.gemsysService = new GestGemsysService();
    }

    onIndexTemplate(data, props) {
        return props.rowIndex + 1;
    }

    get_liste_data() {
        let spd1 = moment(this.state.pd1).format('DD/MM/YYYY');
        let spd2 = moment(this.state.pd2).format('DD/MM/YYYY');
        this.setState({
            showindicator: true,
            total: 0,
        });
        this.gemsysService.get_journal_vers(spd1, spd2, this.state.rlibelle).then(data => {
            this.setState({ liste_data: data, showindicator: false });
            if (data !== null && data !== undefined) {
                if (data.length > 0) {
                    let initialValue = 0;
                    let tt = data.reduce(function (accumulator, currentValue) {
                        return accumulator + currentValue.mt_vers
                    }, initialValue);
                    this.setState({
                        total: this.gemsysService.formaterValueSep(tt),
                    });
                }
            }
        });
    }

    conf_print(format) {
        let spd1 = moment(this.state.pd1).format('DD/MM/YYYY');
        let spd2 = moment(this.state.pd2).format('DD/MM/YYYY');
        this.gemsysService.generer_journal_vers(spd1, spd2, this.state.rlibelle, format);
    }

    componentDidMount() {
        this.get_liste_data();
    }


    render() {
        let liste_libelle = [
            { label: 'Libelle', value: 'Tous' },
            { label: 'Frais scolarité', value: 'Frais scolarité' },
            { label: 'Frais inscription', value: 'Frais inscription' },
            { label: 'Frais réinscription', value: 'Frais réinscription' },
            { label: 'Frais badge', value: 'Frais badge' },
            { label: 'Frais APEAE', value: 'Frais APEAE' },
            { label: 'Frais Cyber', value: 'Frais Cyber' },
            { label: 'Ancien bon', value: 'Ancien bon' },
            { label: 'Autre recette', value: 'Autre recette' }
        ];
        let footerGroup = <ColumnGroup>
            <Row>
                <Column footer="TOTAUX" colSpan={5} footerStyle={{ textAlign: 'center', fontWeight: 'bold' }} />
                <Column footer={this.state.total} style={{ color: '#000000', fontWeight: 'bold' }} />
            </Row>
        </ColumnGroup>;
 
        return (
            <div className="p-fluid">
                <div className="grid">
                    <div className="col-12">
                        <div className="card card-w-title">
                            <i className="pi pi-search"></i>
                            <span style={{ fontSize: 14, marginLeft: 10, color: '#999999' }}>Options de recherche</span>
                            <div className="grid">
                                <div className="col-12 md:col-2" >
                                    <Calendar id="pd1" placeholder="Date début" dateFormat="dd/mm/yy" value={this.state.pd1} onChange={(e) => this.setState({ pd1: e.target.value })} />
                                </div>
                                <div className="col-12 md:col-2" >
                                    <Calendar id="pd2" placeholder="Date fin"  dateFormat="dd/mm/yy" value={this.state.pd2} onChange={e => this.setState({ pd2: e.target.value })} />
                                </div>
                                <div className="col-12 md:col-3">
                                    <Dropdown options={liste_libelle} value={this.state.rlibelle} onChange={(e) => this.setState({ rlibelle: e.value })} autoWidth={false} />
                                </div>
                                <div className="col-12 md:col-3">
                                    <SplitButton label="Rechercher" style={{ width: '170px' }} onClick={(e) => this.get_liste_data()} icon="pi pi-search" model={this.state.items} />
                                </div>
                                <div className="col-12 md:col-2">
                                    {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                </div>
                            </div>
                            <DataTable value={this.state.liste_data} style={{ minHeight: '700px' }}  ref={(el) => { this.dt = el; }}
                                 footerColumnGroup={footerGroup} dataKey="ordre" className="p-datatable-gridlines" showGridlines responsiveLayout="scroll" paginator rows={10}>
                                <Column field="ordre" header="#" style={{ width: '5%' }} />
                                <Column field="date_vers" header="Date" style={{ width: '15%' }} sortable/>
                                <Column field="num_recu" header="No reçu" style={{ width: '15%' }} sortable />
                                <Column field="lib_vers" header="Libelle" style={{ width: '20%' }} sortable />
                                <Column field="cmt_vers" header="Commeentaire" style={{ width: '30%' }} sortable />
                                <Column field="mt_versFormat" header="Montant" style={{ width: '15%' }} sortable />
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}