import React, { Component } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import './styles.css';
import  GestGemsysService  from '../service/GestGemsysService';
import { Row } from 'primereact/row';
import { ColumnGroup } from 'primereact/columngroup';
import { ProgressSpinner } from 'primereact/progressspinner';
export class EditionBilanAnnuel extends Component {
    constructor() {
        super();
        this.state = {
            liste_recettes: [],
            liste_depenses: [],
            liste_detrcv: [],
            showindicator: false,
            solde: 0,
            nb: 0,
            total_recette: '0',
            total_depense: '0',
            tt_salaire: 0,
            tt_bon: 0,
            tt_autre_dep: 0,
            fsprev: '0',
            fsrecv: '0',
            fsrest: '0',
            fiprev: '0',
            firecv: '0',
            firest: '0',
            fbprev: '0',
            fbrecv: '0',
            fbrest: '0',
        };
        this.conf_print = this.conf_print.bind(this);
        this.gestGemsysService = new GestGemsysService();
    }

    get_bilan() {
        this.setState({
            solde: 0,
            total_recette: '0',
            total_depense: '0',
            tt_salaire: 0,
            tt_bon: 0,
            tt_autre_dep: 0,
            fsprev: '0',
            fsrecv: '0',
            fsrest: '0',
            fiprev: '0',
            firecv: '0',
            firest: '0',
            fbprev: '0',
            fbrecv: '0',
            fbrest: '0',
            nb: 0,
            showindicator: true
        });

        this.gestGemsysService.get_bilan().then(data => {
            if (data !== null && data !== undefined) {
                this.setState({
                    liste_recettes: data.liste_recettes,
                    liste_depenses: data.liste_depenses,
                    liste_detrcv: data.liste_detrcv,
                    showindicator: false
                }, () => {
                    let nb = 0;
                    let total_recette = 0;
                    let total_depense = 0;
                    let tt_salaire = 0;
                    let tt_bon = 0;
                    let tt_autre_dep = 0;
                    let fsprev = 0;
                    let fsrecv = 0;
                    let fsrest = 0;
                    let fiprev = 0;
                    let firecv = 0;
                    let firest = 0;
                    let fbprev = 0;
                    let fbrecv = 0;
                    let fbrest = 0;
                    if (this.state.liste_recettes != null && this.state.liste_recettes != undefined && this.state.liste_recettes.length > 0) {
                        for (var i = this.state.liste_recettes.length; i--;) {
                            total_recette += this.state.liste_recettes[i].val;
                        }
                    }
                    if (this.state.liste_detrcv != null && this.state.liste_detrcv != undefined && this.state.liste_detrcv.length > 0) {
                        for (var i = this.state.liste_detrcv.length; i--;) {
                            fsrecv += this.state.liste_detrcv[i].fsrecv;
                            fsrest += this.state.liste_detrcv[i].fsrest;
                            fiprev += this.state.liste_detrcv[i].fiprev;
                            firecv += this.state.liste_detrcv[i].firecv;
                            firest += this.state.liste_detrcv[i].firest;
                            fbprev += this.state.liste_detrcv[i].fbprev;
                            fbrecv += this.state.liste_detrcv[i].fbrecv;
                            fbrest += this.state.liste_detrcv[i].fbrest;
                            fsprev += this.state.liste_detrcv[i].fsprev;
                            nb += this.state.liste_detrcv[i].nb;
                        }
                    }
                    if (this.state.liste_depenses != null && this.state.liste_depenses != undefined && this.state.liste_depenses.length > 0) {
                        for (var i = this.state.liste_depenses.length; i--;) {
                            tt_salaire += this.state.liste_depenses[i].val1;
                            tt_autre_dep += this.state.liste_depenses[i].val2;
                            tt_bon += this.state.liste_depenses[i].val3;
                        }
                    }
                    total_depense = tt_salaire + tt_autre_dep + tt_bon;
                    this.setState({
                        solde: total_recette - total_depense,
                        total_recette: this.gestGemsysService.formaterValueSep(total_recette),
                        total_depense: this.gestGemsysService.formaterValueSep(total_depense),
                        tt_salaire: this.gestGemsysService.formaterValueSep(tt_salaire),
                        tt_bon: this.gestGemsysService.formaterValueSep(tt_bon),
                        tt_autre_dep: this.gestGemsysService.formaterValueSep(tt_autre_dep),
                        fsprev: this.gestGemsysService.formaterValueSep(fsprev),
                        fsrecv: this.gestGemsysService.formaterValueSep(fsrecv),
                        fsrest: this.gestGemsysService.formaterValueSep(fsrest),
                        fiprev: this.gestGemsysService.formaterValueSep(fiprev),
                        firecv: this.gestGemsysService.formaterValueSep(firecv),
                        firest: this.gestGemsysService.formaterValueSep(firest),
                        fbprev: this.gestGemsysService.formaterValueSep(fbprev),
                        fbrecv: this.gestGemsysService.formaterValueSep(fbrecv),
                        fbrest: this.gestGemsysService.formaterValueSep(fbrest),
                        nb: nb
                    });

                })
            }
        });
    }

    conf_print() {
        this.gestGemsysService.generer_bilan_annuel();
    }

    render() {
        let footerGroupDet = <ColumnGroup>
            <Row>
                <Column footer="TOTAUX" colSpan={1} footerStyle={{ textAlign: 'center', fontWeight: 'bold' }} />
                <Column footer={this.state.nb} style={{ color: '#000000', fontWeight: 'bold' }} />
                <Column footer={this.state.fsprev} style={{ color: '#000000', fontWeight: 'bold' }} />
                <Column footer={this.state.fsrecv} style={{ color: '#000000', fontWeight: 'bold' }} />
                <Column footer={this.state.fsrest} style={{ color: '#000000', fontWeight: 'bold' }} />
                <Column footer={this.state.fiprev} style={{ color: '#000000', fontWeight: 'bold' }} />
                <Column footer={this.state.firecv} style={{ color: '#000000', fontWeight: 'bold' }} />
                <Column footer={this.state.firest} style={{ color: '#000000', fontWeight: 'bold' }} />
            </Row>
        </ColumnGroup>;
        let footerGroupDep = <ColumnGroup>
            <Row>
                <Column footer="TOTAUX" colSpan={1} footerStyle={{ textAlign: 'center', fontWeight: 'bold' }} />
                <Column footer={this.state.tt_salaire} style={{ color: 'red', fontWeight: 'bold' }} />
                <Column footer={this.state.tt_autre_dep} style={{ color: 'red', fontWeight: 'bold' }} />
                <Column footer={this.state.tt_bon} style={{ color: 'red', fontWeight: 'bold' }} />
            </Row>
        </ColumnGroup>;
        let footerGroupRec = <ColumnGroup>
            <Row>
                <Column footer="TOTAUX" colSpan={1} footerStyle={{ textAlign: 'center', fontWeight: 'bold' }} />
                <Column footer={this.state.total_recette} style={{ color: 'green', fontWeight: 'bold' }} />
            </Row>
        </ColumnGroup>;
        let footerDep = <div className="grid">
            <div className="col-6 md:col-6">
                <label style={{ color: '#000', fontWeight: 'bold' }}>TOTAL DEPENSE</label>
            </div>
            <div className="col-6 md:col-6">
                <label style={{ color: 'red', fontWeight: 'bold' }} >{this.state.total_depense}</label>
            </div>
        </div>
        return (
            <div className="p-fluid">
                <div className="col-12">
                    <div className="card card-w-title">
                        <div className="grid">
                            <div className="col-12" style={{ marginBottom: -30 }}>
                                <div className="grid">
                                    <div className="col-3 md:col-2">
                                        <label style={{ color: '#000', fontWeight: 'bold', fontSize: '20px' }}>Solde</label>
                                    </div>
                                    <div className="col-9 md:col-5">
                                        <label style={{ color: 'green', fontWeight: 'bold', fontSize: '20px' }} >{this.gestGemsysService.formaterValueSep(this.state.solde)}</label>
                                    </div>
                                    <div className="col-9 md:col-4">
                                        <div className="grid" style={{ width: 300 }}>
                                            <div className="col"> <Button label="Charger" loading={this.state.showindicator} onClick={(e) => this.get_bilan()} icon="pi pi-search" /> </div>
                                            <div className="col"><Button label="Edition" onClick={(e) => this.conf_print()} icon="pi pi-print" /> </div>
                                        </div>
                                    </div>
                                    <div className="col-3 md:col-1">
                                        {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 md:col-6">
                                <i className="pi pi-money-bill"></i>
                                <span style={{ fontSize: 14, marginLeft: 10, color: '#999999' }}>Bilan des recettes</span><hr />
                                <DataTable value={this.state.liste_recettes} style={{ minHeight: '250px' }} paginatorPosition='top'  footerColumnGroup={footerGroupRec} 
                                dataKey="lib" className="p-datatable-gridlines" showGridlines responsiveLayout="scroll" paginator rows={5}>
                                    <Column field="lib" header="Libellé" style={{ width: '60%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                    <Column field="sval" body={(rowData, column) => {
                                        return <div>
                                            <span style={{ color: 'green' }}>{rowData.sval}</span>
                                        </div>
                                    }} header="Montant" style={{ width: '40%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                </DataTable>
                            </div>
                            <div className="col-12 md:col-6">
                                <i className="pi pi-credit-card"></i>
                                <span style={{ fontSize: 14, marginLeft: 10, color: '#999999' }}>Bilan des dépenses</span><hr />
                                <DataTable value={this.state.liste_depenses} style={{ minHeight: '250px' }} footer={footerDep}  footerColumnGroup={footerGroupDep} 
                                dataKey="lib" className="p-datatable-gridlines" showGridlines responsiveLayout="scroll" paginator rows={5} paginatorPosition='top' >
                                    <Column field="lib" header="Mois" style={{ width: '16%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                    <Column field="sval1" body={(rowData, column) => {
                                        return <div>
                                            <span style={{ color: 'red' }}>{rowData.sval1}</span>
                                        </div>
                                    }} header="Salaires" style={{ width: '28%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                    <Column field="sval2" body={(rowData, column) => {
                                        return <div>
                                            <span style={{ color: 'red' }}>{rowData.sval2}</span>
                                        </div>
                                    }} header="Autres" style={{ width: '28%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                    <Column field="sval3" body={(rowData, column) => {
                                        return <div>
                                            <span style={{ color: 'red' }}>{rowData.sval3}</span>
                                        </div>
                                    }} header="Emprunts" style={{ width: '28%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                </DataTable>

                            </div>

                            <div className="col-12">
                                <i className="pi pi-check-square"></i>
                                <span style={{ fontSize: 14, marginLeft: 10, color: '#999999' }}>Situation par classe</span><hr />

                                <DataTable value={this.state.liste_detrcv} style={{ minHeight: '400px' }}  footerColumnGroup={footerGroupDet}
                                 dataKey="niveau" className="p-datatable-gridlines" showGridlines responsiveLayout="scroll" paginator rows={10}>
                                    <Column field="niveau" header="Classe" style={{ width: '22%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                    <Column field="nb" header="Nb" style={{ width: '6%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                    <Column field="fsprev_format" body={(rowData, column) => {
                                        return <div>
                                            <span style={{ color: '#000' }}>{rowData.fsprev_format}</span>
                                        </div>
                                    }} header="Prév_sco" style={{ width: '12%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                    <Column field="fsrecv_format" body={(rowData, column) => {
                                        return <div>
                                            <span style={{ color: 'green' }}>{rowData.fsrecv_format}</span>
                                        </div>
                                    }} header="Récv_sco" style={{ width: '12%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                    <Column field="fsrest_format" body={(rowData, column) => {
                                        return <div>
                                            <span style={{ color: 'red' }}>{rowData.fsrest_format}</span>
                                        </div>
                                    }} header="Rest_sco" style={{ width: '12%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                    <Column field="fiprev_format" body={(rowData, column) => {
                                        return <div>
                                            <span style={{ color: '#000' }}>{rowData.fiprev_format}</span>
                                        </div>
                                    }} header="Prév_ins" style={{ width: '12%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                    <Column field="firecv_format" body={(rowData, column) => {
                                        return <div>
                                            <span style={{ color: 'green' }}>{rowData.firecv_format}</span>
                                        </div>
                                    }} header="Récv_ins" style={{ width: '12%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                    <Column field="firest_format" body={(rowData, column) => {
                                        return <div>
                                            <span style={{ color: 'red' }}>{rowData.firest_format}</span>
                                        </div>
                                    }} header="Rest_ins" style={{ width: '12%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                </DataTable>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}