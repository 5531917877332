import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import './styles.css';
import { SplitButton } from 'primereact/splitbutton';
import  GestGemsysService from '../service/GestGemsysService';
import { ProgressSpinner } from 'primereact/progressspinner';

export class EditionEmploiTemps extends Component {
    constructor() {
        super();
        this.state = {
            pd1: new Date(),
            pd2: new Date(),
            rcode_classe: 'Tous',
            liste_data: [],
            selectedListes: [],
            showindicator: false,
            items: [
                {
                    label: 'Format PDF',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.conf_print('PDF');
                    }
                },
                {
                    label: 'Format XLS',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.conf_print('XLS');
                    }
                }
            ],
        };
        this.conf_print = this.conf_print.bind(this);
        this.onIndexTemplate = this.onIndexTemplate.bind(this);
        this.gemsysService = new GestGemsysService();
    }

    get_liste_classe() {
        this.setState({ showindicator: true });
        this.gemsysService.getClasse().then(data => {
            this.setState({ showindicator: false });
            this.setState({ liste_data: data });
        });
    }

    onIndexTemplate(data, props) {
        return props.rowIndex + 1;
    }


    conf_print(format) {
        let ids = "";
        if (this.state.selectedListes.length > 0) {
            ids = this.state.selectedListes.map(x => {
                return x.codeClasse
            }).join("_");
        } else {
            ids = this.state.liste_data.map(x => {
                return x.codeClasse
            }).join("_");
        }
        this.gemsysService.generer_emploi(ids, format);
    }

    componentDidMount() {
        this.get_liste_classe();
    }


    render() {
        const header = <>
            <SplitButton label="Rechercher" style={{ width: '140px' }} onClick={(e) => this.get_liste_classe()} icon="pi pi-search" model={this.state.items} />
            {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
        </>
        return (
            <div className="p-fluid">
                <div className="grid">
                    <div className="col-12">
                        <div className="card card-w-title">
                            <DataTable value={this.state.liste_data} style={{ minHeight: '600px' }}  ref={(el) => { this.dt = el; }}
                                onSelectionChange={e => this.setState({ selectedListes: e.value })}
                                selection={this.state.selectedListes} header={header}
                                className="p-datatable-gridlines" showGridlines responsiveLayout="scroll" paginator rows={10}>
                                <Column selectionMode="multiple" style={{ width: '5%' }} />
                                <Column body={this.onIndexTemplate} header="No" style={{ width: '5%' }} />
                                <Column field="codeEnseign.libEnseign" header="Enseignement" style={{ width: '35%' }} sortable={true} filter={true} filterMatchMode='contains' />
                                <Column field="libClasse" header="Classe" style={{ width: '20%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="optClasse" header="Option" style={{ width: '35%' }} filter={true} filterMatchMode='contains' sortable={true} />
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}