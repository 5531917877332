import React, { Component } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import GestGemsysService from '../service/GestGemsysService';
import { InputTextarea } from 'primereact/inputtextarea';
import './styles.css';
import moment from 'moment';
import { Calendar } from 'primereact/calendar';
import { SplitButton } from 'primereact/splitbutton';

export class FormMessage extends Component {
    constructor() {
        super();
        this.state = {
            rcodeClasse: 'Tous',
            rtype: 'Tous',
            rstatut: 'Tous',
            canal: 'SMS',
            idEvent: null,
            msgFil: '',
            liste_selection: [],
            liste_classe: [],
            liste_cible: [],
            liste_events: [],
            liste_msgs: [],
            liste_type_cible: [
                { value: 'Tous', label: 'CIBLE' },
                { value: 'ELEVE', label: 'ELEVES/ETUDIANTS' },
                { value: 'PERSONNEL', label: 'PERSONNEL' }
            ],
            liste_statut: [
                { value: 'Tous', label: 'Statut' },
                { value: 'Envoyé', label: 'Envoyé' },
                { value: 'En attente', label: 'En attente' },
                { value: 'Echec', label: 'Echec' }
            ],
            items: [
                {
                    label: 'Format CSV',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.dt.exportCSV();
                    }
                }
            ],
            selectedLine: null,
            msg: '',
            visible: false,
            showindicator: false
        };
        this.gemsysService = new GestGemsysService();
    }

    componentDidMount() {
        this.get_liste_classe();
        this.get_liste_event();
    }

    get_liste_cibles() {
        this.setState({ showindicator: true });
        this.gemsysService.get_liste_titulaire(this.state.rtype, this.state.rcodeClasse).then(data => {
            this.setState({ showindicator: false, liste_cible: data });
        });
    }

    get_liste_classe() {
        this.setState({ showindicator: true });
        this.gemsysService.getClasse().then(data => {
            this.setState({ showindicator: false });
            if (data !== null && data !== undefined && data !== '') {
                if (data.length > 0) {
                    let liste = data.map(x => {
                        return { value: x.codeClasse, label: x.libClasse + " " + x.optClasse }
                    });
                    this.setState({ liste_classe: this.state.liste_classe.concat(liste) });
                }
            }
        });
    }

    get_liste_event() {
        this.setState({ showindicator: true });
        this.gemsysService.get_liste_events().then(data => {
            this.setState({ showindicator: false });
            if (data !== null && data !== undefined && data !== '') {
                if (data.length > 0) {
                    let liste = data.map(x => {
                        return { value: x.idEvent, label: x.libEvent }
                    });
                    this.setState({ liste_events: liste });
                }
            }
        });
    }

    annuler_Msg() {
        this.setState({
            msgFil: '',
            visible: false
        })
    }


    exporter() {
        this.dt.exportCSV();
    }


    get_liste_fileMsg() {
        let spd1 = moment(this.state.rpd1).format('DD/MM/YYYY');
        let spd2 = moment(this.state.rpd2).format('DD/MM/YYYY');
        this.setState({ showindicator: true });
        this.gemsysService.get_liste_fileMsg(spd1, spd2, this.state.rtype, this.state.rcodeClasse, this.state.rstatut).then(data => this.setState({ liste_msgs: data, showindicator: false }));
    }


    resultat(code, contenu) {
        if (code == '200') {
            this.toast.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annuler_Msg()
            this.componentDidMount();
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({ showindicator: true });
    }



    enreg_Msg() {
        if (this.state.msgFil == "" || this.state.idEvent == null || this.state.idEvent == '') {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez renseigner toutes les informations obligatoires' });
        } else if (this.state.liste_selection.length > 0) {
            this.setState({ showindicator: true });
            let liste = [];
            for (var i = this.state.liste_selection.length; i--;) {
                let adr_Fil = 'GEMSYS';
                let adr_tuteur = 'GEMSYS';
                let adrIsvalide = false;
                let adrTiteurIsvalide = false;
                if (this.state.canal == 'Mail') {
                    adr_Fil = this.state.liste_selection[i].emailtitulaire;
                    adrIsvalide = this.gemsysService.validateEmail(adr_Fil);

                    if (this.state.liste_selection[i].typetitulaire == 'ELEVE') {
                        adr_tuteur = this.state.liste_selection[i].emailParenttitulaire;
                        adrTiteurIsvalide = this.gemsysService.validateEmail(adr_tuteur);
                    }

                } else if (this.state.canal == 'SMS') {
                    adr_Fil = this.state.liste_selection[i].teltitulaire;
                    adrIsvalide = this.gemsysService.validateTelephone(adr_Fil);

                    if (this.state.liste_selection[i].typetitulaire == 'ELEVE') {
                        adr_tuteur = this.state.liste_selection[i].telParenttitulaire;
                        adrTiteurIsvalide = this.gemsysService.validateTelephone(adr_tuteur);
                    }
                }
                if (adrIsvalide) {
                    let filMsg = {
                        idFil: this.state.liste_selection[i].id,
                        adrFil: adr_Fil,
                        etatFil: this.state.canal == 'Web' ? 'Envoyé' : 'En attente',
                        idEvent: { idEvent: this.state.idEvent },
                        msgFil: this.state.msgFil,
                        refFil: this.state.liste_selection[i].idtitulaire,
                        canalMsg: this.state.canal,
                        iduser: this.gemsysService.get_userconnected().numUtil,
                        nomCible: this.state.liste_selection[i].nomtitulaire,
                        refCible: this.state.liste_selection[i].reftitulaire,
                        librefCible: this.state.liste_selection[i].libreftitulaire,
                        cibleMsg: this.state.liste_selection[i].typetitulaire
                    }
                    liste.push(filMsg);
                };
                if (adrTiteurIsvalide) {
                    let filMsg = {
                        idFil: this.state.liste_selection[i].id + 'T',
                        adrFil: adr_tuteur,
                        etatFil: this.state.canal == 'Web' ? 'Envoyé' : 'En attente',
                        idEvent: { idEvent: this.state.idEvent },
                        msgFil: this.state.msgFil,
                        refFil: this.state.liste_selection[i].idtitulaire,
                        canalMsg: this.state.canal,
                        iduser: this.gemsysService.get_userconnected().numUtil,
                        nomCible: this.state.liste_selection[i].nomtitulaire,
                        refCible: this.state.liste_selection[i].reftitulaire,
                        librefCible: this.state.liste_selection[i].libreftitulaire,
                        cibleMsg: 'TUTEUR'
                    }
                    liste.push(filMsg);
                };
            }
            if (liste.length > 0) {
                this.gemsysService.AddFileMsg(liste).then(data => this.resultat(data.code, data.contenu));
            } else {
                this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Les adresses ne sont pas valides' });
            }
        } else {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner les destinataires' });
        }
    }

    photoTemplate(rowData, column) {
        let fic = rowData.photo;
        if (fic === undefined || fic === null || fic === '') {
            fic = 'nophoto.png';
        }
        return <img src={this.gemsysService.download_file(fic, 'photos/')} alt='Photo' style={{ width: 45, height: 45 }} />
    }

    render() {

        let canals = [
            { label: 'SMS', value: 'SMS' },
            { label: 'Mail', value: 'Mail' },
            { label: 'Web', value: 'Web' }
        ];

        const dialogFooter = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.enreg_Msg();
                    this.setState({ visible: false });
                }} label="Valider" />
                <Button icon="pi pi-times" onClick={() => this.annuler_Msg()} label="Annuler" className="p-button-secondary" />
            </div>
        );

        return (
            <div className="p-fluid">
                <Toast ref={(el) => this.toast = el} />
                <Dialog header="Nouveaux messages" visible={this.state.visible} style={{ width: '75%' }} modal={true} onHide={() => this.setState({ visible: false })} footer={dialogFooter}>
                    <div className="grid" style={{ marginTop: -10}}>
                        <div className="col-12 md:col-3">
                            <Dropdown options={this.state.liste_type_cible} placeholder="Sélectionner" value={this.state.rtype} onChange={(e) => this.setState({ rtype: e.value })} autoWidth={true} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' style={{width: '100%'}} />
                        </div>
                        {this.state.rtype == 'ELEVE' ?
                            <div className="col-12 md:col-3">
                                <Dropdown options={this.state.liste_classe} optionLabel='label' panelStyle={{ width: '400px' }} filter={true} filterBy='label' filterMatchMode='contains' placeholder="Selectionner classe" value={this.state.rcodeClasse} onChange={(e) => this.setState({ rcodeClasse: e.value })}  style={{width: '100%'}}  />
                            </div>
                            : null}
                        <div className="col-12 md:col-2">
                            <Button icon='pi pi-search' label='Rechercher' onClick={(e) => this.get_liste_cibles()} />
                        </div>
                        <div className="col-12 md:col-1">
                            {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                        </div>
                    </div>
                    <DataTable value={this.state.liste_cible} style={{ minHeight: '300px' }}
                        alwaysShowPaginator={false} paginator={true} rows={4} paginatorPosition="top"
                        onSelectionChange={e => this.setState({ liste_selection: e.value })}
                        selection={this.state.liste_selection} dataKey="idtitulaire" className="p-datatable-gridlines" showGridlines responsiveLayout="scroll">
                        <Column selectionMode="multiple" style={{ width: '5%' }} />
                        <Column body={this.photoTemplate.bind(this)} header="Photo" style={{ width: '5%' }} />
                        <Column field="idtitulaire" header="Matricule" style={{ width: '15%' }} filter={true} filterMatchMode='contains' />
                        <Column field="nomtitulaire" header="Nom" style={{ width: '25%' }} filter={true} filterMatchMode='contains' />
                        <Column field="teltitulaire" header="Telephone" style={{ width: '10%' }} filter={true} filterMatchMode='contains' />
                        <Column field="emailtitulaire" header="Email" style={{ width: '30%' }} filter={true} filterMatchMode='contains' />
                        <Column field="typetitulaire" header="Catégorie" style={{ width: '10%' }} filter={true} filterMatchMode='contains' />
                    </DataTable>

                    <div className="col-12" style={{ marginBottom: 0 }}>
                        <div className="grid">
                            <div className="col-12 md:col-4" >
                                <label htmlFor="canal">Canal *</label>
                                <Dropdown options={canals} value={this.state.canal} onChange={(e) => this.setState({ canal: e.target.value })}  style={{width: '100%'}} />
                            </div>

                            <div className="col-12 md:col-4" >
                                <label htmlFor="idEvent">Evènement *</label>
                                <Dropdown options={this.state.liste_events} value={this.state.idEvent} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ idEvent: e.target.value })} style={{width: '100%'}}  />
                            </div>

                            <div className="col-12 md:col-4" >
                                <label htmlFor="msgFil">Message *</label><br/>
                                <InputTextarea id="msgFil" rows={2} cols={30} placeholder="Saisir le message" value={this.state.msgFil} onChange={(e) => this.setState({ msgFil: e.target.value })} />
                            </div>
                        </div>

                    </div>

                </Dialog>


                <div className="card card-w-title">
                    <i className="pi pi-search"></i>
                    <span style={{ fontSize: 14, marginLeft: 10, color: '#999999' }}>Options de recherche  <Button label="Nouveau message" style={{ width: 200, float: 'right' }} icon="pi pi-plus" onClick={() => this.setState({ visible: true })} className="p-button-warning" /></span><hr />
                    <div className="grid">
                        <div className="col-12 md:col-2">
                            <Calendar id="pd1" placeholder={moment(new Date()).format('DD/MM/YYYY')} dateFormat="dd/mm/yy" value={this.state.pd1} onChange={(e) => this.setState({ pd1: e.target.value })} />
                        </div>
                        <div className="col-12 md:col-2">
                            <Calendar id="pd2" placeholder={moment(new Date()).format('DD/MM/YYYY')} dateFormat="dd/mm/yy" value={this.state.pd2} onChange={(e) => this.setState({ pd2: e.target.value })} />
                        </div>
                        <div className="col-12 md:col-2">
                            <Dropdown options={this.state.liste_type_cible} placeholder="Sélectionner" value={this.state.rtype} onChange={(e) => this.setState({ rtype: e.value })} autoWidth={false} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' />
                        </div>
                        <div className="col-12 md:col-2">
                            <Dropdown options={this.state.liste_statut} placeholder="Sélectionner" value={this.state.rstatut} onChange={(e) => this.setState({ rstatut: e.value })} autoWidth={false} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' />
                        </div>
                        {this.state.rtype == 'ELEVE' ?
                            <div className="col-12 md:col-2">
                                <Dropdown options={this.state.liste_classe} optionLabel='label' panelStyle={{ width: '400px' }} filter={true} filterBy='label' filterMatchMode='contains' placeholder="Selectionner classe" value={this.state.rcodeClasse} onChange={(e) => this.setState({ rcodeClasse: e.value })} autoWidth={false} />
                            </div>
                            : null}
                        <div className="col-12 md:col-2">
                            {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> :
                                <SplitButton label="Rechercher" style={{ width: '170px' }} onClick={(e) => this.get_liste_fileMsg()} icon="pi pi-search" model={this.state.items} />}
                        </div>

                    </div>
                    <DataTable value={this.state.liste_msgs} style={{ minHeight: '600px' }}
                        alwaysShowPaginator={false} paginator={true} rows={50} paginatorPosition="top" ref={(el) => { this.dt = el; }}
                        onRowClick={e => this.setState({ selectedLine: e.data })} dataKey="ordre" className="p-datatable-gridlines" showGridlines responsiveLayout="scroll"
                        selection={this.state.selectedLine} >
                        <Column field="denregFil" header="Date" style={{ width: '8%' }} filter={true} filterMatchMode='contains' sortable={true} />
                        <Column field="refFil" header="Matricule" style={{ width: '10%' }} filter={true} filterMatchMode='contains' sortable={true} />
                        <Column field="nomCible" header="Destinataire" style={{ width: '22' }} filter={true} filterMatchMode='contains' sortable={true} />
                        <Column field="librefCible" header="Référence" style={{ width: '20%' }} filter={true} filterMatchMode='contains' sortable={true} />
                        <Column field="adrFil" header="Adresse" style={{ width: '19%' }} filter={true} filterMatchMode='contains' sortable={true} />
                        <Column field="canalMsg" header="Canal" style={{ width: '7%' }} filter={true} filterMatchMode='contains' sortable={true} />
                        <Column field="etatFil" header="Statut" style={{ width: '8%' }} filter={true} filterMatchMode='contains' sortable={true} />
                        <Column field="cibleMsg" header="Cible" style={{ width: '8%' }} filter={true} filterMatchMode='contains' sortable={true} />
                    </DataTable>
                </div>
            </div>

        );
    }
}
