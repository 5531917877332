import React, { Component } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import './styles.css';
import { Calendar } from 'primereact/calendar';
import { SplitButton } from 'primereact/splitbutton';
import GestGemsysService from '../service/GestGemsysService';
import { ProgressSpinner } from 'primereact/progressspinner';
import moment from 'moment';
import { Row } from 'primereact/row';
import { ColumnGroup } from 'primereact/columngroup';

export class EditionReçus extends Component {
    constructor() {
        super();
        this.state = {
            pd1: new Date(),
            pd2: new Date(),
            rcode_classe: 'Tous',
            liste_data: [],
            selectedListes: [],
            total: 0,
            liste_classe: [],
            liste_eleve: [],
            rlibelle: 'Tous',
            rcode_classe: 'Tous',
            rmatricule: 'Tous',
            showindicator: false,
            items: [
                {
                    label: 'Générer reçu',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.conf_print();
                    }
                }
            ]
        }
        this.conf_print = this.conf_print.bind(this);
        this.onIndexTemplate = this.onIndexTemplate.bind(this);
        this.gemsysService = new GestGemsysService();
    }

    get_liste_classe() {
        this.setState({ showindicator: true });
        this.gemsysService.getClasse().then(data => {
            this.setState({ showindicator: false });
            if (data !== null && data !== undefined && data !== '') {
                if (data.length > 0) {
                    let liste = data.map(x => {
                        return { value: x.codeClasse, label: x.libClasse + " " + x.optClasse }
                    });
                    this.setState({ liste_classe: this.state.liste_classe.concat(liste) });
                }
            }
        });
    }

    get_liste_data() {
        let spd1 = moment(this.state.pd1).format('DD/MM/YYYY');
        let spd2 = moment(this.state.pd2).format('DD/MM/YYYY');
        this.setState({
            showindicator: true,
            total: 0,
        });
        this.gemsysService.get_liste_recu(spd1, spd2, this.state.rcode_classe, this.state.rmatricule, this.state.rlibelle).then(data => {
            this.setState({ liste_data: data, showindicator: false });
            if (data !== null && data !== undefined) {
                if (data.length > 0) {
                    let initialValue = 0;
                    let tt = data.reduce(function (accumulator, currentValue) {
                        return accumulator + currentValue.mt_recu
                    }, initialValue);
                    this.setState({
                        total: this.gemsysService.formaterValueSep(tt)
                    });
                }
            }
        });
    }

    conf_print() {
        let ids = "";
        if (this.state.selectedListes.length > 0) {
            ids = this.state.selectedListes.map(x => {
                return x.num_recu
            }).join("_");
        } else {
            ids = this.state.liste_data.map(x => {
                return x.num_recu
            }).join("_");
        }
        this.setState({ showindicator: true });
        this.gemsysService.generer_liste_recu(ids).then(reponse => {
            this.setState({ showindicator: false });
        });
    }

    componentDidMount() {
        this.get_liste_classe();
        this.get_liste_data();
    }

    onIndexTemplate(data, props) {
        return props.rowIndex + 1;
    }

    get_liste_eleve_by_classe(codeClasse) {
        this.setState({ showindicator: true });
        this.gemsysService.getliste_eleve_by_inscrit(codeClasse).then(data => {
            this.setState({ showindicator: false });
            if (data !== null && data !== undefined && data !== '') {
                if (data.length > 0) {
                    let liste = data.map(x => {
                        return { value: x.matricule.matricule, label: x.matricule.nomEleve + ' ' + x.matricule.prenomEleve }
                    });
                    this.setState({ liste_eleve: this.state.liste_eleve.concat(liste) });
                }
            }
        });
    }

    render() {

        let liste_libelle = [
            { label: 'Libelle', value: 'Tous' },
            { label: 'Frais scolarité', value: 'Frais scolarité' },
            { label: 'Frais inscription', value: 'Frais inscription' },
            { label: 'Frais réinscription', value: 'Frais réinscription' },
            { label: 'Frais badge', value: 'Frais badge' },
            { label: 'Frais APEAE', value: 'Frais APEAE' },
            { label: 'Frais Cyber', value: 'Frais Cyber' },
            { label: 'Ancien bon', value: 'Ancien bon' },
            { label: 'Autre recette', value: 'Autre recette' }
        ];

        let footerGroup = <ColumnGroup>
            <Row>
                <Column footer="TOTAL" colSpan={8} footerStyle={{ textAlign: 'center', fontWeight: 'bold' }} />
                <Column footer={this.state.total} style={{ color: '#000000', fontWeight: 'bold' }} />
            </Row>
        </ColumnGroup>;

        return (
            <div className="p-fluid">
                <div className="grid">
                    <div className="col-12">
                        <div className="card card-w-title">
                            <i className="pi pi-search"></i>
                            <span style={{ fontSize: 14, marginLeft: 10, color: '#999999' }}>Options de recherche</span>
                            <div className="grid">
                                <div className="col-12 md:col-2">
                                    <Calendar id="pd1" dateFormat="dd/mm/yy" value={this.state.pd1} onChange={(e) => this.setState({ pd1: e.target.value })} />
                                </div>
                                <div className="col-12 md:col-2">
                                    <Calendar id="pd2" dateFormat="dd/mm/yy" value={this.state.pd2} onChange={(e) => this.setState({ pd2: e.target.value })} />
                                </div>
                                <div className="col-12 md:col-2">
                                    <Dropdown options={liste_libelle} value={this.state.rlibelle} onChange={(e) => this.setState({ rlibelle: e.value })} autoWidth={false} />
                                </div>
                                <div className="col-12 md:col-2">
                                    <Dropdown options={this.state.liste_classe} value={this.state.rcode_classe} onChange={(e) => this.setState({ rcode_classe: e.value }, () => {
                                        this.get_liste_eleve_by_classe(this.state.rcode_classe);
                                    })} autoWidth={false} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' placeholder='Classe' />
                                </div>
                                <div className="col-12 md:col-2">
                                    <Dropdown options={this.state.liste_eleve} value={this.state.rmatricule} onChange={(e) => this.setState({ rmatricule: e.value })} autoWidth={false} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' placeholder='Source' panelStyle={{ width: '350px' }} />
                                </div>
                                <div className="col-12 md:col-2">
                                    <>
                                        {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> :
                                            <SplitButton label="Rechercher" style={{ width: '140px' }} onClick={(e) => this.get_liste_data()} icon="pi pi-search" model={this.state.items} />
                                        }
                                    </>
                                </div>
                            </div>
                            <DataTable value={this.state.liste_data} style={{ minHeight: '600px' }}
                                dataKey="ordre" className="p-datatable-gridlines" showGridlines responsiveLayout="scroll" paginator rows={30}
                                onSelectionChange={e => this.setState({ selectedListes: e.value })}
                                selection={this.state.selectedListes} footerColumnGroup={footerGroup}  >
                                <Column selectionMode="multiple" style={{ width: '5%' }} />
                                <Column body={this.onIndexTemplate} header="No" style={{ width: '5%' }} />
                                <Column field="num_recu" header="No reçu" style={{ width: '10%' }} />
                                <Column field="matrEtud" header="Matricule" style={{ width: '10%' }} />
                                <Column field="nomEtud" header="Nom" style={{ width: '10%' }} />
                                <Column field="prenomEtu" header="Prénoms" />
                                <Column field="date_recu" header="Date" style={{ width: '8%' }} />
                                <Column field="lib_recu" header="Libellé" style={{ width: '20%' }} />
                                <Column field="mt_recu" header="Montant" body={(rowData, column) => {
                                    return <div>
                                        <span style={{ color: '#000', fontWeight: 'bold' }}>{this.gemsysService.formaterValueSep(parseFloat(rowData.mt_recu))}</span>
                                    </div>
                                }} style={{ width: '12%' }} />
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}