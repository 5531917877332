import React, { Component } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import './styles.css';
import { SplitButton } from 'primereact/splitbutton';
import  GestGemsysService  from '../service/GestGemsysService';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Row } from 'primereact/row';
import { ColumnGroup } from 'primereact/columngroup';
import { MultiSelect } from 'primereact/multiselect';

export class EditionEtatPaie extends Component {
    constructor() {
        super();
        this.state = {
            rlibelle: 'Tous',
            retat: 'Tous',
            rmatrPers: 'Tous',
            rcpb: 'Tous',
            liste_data: [],
            liste_pers: [{ label: 'Personnel', value: 'Tous' }],
            listeSelecteds: [],
            liste_mois: [
                { label: 'Janvier', value: '01' },
                { label: 'Février', value: '02' },
                { label: 'Mars', value: '03' },
                { label: 'Avril', value: '04' },
                { label: 'Mai', value: '05' },
                { label: 'Juin', value: '06' },
                { label: 'Juillet', value: '07' },
                { label: 'Août', value: '08' },
                { label: 'Septembre', value: '09' },
                { label: 'Octobre', value: '10' },
                { label: 'Novembre', value: '11' },
                { label: 'Décembre', value: '12' }
            ],
            totalBon: 0,
            salairebrut: 0,
            salaireNet: 0,
            showindicator: false,
            items: [
                {
                    label: 'Format PDF',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.conf_print('PDF');
                    }
                },
                {
                    label: 'Format XLS',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.conf_print('XLS');
                    }
                },
                {
                    label: 'Format CSV',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.dt.exportCSV();
                    }
                }
            ],
        };
        this.conf_print = this.conf_print.bind(this);
        this.onIndexTemplate = this.onIndexTemplate.bind(this);
        this.gemsysService = new GestGemsysService();
    }

    onIndexTemplate(data, props) {
        return props.rowIndex + 1;
    }

    get_liste_personnel() {
        this.setState({ showindicator: true });
        this.gemsysService.getPersonnel().then(data => {
            this.setState({ showindicator: false });
            if (data !== null && data !== undefined && data !== '') {
                if (data.length > 0) {
                    let liste = data.map(x => {
                        return { value: x.matrPers, label: x.prenomPers + " " + x.nomPers, categoriePers: x.categorie }
                    });
                    this.setState({ liste_pers: this.state.liste_pers.concat(liste) });
                }
            }
        });
    }

    get_liste_data() {
        let lmois = "";
        if (this.state.listeSelecteds.length > 0) {
            lmois = this.state.listeSelecteds.map(x => {
                return x
            }).join("_");
        } else {
            lmois = this.state.liste_mois.map(x => {
                return x.value
            }).join("_");
        };
        this.setState({
            showindicator: true,
            totalBon: 0,
            salairebrut: 0,
            salaireNet: 0,
        });
        this.gemsysService.get_liste_etat_paie(this.state.rmatrPers, lmois, this.state.retat, this.state.rlibelle, this.state.rcpb).then(data => {
            this.setState({ liste_data: data, showindicator: false });
            if (data !== null && data !== undefined) {
                if (data.length > 0) {
                    let initialValue = 0;
                    let totalBon = data.reduce(function (accumulator, currentValue) {
                        return accumulator + currentValue.totalBon
                    }, initialValue);
                    let salairebrut = data.reduce(function (accumulator, currentValue) {
                        return accumulator + currentValue.salairebrut
                    }, initialValue);
                    let salaireNet = data.reduce(function (accumulator, currentValue) {
                        return accumulator + currentValue.salaireNet
                    }, initialValue);
                    this.setState({
                        salaireNet: this.gemsysService.formaterValueSep(salaireNet),
                        totalBon: this.gemsysService.formaterValueSep(totalBon),
                        salairebrut: this.gemsysService.formaterValueSep(salairebrut),
                    });
                }
            }
        });
    }

    conf_print(format) {
        let lmois = "";
        if (this.state.listeSelecteds.length > 0) {
            lmois = this.state.listeSelecteds.map(x => {
                return x
            }).join("_");
        } else {
            lmois = this.state.liste_mois.map(x => {
                return x.value
            }).join("_");
        };
        this.gemsysService.generer_etat_paie(this.state.rmatrPers, lmois, this.state.retat, this.state.rlibelle, this.state.rcpb, format);
    }

    componentDidMount() {
        this.get_liste_personnel();
        this.get_liste_data();
    }

    render() {

        let liste_libelle = [
            { label: 'Libelle', value: 'Tous' },
            { label: 'Primes de fonction', value: 'Primes de fonction' },
            { label: 'Salaire enseignant', value: 'Salaire enseignant' }
        ];
        let liste_etats = [
            { label: 'Etat', value: 'Tous' },
            { label: 'Payé', value: 'Oui' },
            { label: 'Non Payé', value: 'Non' }
        ];

        let liste_cpb = [
            { label: 'Numéro de compte', value: 'Tous' },
            { label: 'Ayant compte bancaire', value: 'Ayant compte bancaire' },
            { label: 'Pas de compte bancaire', value: 'Pas de compte bancaire' }
        ];

        let footerGroup = <ColumnGroup>
            <Row>
                <Column footer="TOTAUX" colSpan={6} footerStyle={{ textAlign: 'center', fontWeight: 'bold' }} />
                <Column footer={this.state.totalBon} style={{ color: 'red', fontWeight: 'bold' }} />
                <Column footer={this.state.salairebrut} style={{ color: '#000', fontWeight: 'bold' }} />
                <Column footer={this.state.salaireNet} style={{ color: 'green', fontWeight: 'bold' }} />
            </Row>
        </ColumnGroup>;

        return (
            <div className="p-fluid">
                <div className="grid">
                    <div className="col-12">
                        <div className="card card-w-title">
                            <i className="pi pi-search"></i>
                            <span style={{ fontSize: 14, marginLeft: 10, color: '#999999' }}>Options de recherche</span>
                            <div className="grid">
                                <div className="col-12 md:col-2" >
                                    <MultiSelect options={this.state.liste_mois} display="chip" value={this.state.listeSelecteds} onChange={(e) => this.setState({ listeSelecteds: e.value })} optionLabel="label" filter={true} filterBy='label' filterMatchMode='contains' placeholder="Mois" autoWidth={false} appendTo={document.body} />
                                </div>
                                <div className="col-12 md:col-2">
                                    <Dropdown options={this.state.liste_pers} value={this.state.rmatrPers} optionLabel='label' filter={true} filterBy='label,value' filterMatchMode='contains' onChange={(e) => this.setState({ rmatrPers: e.target.value }, () => {
                                        this.get_liste_data();
                                    })} itemTemplate={(option) => {
                                        if (!option.value) {
                                            return option.label;
                                        }
                                        else {
                                            return (
                                                <div className="grid" >
                                                    <div className="col-12 md:col-3">
                                                        <label>{option.value}</label>
                                                    </div>
                                                    <div className="col-12 md:col-9">
                                                        <label>{option.label}</label>
                                                    </div>
                                                </div>
                                            );
                                        }
                                    }} autoWidth={false} panelStyle={{ width: '500px' }} />
                                </div>
                                <div className="col-12 md:col-2" >
                                    <Dropdown options={liste_libelle} placeholder="Libelle" value={this.state.rlibelle} onChange={(e) => this.setState({ rlibelle: e.target.value })} autoWidth={false} />
                                </div>
                                <div className="col-12 md:col-2" >
                                    <Dropdown options={liste_etats} placeholder="Etat" value={this.state.retat} onChange={(e) => this.setState({ retat: e.target.value })} autoWidth={false} />
                                </div>
                                <div className="col-12 md:col-2" >
                                    <Dropdown options={liste_cpb} placeholder="Compte bancaire" value={this.state.rcpb} onChange={(e) => this.setState({ rcpb: e.target.value })} autoWidth={false} />
                                </div>
                                <div className="col-12 md:col-2">
                                    <SplitButton label="Rechercher" style={{ width: '140px' }} onClick={(e) => this.get_liste_data()} icon="pi pi-search" model={this.state.items} />
                                    {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                </div>
                            </div>

                            <DataTable value={this.state.liste_data} style={{ minHeight: '600px' }}  ref={(el) => { this.dt = el; }}
                                footerColumnGroup={footerGroup} dataKey="ordre" className="p-datatable-gridlines" showGridlines responsiveLayout="scroll" paginator rows={10}>
                                <Column field="ordre" header="#" style={{ width: '4%' }} />
                                <Column field="matrPers" header="Matricule" style={{ width: '10%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="nomPers" header="Nom" style={{ width: '10%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="prenomPers" header="Prénoms" style={{ width: '20%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="libpaie" header="Libelle" style={{ width: '18%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="moisPaie" header="Mois" style={{ width: '7%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="totalBon" body={(rowData, Column) => {
                                    return <div>
                                        <span style={{ color: 'red' }}>{this.gemsysService.formaterValueSep(parseFloat(rowData.totalBon))}</span>
                                    </div>
                                }} header="Bon" style={{ width: '10%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="salairebrut" body={(rowData, Column) => {
                                    return <div>
                                        <span style={{ color: '#000' }}>{this.gemsysService.formaterValueSep(parseFloat(rowData.salairebrut))}</span>
                                    </div>
                                }} header="Salaire_brut" style={{ width: '11%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="salaireNet" body={(rowData, Column) => {
                                    return <div>
                                        <span style={{ color: 'green' }}>{this.gemsysService.formaterValueSep(parseFloat(rowData.salaireNet))}</span>
                                    </div>
                                }} header="Mt_payé" style={{ width: '10%' }} filter={true} filterMatchMode='contains' sortable={true} />

                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}