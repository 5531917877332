import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import GestGemsysService from '../service/GestGemsysService';
import { InputText } from 'primereact/inputtext';
import { Panel } from 'primereact/panel';
import { Calendar } from 'primereact/calendar';
import moment from 'moment';
import { InputNumber } from 'primereact/inputnumber';

export class FormMatiere extends Component {

    constructor() {
        super();
        this.state = {
            codemat: '',
            libmat: '',
            coeff: 1,
            prixHoraire: 0,
            nbHeure: 0,
            libmat2: '',
            codeClasse: null,
            nbCredit: 0,
            minSession: 0,
            minReprise: 0,
            idEtabl: 1,
            iduser: '1',
            eval1: 'Oui',
            eval2: 'Oui',
            eval3: 'Oui',
            pd1: 0.0,
            pd2: 0.0,
            pd3: 0.0,
            idEns: 0,
            dateDebEns: new Date(),
            dateFinEns: new Date(),
            nbEns: 0,
            denregEns: new Date(),
            matrPers: null,
            codemat: null,
            prixHoraireEns: 0,
            selectedMat: null,
            selectedEnseigner: null,
            matieres: [],
            liste_classe: [],
            personnels: [],
            msg: '',
            modif: false,
            visible: false,
            modifEns: false,
            visibleEns: false,
            liste_eval: [
                { value: 'Oui', label: 'Oui' },
                { value: 'Non', label: 'Non' },
            ],
            showindicator: false
        };
        this.edit_matiere = this.edit_matiere.bind(this);
        this.enreg_matiere = this.enreg_matiere.bind(this);
        this.conf_delete_matiere = this.conf_delete_matiere.bind(this);
        this.gemsysService = new GestGemsysService();
    }


    componentDidMount() {
        this.setState({ showindicator: true });
        this.gemsysService.getClasse().then(data => {
            this.setState({ showindicator: false });
            let liste_classe = null;
            if (data != null && data != undefined) {
                if (data.length > 0) {
                    let lc = this.gemsysService.get_userconnected().idEtabl.typeEtabl == 'Université' ? 'S' : '';
                    liste_classe = data.map(x => {
                        return { value: x.codeClasse, label: lc + x.libClasse + " " + x.optClasse }
                    });
                    this.setState({ liste_classe: liste_classe }, () => {
                        this.setState({ codeClasse: liste_classe[0].value }, () => {
                            this.get_liste_matiere(this.state.codeClasse);
                        });
                    });
                }
            }
        });
        this.gemsysService.getPersonnel().then(data => this.setState({ personnels: data }));
    }




    get_liste_matiere(codeClasse) {
        this.gemsysService.getMatiere_by_classe(codeClasse).then(data => this.setState({ matieres: data }));
    }



    resultat(code, contenu) {
        if (code == '200') {
            this.toast.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annuler_matiere();
            this.get_liste_matiere(this.state.codeClasse);
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({ showindicator: false });
    }

    resultatEns(code, contenu) {
        if (code == '200') {
            this.toast.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annuler_enseigner();
            this.get_liste_enseigenr_by_matiere();
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({ showindicator: false });
    }

    edit_matiere(data) {
        if (data != null && data != undefined) {
            this.setState({ codemat: data.codemat });
            this.setState({ libmat: data.libmat });
            this.setState({ coeff: data.coeff });
            this.setState({ prixHoraire: data.prixHoraire });
            this.setState({ nbHeure: data.nbHeure });
            this.setState({ libmat2: data.libmat2 });
            this.setState({ annee: data.annee });
            this.setState({ nbCredit: data.nbCredit });
            this.setState({ codeClasse: data.codeClasse.codeClasse });
            this.setState({ minSession: data.minSession });
            this.setState({ minReprise: data.minReprise });
            this.setState({ eval1: data.eval1 });
            this.setState({ eval2: data.eval2 });
            this.setState({ eval3: data.eval3 });

            this.setState({ pd1: data.pd1 });
            this.setState({ pd2: data.pd2 });
            this.setState({ pd3: data.pd3 });

            this.setState({ selectedMat: data });
            this.setState({ modif: true });
        }
    }
    edit_enseigner(data) {
        if (data != null && data != undefined) {
            this.setState({ idEns: data.idEns });
            this.setState({ dateDebEns: new Date(moment(data.dateDebEns, 'DD/MM/YYYY').format('YYYY-MM-DD')) });
            this.setState({ dateFinEns: new Date(moment(data.dateFinEns, 'DD/MM/YYYY').format('YYYY-MM-DD')) });
            this.setState({ nbEns: data.nbEns });
            this.setState({ prixHoraireEns: data.prixHoraireEns });
            this.setState({ matrPers: data.matrPers.matrPers });
            this.setState({ codemat: data.codemat.codemat });
            this.setState({ modifEns: true });
        }
    }
    annuler_enseigner() {
        this.setState({ idEns: 0 });
        this.setState({ dateDebEns: "" });
        this.setState({ dateFinEns: "" });
        this.setState({ nbEns: 0 });
        this.setState({ prixHoraireEns: 0 });
        this.setState({ matrPers: null });
        this.setState({ codemat: null });
        this.setState({ modif: false });
        this.forceUpdate();
    }

    annuler_matiere() {
        this.setState({ codemat: '' });
        this.setState({ libmat: '' });
        this.setState({ coeff: '' });
        this.setState({ prixHoraire: '' });
        this.setState({ nbHeure: '' });
        this.setState({ libmat2: '' });
        this.setState({ nbCredit: 0 });
        //this.setState({ codeClasse: '' });
        this.setState({ minSession: 0 });
        this.setState({ minReprise: 0 });
        this.setState({ eval1: 'Oui' });
        this.setState({ eval2: 'Oui' });
        this.setState({ eval3: 'Oui' });
        this.setState({ pd1: 0 });
        this.setState({ pd2: 0 });
        this.setState({ pd3: 0 });
        this.setState({ modif: false });
        this.forceUpdate();
    }
    enreg_matiere() {
        if (this.state.libmat == "" || this.state.coeff === "" || this.state.prixHoraire === "" || this.state.nbHeure === ""
            || this.state.libmat2 == "" || this.state.nbCredit === "" || this.state.minSession === "" || this.state.codeClasse === "") {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else {
            this.setState({ showindicator: true });
            if (this.state.modif == false) {
                let matiere = {
                    codemat: '0',
                    libmat: this.state.libmat,
                    coeff: this.state.coeff,
                    prixHoraire: this.state.prixHoraire,
                    nbHeure: this.state.nbHeure,
                    libmat2: this.state.libmat2,
                    annee: this.gemsysService.get_rannee(),
                    codeClasse: { codeClasse: this.state.codeClasse },
                    nbCredit: this.state.nbCredit,
                    minSession: this.state.minSession,
                    minReprise: this.state.minReprise,
                    eval1: this.state.eval1,
                    eval2: this.state.eval2,
                    eval3: this.state.eval3,
                    pd1: this.state.pd1,
                    pd2: this.state.pd2,
                    pd3: this.state.pd3,
                };
                this.gemsysService.AddMatiere(matiere).then(data => this.resultat(data.code, data.contenu));
            } else {
                let matiere = this.state.selectedMat;
                matiere.codemat = this.state.codemat;
                matiere.libmat = this.state.libmat;
                matiere.coeff = this.state.coeff;
                matiere.prixHoraire = this.state.prixHoraire;
                matiere.nbHeure = this.state.nbHeure;
                matiere.libmat2 = this.state.libmat2;
                matiere.nbCredit = this.state.nbCredit;
                matiere.minSession = this.state.minSession;
                matiere.eval1 = this.state.eval1;
                matiere.eval2 = this.state.eval2;
                matiere.eval3 = this.state.eval3;
                matiere.pd1 = this.state.pd1;
                matiere.pd2 = this.state.pd2;
                matiere.pd3 = this.state.pd3;
                matiere.minReprise = this.state.minReprise;
                matiere.codeClasse = { codeClasse: this.state.codeClasse };
                this.gemsysService.UpdateMatiere(matiere).then(data => this.resultat(data.code, data.contenu));
            }
        }
    }
    enreg_enseigner() {
        if (this.state.dateDebEns == "" || this.state.selectedMat == null
            || this.state.selectedMat == undefined || this.state.matrPers == "") {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else {
            let date_fin = moment(this.state.dateFinEns, 'DD/MM/YYYY').format('DD/MM/YYYY');
            if (date_fin == 'Invalid date') {
                date_fin = null;
            }
            this.setState({ showindicator: true });
            if (this.state.modifEns == false) {
                let enseigner = {
                    idEns: '0',
                    dateDebEns: moment(this.state.dateDebEns, 'DD/MM/YYYY').format('DD/MM/YYYY'),
                    dateFinEns: date_fin,
                    nbEns: this.state.nbEns,
                    matrPers: { matrPers: this.state.matrPers },
                    codemat: { codemat: this.state.selectedMat.codemat },
                    prixHoraireEns: this.state.prixHoraireEns
                };
                this.gemsysService.AddEnseigner(enseigner).then(data => this.resultatEns(data.code, data.contenu));
            } else {
                let enseigner = this.state.selectedEnseigner;
                enseigner.dateDebEns = moment(this.state.dateDebEns, 'DD/MM/YYYY').format('DD/MM/YYYY');
                enseigner.dateFinEns = date_fin;
                enseigner.nbEns = this.state.nbEns;
                enseigner.matrPers = { matrPers: this.state.matrPers };
                enseigner.codemat = this.state.selectedMat;
                enseigner.prixHoraireEns = this.state.prixHoraireEns;
                this.gemsysService.UpdateEnseigner(enseigner).then(data => this.resultatEns(data.code, data.contenu));
            }
        }
    }
    get_liste_enseigenr_by_matiere() {
        if (this.state.selectedMat !== null && this.state.selectedMat !== undefined) {
            this.gemsysService.getEnseigner_by_matiere(this.state.selectedMat.codemat).then(data => this.setState({ liste_enseigner: data }));
            this.setState({ prixHoraireEns: this.state.selectedMat.prixHoraire });
        }
    }
    actionTemplate(rowData) {
        return <div className="grid" style={{ width: 90 }}>
            <div className="col"><Button onClick={() => this.edit_matiere(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
            <div className="col"><Button onClick={() => this.conf_delete_matiere(rowData)} className="p-button-danger" style={{ width: 25, height: 25, marginLeft: -20 }} icon="pi pi-times" /></div>
        </div>
    }
    actionTemplateEns(rowData) {
        return <div className="grid" style={{ width: 90 }}>
            <div className="col"><Button onClick={() => this.edit_enseigner(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
            <div className="col"><Button onClick={() => this.conf_delete_enseigner(rowData)} className="p-button-danger" style={{ width: 25, height: 25, marginLeft: -20 }} icon="pi pi-times" /></div>
        </div>
    }
    delete_matiere() {
        if (this.state.selectedMat === null || this.state.selectedMat === undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.setState({ showindicator: true });
            this.gemsysService.DeleteMatiere(this.state.selectedMat).then(data => this.resultat(data.code, data.contenu));
        }
    }
    delete_enseigner() {
        if (this.state.selectedEns === null || this.state.selectedEns === undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.setState({ showindicator: true });
            this.gemsysService.DeleteEnseigner(this.state.selectedEns).then(data => this.resultatEns(data.code, data.contenu));
        }
    }
    conf_delete_matiere(data) {
        if (data != null && data != undefined) {
            this.setState({ selectedMat: data });
            this.setState({ visible: true });
        }
    }
    conf_delete_enseigner(data) {
        if (data != null && data != undefined) {
            this.setState({ selectedEns: data });
            this.setState({ msg: data.idEns });
            this.setState({ visibleEns: true });
        }
    }

    onIndexTemplate(data, props) {
        return props.rowIndex + 1;
    }

    render() {

        let personnels = null;
        if (this.state.personnels != null && this.state.personnels != undefined) {
            personnels = this.state.personnels.map(x => {
                return { value: x.matrPers, label: x.nomPers + ' ' + x.prenomPers, categoriePers: x.categorie, actif: x.estEnFonction }
            });
        }
        const dialogFooter = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible: false });
                    this.delete_matiere();
                }} label="Oui" />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );
        const dialogFooterEns = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visibleEns: false });
                    this.delete_enseigner();
                }} label="Oui" loading={this.state.showindicator} />
                <Button icon="pi pi-times" onClick={() => this.setState({ visibleEns: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );
        return (
            <div className="p-fluid">
                <Toast ref={(el) => this.toast = el} />
                <Dialog header="Confirmation" visible={this.state.visible} style={{ width: '250px' }} modal={true} footer={dialogFooter} onHide={() => this.setState({ visible: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>
                <Dialog header="Confirmation" visible={this.state.visibleEns} style={{ width: '250px' }} modal={true} footer={dialogFooterEns} onHide={() => this.setState({ visibleEns: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>
                <div className="grid">
                    <div className="col-12">
                        <div className="card card-w-title">
                            <i className="pi pi-sitemap"></i> Gestion des matières<hr />
                            <div className="grid">
                                <div className="col-12 md:col-2">
                                    <label htmlFor="libmat">Classe *</label>
                                </div>
                                <div className="col-12 md:col-4">
                                    <Dropdown id="codeClasse" options={this.state.liste_classe} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' value={this.state.codeClasse} onChange={e => this.setState({ codeClasse: e.value }, () => {
                                        this.get_liste_matiere(this.state.codeClasse);
                                    })} autoWidth={false} panelStyle={{ width: '400px' }} />
                                </div>
                                <div className="col-12 md:col-2">
                                    <label htmlFor="libmat">Libelle *</label>
                                </div>
                                <div className="col-12 md:col-4">
                                    <InputText id="libmat" value={this.state.libmat} onChange={(e) => this.setState({ libmat: e.target.value })} />
                                </div>
                                <div className="col-12 md:col-2">
                                    <label htmlFor="libmat2">Libelle Abregé *</label>
                                </div>
                                <div className="col-12 md:col-4">
                                    <InputText id="libmat2" value={this.state.libmat2} onChange={(e) => this.setState({ libmat2: e.target.value })} />
                                </div>
                                <div className="col-12 md:col-2">
                                    <label htmlFor="coeff">Coefficient *</label>
                                </div>
                                <div className="col-12 md:col-4">
                                    <InputText id="coeff" value={this.state.coeff} onChange={(e) => this.setState({ coeff: e.target.value })} />
                                </div>
                                {this.gemsysService.get_userconnected().idEtabl.typeEtabl == 'Université' ? <div className="col-12 md:col-2">
                                    <label htmlFor="nbCredit">Nombre de credits *</label>
                                </div> : null}
                                {this.gemsysService.get_userconnected().idEtabl.typeEtabl == 'Université' ? <div className="col-12 md:col-4">
                                    <InputNumber id="nbCredit" value={this.state.nbCredit} onChange={(e) => this.setState({ nbCredit: e.value })} />
                                </div> : null}
                                {this.gemsysService.get_userconnected().idEtabl.typeEtabl == 'Université' ? <div className="col-12 md:col-2">
                                    <label htmlFor="eval1">Pondération 1 *</label>
                                </div> : null}
                                {this.gemsysService.get_userconnected().idEtabl.typeEtabl == 'Université' ? <div className="col-12 md:col-4">
                                    <InputText keyfilter="pnum" id="eval1" value={this.state.pd1} onChange={(e) => this.setState({ pd1: e.target.value })} />
                                </div> : null}

                                {this.gemsysService.get_userconnected().idEtabl.typeEtabl == 'Université' ? <div className="col-12 md:col-2">
                                    <label htmlFor="eval2">Pondération 2 *</label>
                                </div> : null}
                                {this.gemsysService.get_userconnected().idEtabl.typeEtabl == 'Université' ? <div className="col-12 md:col-4">
                                    <InputText keyfilter="pnum" id="eval2" value={this.state.pd2} onChange={(e) => this.setState({ pd2: e.target.value })} />
                                </div> : null}
                                {this.gemsysService.get_userconnected().idEtabl.typeEtabl == 'Université' ? <div className="col-12 md:col-2">
                                    <label htmlFor="eval3">Pondération 3 *</label>
                                </div> : null}
                                {this.gemsysService.get_userconnected().idEtabl.typeEtabl == 'Université' ? <div className="col-12 md:col-4">
                                    <InputText keyfilter="pnum" id="eval3" value={this.state.pd3} onChange={(e) => this.setState({ pd3: e.target.value })} />
                                </div> : null}

                                <div className="col-12 md:col-2">
                                    <label htmlFor="prixHoraire">Prix Horaire *</label>
                                </div>
                                <div className="col-12 md:col-4">
                                    <InputNumber value={this.state.prixHoraire} onChange={(e) => this.setState({ prixHoraire: e.value })} />
                                </div>
                                <div className="col-12 md:col-2">
                                    <label htmlFor="nbHeure">Nombre heures *</label>
                                </div>
                                <div className="col-12 md:col-4">
                                    <InputNumber id="nbHeure" value={this.state.nbHeure} onChange={(e) => this.setState({ nbHeure: e.value })} />
                                </div>

                                <div className="col-12 md:col-2">
                                    <label htmlFor="minSession">Min. echec *</label>
                                </div>
                                <div className="col-12 md:col-4">
                                    <InputNumber id="minSession" value={this.state.minSession} onChange={(e) => this.setState({ minSession: e.value })} />
                                </div>

                                {this.gemsysService.get_userconnected().idEtabl.typeEtabl == 'Université' ? <div className="col-12 md:col-2">
                                    <label htmlFor="minReprise">Min. reprise *</label>
                                </div> : null}
                                {this.gemsysService.get_userconnected().idEtabl.typeEtabl == 'Université' ? <div className="col-12 md:col-4">
                                    <InputNumber id="minReprise" value={this.state.minReprise} onChange={(e) => this.setState({ minReprise: e.value })} />
                                </div> : null}

                                {this.gemsysService.get_userconnected().idEtabl.typeEtabl == 'Pré-universitaire' || this.gemsysService.get_userconnected().idEtabl.typeEtabl == 'Professionnel' ?
                                    <div className="col-12 md:col-2">
                                        <label htmlFor="libmat">Eval1 *</label>
                                    </div>
                                    : null}
                                {this.gemsysService.get_userconnected().idEtabl.typeEtabl == 'Pré-universitaire' || this.gemsysService.get_userconnected().idEtabl.typeEtabl == 'Professionnel' ?
                                    <div className="col-12 md:col-4">
                                        <Dropdown options={this.state.liste_eval} optionLabel='label' value={this.state.eval1} onChange={e => this.setState({ eval1: e.value })} autoWidth={false} />
                                    </div>
                                    : null}
                                {this.gemsysService.get_userconnected().idEtabl.typeEtabl == 'Pré-universitaire' || this.gemsysService.get_userconnected().idEtabl.typeEtabl == 'Professionnel' ?
                                    <div className="col-12 md:col-2">
                                        <label htmlFor="libmat">Eval2 *</label>
                                    </div>
                                    : null}
                                {this.gemsysService.get_userconnected().idEtabl.typeEtabl == 'Pré-universitaire' || this.gemsysService.get_userconnected().idEtabl.typeEtabl == 'Professionnel' ?
                                    <div className="col-12 md:col-4">
                                        <Dropdown options={this.state.liste_eval} optionLabel='label' value={this.state.eval2} onChange={e => this.setState({ eval2: e.value })} autoWidth={false} />
                                    </div>
                                    : null}

                                {this.gemsysService.get_userconnected().idEtabl.typeEtabl == 'Pré-universitaire' ?
                                    <div className="col-12 md:col-2">
                                        <label htmlFor="libmat">Eval3 *</label>
                                    </div>
                                    : null}
                                {this.gemsysService.get_userconnected().idEtabl.typeEtabl == 'Pré-universitaire' ?
                                    <div className="col-12 md:col-4">
                                        <Dropdown options={this.state.liste_eval} optionLabel='label' value={this.state.eval3} onChange={e => this.setState({ eval3: e.value })} autoWidth={false} />
                                    </div>
                                    : null}
                            </div>
                            <center>
                                <div className="grid" style={{ width: 300, marginTop: '0%' }}>
                                    <div className="col"><Button label="Annuler" icon="pi pi-ban" onClick={() => this.annuler_matiere()} /></div>
                                    <div className="col"><Button label="Valider" loading={this.state.showindicator} icon="pi pi-check" onClick={() => this.enreg_matiere()} /></div>
                                </div>
                            </center>
                            <DataTable value={this.state.matieres} paginatorPosition="top" selectionMode="single"
                                alwaysShowPaginator={false} onRowClick={e => this.setState({ selectedMat: e.data, })}
                                onRowSelect={e => this.setState({ selectedMat: e.data }, () => {
                                    this.get_liste_enseigenr_by_matiere();
                                })} style={{ minHeight: '250px' }}
                                selection={this.state.selectedMat} dataKey="codemat" className="p-datatable-gridlines" showGridlines responsiveLayout="scroll" paginator rows={10}>
                                <Column field="Index" header="No" body={this.onIndexTemplate} style={{ width: '4%' }} />
                                <Column header="Actions" body={this.actionTemplate.bind(this)} style={{ width: '7%' }} />
                                <Column field="libmat" header="Libellé" style={{ width: '38%' }} filter={true} filterMatchMode='contains' />
                                <Column field="libmat2" header="Abréviation" style={{ width: '20%' }} filter={true} filterMatchMode='contains' />
                                <Column field="coeff" header="Coef." style={{ width: '7%' }} sortable={true} />
                                <Column field="nbCredit" header="Crédit" style={{ width: '8%' }} sortable={true} />
                                <Column field="prixHoraire" body={(rowData, column) => {
                                    return <div>
                                        <span style={{ color: '#000' }}>{this.gemsysService.formaterValueSep(parseFloat(rowData.prixHoraire))}</span>
                                    </div>
                                }} header="Prix/H" style={{ width: '9%' }} sortable={true} />
                                <Column field="nbHeure" header="NbH" style={{ width: '7%' }} sortable={true} />
                            </DataTable>

                            <hr />
                            <i className="pi pi-users"></i> Gestion des assignations<hr />
                            <DataTable value={this.state.liste_enseigner} style={{ minHeight: '200px' }}
                                selectionMode="single" onRowClick={e => this.setState({ selectedEnseigner: e.data })}
                                selection={this.state.selectedEnseigner} dataKey="idEns" className="p-datatable-gridlines" showGridlines responsiveLayout="scroll" paginator rows={10}>
                                <Column header="Actions" body={this.actionTemplateEns.bind(this)} style={{ width: '7%' }} />
                                <Column field="matrPers.matrPers" header="Matricule" style={{ width: '12%' }} />
                                <Column header="Personnel" body={(rowData, column) => {
                                    return <div>
                                        <span style={{ color: '#000000' }}>{rowData.matrPers.nomPers + " " + rowData.matrPers.prenomPers}</span>
                                    </div>
                                }} style={{ width: '21%' }} />
                                <Column header="Date début" field='dateDebEns' style={{ width: '15%' }} />
                                <Column header="Date fin" field='dateFinEns' style={{ width: '15%' }} />
                                <Column field="nbEns" header="NbH prévu" body={(rowData, column) => {
                                    return <div>
                                        <span style={{ color: '#000' }}>{rowData.nbEns}</span>
                                    </div>
                                }} style={{ width: '15%' }} />
                                <Column field="prixHoraireEns" body={(rowData, column) => {
                                    return <div>
                                        <span style={{ color: '#000' }}>{this.gemsysService.formaterValueSep(parseFloat(rowData.prixHoraireEns))}</span>
                                    </div>
                                }} header="Prix H" style={{ width: '15%' }} />
                            </DataTable>

                            <div className="col-12">
                                <Panel>
                                    <div className="p-fluid grid">
                                        <div className="p-field col-12 md:col-4">
                                            <label >Professeur *</label>
                                            <Dropdown options={personnels} value={this.state.matrPers} optionLabel='label' filter={true} filterBy='label,value' filterMatchMode='contains' onChange={(e) => this.setState({ matrPers: e.target.value })} itemTemplate={(option) => {
                                                if (!option.value) {
                                                    return option.label;
                                                }
                                                else {
                                                    return (
                                                        <div className="grid" >
                                                            <div className="col-12 md:col-4">
                                                                <label>{option.value}</label>
                                                            </div>
                                                            <div className="col-12 md:col-6">
                                                                <label>{option.label}</label>
                                                            </div>
                                                            <div className="col-12 md:col-2">
                                                                <label style={{ fontWeight: 'bold' }}>{option.actif}</label>
                                                            </div>
                                                        </div>
                                                    );
                                                }
                                            }} autoWidth={false} panelStyle={{ width: '500px' }} />
                                        </div>

                                        <div className="p-field col-12 md:col-4">
                                            <label htmlFor="dateDebEns">Date début *</label>
                                            <Calendar id="dateDebEns" dateFormat="dd/mm/yy" value={this.state.dateDebEns} onChange={(e) => this.setState({ dateDebEns: e.target.value })}></Calendar>
                                        </div>

                                        <div className="p-field col-12 md:col-4">
                                            <label htmlFor="dateFinEns">Date fin *</label>
                                            <Calendar id="dateFinEns" dateFormat="dd/mm/yy" value={this.state.dateFinEns} onChange={(e) => this.setState({ dateFinEns: e.target.value })}></Calendar>
                                        </div>

                                        <div className="p-field col-12 md:col-4">
                                            <label htmlFor="nbEns">Nb Heure Prévu *</label>
                                            <InputNumber value={this.state.nbEns} onChange={(e) => this.setState({ nbEns: e.value })} />
                                        </div>

                                        <div className="p-field col-12 md:col-4">
                                            <label htmlFor="prixHoraireEns">Prix Horaire *</label>
                                            <InputNumber value={this.state.prixHoraireEns} onChange={(e) => this.setState({ prixHoraireEns: e.value })} />
                                        </div>
                                    </div>
                                </Panel>
                                <center>
                                    <div className="grid" style={{ width: 300, marginTop: '0%' }}>
                                        <div className="col"><Button label="Annuler" icon="pi pi-ban" onClick={() => this.annuler_enseigner()} /></div>
                                        <div className="col"><Button label="Valider" loading={this.state.showindicator} icon="pi pi-check" onClick={() => this.enreg_enseigner()} /></div>
                                    </div>
                                </center>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}