import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import GestGemsysService from '../service/GestGemsysService';
import './styles.css';
import { InputTextarea } from 'primereact/inputtextarea';
import { SplitButton } from 'primereact/splitbutton';
import { ProgressSpinner } from 'primereact/progressspinner';
import { RadioButton } from "primereact/radiobutton";

export class FormEvalSondage extends Component {
    constructor() {
        super();
        this.state = {
            ridprog: 'Tous',
            rcible: 'Tous',
            liste_programme: [],
            liste_cible: [],
            liste_data: [],
            libelle: '',
            msg: '',
            items: [
                {
                    label: 'Format PDF',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.conf_print('PDF');
                    }
                },
                {
                    label: 'Format XLS',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.conf_print('XLS');
                    }
                },
                {
                    label: 'Format CSV',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.dt.exportCSV();
                    }
                }
            ],
            list: [
                { name: "Fortement d’accord", key: 4 },
                { name: "Plutôt d’accord", key: 3 },
                { name: "Plutôt en désaccord", key: 2 },
                { name: "Fortement en désaccord", key: 1 },
                { name: "Non notée", key: 0 },
            ],
            showindicator: false,
            visible: false
        };
        this.gemsysservice = new GestGemsysService();
        this.repEvalEditor = this.repEvalEditor.bind(this);
        this.inputTextEditor = this.inputTextEditor.bind(this);
        this.cmtEvalEditor = this.cmtEvalEditor.bind(this);
        this.radioEditor = this.radioEditor.bind(this);
        this.conf_print = this.conf_print.bind(this);
    }

    conf_print(format) {
        if (this.state.ridprog == "" || this.state.ridprog == "Tous" || this.state.ridprog == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner un programme' });
        } else {
            this.gemsysservice.generer_resultat_sondage(this.state.ridprog , format);
        }
    }

    componentDidMount() {
        this.get_liste_progeval();
    }

    onEditorValueChange(props, value) {
        let updateds = [...props.value];
        updateds[props.rowIndex][props.field] = value;
        this.setState({ liste_data: updateds });
        this.forceUpdate();
    }

    repEvalEditor(props) {
        return this.radioEditor(props, 'repEval');
    }

    inputTextEditor(props, field) {
        return <InputTextarea rows={3} value={props.rowData[field]} onChange={(e) => this.onEditorValueChange(props, e.target.value)} />;
    }

    cmtEvalEditor(props) {
        return this.inputTextEditor(props, 'cmtEval');
    }

    radioEditor(props, field) {
        let list = this.state.list;
        return <div className="grid" style={{ width: '100%', paddingTop: 10, paddingLeft: 5 }}>
            {list.map((item) => {
                return (
                    <div key={item} className="col p-field-radiobutton" style={{ marginLeft: -8 }}>
                        <RadioButton inputId={item.key} name="item" value={item.key} onChange={(e) => {
                            this.onEditorValueChange(props, e.value);
                            console.log(e.value);
                        }} checked={props.rowData.repEval === item.key} />
                        <label htmlFor={item.key}> {item.name} </label>
                    </div>
                );
            })}
        </div>
    }

    get_liste_progeval() {
        this.gemsysservice.getProgEval().then(data => {
            if (data != null && data != undefined && data.length > 0) {
                let liste = data.map(x => {
                    return { value: x.idProg, label: x.libProg }
                });
                this.setState({ liste_programme: liste });
            }
        });
    }

    get_liste_prog_cible() {
        this.gemsysservice.getProgCible(this.state.ridprog).then(data => {
            if (data != null && data != undefined && data.length > 0) {
                let liste = data.map(x => {
                    return { value: x.idProgcible, matricule: x.matrCible.matrPers, label: x.matrCible.prenomPers + ' ' + x.matrCible.nomPers }
                });
                this.setState({ liste_cible: liste });
            }
        });
    }

    resultat(code, contenu) {
        if (code == '200') {
            this.toast.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.get_liste_for_sondage();
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
    }


    enreg_evaluation() {
        if (this.state.liste_data == null || this.state.liste_data == undefined || this.state.liste_data.length <= 0) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner les lignes à évaluer' });
        } else {
            this.gemsysservice.AddEvaluations(this.state.liste_data).then(data => this.resultat(data.code, data.contenu));
        }
    }

    get_liste_data() {
        this.setState({ showindicator: true });
        this.gemsysservice.get_liste_eval_sondage(this.state.ridprog, this.state.rcible).then(data => {
            this.setState({ liste_data: data, showindicator: false });
        });
    }


    get_liste_for_sondage() {
        this.setState({ showindicator: true });
        this.gemsysservice.get_liste_for_sondage(this.state.ridprog, this.state.rcible).then(data => {
            this.setState({ liste_data: data, showindicator: false });
        });
    }


    render() {
        let mode = 'liste';
        if (this.props.match.params.typeOp != null && this.props.match.params.typeOp != undefined) {
            mode = this.props.match.params.typeOp;
        }
        return (
            <div className="p-fluid">
                <Toast ref={(el) => this.toast = el} />
                <div className="card card-w-title">
                    {mode == 'resultats' ?
                        <div className="grid">
                            <i className="pi pi-search"></i>
                            <span style={{ fontSize: 14, marginLeft: 10, color: '#999999' }}>Options de recherche</span>
                            <hr />
                            <div className="col-12">
                                <div className="grid">
                                    <div className="col-12 md:col-3">
                                        <Dropdown value={this.state.ridprog} placeholder='Programme' options={[{ value: 'Tous', label: 'Programme' }].concat(this.state.liste_programme)} onChange={(e) => this.setState({ ridprog: e.target.value }, () => {
                                            this.get_liste_prog_cible();
                                        })} autoWidth={false} filter filterBy='label' filterMatchMode='contains' panelStyle={{ width: "400px" }} />
                                    </div>
                                    <div className="col-12 md:col-3">
                                        <Dropdown value={this.state.rcible} placeholder='Cible' options={[{ value: 'Tous', label: 'Personnel' }].concat(this.state.liste_cible)} onChange={(e) => this.setState({ rcible: e.target.value })} autoWidth={false} filter filterBy='label' filterMatchMode='contains'
                                            itemTemplate={(option) => {
                                                if (!option.value) {
                                                    return option.label;
                                                }
                                                else {
                                                    return (
                                                        <div className="grid" >
                                                            <div className="col-12 md:col-3">
                                                                <label>{option.matricule}</label>
                                                            </div>
                                                            <div className="col-12 md:col-9">
                                                                <label>{option.label}</label>
                                                            </div>
                                                        </div>
                                                    );
                                                }
                                            }} panelStyle={{ width: '400px' }} />
                                    </div>
                                    <div className='col-12 md:col-2'>
                                        <SplitButton label="Rechercher" style={{ width: '170px' }} onClick={(e) => this.get_liste_data()} icon="pi pi-search" model={this.state.items} />
                                    </div>
                                    <div className='col-12 md:col-2'>
                                        {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                    </div>
                                </div>
                                <DataTable value={this.state.liste_data} alwaysShowPaginator={false} paginator={true} rows={50} paginatorPosition="top" ref={(el) => { this.dt = el; }}
                                    dataKey="idEval" className="p-datatable-gridlines" showGridlines responsiveLayout="scroll" style={{ minHeight: '600px' }} >
                                    <Column field="dateEval" header="Date" style={{ width: '8%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                    <Column field="idProgrammer.idProg.libProg" header="Programme" style={{ width: '15%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                    <Column field="idProgcible.matrCible.prenomPers" body={(rowData, column) => {
                                        return <div>
                                            <span> {rowData.idProgcible.matrCible.prenomPers + " " + rowData.idProgcible.matrCible.nomPers} </span>
                                        </div>
                                    }} header="Personnel" style={{ width: '20%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                    <Column field="idProgrammer.idQuest.libQuest" header="Questionnaire" style={{ width: '30%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                    <Column field="repEval" header="Note" style={{ width: '7%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                    <Column field="cmtEval" header="Commentaire" style={{ width: '20%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                </DataTable>
                            </div>
                        </div>

                        :

                        <div className="grid">
                            <i className="pi pi-search"></i>
                            <span style={{ fontSize: 14, marginLeft: 10, color: '#999999' }}>Options de recherche</span>
                            <hr />
                            <div className="col-12">
                                <div className="grid">
                                    <div className="col-12 md:col-3">
                                        <Dropdown value={this.state.ridprog} placeholder='Programme' options={this.state.liste_programme} onChange={(e) => this.setState({ ridprog: e.target.value }, () => {
                                            this.get_liste_prog_cible();
                                            this.get_liste_data();
                                        })} autoWidth={false} filter filterBy='label' filterMatchMode='contains' panelStyle={{ width: '400px' }} />
                                    </div>
                                    <div className="col-12 md:col-3">
                                        <Dropdown value={this.state.rcible} placeholder='Cible' options={this.state.liste_cible} onChange={(e) => this.setState({ rcible: e.target.value }, () => {
                                            this.get_liste_for_sondage();
                                        })} autoWidth={false} filter filterBy='label' filterMatchMode='contains'
                                            itemTemplate={(option) => {
                                                if (!option.value) {
                                                    return option.label;
                                                }
                                                else {
                                                    return (
                                                        <div className="grid" >
                                                            <div className="col-12 md:col-3">
                                                                <label>{option.matricule}</label>
                                                            </div>
                                                            <div className="col-12 md:col-9">
                                                                <label>{option.label}</label>
                                                            </div>
                                                        </div>
                                                    );
                                                }
                                            }} panelStyle={{ width: '400px' }} />
                                    </div>
                                    <div className='col-12 md:col-2'>
                                        <Button label="Rechercher" style={{ width: '170px' }} onClick={(e) => this.get_liste_for_sondage()} icon="pi pi-search" />
                                    </div>
                                    <div className='col-12 md:col-2'>
                                        {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                    </div>
                                </div>
                                <DataTable value={this.state.liste_data} alwaysShowPaginator={false} paginator={true} rows={50} paginatorPosition="top" ref={(el) => { this.dt = el; }}
                                    dataKey="idEval" className="p-datatable-gridlines" showGridlines responsiveLayout="scroll" style={{ minHeight: '600px' }} >
                                    <Column field="idProgrammer.idQuest.libQuest" header="Questionnaire" style={{ width: '25%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                    <Column field="repEval" header="Reponse" body={(rowData, column) => {
                                        let list = this.state.list;
                                        return <div className="grid p-flex-row" style={{ width: '100%', paddingTop: 10, paddingLeft: 5 }}>
                                            {list.map((item) => {
                                                return (
                                                    <div key={item} className="col p-field-radiobutton" style={{ marginLeft: -8 }}>
                                                        <RadioButton inputId={item.key} name="item" value={item} onChange={(e) => this.setState({ repEval: e.target.value })} checked={rowData.repEval === item.key} />
                                                        <label htmlFor={item}> {item.name} </label>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    }} style={{ width: '60%' }} editor={this.repEvalEditor} filter={true} filterMatchMode='contains' sortable={true} />
                                    <Column field="cmtEval" header="Commentaire" style={{ width: '15%' }} editor={this.cmtEvalEditor} filter={true} filterMatchMode='contains' sortable={true} />
                                </DataTable>

                                <center style={{ marginTop: 10 }}>
                                    <Button label="Valider" icon="pi pi-check" style={{ width: 200 }} onClick={() => this.enreg_evaluation()} />
                                    <br />
                                    {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                </center>

                            </div>
                        </div>

                    }
                </div>
            </div>
        );
    }
}