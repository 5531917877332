import React, { Component } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import './styles.css';
import { SplitButton } from 'primereact/splitbutton';
import GestGemsysService from '../service/GestGemsysService';
import { ProgressSpinner } from 'primereact/progressspinner';
import { InputText } from 'primereact/inputtext';
import { Panel } from 'primereact/panel';
import { Toast } from 'primereact/toast';

export class EditionEtudiantDoc extends Component {
    constructor() {
        super();
        this.state = {
            items: [],
            rcode_classe: 'Tous',
            liste_data: [],
            selectedListes: [],
            showindicator: false,
            rcode_enseign: 'Tous',
            titredoc: 'Liste générale',
            rstac: 'Tous',
            rstaf: 'Tous',
            rsexe: 'Tous',
            rsituation: 'Tous',
            rgroupe: 'Tous',
            rval: 'Tous',
            liste_classe: [],
            liste_all_classe: [],
            liste_statut_accademique: [{ label: 'Statut accadémique', value: 'Tous' }],
            liste_statut_financier: [{ label: 'Statut financier', value: 'Tous' }],
            liste_enseignement: [],


        };
        this.conf_print_liste = this.conf_print_liste.bind(this);
        this.conf_print_selection = this.conf_print_selection.bind(this);
        this.conf_print_bulletin = this.conf_print_bulletin.bind(this);
        this.conf_print_releve_note = this.conf_print_releve_note.bind(this);
        this.conf_print_attestation_niveau = this.conf_print_attestation_niveau.bind(this);
        this.conf_print_diplome = this.conf_print_diplome.bind(this);
        this.conf_print_liste_diplome = this.conf_print_liste_diplome.bind(this);

        this.gestGemsysService = new GestGemsysService();
    }


    /* Exportation du tableau  en fichier XLSX */
    exportExcel = () => {
        let liste_data = this.state.liste_data.length > 0 ? this.state.liste_data.map(x => {
            return {
                'NO': x.ordre,
                'MATRICULE': x.matrEtud,
                'NOM': x.nomEtud,
                'PRENOM': x.prenomEtu,
                'SEXE': x.sexe,
                'DATE_NAISS': x.dateNaiss,
                'LIEU_NAISS': x.lieuNais,
                'PERE': x.prenomPere,
                'MERE': x.nomMere,
                'PREFECTURE': x.pref,
                'PV_BAC': x.pvb,
                'PROFIL_BAC': x.profilb,
                'SESSION_BAC': x.sessionb,
                'INE': x.pvs,
                'CENTRE_BAC': x.centreb,
                'DATE_INSC': x.dateInsc,
                'STATUT_FINANCIER': x.staf,
                'STATUT_ACCADEMIQUE': x.stac,
                'NOTIFICATION': x.optNotif,
                'GROUPE_SANGUIN': x.groupesanguin,
                'SITUATION_MATRIM': x.situationMatrimoniale,
                'HANDICAP': x.handicap,
                'TYPE_HANDICAP': x.typeHandicap,
                'IMPACT_HANDICAP': x.impactHandicap,
                'CLASSE': x.niveau,
                'SEMESTRE': x.libsem,
                'OPTION': x.concentration,
                'NUM_QUITANCE': x.numQuitance,
                'EMAIL': x.emailEtud,
                'TELEPHONE': x.telEtud
            }
        }) : [];
        import('xlsx').then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(liste_data);
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            this.saveAsExcelFile(excelBuffer, 'LISTE_BRUTE_');
        });
    }

    /* Enregistrer le fichier sur le client */
    saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then(module => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    }

    conf_print_liste(format, doc) {
        if (doc == 'LISTE') {
            this.gestGemsysService.generer_liste_eleve(this.state.rcode_enseign, this.state.rcode_classe, this.state.rsexe, this.state.rstac, this.state.rstaf, this.state.rval, this.state.rsituation, this.state.rgroupe, this.state.titredoc, format);
        } else if (doc == 'PHOTO') {
            this.gestGemsysService.generer_liste_eleve_photo(this.state.rcode_enseign, this.state.rcode_classe, this.state.rsexe, this.state.rstac, this.state.rstaf, this.state.rval, this.state.rsituation, this.state.rgroupe, this.state.titredoc, format);
        } else if (doc == 'BRUTE') {
            this.gestGemsysService.generer_liste_eleve_brute(this.state.rcode_enseign, this.state.rcode_classe, this.state.rsexe, this.state.rstac, this.state.rstaf, this.state.rval, this.state.rsituation, this.state.rgroupe, this.state.titredoc, format);
        } else if (doc == 'ETAT_PAIE') {
            this.gestGemsysService.generer_etat_paie_boursier(this.state.rcode_enseign, this.state.rcode_classe, this.state.rsexe, this.state.rstac, this.state.rstaf, this.state.rval, this.state.rsituation, this.state.rgroupe, this.state.titredoc, format);
        }
    }

    conf_print_bulletin(format, lib) {
        if (this.state.rcode_classe == "" || this.state.rcode_classe == "Tous") {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner une classe' });
        } else {
            let ids = "";
            if (this.state.selectedListes.length > 0) {
                ids = this.state.selectedListes.map(x => {
                    return x.idInsc
                }).join("_");
            } else {
                ids = this.state.liste_data.map(x => {
                    return x.idInsc
                }).join("_");
            }
            this.gestGemsysService.generer_bulletin_note(ids, lib, this.state.rcode_classe, format);
        }
    }

    conf_print_diplome(lib) {
        if (this.state.rcode_classe == "" || this.state.rcode_classe == "Tous") {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner une classe' });
        } else {
            let ids = "";
            if (this.state.selectedListes.length > 0) {
                ids = this.state.selectedListes.map(x => {
                    return x.idInsc
                }).join("_");
            } else {
                ids = this.state.liste_data.map(x => {
                    return x.idInsc
                }).join("_");
            }
            this.gestGemsysService.generer_diplome(ids, lib, this.state.rcode_classe);
        }
    }

    conf_print_liste_diplome(format) {
        if (this.state.rcode_classe == "" || this.state.rcode_classe == "Tous") {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner une classe' });
        } else {
            let ids = "";
            if (this.state.selectedListes.length > 0) {
                ids = this.state.selectedListes.map(x => {
                    return x.idInsc
                }).join("_");
            } else {
                ids = this.state.liste_data.map(x => {
                    return x.idInsc
                }).join("_");
            }
            this.gestGemsysService.generer_liste_diplome(ids, this.state.rcode_classe, format);
        }
    }


    conf_print_attestation_niveau(lib) {
        if (this.state.rcode_classe == "" || this.state.rcode_classe == "Tous") {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner une classe' });
        } else {
            let ids = "";
            if (this.state.selectedListes.length > 0) {
                ids = this.state.selectedListes.map(x => {
                    return x.idInsc
                }).join("_");
            } else {
                ids = this.state.liste_data.map(x => {
                    return x.idInsc
                }).join("_");
            }
            this.gestGemsysService.generer_attestation_niveau(ids, lib, this.state.rcode_classe);
        }
    }

    conf_print_attestation_cycle(lib) {
        if (this.state.rcode_classe == "" || this.state.rcode_classe == "Tous") {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner une classe' });
        } else {
            let ids = "";
            if (this.state.selectedListes.length > 0) {
                ids = this.state.selectedListes.map(x => {
                    return x.idInsc
                }).join("_");
            } else {
                ids = this.state.liste_data.map(x => {
                    return x.idInsc
                }).join("_");
            }
            this.gestGemsysService.generer_attestation_cycle(ids, lib, this.state.rcode_classe);
        }
    }

    conf_print_releve_note(format, lib) {
        if (this.state.rcode_classe == "" || this.state.rcode_classe == "Tous") {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner une classe' });
        } else {
            let ids = "";
            if (this.state.selectedListes.length > 0) {
                ids = this.state.selectedListes.map(x => {
                    return x.idInsc
                }).join("_");
            } else {
                ids = this.state.liste_data.map(x => {
                    return x.idInsc
                }).join("_");
            }
            this.gestGemsysService.generer_releve_note(ids, lib, this.state.rcode_classe, format);
        }
    }



    conf_print_selection(doc) {
        let ids = "";
        if (this.state.selectedListes.length > 0) {
            ids = this.state.selectedListes.map(x => {
                return x.idInsc
            }).join("_");
        } else {
            ids = this.state.liste_data.map(x => {
                return x.idInsc
            }).join("_");
        }
        if (doc == 'BADGE') {
            this.gestGemsysService.generer_badge_eleve(ids);
        } else if (doc == 'FICHE_PAIE') {
            this.gestGemsysService.generer_fiche_paie(ids);
        } else if (doc == 'ATTI') {
            this.gestGemsysService.generer_attestation_insc(ids);
        } else if (doc == 'CSCOL') {
            this.gestGemsysService.generer_certificat_scol(ids);
        } else if (doc == 'FICHE_INSC') {
            this.gestGemsysService.generer_fiche_insc(ids);
        }
    }

    photoTemplate(rowData, column) {
        let fic = rowData.photo;
        if (fic === undefined || fic === null || fic === '') {
            fic = 'nophoto.png';
        }
        return <img src={this.gestGemsysService.download_file(fic, 'photos/')} alt='Photo' style={{ width: 45, height: 45 }} />
    }

    get_liste_classe() {
        this.setState({ showindicator: true, liste_classe: [], liste_all_classe: [] });
        this.gestGemsysService.getClasse().then(data => {
            this.setState({ showindicator: false });
            if (data !== null && data !== undefined) {
                if (data.length > 0) {
                    let liste = data.map(x => {
                        let lc = this.gestGemsysService.get_userconnected().idEtabl.typeEtabl == 'Université' ? 'S' : '';
                        return { value: x.codeClasse, label: lc + x.libClasse + " " + x.optClasse, codeEnseign: x.codeEnseign.codeEnseign }
                    })
                    this.setState({ liste_all_classe: liste });
                }
            }
        });
    }

    filterClasse(codeEnseign) {
        this.setState({ liste_classe: [] });
        if (this.state.liste_all_classe.length > 0) {
            let liste = this.state.liste_all_classe.filter(function (el) {
                return el.codeEnseign == codeEnseign;
            });
            this.setState({ liste_classe: liste });
        }
    }

    remove_duplicates(arr) {
        let s = new Set(arr);
        let it = s.values();
        return Array.from(it);
    }

    get_liste_statut() {
        this.gestGemsysService.getStatut().then(data => {
            if (data !== null && data !== undefined) {
                let liste_f = this.remove_duplicates(data.map(x => { return x.statutFinancier })).map(x => { return { value: x, label: x } });
                let liste_a = this.remove_duplicates(data.map(x => { return x.statutAccademique })).map(x => { return { value: x, label: x } });
                this.setState({
                    liste_statut_accademique: this.state.liste_statut_accademique.concat(liste_a),
                    liste_statut_financier: this.state.liste_statut_financier.concat(liste_f)
                });
            }
        });
    }

    get_liste_enseignement() {
        this.setState({ showindicator: true });
        this.gestGemsysService.getEnseignement().then(data => {
            this.setState({ showindicator: false });
            if (data !== null && data !== undefined && data !== '') {
                if (data.length > 0) {
                    let liste = data.map(x => {
                        return { value: x.codeEnseign, label: x.libEnseign }
                    });
                    this.setState({ liste_enseignement: this.state.liste_enseignement.concat(liste) });
                }
            }
        });
    }

    componentDidMount() {
        this.get_liste_enseignement();
        this.get_liste_statut();
        let liste = [];
        if (this.gestGemsysService.get_userconnected().idEtabl.typeEtabl == 'Université') {
            if (this.gestGemsysService.get_userconnected().profilu == 'Administrateur' || this.gestGemsysService.get_userconnected().profilu == 'Recteur' || this.gestGemsysService.get_userconnected().profilu == 'Vice Recteur' || this.gestGemsysService.get_userconnected().profilu == 'Chargé scolarité') {
                liste = [
                    {
                        label: 'Liste formatée',
                        icon: 'pi pi-print',
                        command: () => {
                            this.conf_print_liste('PDF', 'LISTE');
                        },
                        typeEtabl: 'ALL',
                        profil: 'ALL'
                    },
                    {
                        label: 'Liste brute',
                        icon: 'pi pi-print',
                        command: () => {
                            this.exportExcel();
                        },
                        typeEtabl: 'ALL',
                        profil: 'ALL'
                    },
                    {
                        label: 'Liste avec photo',
                        icon: 'pi pi-print',
                        command: () => {
                            this.conf_print_liste('PDF', 'PHOTO');
                        },
                        typeEtabl: 'ALL',
                        profil: 'ALL'
                    },
                    {
                        label: 'Diplomes',
                        icon: 'pi pi-print',
                        command: () => {
                            this.conf_print_diplome('ORIGINAL');
                        },
                        typeEtabl: 'Université_Professionnel',
                        profil: 'Administrateur_DG_DGE_Chargé scolarité'
                    },
                    {
                        label: 'Duplicata diplome',
                        icon: 'pi pi-print',
                        command: () => {
                            this.conf_print_diplome('DUPLICATA');
                        },
                        typeEtabl: 'Université_Professionnel',
                        profil: 'Administrateur_DG_DGE_Chargé scolarité'
                    },
                    {
                        label: 'Badges',
                        icon: 'pi pi-print',
                        command: () => {
                            this.conf_print_selection('BADGE');
                        },
                        typeEtabl: 'ALL',
                        profil: 'ALL'
                    },
                    {
                        label: 'Bulettin notes PDF',
                        icon: 'pi pi-print',
                        command: () => {
                            this.conf_print_bulletin('PDF', 'BULLETIN');
                        },
                        typeEtabl: 'Université_Professionnel',
                        profil: 'Administrateur_DG_DGE_Chargé scolarité'
                    },
                    {
                        label: 'Bulettin notes XLS',
                        icon: 'pi pi-print',
                        command: () => {
                            this.conf_print_bulletin('XLS', 'BULLETIN');
                        },
                        typeEtabl: 'Université_Professionnel',
                        profil: 'Administrateur_DG_DGE_Chargé scolarité'
                    },
                    {
                        label: 'Relevés notes PDF',
                        icon: 'pi pi-print',
                        command: () => {
                            this.conf_print_releve_note('PDF', 'RELEVE_NOTE');
                        },
                        typeEtabl: 'Université_Professionnel',
                        profil: 'Administrateur_DG_DGE_Chargé scolarité'
                    },
                    {
                        label: 'Relevés notes XLS',
                        icon: 'pi pi-print',
                        command: () => {
                            this.conf_print_releve_note('XLS', 'RELEVE_NOTE');
                        },
                        typeEtabl: 'Université_Professionnel',
                        profil: 'Administrateur_DG_DGE_Chargé scolarité'
                    },
                    {
                        label: 'Relevés niveau PDF',
                        icon: 'pi pi-print',
                        command: () => {
                            this.conf_print_releve_note('PDF', 'RELEVE_NIVEAU');
                        },
                        typeEtabl: 'Université_Professionnel',
                        profil: 'Administrateur_DG_DGE_Chargé scolarité'
                    },
                    {
                        label: 'Relevés niveau XLS',
                        icon: 'pi pi-print',
                        command: () => {
                            this.conf_print_releve_note('XLS', 'RELEVE_NIVEAU');
                        },
                        typeEtabl: 'Université_Professionnel',
                        profil: 'Administrateur_DG_DGE_Chargé scolarité'
                    },
                    {
                        label: 'Fiche de paie',
                        icon: 'pi pi-print',
                        command: () => {
                            this.conf_print_selection('FICHE_PAIE');
                        },
                        typeEtabl: 'Université_Professionnel',
                        profil: 'Administrateur_DG_DGE_Chargé scolarité_Comptable'
                    },
                    {
                        label: 'Etat de paie',
                        icon: 'pi pi-print',
                        command: () => {
                            this.conf_print_liste('PDF', 'ETAT_PAIE');
                        },
                        typeEtabl: 'Université_Professionnel',
                        profil: 'Administrateur_DG_DGE_Chargé scolarité_Comptable'
                    },
                    {
                        label: "Fiche d'inscription",
                        icon: 'pi pi-print',
                        command: () => {
                            this.conf_print_selection('FICHE_INSC');
                        },
                        typeEtabl: 'Université_Professionnel',
                        profil: 'Administrateur_DG_DGE_Chargé scolarité'
                    },
                    {
                        label: 'Résultats classe PDF',
                        icon: 'pi pi-print',
                        command: () => {
                            if (this.state.rcode_classe == "" || this.state.rcode_classe == "Tous") {
                                this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner une classe' });
                            } else {
                                this.gestGemsysService.generer_resultat_classe(this.state.rcode_classe, 'Tous', 'PDF');
                            }
                        },
                        typeEtabl: 'Pré-universitaire',
                        profil: 'Administrateur_DG_DGE_Chargé scolarité'
                    },
                    {
                        label: 'Résultats classe XLS',
                        icon: 'pi pi-print',
                        command: () => {
                            if (this.state.rcode_classe == "" || this.state.rcode_classe == "Tous") {
                                this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner une classe' });
                            } else {
                                this.gestGemsysService.generer_resultat_classe(this.state.rcode_classe, 'Tous', 'XLS');
                            }
                        },
                        typeEtabl: 'Pré-universitaire',
                        profil: 'Administrateur_DG_DGE_Chargé scolarité'
                    },
                    {
                        label: 'Résultats niveau XLS',
                        icon: 'pi pi-print',
                        command: () => {
                            if (this.state.rcode_classe == "" || this.state.rcode_classe == "Tous") {
                                this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner une classe' });
                            } else {
                                this.gestGemsysService.generer_resultat_classe(this.state.rcode_classe, 'NIVEAU', 'XLS');
                            }
                        },
                        typeEtabl: 'Pré-universitaire',
                        profil: 'Administrateur_DG_DGE_Chargé scolarité'
                    },
                    {
                        label: 'Relevés des moy. PDF',
                        icon: 'pi pi-print',
                        command: () => {
                            if (this.state.rcode_classe == "" || this.state.rcode_classe == "Tous") {
                                this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner une classe' });
                            } else {
                                let ids = "";
                                if (this.state.selectedListes.length > 0) {
                                    ids = this.state.selectedListes.map(x => {
                                        return x.idInsc
                                    }).join("_");
                                } else {
                                    ids = this.state.liste_data.map(x => {
                                        return x.idInsc
                                    }).join("_");
                                }
                                this.gestGemsysService.generer_resultat_cycle(ids, this.state.rcode_classe, 'PDF');
                            }
                        },
                        typeEtabl: 'Université',
                        profil: 'Administrateur_DG_DGE_Chargé scolarité'
                    },
                    {
                        label: 'Relevés des moy. XLS',
                        icon: 'pi pi-print',
                        command: () => {
                            if (this.state.rcode_classe == "" || this.state.rcode_classe == "Tous") {
                                this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner une classe' });
                            } else {
                                let ids = "";
                                if (this.state.selectedListes.length > 0) {
                                    ids = this.state.selectedListes.map(x => {
                                        return x.idInsc
                                    }).join("_");
                                } else {
                                    ids = this.state.liste_data.map(x => {
                                        return x.idInsc
                                    }).join("_");
                                }
                                this.gestGemsysService.generer_resultat_cycle(ids, this.state.rcode_classe, 'XLS');
                            }
                        },
                        typeEtabl: 'Université',
                        profil: 'Administrateur_DG_DGE_Chargé scolarité'
                    },
                    {
                        label: "Attestation (ré)inscription",
                        icon: 'pi pi-print',
                        command: () => {
                            this.conf_print_selection('ATTI');
                        },
                        typeEtabl: 'Université_Professionnel',
                        profil: 'Administrateur_DG_DGE_Chargé scolarité'
                    },
                    {
                        label: 'Attestation niveau',
                        icon: 'pi pi-print',
                        command: () => {
                            this.conf_print_attestation_niveau('RES_FINAL');
                        },
                        typeEtabl: 'Université_Professionnel',
                        profil: 'Administrateur_DG_DGE_Chargé scolarité'
                    },
                    {
                        label: 'Certificat scolarité',
                        icon: 'pi pi-print',
                        command: () => {
                            this.conf_print_selection('CSCOL');
                        },
                        typeEtabl: 'Université_Professionnel',
                        profil: 'Administrateur_Chargé scolarité'
                    },
                    {
                        label: 'Attestation fin de cycle',
                        icon: 'pi pi-print',
                        command: () => {
                            this.conf_print_attestation_cycle('RES_FINAL');
                        },
                        typeEtabl: 'Université_Professionnel',
                        profil: 'Administrateur_DG_DGE_Chargé scolarité'
                    },
                    {
                        label: 'Liste des diplomés XLS',
                        icon: 'pi pi-print',
                        command: () => {
                            this.conf_print_liste_diplome('PDF');
                        },
                        typeEtabl: 'Université',
                        profil: 'Administrateur_DG_DGE_Chargé scolarité'
                    }
                ]


            } else if (this.gestGemsysService.get_userconnected().profilu == 'Comptable') {
                liste = [
                    {
                        label: 'Liste formatée',
                        icon: 'pi pi-print',
                        command: () => {
                            this.conf_print_liste('PDF', 'LISTE');
                        },
                        typeEtabl: 'ALL',
                        profil: 'ALL'
                    },
                    {
                        label: 'Liste brute',
                        icon: 'pi pi-print',
                        command: () => {
                            this.conf_print_liste('XLS', 'BRUTE');
                        },
                        typeEtabl: 'ALL',
                        profil: 'ALL'
                    },
                    {
                        label: 'Liste avec photo',
                        icon: 'pi pi-print',
                        command: () => {
                            this.conf_print_liste('PDF', 'PHOTO');
                        },
                        typeEtabl: 'ALL',
                        profil: 'ALL'
                    },
                    {
                        label: 'Badges',
                        icon: 'pi pi-print',
                        command: () => {
                            this.conf_print_selection('BADGE');
                        },
                        typeEtabl: 'ALL',
                        profil: 'ALL'
                    },
                    {
                        label: 'Fiche de paie',
                        icon: 'pi pi-print',
                        command: () => {
                            this.conf_print_selection('FICHE_PAIE');
                        },
                        typeEtabl: 'Université_Professionnel',
                        profil: 'Administrateur_DG_DGE_Chargé scolarité_Comptable'
                    },
                    {
                        label: 'Etat de paie',
                        icon: 'pi pi-print',
                        command: () => {
                            this.conf_print_liste('PDF', 'ETAT_PAIE');
                        },
                        typeEtabl: 'Université_Professionnel',
                        profil: 'Administrateur_DG_DGE_Chargé scolarité_Comptable'
                    },
                ]
            }
        } else if (this.gestGemsysService.get_userconnected().idEtabl.typeEtabl == 'Pré-universitaire') {
            if (this.gestGemsysService.get_userconnected().profilu == 'Administrateur' || this.gestGemsysService.get_userconnected().profilu == 'Chargé scolarité' || this.gestGemsysService.get_userconnected().profilu == 'DG' || this.gestGemsysService.get_userconnected().profilu == 'DGE') {
                liste = [
                    {
                        label: 'Liste formatée',
                        icon: 'pi pi-print',
                        command: () => {
                            this.conf_print_liste('PDF', 'LISTE');
                        },
                        typeEtabl: 'ALL',
                        profil: 'ALL'
                    },
                    {
                        label: 'Liste brute',
                        icon: 'pi pi-print',
                        command: () => {
                            this.conf_print_liste('XLS', 'BRUTE');
                        },
                        typeEtabl: 'ALL',
                        profil: 'ALL'
                    },
                    {
                        label: 'Liste avec photo',
                        icon: 'pi pi-print',
                        command: () => {
                            this.conf_print_liste('PDF', 'PHOTO');
                        },
                        typeEtabl: 'ALL',
                        profil: 'ALL'
                    },
                    {
                        label: 'Badges',
                        icon: 'pi pi-print',
                        command: () => {
                            this.conf_print_selection('BADGE');
                        },
                        typeEtabl: 'ALL',
                        profil: 'ALL'
                    },

                    {
                        label: 'Bulettin notes annuel',
                        icon: 'pi pi-print',
                        command: () => {
                            this.conf_print_bulletin('PDF', 'Tous');
                        },
                        typeEtabl: 'Université_Professionnel',
                        profil: 'Administrateur_DG_DGE_Chargé scolarité'
                    },
                    {
                        label: "Fiche d'inscription",
                        icon: 'pi pi-print',
                        command: () => {
                            this.conf_print_selection('FICHE_INSC');
                        },
                        typeEtabl: 'Université_Professionnel',
                        profil: 'Administrateur_DG_DGE_Chargé scolarité'
                    },
                    {
                        label: 'Résultats classe',
                        icon: 'pi pi-print',
                        command: () => {
                            if (this.state.rcode_classe == "" || this.state.rcode_classe == "Tous") {
                                this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner une classe' });
                            } else {
                                this.gestGemsysService.generer_resultat_classe(this.state.rcode_classe, 'Tous', 'PDF');
                            }
                        },
                        typeEtabl: 'Pré-universitaire',
                        profil: 'Administrateur_DG_DGE_Chargé scolarité'
                    },
                    {
                        label: "Attestation (ré)inscription",
                        icon: 'pi pi-print',
                        command: () => {
                            this.conf_print_selection('ATTI');
                        },
                        typeEtabl: 'Université_Professionnel',
                        profil: 'Administrateur_DG_DGE_Chargé scolarité'
                    },
                    {
                        label: 'Attestation niveau',
                        icon: 'pi pi-print',
                        command: () => {
                            this.conf_print_attestation_niveau('RES_FINAL');
                        },
                        typeEtabl: 'Université_Professionnel',
                        profil: 'Administrateur_DG_DGE_Chargé scolarité'
                    },
                ]

                if (this.gestGemsysService.get_userconnected().idEtabl.systemEtabl == 'LOCAL') {
                    liste.splice(4, 0,
                        {
                            label: 'Bulettin notes M1',
                            icon: 'pi pi-print',
                            command: () => {
                                this.conf_print_bulletin('PDF', 'Module 1');
                            },
                            typeEtabl: 'Université_Professionnel',
                            profil: 'Administrateur_DG_DGE_Chargé scolarité'
                        });
                    liste.splice(5, 0,
                        {
                            label: 'Bulettin notes M2',
                            icon: 'pi pi-print',
                            command: () => {
                                this.conf_print_bulletin('PDF', 'Module 2');
                            },
                            typeEtabl: 'Université_Professionnel',
                            profil: 'Administrateur_DG_DGE_Chargé scolarité'
                        });
                    liste.splice(6, 0,
                        {
                            label: 'Bulettin notes M3',
                            icon: 'pi pi-print',
                            command: () => {
                                this.conf_print_bulletin('PDF', 'Module 3');
                            },
                            typeEtabl: 'Université_Professionnel',
                            profil: 'Administrateur_DG_DGE_Chargé scolarité'
                        });


                } else {
                    liste.splice(4, 0,
                        {
                            label: 'Bulettin notes P1',
                            icon: 'pi pi-print',
                            command: () => {
                                this.conf_print_bulletin('PDF', 'PERIODE 1');
                            },
                            typeEtabl: 'Université_Professionnel',
                            profil: 'Administrateur_DG_DGE_Chargé scolarité'
                        });
                    liste.splice(5, 0,
                        {
                            label: 'Bulettin notes P2',
                            icon: 'pi pi-print',
                            command: () => {
                                this.conf_print_bulletin('PDF', 'PERIODE 2');
                            },
                            typeEtabl: 'Université_Professionnel',
                            profil: 'Administrateur_DG_DGE_Chargé scolarité'
                        });
                    liste.splice(6, 0,
                        {
                            label: 'Bulettin notes P3',
                            icon: 'pi pi-print',
                            command: () => {
                                this.conf_print_bulletin('PDF', 'PERIODE 3');
                            },
                            typeEtabl: 'Université_Professionnel',
                            profil: 'Administrateur_DG_DGE_Chargé scolarité'
                        });

                    liste.splice(7, 0,
                        {
                            label: 'Bulettin notes P4',
                            icon: 'pi pi-print',
                            command: () => {
                                this.conf_print_bulletin('PDF', 'PERIODE 4');
                            },
                            typeEtabl: 'Université_Professionnel',
                            profil: 'Administrateur_DG_DGE_Chargé scolarité'
                        });

                    liste.splice(8, 0,
                        {
                            label: 'Bulettin notes P5',
                            icon: 'pi pi-print',
                            command: () => {
                                this.conf_print_bulletin('PDF', 'PERIODE 5');
                            },
                            typeEtabl: 'Université_Professionnel',
                            profil: 'Administrateur_DG_DGE_Chargé scolarité'
                        });

                }
            } else if (this.gestGemsysService.get_userconnected().profilu == 'Comptable') {
                liste = [
                    {
                        label: 'Liste formatée',
                        icon: 'pi pi-print',
                        command: () => {
                            this.conf_print_liste('PDF', 'LISTE');
                        },
                        typeEtabl: 'ALL',
                        profil: 'ALL'
                    },
                    {
                        label: 'Liste brute',
                        icon: 'pi pi-print',
                        command: () => {
                            this.conf_print_liste('XLS', 'BRUTE');
                        },
                        typeEtabl: 'ALL',
                        profil: 'ALL'
                    },
                    {
                        label: 'Liste avec photo',
                        icon: 'pi pi-print',
                        command: () => {
                            this.conf_print_liste('PDF', 'PHOTO');
                        },
                        typeEtabl: 'ALL',
                        profil: 'ALL'
                    },
                    {
                        label: 'Badges',
                        icon: 'pi pi-print',
                        command: () => {
                            this.conf_print_selection('BADGE');
                        },
                        typeEtabl: 'ALL',
                        profil: 'ALL'
                    },
                ]
            }
        } else {
            if (this.gestGemsysService.get_userconnected().profilu == 'Administrateur' || this.gestGemsysService.get_userconnected().profilu == 'Chargé scolarité' || this.gestGemsysService.get_userconnected().profilu == 'DG' || this.gestGemsysService.get_userconnected().profilu == 'DGE') {
                liste = [
                    {
                        label: 'Liste formatée',
                        icon: 'pi pi-print',
                        command: () => {
                            this.conf_print_liste('PDF', 'LISTE');
                        },
                        typeEtabl: 'ALL',
                        profil: 'ALL'
                    },
                    {
                        label: 'Liste brute',
                        icon: 'pi pi-print',
                        command: () => {
                            this.conf_print_liste('XLS', 'BRUTE');
                        },
                        typeEtabl: 'ALL',
                        profil: 'ALL'
                    },
                    {
                        label: 'Liste avec photo',
                        icon: 'pi pi-print',
                        command: () => {
                            this.conf_print_liste('PDF', 'PHOTO');
                        },
                        typeEtabl: 'ALL',
                        profil: 'ALL'
                    },
                    {
                        label: 'Diplomes',
                        icon: 'pi pi-print',
                        command: () => {
                            this.conf_print_diplome('ORIGINAL');
                        },
                        typeEtabl: 'Université_Professionnel',
                        profil: 'Administrateur_DG_DGE_Chargé scolarité'
                    },
                    {
                        label: 'Duplicata diplome',
                        icon: 'pi pi-print',
                        command: () => {
                            this.conf_print_diplome('DUPLICATA');
                        },
                        typeEtabl: 'Université_Professionnel',
                        profil: 'Administrateur_DG_DGE_Chargé scolarité'
                    },
                    {
                        label: 'Badges',
                        icon: 'pi pi-print',
                        command: () => {
                            this.conf_print_selection('BADGE');
                        },
                        typeEtabl: 'ALL',
                        profil: 'ALL'
                    },
                    {
                        label: 'Bulettin notes PDF',
                        icon: 'pi pi-print',
                        command: () => {
                            this.conf_print_bulletin('PDF', 'BULLETIN');
                        },
                        typeEtabl: 'Université_Professionnel',
                        profil: 'Administrateur_DG_DGE_Chargé scolarité'
                    },
                    {
                        label: 'Bulettin notes XLS',
                        icon: 'pi pi-print',
                        command: () => {
                            this.conf_print_bulletin('XLS', 'BULLETIN');
                        },
                        typeEtabl: 'Université_Professionnel',
                        profil: 'Administrateur_DG_DGE_Chargé scolarité'
                    },
                    {
                        label: 'Relevés notes PDF',
                        icon: 'pi pi-print',
                        command: () => {
                            this.conf_print_releve_note('PDF', 'RELEVE_NOTE');
                        },
                        typeEtabl: 'Université_Professionnel',
                        profil: 'Administrateur_DG_DGE_Chargé scolarité'
                    },
                    {
                        label: 'Relevés notes XLS',
                        icon: 'pi pi-print',
                        command: () => {
                            this.conf_print_releve_note('XLS', 'RELEVE_NOTE');
                        },
                        typeEtabl: 'Université_Professionnel',
                        profil: 'Administrateur_DG_DGE_Chargé scolarité'
                    },
                    {
                        label: "Fiche d'inscription",
                        icon: 'pi pi-print',
                        command: () => {
                            this.conf_print_selection('FICHE_INSC');
                        },
                        typeEtabl: 'Université_Professionnel',
                        profil: 'Administrateur_DG_DGE_Chargé scolarité'
                    },
                    {
                        label: 'Résultats classe PDF',
                        icon: 'pi pi-print',
                        command: () => {
                            if (this.state.rcode_classe == "" || this.state.rcode_classe == "Tous") {
                                this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner une classe' });
                            } else {
                                this.gestGemsysService.generer_resultat_classe(this.state.rcode_classe, 'Tous', 'PDF');
                            }
                        },
                        typeEtabl: 'Pré-universitaire',
                        profil: 'Administrateur_DG_DGE_Chargé scolarité'
                    },
                    {
                        label: 'Résultats classe XLS',
                        icon: 'pi pi-print',
                        command: () => {
                            if (this.state.rcode_classe == "" || this.state.rcode_classe == "Tous") {
                                this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner une classe' });
                            } else {
                                this.gestGemsysService.generer_resultat_classe(this.state.rcode_classe, 'Tous', 'XLS');
                            }
                        },
                        typeEtabl: 'Pré-universitaire',
                        profil: 'Administrateur_DG_DGE_Chargé scolarité'
                    },

                    {
                        label: "Attestation (ré)inscription",
                        icon: 'pi pi-print',
                        command: () => {
                            this.conf_print_selection('ATTI');
                        },
                        typeEtabl: 'Université_Professionnel',
                        profil: 'Administrateur_DG_DGE_Chargé scolarité'
                    },
                    {
                        label: 'Attestation niveau',
                        icon: 'pi pi-print',
                        command: () => {
                            this.conf_print_attestation_niveau('RES_FINAL');
                        },
                        typeEtabl: 'Université_Professionnel',
                        profil: 'Administrateur_DG_DGE_Chargé scolarité'
                    },
                    {
                        label: 'Certificat scolarité',
                        icon: 'pi pi-print',
                        command: () => {
                            this.conf_print_selection('CSCOL');
                        },
                        typeEtabl: 'Université_Professionnel',
                        profil: 'Administrateur_Chargé scolarité'
                    },
                    {
                        label: 'Attestation fin de cycle',
                        icon: 'pi pi-print',
                        command: () => {
                            this.conf_print_attestation_cycle('RES_FINAL');
                        },
                        typeEtabl: 'Université_Professionnel',
                        profil: 'Administrateur_DG_DGE_Chargé scolarité'
                    },
                    {
                        label: 'Liste des diplomés XLS',
                        icon: 'pi pi-print',
                        command: () => {
                            this.conf_print_liste_diplome('PDF');
                        },
                        typeEtabl: 'Université',
                        profil: 'Administrateur_DG_DGE_Chargé scolarité'
                    }
                ]


            } else if (this.gestGemsysService.get_userconnected().profilu == 'Comptable') {
                liste = [
                    {
                        label: 'Liste formatée',
                        icon: 'pi pi-print',
                        command: () => {
                            this.conf_print_liste('PDF', 'LISTE');
                        },
                        typeEtabl: 'ALL',
                        profil: 'ALL'
                    },
                    {
                        label: 'Liste brute',
                        icon: 'pi pi-print',
                        command: () => {
                            this.conf_print_liste('XLS', 'BRUTE');
                        },
                        typeEtabl: 'ALL',
                        profil: 'ALL'
                    },
                    {
                        label: 'Liste avec photo',
                        icon: 'pi pi-print',
                        command: () => {
                            this.conf_print_liste('PDF', 'PHOTO');
                        },
                        typeEtabl: 'ALL',
                        profil: 'ALL'
                    },
                    {
                        label: 'Badges',
                        icon: 'pi pi-print',
                        command: () => {
                            this.conf_print_selection('BADGE');
                        },
                        typeEtabl: 'ALL',
                        profil: 'ALL'
                    },

                ]
            }
        }
        this.get_liste_classe();
        this.setState({ items: liste });
    }

    get_liste_data() {
        this.setState({ showindicator: true });
        this.gestGemsysService.get_liste_eleve_edition(this.state.rcode_enseign, this.state.rcode_classe, this.state.rsexe, this.state.rstac, this.state.rstaf, this.state.rval, this.state.rsituation, this.state.rgroupe).then(data => {
            this.setState({
                liste_data: data,
                showindicator: false
            });
        });
    }
    render() {
        let liste_grs = [
            { label: 'Groupe sanguin', value: 'Tous' },
            { label: 'A', value: 'A' },
            { label: 'B', value: 'B' },
            { label: 'AB', value: 'AB' },
            { label: 'AB+', value: 'AB+' },
            { label: 'AB-', value: 'AB-' },
            { label: 'B+', value: 'B+' },
            { label: 'A+', value: 'A+' },
            { label: 'B-', value: 'B-' },
            { label: 'O+', value: 'O+' },
            { label: 'O-', value: 'O-' }
        ];
        let Liste_sexe = [
            { label: 'Sexe', value: 'Tous' },
            { label: 'Masculin', value: 'M' },
            { label: 'Féminin', value: 'F' }
        ];
        let liste_situation = [
            { label: 'Situation maritale', value: 'Tous' },
            { label: 'Célibataire', value: 'Célibataire' },
            { label: 'Marié(e)', value: 'Marié(e)' },
            { label: 'Divorcé(e)', value: 'Divorcé(e)' },
            { label: 'Veuve', value: 'Veuve' }
        ];

        let liste_val = [
            { label: 'Statut inscription', value: 'Tous' },
            { label: 'Validé', value: '1' },
            { label: 'Non validé', value: '0' },
            { label: 'Annulé', value: '2' },
        ]

        return (
            <div className="p-fluid">
                <Toast ref={(el) => this.toast = el} />
                <div className="grid">
                    <div className="col-12">
                        <div className="card card-w-title">
                            <i className="pi pi-search"></i>
                            <span style={{ fontSize: 14, marginLeft: 10, color: '#999999' }}>Options de recherche</span>
                            <div className="grid">
                                <div className="col-12 md:col-3">
                                    <Dropdown options={[{ label: this.gestGemsysService.get_type_etabl() == 'Université' ? "Département" : "Enseignement", value: 'Tous' }].concat(this.state.liste_enseignement)} placeholder={this.gestGemsysService.get_type_etabl() == 'Université' ? "Département" : "Enseignement"} value={this.state.rcode_enseign} onChange={(e) => this.setState({ rcode_enseign: e.value }, () => {
                                        this.filterClasse(this.state.rcode_enseign);
                                    })} autoWidth={false} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' panelStyle={{ width: '400px' }} />
                                </div>
                                <div className="col-12 md:col-3">
                                    <Dropdown options={this.state.liste_classe} placeholder="Classe" value={this.state.rcode_classe} onChange={(e) => this.setState({ rcode_classe: e.value }, () => {
                                        this.get_liste_data();
                                    })} autoWidth={false} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' panelStyle={{ width: '400px' }} />
                                </div>
                                <div className="col-12 md:col-3">
                                    <Dropdown options={Liste_sexe} value={this.state.rsexe} onChange={(e) => this.setState({ rsexe: e.value })} autoWidth={false} />
                                </div>
                                <div className="col-12 md:col-3">
                                    <Dropdown options={liste_situation} placeholder="Sélectionner" value={this.state.rsituation} onChange={(e) => this.setState({ rsituation: e.value })} autoWidth={false} />
                                </div>
                                <div className="col-12 md:col-3">
                                    <Dropdown options={this.state.liste_statut_accademique} value={this.state.rstac} onChange={(e) => this.setState({ rstac: e.value })} autoWidth={false} />
                                </div>
                                <div className="col-12 md:col-3">
                                    <Dropdown options={this.state.liste_statut_financier} placeholder="Statut financier" value={this.state.rstaf} onChange={(e) => this.setState({ rstaf: e.value })} autoWidth={false} />
                                </div>
                                <div className="col-12 md:col-3">
                                    <Dropdown options={liste_grs} placeholder="Groupe sanguin" value={this.state.rgroupe} onChange={(e) => this.setState({ rgroupe: e.value })} autoWidth={false} />
                                </div>
                                <div className="col-12 md:col-3">
                                    <Dropdown options={liste_val} value={this.state.rval} onChange={(e) => this.setState({ rval: e.value })} autoWidth={false} />
                                </div>
                            </div>
                            <Panel style={{ marginTop: '1%' }}>
                                <div className="grid">
                                    <div className="col-12 md:col-3">
                                        <label>Titre du document</label>
                                    </div>
                                    <div className="col-12 md:col-3">
                                        <InputText id="titredoc" value={this.state.titredoc} onChange={(e) => this.setState({ titredoc: e.target.value })} />
                                    </div>
                                    <div className="col-12 md:col-3">
                                        <SplitButton label="Rechercher" onClick={(e) => this.get_liste_data()} icon="pi pi-search" model={this.state.items} />
                                    </div>
                                    <div className="col-12 md:col-2">
                                        {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                    </div>
                                </div>
                            </Panel>

                            <DataTable value={this.state.liste_data} style={{ minHeight: '600px' }}
                                paginator={true} rows={20} alwaysShowPaginator={true} paginatorPosition="top"
                                dataKey="ordre" className="p-datatable-gridlines" showGridlines responsiveLayout="scroll"
                                onSelectionChange={e => this.setState({ selectedListes: e.value })} selection={this.state.selectedListes} >
                                <Column selectionMode="multiple" style={{ width: '4%' }} />
                                <Column field="ordre" header="#" style={{ width: '4%' }} />
                                <Column body={this.photoTemplate.bind(this)} header="Photo" style={{ width: '5%' }} />
                                <Column field="matrEtud" header="Matricule" style={{ width: '10%' }} filter={true} filterMatchMode='contains' />
                                <Column field="nomEtud" header="Nom" style={{ width: '10%' }} filter={true} filterMatchMode='contains' />
                                <Column field="prenomEtu" header="Prénoms" style={{ width: '20%' }} filter={true} filterMatchMode='contains' />
                                {this.state.rcode_classe == 'Tous' ?
                                    <Column field="classe" header="Classe" style={{ width: '19%' }} filter={true} filterMatchMode='contains' />
                                    :
                                    null
                                }
                                {this.state.rcode_classe !== 'Tous' ?
                                    <Column field="dateNaiss" header="Date_Nais" style={{ width: '9%' }} filter={true} filterMatchMode='contains' />
                                    :
                                    null
                                }
                                {this.state.rcode_classe !== 'Tous' ?
                                    <Column field="sexe" header="Sexe" style={{ width: '5%' }} filter={true} filterMatchMode='contains' />
                                    :
                                    null
                                }
                                {this.state.rcode_classe !== 'Tous' ?
                                    <Column field="groupesanguin" header="GRS" style={{ width: '5%' }} filter={true} filterMatchMode='contains' />
                                    :
                                    null
                                }
                                <Column field="telEtud" header="Télephone" style={{ width: '9%' }} filter={true} filterMatchMode='contains' />
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}