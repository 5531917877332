import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import GestGemsysService from '../service/GestGemsysService';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';

export class FormSemestre extends Component {
    constructor() {
        super();
        this.state = {
            codeClasse: '',
            libClasse: '1',
            optClasse: '',
            niveau: '',
            bourse: 0,
            fraisBadge: 0,
            fraisInscription: 0,
            fraisReinscription: 0,
            fraisApae: 0,
            idPromo: null,
            codeEnseign: null,
            mtTranche1: '',
            mtTranche2: '',
            mtTranche3: '',
            idEtabl: 1,
            iduser: '1',
            selectedSemestre: null,
            semestres: [],
            liste_all_classe: [],
            promotions: null,
            departements: null,
            msg: '',
            modif: false,
            visible: false,
            showindicator: false,

        };
        this.edit_semestre = this.edit_semestre.bind(this);
        this.conf_delete_semestre = this.conf_delete_semestre.bind(this);
        this.enreg_semestre = this.enreg_semestre.bind(this);
        this.conf_delete_semestre = this.conf_delete_semestre.bind(this);
        this.gemsysService = new GestGemsysService();
    }

    componentDidMount() {
        this.gemsysService.getPromotion().then(data => this.setState({ promotions: data }));
        this.gemsysService.getEnseignement().then(data => this.setState({ departements: data }));
        this.gemsysService.getClasse().then(data => this.setState({ liste_all_classe: data }));
    }


    resultat(code, contenu) {
        if (code == '200') {
            this.toast.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annuler_semestre();
            this.componentDidMount();
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({ showindicator: false });
    }

    filterClasse(codeEnseign) {
        this.setState({ semestres: [] });
        if (this.state.liste_all_classe.length > 0) {
            let liste = this.state.liste_all_classe.filter(function (el) {
                return el.codeEnseign.codeEnseign == codeEnseign;
            });
            this.setState({ semestres: liste });
        }
    }


    edit_semestre(data) {
        if (data != null && data != undefined) {
            this.setState({ codeClasse: data.codeClasse });
            this.setState({ libClasse: data.libClasse });
            this.setState({ annee: data.annee });
            this.setState({ optClasse: data.optClasse });
            this.setState({ niveau: data.niveau });
            this.setState({ bourse: data.bourse });
            this.setState({ fraisBadge: data.fraisBadge });
            this.setState({ fraisInscription: data.fraisInscription });
            this.setState({ fraisReinscription: data.fraisReinscription });
            this.setState({ fraisApae: data.fraisApae });
            this.setState({ idPromo: data.idPromo.idPromo });
            this.setState({ codeEnseign: data.codeEnseign.codeEnseign });
            this.setState({ mtTranche1: data.mtTranche1 });
            this.setState({ mtTranche2: data.mtTranche2 });
            this.setState({ mtTranche3: data.mtTranche3 });
            this.setState({ selectedSemestre: data });
            this.setState({ modif: true });
            window.scrollTo(0, 0);
        }
    }
    annuler_semestre() {
        this.setState({ codeClasse: '' });
        this.setState({ libClasse: '' });
        this.setState({ optClasse: '' });
        this.setState({ niveau: '' });
        this.setState({ bourse: 0 });
        this.setState({ fraisBadge: 0 });
        this.setState({ fraisInscription: 0 });
        this.setState({ fraisReinscription: 0 });
        this.setState({ fraisApae: 0 });
        this.setState({ idPromo: null });
        this.setState({ codeEnseign: null });
        this.setState({ mtTranche1: '' });
        this.setState({ mtTranche2: '' });
        this.setState({ mtTranche3: '' });
        this.setState({ idEtabl: 1 });
        this.setState({ iduser: '1' });
        this.setState({ modif: false });
        this.forceUpdate();
    }

    enreg_semestre() {
        if (this.state.libClasse == "" || this.state.optClasse == "" || this.state.niveau == ""
            || this.state.bourse === "" || this.state.fraisBadge === "" || this.state.fraisApae === "" || this.state.fraisInscription === ""
            || this.state.fraisReinscription === "") {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else {
            this.setState({ showindicator: true });
            if (this.state.modif == false) {
                let semestre = {
                    codeClasse: '0',
                    libClasse: this.state.libClasse,
                    annee: this.gemsysService.get_rannee(),
                    optClasse: this.state.optClasse,
                    niveau: this.state.niveau,
                    bourse: this.state.bourse,
                    fraisBadge: this.state.fraisBadge,
                    fraisInscription: this.state.fraisInscription,
                    fraisReinscription: this.state.fraisReinscription,
                    fraisApae: this.state.fraisApae,
                    idPromo: { idPromo: this.state.idPromo },
                    codeEnseign: { codeEnseign: this.state.codeEnseign },
                    mtTranche1: this.state.mtTranche1,
                    mtTranche2: this.state.mtTranche2,
                    mtTranche3: this.state.mtTranche3
                }
                this.gemsysService.AddClasse(semestre).then(data => this.resultat(data.code, data.contenu));
            } else {
                let classe = this.state.selectedSemestre;
                classe.libClasse = this.state.libClasse;
                classe.optClasse = this.state.optClasse;
                classe.niveau = this.state.niveau;
                classe.bourse = this.state.bourse;
                classe.fraisBadge = this.state.fraisBadge;
                classe.fraisInscription = this.state.fraisInscription;
                classe.fraisReinscription = this.state.fraisReinscription;
                classe.idPromo = { idPromo: this.state.idPromo };
                classe.codeEnseign = { codeEnseign: this.state.codeEnseign };
                classe.mtTranche1 = this.state.mtTranche1;
                classe.mtTranche2 = this.state.mtTranche2;
                classe.mtTranche3 = this.state.mtTranche3;
                this.gemsysService.UpdateClasse(classe).then(data => this.resultat(data.code, data.contenu));
            }
        }
    }
    actionTemplate(rowData) {
        return <div className="grid" style={{ width: 90 }}>
            <div className="col"><Button onClick={() => this.edit_semestre(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
            <div className="col"><Button onClick={() => this.conf_delete_semestre(rowData)} className="p-button-danger" style={{ width: 25, height: 25, marginLeft: -20 }} icon="pi pi-times" /></div>
        </div>
    }
    delete_semestre() {
        if (this.state.selectedSemestre === null || this.state.selectedSemestre === undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.setState({ showindicator: true });
            this.gemsysService.DeleteClasse(this.state.selectedSemestre).then(data => this.resultat(data.code, data.contenu));
        }
    }
    conf_delete_semestre(data) {
        if (data != null && data != undefined) {
            this.setState({ selectedSemestre: data });
            this.setState({ msg: data.libClasse });
            this.setState({ visible: true });
        }
    }
    render() {
        let departements = null;
        if (this.state.departements != null && this.state.departements != undefined) {
            departements = this.state.departements.map(x => {
                return { value: x.codeEnseign, label: x.libEnseign }
            });
        }
        let promotions = null;
        if (this.state.promotions != null && this.state.promotions != undefined) {
            promotions = this.state.promotions.map(x => {
                return { value: x.idPromo, label: x.libPromo }
            });
        }
        let libelles = [
            { label: "1", value: "1" },
            { label: "2", value: "2" },
            { label: "3", value: "3" },
            { label: "4", value: "4" },
            { label: "5", value: "5" },
            { label: "6", value: "6" },
            { label: "7", value: "7" },
            { label: "8", value: "8" },
            { label: "9", value: "9" },
            { label: "10", value: "10" },
        ];
        let niveaux = [
            { label: "Licence 1", value: "Licence 1" },
            { label: "Licence 2", value: "Licence 2" },
            { label: "Licence 3", value: "Licence 3" },
            { label: "Licence 4", value: "Licence 4" },
            { label: "Master 1", value: "Master 1" },
            { label: "Master 2", value: "Master 2" }
        ];
        if (this.gemsysService.get_userconnected().idEtabl.typeEtabl == 'Professionnel') {
            libelles = [
                { label: "1ère année", value: "1ère année" },
                { label: "2ème année", value: "2ème année" },
                { label: "3ème année", value: "3ème année" },
            ];
            niveaux = [
                { label: "NA", value: "NA" }
            ];
        } else if (this.gemsysService.get_userconnected().idEtabl.typeEtabl == 'Pré-universitaire') {
            libelles = [
                { label: "Petite section", value: "Petite section" },
                { label: "Grande section", value: "Grande section" },
                { label: "Maternelle", value: "Maternelle" },
                { label: "CP1", value: "CP1" },
                { label: "CP2", value: "CP2" },
                { label: "CE1", value: "CE1" },
                { label: "CE2", value: "CE2" },
                { label: "CM1", value: "CM1" },
                { label: "CM2", value: "CM2" },
                { label: "7ème année", value: "7ème année" },
                { label: "8ème année", value: "8ème année" },
                { label: "9ème année", value: "9ème année" },
                { label: "10ème année", value: "10ème année" },
                { label: "11ème année", value: "11ème année" },
                { label: "12ème année", value: "12ème année" },
                { label: "Terminale", value: "Terminale" },
            ];
            niveaux = [];
        }
        const dialogFooter = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible: false });
                    this.delete_semestre();
                }} label="Oui" loading={this.state.showindicator} />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );
        return (
            <div className="p-fluid">
                <Toast ref={(el) => this.toast = el} />
                <Dialog header="Confirmation" visible={this.state.visible} style={{ width: '250px' }} modal={true} footer={dialogFooter} onHide={() => this.setState({ visible: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>

                <div className="card card-w-title">
                    <div className="grid">
                        <div className="col-12 md:col-2">
                            <label htmlFor="codeEnseign">{this.gemsysService.get_userconnected().idEtabl.typeEtabl == 'Université' ? 'Départements' : 'Fillières'} *</label>
                        </div>
                        <div className="col-12 md:col-4">
                            <Dropdown id="codeEnseign" filter filterBy='label' filterMatchMode='contains' options={departements} optionLabel='label' value={this.state.codeEnseign} onChange={e => this.setState({ codeEnseign: e.value }, () => {
                                this.filterClasse(this.state.codeEnseign);
                            })} autoWidth={false} />
                        </div>
                        <div className="col-12 md:col-2">
                            <label htmlFor="idPromo">Promotion *</label>
                        </div>
                        <div className="col-12 md:col-4">
                            <Dropdown filter filterBy='label' filterMatchMode='contains' options={promotions} optionLabel='label' value={this.state.idPromo} onChange={e => this.setState({ idPromo: e.value })} autoWidth={false} />
                        </div>
                        <div className="col-12 md:col-2">
                            <label htmlFor="libClasse">Libelle *</label>
                        </div>
                        <div className="col-12 md:col-4">
                            <Dropdown id="libClasse" editable options={libelles} label='label' value={this.state.libClasse} onChange={e => this.setState({ libClasse: e.value })} autoWidth={false} />
                        </div>
                        {this.gemsysService.get_userconnected().idEtabl.typeEtabl !== 'Pré-universitaire' ?
                            <>
                                <div className="col-12 md:col-2">
                                    <label htmlFor="niveau">Niveau *</label>
                                </div>
                                <div className="col-12 md:col-4">
                                    <Dropdown id="niveau" options={niveaux} label='label' value={this.state.niveau} onChange={e => this.setState({ niveau: e.value })} autoWidth={false} editable />
                                </div>
                                <div className="col-12 md:col-2">
                                    <label htmlFor="bourse">Bourse</label>
                                </div>
                                <div className="col-12 md:col-4">
                                    <InputNumber id="bourse" value={this.state.bourse} onChange={(e) => this.setState({ bourse: e.value })} />
                                </div>
                                <div className="col-12 md:col-2">
                                    <label htmlFor="optClasse">Programme *</label>
                                </div>
                                <div className="col-12 md:col-4">
                                    <InputText id="optClasse" value={this.state.optClasse} onChange={(e) => this.setState({ optClasse: e.target.value })} />
                                </div>

                            </>
                            : null}

                        {this.gemsysService.get_userconnected().idEtabl.typeEtabl == 'Pré-universitaire' ?
                            <>
                                <div className="col-12 md:col-2">
                                    <label htmlFor="optClasse">Option *</label>
                                </div>
                                <div className="col-12 md:col-4">
                                    <Dropdown id="optClasse" options={[
                                        { label: "SM", value: "SM" },
                                        { label: "SE", value: "SE" },
                                        { label: "SS", value: "SS" },
                                        { label: "EG", value: "EG" },
                                    ]} label='label' value={this.state.optClasse} onChange={e => this.setState({ optClasse: e.value })} autoWidth={false} editable />
                                </div>
                            </> :

                            <>
                                <div className="col-12 md:col-2">
                                    <label htmlFor="optClasse">Concentration *</label>
                                </div>
                                <div className="col-12 md:col-4">
                                    <InputText id="optClasse" value={this.state.optClasse} onChange={(e) => this.setState({ optClasse: e.target.value })} />
                                </div>
                            </>

                        }
                        <div className="col-12 md:col-2">
                            <label htmlFor="mtTranche1">Tranche 1 *</label>
                        </div>
                        <div className="col-12 md:col-4">
                            <InputNumber id="mtTranche1" value={this.state.mtTranche1} onChange={(e) => this.setState({ mtTranche1: e.value })} />
                        </div>
                        <div className="col-12 md:col-2">
                            <label htmlFor="mtTranche2">Tranche 2 *</label>
                        </div>
                        <div className="col-12 md:col-4">
                            <InputNumber id="mtTranche2" value={this.state.mtTranche2} onChange={(e) => this.setState({ mtTranche2: e.value })} />
                        </div>
                        <div className="col-12 md:col-2">
                            <label htmlFor="mtTranche3">Tranche 3 *</label>
                        </div>
                        <div className="col-12 md:col-4">
                            <InputNumber id="mtTranche3" value={this.state.mtTranche3} onChange={(e) => this.setState({ mtTranche3: e.value })} />
                        </div>
                        <div className="col-12 md:col-2">
                            <label htmlFor="fraisBadge">Frais badge *</label>
                        </div>
                        <div className="col-12 md:col-4">
                            <InputNumber id="fraisBadge" value={this.state.fraisBadge} onChange={(e) => this.setState({ fraisBadge: e.value })} />
                        </div>
                        <div className="col-12 md:col-2">
                            <label htmlFor="fraisInscription">Frais Inscr. *</label>
                        </div>
                        <div className="col-12 md:col-4">
                            <InputNumber id="fraisInscription" value={this.state.fraisInscription} onChange={(e) => this.setState({ fraisInscription: e.value })} />
                        </div>
                        <div className="col-12 md:col-2">
                            <label htmlFor="fraisReinscription">Frais réinscr. *</label>
                        </div>
                        <div className="col-12 md:col-4">
                            <InputNumber id="fraisReinscription" value={this.state.fraisReinscription} onChange={(e) => this.setState({ fraisReinscription: e.value })} />
                        </div>
                        {/* <div className="col-12 md:col-2">
                            <label htmlFor="fraisApae">Frais APAE *</label>
                        </div>
                        <div className="col-12 md:col-4">
                            <InputNumber id="fraisApae" value={this.state.fraisApae} onChange={(e) => this.setState({ fraisApae: e.value })} />
                        </div> */}
                    </div>
                    <center>
                        <div className="grid" style={{ width: 300, marginTop: '0%' }}>
                            <div className="col"><Button label="Annuler" icon="pi pi-ban" onClick={() => this.annuler_semestre()} /></div>
                            <div className="col"><Button loading={this.state.showindicator} label="Valider" icon="pi pi-check" onClick={() => this.enreg_semestre()} /></div>
                        </div>
                    </center>
                    <div className="grid">
                        <div className="col-12">
                            <DataTable value={this.state.semestres} selectionMode="single"
                                alwaysShowPaginator={false} onRowClick={e => this.setState({ selectedSemestre: e.data })}
                                onRowSelect={e => this.setState({ selectedSemestre: e.data })} style={{ marginTop: '10px' }}
                                responsive={true} selection={this.state.selectedSemestre}
                                className="p-datatable-gridlines" showGridlines responsiveLayout="scroll" paginator rows={10}>
                                <Column header="Actions" body={this.actionTemplate.bind(this)} style={{ width: '7%' }} />
                                {/* <Column field="codeClasse" header="Code" style={{ width: '10%' }} filter={true} filterMatchMode='contains'   sortable={true} /> */}
                                <Column field="libClasse" header="Classe" style={{ width: '10%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="optClasse" header="Option" style={{ width: '20%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="mtTranche1" body={(rowData, column) => {
                                    return <div>
                                        <span style={{ color: '#000' }}>{this.gemsysService.formaterValueSep(parseFloat(rowData.mtTranche1))}</span>
                                    </div>
                                }} header="Tranche1" style={{ width: '8%' }} sortable={true} />
                                <Column field="mtTranche2" body={(rowData, column) => {
                                    return <div>
                                        <span style={{ color: '#000' }}>{this.gemsysService.formaterValueSep(parseFloat(rowData.mtTranche2))}</span>
                                    </div>
                                }} header="Tranche2" style={{ width: '8%' }} sortable={true} />
                                <Column field="mtTranche3" body={(rowData, column) => {
                                    return <div>
                                        <span style={{ color: '#000' }}>{this.gemsysService.formaterValueSep(parseFloat(rowData.mtTranche3))}</span>
                                    </div>
                                }} header="Tranche3" style={{ width: '8%' }} sortable={true} />
                                <Column field="fraisInscription" body={(rowData, column) => {
                                    return <div>
                                        <span style={{ color: '#000' }}>{this.gemsysService.formaterValueSep(parseFloat(rowData.fraisInscription))}</span>
                                    </div>
                                }} header="Frais_ins" style={{ width: '8%' }} sortable={true} />
                                <Column field="fraisReinscription" body={(rowData, column) => {
                                    return <div>
                                        <span style={{ color: '#000' }}>{this.gemsysService.formaterValueSep(parseFloat(rowData.fraisReinscription))}</span>
                                    </div>
                                }} header="Frais_réins" style={{ width: '8%' }} sortable={true} />
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}