import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import GestGemsysService  from '../service/GestGemsysService';
import { InputText } from 'primereact/inputtext';
import moment from 'moment';
import './styles.css';
import { Panel } from 'primereact/panel';

export class FormSalle extends Component {
    constructor() {
        super();
        this.state = {
            codesalle: '',
            libsalle: '',
            effectif: 0,
            idEtabl: 1,
            selectedSalle: null,
            salles: null,
            msg: '',
            modif: false,
            visible: false,
            showindicator: false
        };
        this.edit_salle = this.edit_salle.bind(this);
        this.conf_delete_salle = this.conf_delete_salle.bind(this);
        this.enreg_salle = this.enreg_salle.bind(this);
        this.conf_delete_salle = this.conf_delete_salle.bind(this);
        this.gemsysService = new GestGemsysService();
    }
    componentDidMount() {
        this.gemsysService.getSalle().then(data => this.setState({ salles: data }));
    }
    resultat(code, contenu) {
        if (code == '200') {
            this.toast.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annuler_salle();
            this.componentDidMount();
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({showindicator: false});
    }
    edit_salle(data) {
        if (data != null && data != undefined) {
            this.setState({ codesalle: data.codesalle });
            this.setState({ libsalle: data.libsalle });
            this.setState({ effectif: data.effectif });
            this.setState({ selectedSalle: data });
            this.setState({ modif: true });
            window.scrollTo(0, 0);
        }
    }
    annuler_salle() {
        this.setState({ codesalle: '' });
        this.setState({ libsalle: '' });
        this.setState({ effectif: 0 });
        this.setState({ denregSalle: '' });
        this.setState({ idEtabl: '' });
        this.setState({ iduser: '' });
        this.setState({ modif: false });
        this.forceUpdate();
    }
    enreg_salle() {
        if (this.state.libsalle === "") {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else {
            this.setState({showindicator: true});
            if (this.state.modif === false) {                
                let salle = {
                    codesalle: '0',
                    libsalle: this.state.libsalle,
                    effectif: this.state.effectif
                };
                this.gemsysService.AddSalle(salle).then(data => this.resultat(data.code, data.contenu));
            } else {
                let salle=this.state.selectedSalle;
                salle.libsalle= this.state.libsalle;
                salle.effectif= this.state.effectif;
                this.gemsysService.UpdateSalle(salle).then(data => this.resultat(data.code, data.contenu));
            }
        }
    }
    actionTemplate(rowData) {
        return <div className="grid" style={{ width: 90 }}>
            <div className="col"><Button onClick={() => this.edit_salle(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
            <div className="col"><Button onClick={() => this.conf_delete_salle(rowData)} className="p-button-danger" style={{ width: 25, height: 25,marginLeft: -20 }} icon="pi pi-times" /></div>
        </div>
    }
    delete_salle() {
        if (this.state.selectedSalle === null || this.state.selectedSalle === undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.setState({showindicator: true});
            this.gemsysService.DeleteSalle(this.state.selectedSalle).then(data => this.resultat(data.code, data.contenu));
        }
    }
    conf_delete_salle(data) {
        if (data != null && data != undefined) {
            this.setState({ selectedSalle: data });
            this.setState({ msg: data.libsalle });
            this.setState({ visible: true });
        }
    }
    render() {
        const dialogFooter = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible: false });
                    this.delete_salle();
                }} label="Oui" loading={this.state.showindicator}  />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );
        return (
            <div className="p-fluid">
                <Toast ref={(el) => this.toast = el} />
                <Dialog header="Confirmation" visible={this.state.visible} style={{ width: '250px' }} modal={true} footer={dialogFooter} onHide={() => this.setState({ visible: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>
                <div className="grid">
                    <div className="col-12">
                        <div className="card card-w-title">
                            <div className="grid">
                                <div className="col-12 md:col-2">
                                    <label htmlFor="libsalle">Libellé *</label>
                                </div>
                                <div className="col-12 md:col-4">
                                    <InputText id="libsalle" value={this.state.libsalle} onChange={(e) => this.setState({ libsalle: e.target.value })} />
                                </div>
                                <div className="col-12 md:col-2">
                                    <label htmlFor="effectif">Effectif *</label>
                                </div>
                                <div className="col-12 md:col-4">
                                    <InputText id="effectif" value={this.state.effectif} onChange={(e) => this.setState({ effectif: e.target.value })} />
                                </div>
                            </div>
                            <center>
                                <div className="grid" style={{ width: 300, marginTop:'0%' }}>
                                    <div className="col"><Button label="Annuler" icon="pi pi-ban" onClick={() => this.annuler_salle()} /></div>
                                    <div className="col"><Button label="Valider" loading={this.state.showindicator}  icon="pi pi-check" onClick={() => this.enreg_salle()} /></div>
                                </div>
                            </center>
                            <DataTable value={this.state.salles} style={{ minHeight:'700px' }}  
                                selectionMode="single" alwaysShowPaginator={false} onRowClick={e => this.setState({ selectedSalle: e.data })}
                                onRowSelect={e => this.setState({ selectedSalle: e.data })}
                                selection={this.state.selectedSalle} dataKey="codesalle" 
                                className="p-datatable-gridlines" showGridlines responsiveLayout="scroll" paginator rows={20}>
                                <Column header="Actions" body={this.actionTemplate.bind(this)} style={{ width: '8%' }} />
                                <Column field="libsalle" header="Libellé" style={{ width: '46%' }} />
                                <Column field="effectif" header="Capacité" style={{ width: '46%' }} />
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}