import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import GestGemsysService from '../service/GestGemsysService';
export class Login extends Component {
    constructor() {
        super();
        this.state = {
            showindicator: false,
            login: '',
            pwd: '',
            msg: '',
        };
        this.appservice = new GestGemsysService();
    }

    handleKeyPress = e => {
        if (e.keyCode === 13) {
            this.identification();
        }
    }


 
    identification() { 
        if (this.state.login == '' || this.state.pwd == '') {
            this.setState({ msg: 'Veuilllez saisir le login et le mot de passe' });
        } else {
            this.setState({ showindicator: true });
            this.appservice.get_identification(this.state.login, this.state.pwd).then(data => {
                if (data !== null && data != undefined && data !== '') {
                    if (data.userconnected !== null && data.userconnected !== undefined) {
                        if (data.userconnected.idEtabl.etatEtabl !== 'Activé') {
                            this.setState({ showindicator: false });
                            this.setState({ msg: "Votre abonnement a expiré, merci de contacter votre fournisseur " });
                            return;
                        }
                        if (data.userconnected.activer === 'Oui') {
                            localStorage.setItem('userconnected', JSON.stringify(data.userconnected));
                            if (data.listeDossier !== null && data.listeDossier != undefined && data.listeDossier !== '') {
                                let liste = data.listeDossier.map(x => {
                                    return { value: x.annee, label: x.annee }
                                });
                                localStorage.setItem('dossiers', JSON.stringify(liste));
                                localStorage.setItem('rannee', liste[liste.length - 1].value);
                            }
                            if (data.listeEtabl !== null && data.listeEtabl != undefined && data.listeEtabl !== '') {
                                let liste = data.listeEtabl.map(x => {
                                    if (x.idEtabl.typeEtabl == 'Université') {
                                        return {
                                            name: "darkgray",
                                            color: "#343a40",
                                            logoColor: "white",
                                            componentTheme: "blue",
                                            idEtabl: x.idEtabl,
                                            idUser: x.idUser
                                        }
                                    } else if (x.idEtabl.typeEtabl == 'Pré-universitaire') {
                                        return {
                                            name: "blue",
                                            color: "#1976D2",
                                            logoColor: "white",
                                            componentTheme: "blue",
                                            idEtabl: x.idEtabl,
                                            idUser: x.idUser
                                        }
                                    } else {
                                        return {
                                            name: "green",
                                            color: "#0097a7",
                                            logoColor: "white",
                                            componentTheme: "teal",
                                            idEtabl: x.idEtabl,
                                            idUser: x.idUser
                                        }
                                    }
                                });
                                localStorage.setItem('etablissements', JSON.stringify(liste));
                            }
                            this.setState({ showindicator: false });
                            window.location = "#/dashboard/0";
                        } else {
                            this.setState({ showindicator: false });
                            this.setState({ msg: "Vous n'etes pas autorisés à vous connecter, merci de contacter l'administrateur " });
                        }
                    } else {
                        this.setState({ showindicator: false });
                        this.setState({ msg: 'Login ou mot de passe incorrects' });
                    }
                } else {
                    this.setState({ showindicator: false });
                    this.setState({ msg: 'Login ou mot de passe incorrects' });
                }
            });
        }
    }


    render() {
        return (
            <div className="login-body">
                <div className="login-wrapper">
                    <div className="login-panel" >
                        <img src="assets/layout/images/logo_gemsys.png" className="logo" alt="diamond-layout" style={{ height: 90, width: 140 }} />
                        <div className="login-form" style={{ marginTop: 80 }}>
                            <h2>Connexion</h2>
                            <h4>Pour vous connecter utiliser vos accès</h4>
                            <InputText placeholder="Votre login" onKeyDown={this.handleKeyPress} onChange={(e) => this.setState({ login: e.target.value })} value={this.state.login} style={{ width: '90%' }} />
                            <Password feedback={false} placeholder="Votre mot de passe" onKeyDown={this.handleKeyPress} onChange={(e) => this.setState({ pwd: e.target.value })} value={this.state.pwd} style={{ width: '90%' }} />
                            <Button label="Connexion" icon="pi pi-sign-in" type="button" style={{ width: 150 }} onClick={(e) => this.identification()}></Button>
                            <br />
                            {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px', marginTop: '10px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                            <br />
                            <label style={{ color: 'red', marginTop: 10, fontWeight: 'bold' }} >{this.state.msg}</label>
                        </div>
                    </div>
                    <div className="login-image">
                        <div className="login-image-content">
                            <h1 style={{ color: 'yellow', fontWeight: 'bold', marginTop: '50%' }}></h1>
                            <h3>
                            </h3>
                        </div>
                        <div className="image-footer">
                            <p style={{ color: '#fff' }}>Nous suivre sur les réseaux sociaux</p>
                            <div className="icons">
                                <i className="pi pi-facebook"></i>
                                <i className="pi pi-twitter"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}